import driver from "./.driver.js";

export const login = (email, password) =>
  driver.post(`auth`, { email, password });

export const requestPassword = (email) =>
  driver.post(`auth/forgot-password`, { email });

export const logout = () => driver.post(`auth/logout`);
export const getUserProfile = () => driver.get("auth");
