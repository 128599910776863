import React from "react";
import EntityList from "../../../components/entity/EntityList";
import { contractEntity } from "../../../store/ducks/entity.duck";

export function ContractsList() {
  return (
    <EntityList
      disableClick
      actionsComponent={null}
      actions={contractEntity.actions}
      select={(state) => state.contracts}
      name="contract"
    />
  );
}
