import React, { useEffect, useState } from "react";
import EntityList from "../../../components/entity/EntityList";
import { customersEntity } from "../../../store/ducks/entity.duck";
import CustomerForm from "./CustomerForm";
import CustomerCard from "./CustomerCard";
import { getDocument } from "../../../service/contract";
import { LinearProgress } from "@material-ui/core";
import WordEditor from "../../../partials/widgets/WordEditor/WordEditor";
import { useSelector } from "react-redux";
import { humanDate } from "../../../helper/date";

export function CustomersList(props) {
  return (
    <EntityList
      actions={customersEntity.actions}
      select={(state) => state.customers}
      clickLink="/customers/:id"
      name="customers"
    />
  );
}

export function CustomerCreate() {
  return (
    <CustomerForm
      actions={customersEntity.actions}
      actionTypes={customersEntity.types}
      select={(state) => state.customers}
      name="customers"
    />
  );
}

export function CustomerEdit(props) {
  return (
    <CustomerForm
      id={props.match.params.id}
      actions={customersEntity.actions}
      actionTypes={customersEntity.types}
      select={(state) => state.customers}
      name="customers"
    />
  );
}

export function CustomerShow(props) {
  return <CustomerCard id={props.match.params.id} />;
}

export function CustomerContract(props) {
  const { contract_id } = props.match.params;
  const contract = useSelector((state) =>
    state.contracts.rows.find((c) => c.id === contract_id),
  );
  // const user_role = useSelector(state => state.auth.user.role_code)
  const [documentId, setDocumentId] = useState(null);
  const [isPending, setPending] = useState(true);

  useEffect(() => {
    getDocument(contract_id).then(({ data }) => {
      setDocumentId(data.document.id);
      setPending(false);
    });
  }, [contract_id]);

  // console.log(user_role)
  return isPending ? (
    <LinearProgress />
  ) : document === null ? (
    "404"
  ) : (
    <WordEditor
      documentId={documentId}
      // isReadOnly={user_role !== 'chief'}
      title={`Договор #${contract.number} от ${humanDate(contract.date_start)}`}
    />
  );
}
