import driver from "./.driver.js";
import { stringify } from "../helper/query-stringifier";
import { isEmpty } from "../helper/object.js";

export const updateEntity = (entityName, payload, pk = null) =>
  driver.post(`${entityName}/update/${pk != null ? `${pk}` : ""}`, payload);

export const createEntity = (entityName, payload) =>
  driver.post(`${entityName}/create`, payload);

export const singleEntity = (entityName, primaryKey, query) =>
  driver.get(
    `${entityName}/show/${primaryKey ?? ""}` +
      (query ? `?${stringify(query)}` : ""),
  );

export const deleteEntity = (entityName, pk, params = {}) =>
  driver.post(`${entityName}/delete/${pk}?${stringify(params)}`);

export const searchEntities = (entityName, query, rule) =>
  driver.get(`${entityName}/search?${stringify({ query, rule })}`);

export const restore = (entityName, ids) =>
  driver.post(`${entityName}/restore?${stringify({ ids })}`);

export const excelExport = (entityName) => driver.open(`/${entityName}/export`);

export const readEntities = (entityName, settings, simple, filter) => {
  const _settings = {
    ...(settings || {}),
    ...(simple ? { simple } : {}),
  };
  let query = "";
  if (!isEmpty(_settings)) {
    query += stringify(_settings);
  }
  if (!isEmpty(filter)) {
    query += stringify({ raw_filter: filter });
  }

  return driver.get(`${entityName}${query ? `?${query}` : ""}`);
};
