import React from "react";
import {
  Portlet,
  PortletBody,
  PortletFooter,
  PortletHeader,
} from "../../../partials/content/Portlet";
import {
  Form,
  Col,
  Row,
  Button,
  Modal,
  Spinner,
  Alert,
  InputGroup,
} from "react-bootstrap";
import clsx from "clsx";
import SelectEntity from "../../../components/entity/SelectEntity";
import { customersEntity } from "../../../store/ducks/entity.duck";
import { humanDate, humanDateParse } from "../../../helper/date";
import reportService from "../../../service/report";
import { isEmpty } from "../../../helper/object";
import { handleError } from "../../../service/.errors";
import { priceFormatter } from "../../../helper/number";
import ReactDatePicker from "react-datepicker";
import { connect } from "react-redux";
import { RangePicker } from "../../../partials/widgets/RangePicker";
class DocumentRegistryReport extends React.Component {
  constructor(context, props) {
    super(context, props);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.openReport = this.openReport.bind(this);
    this.downloadReport = this.downloadReport.bind(this);
  }

  handleChangeDate({ startDate, endDate, key } = {}) {
    this.setState({
      fields: {
        ...this.state.fields,
        date_start: startDate,
        date_end: endDate,
      },
    });
  }

  state = {
    errors: {},
    showModal: false,
    fields: {
      date_start: undefined,
      date_end: undefined,
      customer_id: "",
      acceptor: "",
      assigneer: `${this.props.user.name.slice(0, 1)}. ${
        this.props.user.lastname
      }`,
      include_trn: true,
      number: "",
      check_numbers: "",
      date: new Date(),
    },
    records: [],
    isPending: false,
    isPendingExport: false,
  };

  set(fields) {
    this.setState({
      fields: { ...this.state.fields, ...fields },
    });
  }

  error(newErrors) {
    this.setState({
      errors: {
        ...this.state.errors,
        ...newErrors,
      },
    });
  }

  async openReport() {
    const errors = this.validate();
    this.setState({ errors });
    if (isEmpty(errors)) {
      this.setState({ isPending: true, showModal: true });
      try {
        const {
          data: { records },
        } = await reportService.loadDocumentRegistry(this.state.fields);
        // console.log(records)
        this.setState({ records });
      } catch (err) {
        handleError(err);
      }
      this.setState({ isPending: false });
    } else {
    }
  }
  validate() {
    const errors = {};
    const { date_start, date_end, customer_id, check_numbers } =
      this.state.fields;
    if ((!date_start || !date_end) && !check_numbers) {
      errors.date_interval =
        "Заполните интервал по датам или номера счетов для выгрузки";
    }
    if (!customer_id) {
      errors.customer = "Заполните заказчика";
    }
    return errors;
  }

  downloadReport() {
    const errors = this.validate();
    this.setState({ errors });
    if (isEmpty(errors)) {
      reportService.downloadDocumentRegistry(this.state.fields);
    }
  }

  render() {
    // console.log(this.props)
    const { errors, fields, isPending, isPendingExport } = this.state;
    return (
      <Portlet>
        <PortletHeader title="Данные по реестру документов" />
        <PortletBody>
          <Form
            noValidate
            onSubmit={this.handleSubmit}
            className="kt-form kt-form--label-right"
          >
            <Row>
              <Col md="3" />
              <Col md="9">
                <h5>Фильр данных:</h5>
              </Col>
            </Row>
            <br />
            <Form.Group as={Row}>
              <Form.Label as={Col} xl="3" lg="3" className="col-form-label">
                Заказчик <span className="kt-font-danger">*</span>
              </Form.Label>
              <Col lg="6" xl="6">
                <SelectEntity
                  errorText={errors.customer}
                  search={(val) =>
                    customersEntity.actions.search({ "org.name": val })
                  }
                  onChange={({ id }) =>
                    this.setState({
                      fields: { ...this.state.fields, customer_id: id },
                    })
                  }
                  noOptionsMessage="Заказчиков не найдено"
                  value={fields.customer_id}
                  getOptionLabel={({ id, short_name, name }) =>
                    `№${id} ${short_name || name}`
                  }
                  getOptionValue={({ id }) => id}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label as={Col} xl="3" lg="3" className="col-form-label">
                Интервал по дате счёта
              </Form.Label>
              <Col lg="6" xl="6">
                <RangePicker
                  range={{
                    startDate: fields.date_start,
                    endDate: fields.date_end,
                    key: "check_dateRange",
                  }}
                  onChange={(range) => this.handleChangeDate(range)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label as={Col} xl="3" lg="3" className="col-form-label">
                Номера счетов
              </Form.Label>
              <Col lg="6" xl="6">
                <Form.Control
                  type={this.props.type}
                  isInvalid={errors.date_interval}
                  onChange={(ev) =>
                    this.set({ [ev.target.name]: ev.target.value })
                  }
                  value={fields.date_interval}
                  name="check_numbers"
                />
                <Form.Text style={{ color: "gray" }}>
                  например: 1515, 1524-1528
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.date_interval}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <hr />
            <Row>
              <Col md="3" />
              <Col md="9">
                <h5>Заполнение документа:</h5>
              </Col>
            </Row>
            <br />
            <Form.Group as={Row}>
              <Form.Label as={Col} xl="3" lg="3" className="col-form-label">
                Номер реестра
              </Form.Label>
              <Col lg="6" xl="6">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>№</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type={this.props.type}
                    isInvalid={errors.number}
                    onChange={(ev) =>
                      this.set({ [ev.target.name]: ev.target.value })
                    }
                    value={fields.number}
                    name="number"
                  />
                </InputGroup>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label as={Col} xl="3" lg="3" className="col-form-label">
                Дата передачи
              </Form.Label>
              <Col lg="6" xl="6">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <i className="la la-calendar" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <ReactDatePicker
                    isClearable
                    placeholderText="дата передачи"
                    selected={fields.date}
                    className={clsx("form-control", {
                      "is-invalid": errors.hasOwnProperty("date"),
                    })}
                    dateFormat="dd.MM.yyyy"
                    onChange={(date) => this.set({ date })}
                  />
                </InputGroup>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label as={Col} xl="3" lg="3" className="col-form-label">
                Передал
              </Form.Label>
              <Col lg="6" xl="6">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <i className="la la-arrow-circle-o-right" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type={this.props.type}
                    isInvalid={errors.assigneer}
                    onChange={(ev) =>
                      this.set({ [ev.target.name]: ev.target.value })
                    }
                    value={fields.assigneer}
                    name="assigneer"
                  />
                </InputGroup>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label as={Col} xl="3" lg="3" className="col-form-label">
                Принял
              </Form.Label>
              <Col lg="6" xl="6">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <i className="la la-arrow-circle-o-left" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type={this.props.type}
                    isInvalid={errors.acceptor}
                    onChange={(ev) =>
                      this.set({ [ev.target.name]: ev.target.value })
                    }
                    value={fields.acceptor}
                    name="acceptor"
                  />
                </InputGroup>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col md="0" lg="3" />
              <Col lg="6" xl="6">
                <Form.Check
                  id="include_trn"
                  name="include_trn"
                  onChange={(ev) =>
                    this.set({ [ev.target.name]: ev.target.checked })
                  }
                  checked={fields.include_trn}
                  label="Добавить транспортные накладные"
                />
              </Col>
            </Form.Group>
          </Form>
          <Modal
            show={this.state.showModal}
            size="lg"
            onHide={() => this.setState({ showModal: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>Предпросмотр реестра документов</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded kt-datatable--scroll">
                {!this.state.isPending && !this.state.records.length && (
                  <Alert variant="secondary">
                    <b>по заданным фильтрам не найдено ни одного документа</b>
                  </Alert>
                )}
                <table
                  className="kt-datatable__table "
                  style={{ display: "table" }}
                >
                  <thead className="kt-datatable__head">
                    <tr className="kt-datatable__row">
                      <th />
                      {["Вид документа", "Номер", "Дата", "Сумма"].map(
                        (el, i) => (
                          <th className="kt-datatable__cell" key={i}>
                            {el}
                          </th>
                        ),
                      )}
                    </tr>
                  </thead>
                  <tbody className="kt-datatable__body">
                    {this.state.isPending && (
                      <tr>
                        <td colSpan="5">
                          <Spinner animation="border" role="status">
                            <span className="sr-only">Загрузка...</span>
                          </Spinner>
                        </td>
                      </tr>
                    )}
                    {!this.state.isPending &&
                      this.state.records.map((record) => (
                        <React.Fragment key={record.id}>
                          <tr className="kt-datatable__row">
                            <td />
                            <td className="kt-datatable__cell">Счёт</td>
                            <td className="kt-datatable__cell">
                              {record.invoice_number}
                            </td>
                            <td className="kt-datatable__cell">
                              {humanDateParse(record.invoice_date)}
                            </td>
                            <td className="kt-datatable__cell">
                              {priceFormatter.format(record.amount)}
                            </td>
                          </tr>
                          {record.waybills?.map((wb) => (
                            <tr className="kt-datatable__row" key={wb.id}>
                              <td />
                              <td className="kt-datatable__cell">
                                Транспортная накладная
                              </td>
                              <td className="kt-datatable__cell">
                                {wb.number}
                              </td>
                              <td className="kt-datatable__cell">
                                {humanDateParse(wb.date)}
                              </td>
                              <td className="kt-datatable__cell" />
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
            </Modal.Body>
          </Modal>
        </PortletBody>
        <PortletFooter>
          <Row>
            <Col md="3" />
            <Col md="6">
              <Button
                variant="primary"
                disabled={isPending}
                onClick={this.openReport}
                className={clsx("mr-4", {
                  "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                    isPending,
                })}
              >
                Предпросмотр
              </Button>
              <Button
                variant="success"
                disabled={isPending || isPendingExport}
                onClick={this.downloadReport}
                className={clsx({
                  "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                    isPendingExport,
                })}
              >
                Экспорт в Excel
              </Button>
            </Col>
          </Row>
        </PortletFooter>
      </Portlet>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});
export default connect(mapStateToProps)(DocumentRegistryReport);
