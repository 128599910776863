/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider, connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import { Routes } from "./app/router/Routes";
import { actions as auth } from "./app/store/ducks/auth.duck";
import { I18nProvider, LayoutSplashScreen, ThemeProvider } from "./_metronic";
// import { initAppAction } from "./app/store/ducks/init.duck";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const mapDispatchToProps = (dispatch) => ({
  requestUser: () => dispatch(auth.requestUser()),
  // init: () => dispatch(initAppAction())
});

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

class App extends React.Component {
  async initApp() {
    await this.props.requestUser();
    const { user } = this.props;
    // if (user) {
    // await this.props.init();
    // }
    this.setState({ isLoaded: true });
  }

  componentDidMount() {
    this.initApp();
  }

  state = { isLoaded: false };

  render() {
    if (!this.state.isLoaded) {
      return <LayoutSplashScreen />;
    }
    const { store, basename } = this.props;
    return (
      /* Provide Redux store */
      <Provider store={store}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <LastLocationProvider>
              {/* Provide Metronic theme overrides. */}
              <ThemeProvider>
                {/* Provide `react-intl` context synchronized with Redux state.  */}
                <I18nProvider>
                  {/* Render routes with provided `Layout`. */}
                  <Routes />
                </I18nProvider>
              </ThemeProvider>
            </LastLocationProvider>
          </BrowserRouter>
        </React.Suspense>
      </Provider>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
