import { L10n, setCulture } from "@syncfusion/ej2-base";
import { ru } from "@syncfusion/ej2-locale/src/ru.json";

L10n.load({
  ru: {
    ...ru,
    DocumentEditor: {
      ...ru["DocumentEditor"],
      "Different First Page": "Отключить колонтитул на первой странице",
    },
    documenteditor: {
      ...ru["documenteditor"],
      Find: "Поиск",
      Replace: "Замена",
      Top: "Верх",
      Bottom: "Низ",
      Cell: "ячейка",
      "Measure in": "Измерение",
      Alignment: "Отбивка",
      None: "Нет",
      Center: "По центру",
      Cut: "Вырезать",
      Copy: "Скопировать",
      Italic: "Курсив",
      General: "Основные настройки",
      "Custom Size": "Пользовательский размер",
      Paper: "Лист",
      Layout: "Шаблон",
      Header: "верхний колонтитул",
      Footer: "нижний колонтитул",
      Orientation: "ориентация",
      Landscape: "Горизонтальная",
      Portrait: "Вертикальная",
      Letter: "Символ",
      "All Done": "Готово",
      "We replaced all": "Заменено",
      instances: "совпадения",
      of: "с",
      with: "на",
      Apply: "применить",
      Margin: "Отступы",
      Left: "Cлева",
      Right: "Справа",
      Justify: "Выравнять",
      Direction: "Направление",
      "Table direction": "Направление в таблице",
      Indentation: "Отступы строки",
      "Before text": "Слева",
      Special: "Красная строка",
      "First line": "Первая строка",
      Hanging: "Все строки",
      "After text": "После текста",
      By: "Отступ",
      Spacing: "Отступы обзаца",
      Before: "Сверху абзаца",
      After: "Снизу абзаца",
      "Contextual Spacing": "Не добавлять отступы между абзацами одного стиля",
      "Different First Page": "Отключить колонтитул на первой странице",
      Exactly: "Только в выделенном",
      Multiple: "Везде",
      "At least": "Как минимум, в выделенном",
    },
    colorpicker: {
      ...ru["spreadsheet"],
      Apply: "применить",
      Cancel: "отменить",
      ModeSwitcher: "Сетка цветов",
      "No color": "прозрачный",
    },
    documenteditorcontainer: {
      ...ru["documenteditorcontainer"],
      Redo: "Вернуть",
      Image: "Картинка",
      Table: "Таблица",
      Link: "Гиперссылка",
      Break: "Обрыв <div class='e-de-text-wrap'>страницы</div>",
      Header: "Верхний <div class='e-de-text-wrap'>колонтитул</div>",
      Footer: "Нижний <div class='e-de-text-wrap'>колонтитул</div>",
      Find: "Поиск",
      Replace: "Замена",
      "We replaced all": "Заменено",
      Apply: "применить",
      "Insert columns to the left": "Столбец слева",
      "Insert columns to the right": "Столбец справа",
      "Insert rows above": "Строка сверху",
      "Insert rows below": "Строка снизу",

      "Cell Margin": "Отступ ячеек",
      Top: "верх",
      Bottom: "низ",
      Left: "лево",
      Right: "право",
      "Align Text": "выровнять",
      "Align top": "сверху",
      "Align bottom": "снизу",
      "Align center": "по центру",

      "Number of heading or outline levels to be shown in table of contents":
        "Количество уровней заголовка или структуры, которые должны быть показаны в оглавлении.",
      "Show page numbers": "Показывать номера страниц",
      "Show page numbers in table of contents":
        "Показывать номера страниц в оглавлении.",
      "Right align page numbers": "Выровнять номера страниц по правому краю",
      "Right align page numbers in table of contents":
        "Выровнять номера страниц по правому краю в оглавлении.",
      "Use hyperlinks": "Использовать гиперссылки",
      "Use hyperlinks instead of page numbers":
        "Использовать гиперссылки вместо номеров страниц.",
      "Different First Page": "Отключить колонтитул на первой странице",

      Bullets: "список",
    },
  },
});
setCulture("ru");
