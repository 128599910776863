import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  Form,
  Alert,
  Row,
  Col,
  InputGroup,
  Table,
} from "react-bootstrap";
import {
  PortletHeaderToolbar,
  Portlet,
  PortletHeader,
  PortletBody,
} from "../content/Portlet";
import { waybillEntity } from "../../store/ducks/entity.duck";
import clsx from "clsx";
import ConfirmButton from "../content/ConfirmButton";
import ReactDatePicker from "react-datepicker";
import { humanDate } from "../../helper/date";

const mapStateToProps = (
  { waybills: { isPending, isSuccess, errors } },
  props,
) => ({
  isPending,
  isSuccess,
  errors,
});
const mapDispatchToProps = (dispatch) => ({
  createWaybill: (fields) => dispatch(waybillEntity.actions.create(fields)),
  updateWaybill: (fields, id) =>
    dispatch(waybillEntity.actions.update(fields, id)),
  removeWaybill: (id) => dispatch(waybillEntity.actions.remove(id)),
});

class WaybillList extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changed = this.changed.bind(this);
  }

  static propTypes = {
    createWaybill: PropTypes.func.isRequired,
    removeWaybill: PropTypes.func.isRequired,
    updateWaybill: PropTypes.func.isRequired,
    requestId: PropTypes.string,
    isPending: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    errors: PropTypes.shape(),
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        number: PropTypes.string,
        date: PropTypes.instanceOf(Date),
      }),
    ),
  };

  static defaultProps = {
    errors: {},
  };

  initialFields = {
    number: "",
    date: "",
    // date_receive: new Date(),
    is_payed: false,
    request_id: this.props.requestId,
  };

  state = {
    showModal: false,
    id: null,
    errors: {},
    fields: { ...this.initialFields },
    additionalList: [],
  };

  get isCreate() {
    return this.state.id === null;
  }

  UNSAFE_componentWillReceiveProps({ errors }) {
    if (errors !== this.state.errors) {
      this.setState({ errors });
    }
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  getWaybillById(queryId) {
    return this.props.list.find(({ id }) => id === queryId);
  }

  handleShow(id = null, ev) {
    if (ev) ev.preventDefault();
    if (!ev?.target?.closest("button")) {
      const fields = {};
      if (id) {
        const record = this.getWaybillById(id);
        Object.keys(this.initialFields).forEach((field) => {
          fields[field] = record[field];
        });
      }
      this.setState({
        id,
        showModal: true,
        fields: id ? fields : { ...this.initialFields },
        additionalList: [],
      });
    }
  }

  async handleSubmit(ev) {
    ev.preventDefault();
    if (this.isCreate) {
      await Promise.all([
        this.props.createWaybill(this.state.fields),
        ...this.state.additionalList.map((el) => {
          return this.props.createWaybill(el);
        }),
      ]);
    } else {
      await this.props.updateWaybill(this.state.fields, this.state.id);
    }
    if (this.props.isSuccess) {
      this.props.onUpdate();
      this.handleClose();
    }
  }

  changed({ target: { name, value } }) {
    const { errors, fields } = this.state;
    delete errors[name];
    this.setState({
      errors,
      fields: { ...fields, [name]: value },
    });
  }

  renderForm(fields, changed, i) {
    const { errors } = this.props;
    return (
      <Form.Group key={i} as={Row}>
        <Col md="6">
          <Form.Label>Номер</Form.Label>
          <Form.Control
            name="number"
            isInvalid={errors.hasOwnProperty("number")}
            onChange={changed}
            value={fields["number"]}
          />
          <Form.Control.Feedback type="invalid">
            {errors["number"]}
          </Form.Control.Feedback>
        </Col>
        <Col md="6">
          <Form.Label>Дата накладной</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <i className="la la-calendar" />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <ReactDatePicker
              isClearable
              placeholderText="дата накладной"
              selected={fields["date"]}
              name="date"
              className={clsx("form-control", {
                "is-invalid": errors.hasOwnProperty("date"),
              })}
              dateFormat="dd.MM.yyyy"
              onChange={(value) =>
                changed({
                  target: { name: "date", value },
                })
              }
            />
            <Form.Control hidden isInvalid={errors["date"]} />
            <Form.Control.Feedback type="invalid">
              {errors["date"]}
            </Form.Control.Feedback>
          </InputGroup>
        </Col>
      </Form.Group>
    );
  }

  changedAdditional({ target: { name, value } }, elIndex) {
    this.setState({
      additionalList: this.state.additionalList.map((el, i) => {
        if (i !== elIndex) return el;
        return { ...el, [name]: value };
      }),
    });
  }

  render() {
    const { list, isPending, removeWaybill } = this.props;
    const { additionalList } = this.state;
    return (
      <>
        <Portlet fluidHeight>
          <PortletHeader
            title="Транспортные накладные"
            toolbar={
              <PortletHeaderToolbar>
                <Button
                  size="sm"
                  onClick={() => this.handleShow()}
                  variant="outline-success"
                >
                  Добавить трн
                </Button>
              </PortletHeaderToolbar>
            }
          />
          <PortletBody scrollable>
            <div className="kt-widget4">
              {list.length === 0 ? (
                <Alert variant="secondary">Не найдено ни одной накладной</Alert>
              ) : (
                <Table responsive striped hover>
                  <thead>
                    <tr>
                      <th>номер</th>
                      <th>дата</th>
                      {/* <th>дата получения</th> */}
                      {/* <th>срок оплаты</th> */}
                      {/* <th>оплачена</th> */}
                      <th width="1%" />
                    </tr>
                  </thead>
                  <tbody>
                    {list.map(({ id, number, date }) => (
                      <tr key={id} onClick={(ev) => this.handleShow(id, ev)}>
                        <td>{number}</td>
                        <td>{humanDate(date)}</td>
                        {/* <td>{boolLabel(is_payed)}</td> */}
                        <td>
                          <ConfirmButton
                            size="sm"
                            action={async () => {
                              await removeWaybill(id);
                              this.props.onUpdate();
                            }}
                            variant="label-danger"
                          >
                            удалить
                          </ConfirmButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>
          </PortletBody>
        </Portlet>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Данные накладной</Modal.Title>
          </Modal.Header>
          <Form noValidate onSubmit={this.handleSubmit}>
            <Modal.Body>
              {this.renderForm(this.state.fields, this.changed)}
              {this.isCreate &&
                additionalList.map((el, i) =>
                  this.renderForm(el, (ev) => this.changedAdditional(ev, i), i),
                )}
              {this.isCreate && (
                <Button
                  variant="success"
                  onClick={() =>
                    this.setState({
                      additionalList: [
                        ...this.state.additionalList,
                        { ...this.initialFields },
                      ],
                    })
                  }
                >
                  <i className="fa fa-plus" /> еще накладная
                </Button>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                отменить
              </Button>
              <Button
                variant="primary"
                disabled={isPending}
                onClick={this.handleSubmit}
                className={clsx({
                  "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                    isPending,
                })}
              >
                Сохранить
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WaybillList);
