import React, { useState, useCallback } from "react";
import { Button, Form, Modal, Row, Col, InputGroup } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { priceFormatter } from "../../../../helper/number";
import * as invoiceService from "../../../../service/invoice";
import clsx from "clsx";
import { apiDate, humanDate } from "../../../../helper/date";

function getTotalAmount(invoices) {
  return invoices.reduce((result, row) => {
    return result + parseInt(row.amount_formatted_raw, 10);
  }, 0);
}

export default function InvoiceDateModal(props) {
  const [date, setDate] = useState("");
  const [isPending, setPending] = useState(false);

  const submit = useCallback(async () => {
    setPending(true);
    try {
      await invoiceService.addDate({
        ids: props.invoices.map((row) => row.id),
        date: date ? apiDate(date) : null,
      });
      props.onSuccess();
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.error(err);
    }
    setPending(false);
  }, [date, props]);

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Дата оплаты</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={submit}>
        <Modal.Body>
          <div className="kt-widget1">
            {props.invoices.map((row) => (
              <div className="kt-widget1__item" key={row.id}>
                <div className="kt-widget1__info">
                  <h3 className="kt-widget1__title">
                    Счёт №{row.invoice_number}&nbsp;
                    {row.payment_delay_date && (
                      <span className="kt-font-warning">
                        <i className="la la-warning kt-font-xl" />
                        &nbsp;дата оплаты {humanDate(row.payment_delay_date)}
                      </span>
                    )}
                  </h3>
                </div>
                <span className="kt-widget1__number">
                  {row.amount_formatted}
                </span>
              </div>
            ))}
            <div className="kt-widget1__item">
              <div className="kt-widget1__info">
                <h3 className="kt-widget1__title">
                  <b>итого</b>
                </h3>
              </div>
              <span className="kt-widget1__number kt-font-success">
                {priceFormatter.format(getTotalAmount(props.invoices))}
              </span>
            </div>
          </div>
          <Form.Group as={Row}>
            <Col md="6">
              <Form.Label>Дата оплаты</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <i className="la la-calendar" />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <ReactDatePicker
                  className="form-control"
                  locale="ru"
                  selected={date}
                  dateFormat={"dd.MM.yyyy"}
                  onChange={setDate}
                />
              </InputGroup>
            </Col>
            <Col md="6">
              <Form.Label>&nbsp;</Form.Label>
              <p className="text-muted">
                Оставьте пустым, чтобы удалить предыдущее значение
              </p>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            отменить
          </Button>
          <Button
            variant="primary"
            onClick={submit}
            disabled={isPending}
            className={
              isPending &&
              "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light"
            }
          >
            Сохранить
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
