import { parse } from "date-fns";
import { apiDate, zeroDate } from "../../helper/date";

export const humanReducer = ({ date, ...contract }) => ({
  ...contract,
  date: !zeroDate(date) && parse(date, "yyyy-MM-dd", new Date()),
  fromRequest: true,
});

const apiReducer = ({ date, ...fields }) => ({
  ...fields,
  date: apiDate(date),
});

export default { from: humanReducer, to: apiReducer };
