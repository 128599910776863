import React from "react";

export function boolLabel(
  val,
  answers = { true: "да", false: "нет", mid: "не знаю" },
) {
  if (val === "2")
    return (
      <span className="kt-font-bold kt-font-warning">
        <span className="kt-badge kt-badge--warning kt-badge--dot" />
        &nbsp;
        {answers.mid}
      </span>
    );
  if (val === "1" || val === true)
    return (
      <span className="kt-font-bold kt-font-success">
        <span className="kt-badge kt-badge--success kt-badge--dot" />
        &nbsp;
        {answers.true}
      </span>
    );
  if (val === "0" || val === false)
    return (
      <span className="kt-font-bold kt-font-danger">
        <span className="kt-badge kt-badge--danger kt-badge--dot" />
        &nbsp;
        {answers.false}
      </span>
    );
  return null;
}
