import * as service from "../../service/file";

export const fileTypes = {
  Progress: "[File] Progress",
  Error: "[File] UploadError",
  Success: "[File] Success",
  Upload: "[File] Upload",
  Delete: "[File] Delete",
  List: "[File] List",
};

const initialAuthState = {
  isUploading: false,
  progress: 0,
  isPending: false,
  list: [],
  payload: null,
  error: null,
};

export const reducer = (
  state = initialAuthState,
  { file, progress, error, payload, ...action },
) => {
  switch (action.type) {
    case fileTypes.Progress:
      return { ...state, isUploading: true, progress, file, error: null };
    case fileTypes.Upload:
      return { ...state, isUploading: true, progress, file, error: null };
    case fileTypes.Success:
      return {
        ...state,
        isUploading: false,
        progress,
        file,
        error: null,
        payload,
      };
    case fileTypes.Error:
      return { ...state, isUploading: false, progress, file, error };
    case fileTypes.List:
      const oldList = state.list.filter(
        (_file) =>
          !action.list || !action.list.map(({ id }) => id).includes(_file.id),
      );
      return {
        ...state,
        file: null,
        isPending: action.pending,
        list: [...(action.list || []), ...oldList],
        error: error || null,
      };
    case fileTypes.Delete:
      return {
        ...state,
        error: error || null,
        list: state.list.filter(({ id }) => id !== action.id),
      };
    default:
      return state;
  }
};

export const actions = {
  upload: (file) => async (dispatch) => {
    dispatch({ type: fileTypes.Upload, progress: 0, file });
    try {
      const { data: payload } = await service.upload(
        file,
        ({ progress, file: _file }) => {
          dispatch({ type: fileTypes.Progress, progress, file: _file });
        },
      );
      dispatch({ type: fileTypes.Success, progress: 100, payload, file });
    } catch (error) {
      dispatch({ type: fileTypes.Error, progress: 0, error, file });
    }
  },
  remove: (id) => async (dispatch) => {
    dispatch({ type: fileTypes.Delete, pending: true, id });
    try {
      await service.remove(id);
      dispatch({ type: fileTypes.Delete, pending: false, id });
    } catch (error) {
      dispatch({ type: fileTypes.Delete, pending: false, id, error });
    }
  },
  list: (id) => async (dispatch) => {
    dispatch({ type: fileTypes.List, pending: true });
    try {
      const { data: list } = await service.list(id);
      dispatch({ type: fileTypes.List, pending: false, list });
    } catch (error) {
      dispatch({ type: fileTypes.List, pending: false, error });
    }
  },
  saveOrgFiles: (orgId, fileIds) => async () => {
    await service.saveOrgFiles(orgId, fileIds);
  },
};

export const fileEntity = { actions, reducer };
