import React from "react";
import { Form, Col, InputGroup, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import SelectEntity from "../../../../components/entity/SelectEntity";
import { carLoadTypeEntity } from "../../../../store/ducks/entity.duck";
import clsx from "clsx";

export class RequestShipment extends React.Component {
  static propTypes = {
    errors: PropTypes.shape({
      organization: PropTypes.string,
      date: PropTypes.string,
      time: PropTypes.string,
      load_type_id: PropTypes.string,
      type: PropTypes.string,
    }),
    fields: PropTypes.shape({
      organization: PropTypes.string,
      date: PropTypes.any,
      time: PropTypes.string,
      load_type_id: PropTypes.string,
      type: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
  };

  render() {
    const { fields, onChange, errors } = this.props;
    return (
      <>
        <Form.Group as={Row}>
          <Col md="6">
            <Form.Label>
              {fields.type === "load" ? "Грузоотправитель" : "Грузополучатель"}
            </Form.Label>
            <Form.Control
              isInvalid={errors["organization"]}
              name="organization"
              value={fields["organization"]}
              onChange={(ev) => onChange(ev.target.name, ev.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              {errors["organization"]}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label>
              Тип {fields.type === "load" ? "загрузки" : "выгрузки"}
            </Form.Label>
            <SelectEntity
              search={(val) => carLoadTypeEntity.actions.search({ title: val })}
              noOptionsMessage={"Типов не найдено"}
              value={fields["load_type_id"]}
              errorText={errors["load_type_id"]}
              onChange={({ id }) => onChange("load_type_id", id)}
              getOptionLabel={({ title }) => title}
              getOptionValue={({ id }) => id}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col md="4">
            <Form.Label>
              Город {fields.type === "load" ? "загрузки" : "выгрузки"}
            </Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <i className="flaticon-map-location" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                name="city"
                isInvalid={errors["city"]}
                value={fields["city"]}
                onChange={(ev) => onChange(ev.target.name, ev.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors["city"]}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>

          <Col md="8">
            <Form.Label>Адрес полностью</Form.Label>
            <Form.Control
              name="address"
              isInvalid={errors["address"]}
              value={fields["address"]}
              onChange={(ev) => onChange(ev.target.name, ev.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              {errors["address"]}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col md="6">
            <Form.Label>
              Дата {fields.type === "load" ? "погрузки" : "выгрузки"}
            </Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <i className="la la-calendar" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <ReactDatePicker
                className={clsx("form-control", {
                  "is-invalid": errors["date"],
                })}
                selected={fields["date"]}
                name="date"
                dateFormat="dd.MM.yyyy"
                onChange={(val) => onChange("date", val)}
              />
              <Form.Control hidden isInvalid={errors.hasOwnProperty("date")} />
              <Form.Control.Feedback type="invalid">
                {errors["date"]}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
          <Col md="6">
            <Form.Label>
              Время или временной интервал{" "}
              {fields.type === "load" ? "погрузки" : "выгрузки"}
            </Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <i className="fa fa-clock" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                name="time"
                isInvalid={errors["time"]}
                value={fields["time"]}
                onChange={(ev) => onChange(ev.target.name, ev.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors["time"]}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col md="12">
            <Form.Label>Контактное лицо, телефон</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <i className="fa fa-user" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                name="contact"
                isInvalid={errors["contact"]}
                value={fields["contact"]}
                onChange={(ev) => onChange(ev.target.name, ev.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors["contact"]}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Form.Group>
      </>
    );
  }
}
