const types = {
  Open: "[Popup] Open",
  Close: "[Popup] Close",
};

const open = (action) => async (dispatch) =>
  dispatch({
    type: types.Open,
    ...action,
    variant: "",
  });

const error = (action) => async (dispatch) =>
  dispatch({
    type: types.Open,
    ...action,
    variant: "danger",
  });

const success = (action) => async (dispatch) =>
  dispatch({
    type: types.Open,
    ...action,
    variant: "success",
  });

const warn = (action) => async (dispatch) =>
  dispatch({
    type: types.Open,
    ...action,
    variant: "warning",
  });

const close = () => (dispatch) => dispatch({ type: types.Close });

const initialState = {
  show: false,
  title: "",
  subtitle: "",
  content: "",
  variant: "",
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.Open:
      return {
        show: true,
        title: action.title,
        subtitle: action.subtitle,
        content: action.content,
        variant: action.variant,
      };
    case types.Close:
      return {
        show: false,
      };
    default:
      return state;
  }
};
export default {
  actions: { error, success, warn, open, close },
  reducer,
  types,
};
