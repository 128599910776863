import React, { Component } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.css";
import {
  humanDate,
  isAfterOrEqual,
  isBeforeOrEqual,
  apiDate,
} from "../../../helper/date";
import { format, parseISO } from "date-fns";
import { connect } from "react-redux";
import SelectEntity from "../../../components/entity/SelectEntity";
import { orgsEntity, taxEntity } from "../../../store/ducks/entity.duck";
import { RangePicker } from "../../../partials/widgets/RangePicker";

const payStatuses = [
  { label: "не оплачен", value: "0" },
  { label: "оплачен", value: "1" },
  { label: "частично оплачен", value: "2" },
];

const types = [
  { label: "заказчику", value: "debet" },
  { label: "от перевозчика", value: "credit" },
];

const reduceFilter = (filter) => ({
  ...filter,
  is_not_match: filter.is_not_match === "1" ? true : false,
  invoice_date_end: filter.invoice_date_end
    ? parseISO(filter.invoice_date_end)
    : "",
  invoice_date_start: filter.invoice_date_start
    ? parseISO(filter.invoice_date_start)
    : "",
  invoice_paydate_end: filter.invoice_paydate_end
    ? parseISO(filter.invoice_paydate_end)
    : "",
  invoice_paydate_start: filter.invoice_paydate_start
    ? parseISO(filter.invoice_paydate_start)
    : "",
});

const mapStateToProps = (_, props) => ({
  filter: reduceFilter(props.filter),
  onFilter: (newFilter, isDrop) =>
    props.onFilter(
      {
        ...props.filter,
        ...newFilter,
        ...(newFilter.is_not_match
          ? { is_not_match: newFilter.is_not_match ? "1" : "0" }
          : {}),
        ...(newFilter.invoice_date_end
          ? { invoice_date_end: apiDate(newFilter.invoice_date_end) }
          : {}),
        ...(newFilter.invoice_date_start
          ? { invoice_date_start: apiDate(newFilter.invoice_date_start) }
          : {}),
        ...(newFilter.invoice_paydate_end
          ? { invoice_paydate_end: apiDate(newFilter.invoice_paydate_end) }
          : {}),
        ...(newFilter.invoice_paydate_start
          ? { invoice_paydate_start: apiDate(newFilter.invoice_paydate_start) }
          : {}),
      },
      isDrop,
    ),
});

class InvoiceListFilters extends Component {
  static propTypes = {
    filter: PropTypes.shape(),
    onFilter: PropTypes.func,
    isPending: PropTypes.bool,
  };

  get isFilterSet() {
    return Object.keys(this.props.filter).find((key) => {
      const filter = this.props.filter[key];
      return (Array.isArray(filter) && filter.length) || Boolean(filter);
    });
  }

  dropFilters() {
    const emptyFilter = {};
    Object.keys(this.props.filter).forEach((key) => {
      if (Array.isArray(this.props.filter[key])) {
        emptyFilter[key] = [];
      } else {
        emptyFilter[key] = "";
      }
    });
    this.props.onFilter(emptyFilter, true);
  }

  handleChangeDate({ startDate, endDate, key }) {
    this.props.onFilter({
      ...this.props.filter,
      [`${key}_start`]: startDate,
      [`${key}_end`]: endDate,
    });
  }

  render() {
    const {
      invoice_date_start,
      invoice_date_end,
      invoice_paydate_start,
      invoice_paydate_end,
      is_payed,
      type,
      is_not_match,
      org_id,
    } = this.props.filter;
    return (
      <div
        className="kt-form kt-form--label-right"
        style={{ position: "relative" }}
      >
        <Button
          onClick={this.dropFilters.bind(this)}
          hidden={!this.isFilterSet || this.props.isPending}
          size="sm"
          variant="danger"
          style={{
            position: "absolute",
            top: -25,
            left: 0,
            padding: "3px 10px",
          }}
        >
          сбросить фильтр
        </Button>
        <Row>
          <Col md="2">{this.props.children}</Col>
          <Col md="10" xl="10" className="kt-margin-b-20-tablet-and-mobile">
            <Row>
              <Col md="4">
                <Row>
                  <Col md="6">
                    <div className="kt-form__group">
                      <Form.Label>Дата счёта:</Form.Label>
                      <div>
                        <RangePicker
                          range={{
                            startDate: invoice_date_start,
                            endDate: invoice_date_end,
                            key: "invoice_date",
                          }}
                          onChange={(range) => this.handleChangeDate(range)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="kt-form__group">
                      <Form.Label>Дата оплаты:</Form.Label>
                      <div>
                        <RangePicker
                          range={{
                            startDate: invoice_paydate_start,
                            endDate: invoice_paydate_end,
                            key: "invoice_paydate",
                          }}
                          onChange={(range) => this.handleChangeDate(range)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md="3">
                <Row>
                  <Col md="6">
                    <div className="kt-form__group">
                      <Form.Label>Статус оплаты:</Form.Label>
                      <SelectEntity
                        static
                        isClearable
                        options={payStatuses}
                        value={payStatuses.find(
                          (status) => status.value === is_payed,
                        )}
                        onChange={(selected) =>
                          this.props.onFilter({ is_payed: selected?.value })
                        }
                        getOptionLabel={(val) => val.label}
                        getOptionValue={(val) => val.value}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="kt-form__group">
                      <Form.Label>Тип оплаты:</Form.Label>
                      <SelectEntity
                        isClearable
                        search={(val) =>
                          taxEntity.actions.search({ name: val })
                        }
                        onChange={(payForm) =>
                          this.props.onFilter({ payment_form_id: payForm?.id })
                        }
                        value={this.props.filter.payment_form_id}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                        noOptionsMessage={"Типов оплаты не найдено"}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md="4">
                <Row>
                  <Col md="6">
                    <div className="kt-form__group">
                      <Form.Label>Юр. лицо:</Form.Label>
                      <SelectEntity
                        isClearable
                        multiple
                        allowSelectAll
                        search={(val) =>
                          orgsEntity.actions.search({
                            "org.name": val,
                            has_invoice: true,
                          })
                        }
                        onChange={(rows) =>
                          this.props.onFilter({
                            org_id: rows?.map(({ id }) => id),
                          })
                        }
                        noOptionsMessage="Заказчиков не найдено"
                        value={org_id}
                        getOptionLabel={({ short_name, name }) =>
                          `${short_name || name}`
                        }
                        getOptionValue={(org) => org.id}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="kt-form__group">
                      <Form.Label>Тип счёта:</Form.Label>
                      <SelectEntity
                        static
                        isClearable
                        options={types}
                        value={types.find(({ value }) => value === type)}
                        onChange={(selected) =>
                          this.props.onFilter({ type: selected?.value })
                        }
                        getOptionLabel={(val) => val.label}
                        getOptionValue={(val) => val.value}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md="1" style={{ alignItems: "flex-end", display: "flex" }}>
                <label className="kt-checkbox kt-checkbox--tick kt-checkbox--brand mt-1 mr-3">
                  <input
                    type="checkbox"
                    checked={is_not_match}
                    onChange={() =>
                      this.props.onFilter({ is_not_match: !is_not_match })
                    }
                  />{" "}
                  ошибки
                  <span />
                </label>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(mapStateToProps)(InvoiceListFilters);
