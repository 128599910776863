/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { ClickAwayListener } from "@material-ui/core";

class Settings extends React.Component {
  menu = [
    "Общие",
    "separator",
    {
      label: "Реестр счетов и ТРН",
      link: "/report/document_registry",
      icon: "flaticon2-list-3",
    },
  ];

  state = {
    show: false,
  };

  render() {
    return (
      <ClickAwayListener onClickAway={() => this.setState({ show: false })}>
        <Dropdown show={this.state.show} drop="down">
          <Dropdown.Toggle
            onClick={() => this.setState({ show: !this.state.show })}
            variant="transparent"
            className="btn btn-default dropdown-toggle"
            id="dropdown-toggle-top"
          >
            Отчёты
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-md">
            <ul className="kt-nav kt-nav--bold kt-nav--md-space">
              {this.menu.map((item, index) => {
                if (item === "separator")
                  return <li key={index} className="kt-nav__separator" />;
                if (typeof item === "string") {
                  return (
                    <h6
                      key={index}
                      className="pl-4 pt-3 kt-menu__heading kt-menu__toggle"
                    >
                      <span className="kt-font-light-inverse">{item}</span>
                    </h6>
                  );
                }
                return (
                  <li key={item.label} className="kt-nav__item">
                    <Link
                      onClick={() => this.setState({ show: false })}
                      className="kt-nav__link active"
                      to={item.link}
                    >
                      <span className="kt-nav__link-icon">
                        <i className={item.icon} />
                      </span>
                      <span className="kt-nav__link-text">{item.label}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      </ClickAwayListener>
    );
  }
}

export default Settings;
