import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";
import clsx from "clsx";
import { LinearProgress } from "@material-ui/core";
import {
  Portlet,
  PortletHeader,
  PortletFooter,
  PortletBody,
} from "../../../partials/content/Portlet";
import { connect } from "react-redux";
import { customersEntity } from "../../../store/ducks/entity.duck";
import staticStore from "../../../store/staticStore";
import {
  replaceNullToString,
  replaceStringToNull,
  isEmpty,
} from "../../../helper/object";
import OrgForm from "../../../partials/widgets/OrgForm";

const mapStateToProps = (
  {
    customers: { type, lastId, single, isSuccess, isPending, errors },
    catalog,
  },
  props,
) => {
  const id =
    props.id ||
    (type === customersEntity.types.Create && isSuccess && lastId) ||
    null;
  return {
    id,
    isPending,
    isSuccess,
    errors,
    defaults: replaceNullToString(single[id]),
    conditions: staticStore.contractConditions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchCustomer: (id) => dispatch(customersEntity.actions.single(id)),
  createCustomer: (fields) => dispatch(customersEntity.actions.create(fields)),
  updateCustomer: (fields, id) =>
    dispatch(customersEntity.actions.update(fields, id)),
});

class CustomerForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitAndClose = this.handleSubmitAndClose.bind(this);
  }

  componentDidMount() {
    const { fetchCustomer, id } = this.props;
    if (!this.isCreate) {
      fetchCustomer(id).then(() => {
        // First load of editable element, fill values
        this.setState({
          isLoaded: true,
          fields: { ...this.state.fields, ...this.props.defaults },
        });
      });
    }
  }

  static propTypes = {
    id: PropTypes.string,
    fetchCustomer: PropTypes.func.isRequired,
    createCustomer: PropTypes.func.isRequired,
    updateCustomer: PropTypes.func.isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    message: PropTypes.shape({
      show: PropTypes.bool,
      title: PropTypes.string,
      description: PropTypes.string,
    }),
    defaults: PropTypes.shape(),
    isPending: PropTypes.bool,
    isSuccess: PropTypes.bool,
    /// Catalogs
    conditions: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    ).isRequired,
  };

  state = {
    isLoaded: this.isCreate,
    closeAfterSave: false,
    fields: {
      inn: "",
      name: "",
      org_type: "ooo",
      short_name: "",
      city: "",
      director_short: "",
      director: "",
      passport: "",
      basis: "",
      cargo_type_id: "",
      address: "",
      real_address: "",
      is_address_coincide: false,
      kpp: "",
      ogrn: "",
      ati_code: "",
      ati_score: 0,
      phone1: "",
      phone2: "",
      phone3: "",
      email: "",
      website: "",
      self_condition_type: this.props.conditions[0].code,
      self_condition_text: "",
      driver_condition_type: this.props.conditions[0].code,
      driver_condition_text: "",
    },
    errors: [],
    validated: false,
  };

  get isCreate() {
    return !this.props.id;
  }

  async handleSubmitAndClose(ev) {
    this.setState({ closeAfterSave: true });
    this.handleSubmit(ev);
  }

  async handleSubmit(ev) {
    ev.preventDefault();
    const { fields: rawFields } = this.state;
    const fields = replaceStringToNull(rawFields);
    if (this.isCreate) {
      await this.props.createCustomer(fields);
    } else {
      await this.props.updateCustomer(fields, this.props.id);
    }
    if (!isEmpty(this.props.errors) && this.state.closeAfterSave) {
      this.setState({ closeAfterSave: false });
    }
  }

  set(fields) {
    this.setState({
      fields: { ...this.state.fields, ...fields },
    });
  }

  typeOptions = [
    { label: "ИП", value: "ip" },
    { label: "ООО", value: "ooo" },
  ];

  render() {
    const { isLoaded, validated, closeAfterSave } = this.state;
    const { history, isPending, id, errors } = this.props;
    if (!isLoaded) {
      return <LinearProgress />;
    }
    if (!isPending && isLoaded && isEmpty(errors) && id > 0) {
      if (closeAfterSave) {
        return <Redirect to={`/customers/${id}`} />;
      } else if (history.location.pathname.startsWith("/customers/create")) {
        history.replace(`/customers/${id}/edit`);
      }
    }
    return (
      <Form
        noValidate
        validated={validated}
        onSubmit={this.handleSubmit}
        style={{ maxWidth: 900, margin: "0 auto" }}
      >
        <Portlet>
          <PortletHeader title="Данные организации" />
          <PortletBody>
            <OrgForm
              errors={errors}
              onChange={this.set.bind(this)}
              fields={this.state.fields}
            >
              {/* <Tab eventKey="conditions" title="Оформление заявки">
                <Form.Group as={Row}>
                  <Col sm={{ span: 12 }}>
                    <Form.Label>Особые условия для заказчика</Form.Label>
                    <Form.Control
                      as="select"
                      name="self_condition_type"
                      onChange={ev => this.set(ev.target.name, ev.target.value)}
                      value={fields["self_condition_type"]}
                    >
                      {conditions.map(({ code, title }) => (
                        <option key={code} value={code}>
                          {title}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm={{ span: 12 }}>
                    <Form.Control
                      as="textarea"
                      rows="5"
                      name="self_condition_text"
                      onChange={ev => this.set(ev.target.name, ev.target.value)}
                      value={fields["self_condition_text"]}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm={{ span: 12 }}>
                    <Form.Label>Особые условия для перевозчика</Form.Label>
                    <Form.Control
                      as="select"
                      name="driver_condition_type"
                      onChange={ev => this.set(ev.target.name, ev.target.value)}
                      value={fields["driver_condition_type"]}
                    >
                      {conditions.map(({ code, title }) => (
                        <option key={code} value={code}>
                          {title}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm={{ span: 12 }}>
                    <Form.Control
                      as="textarea"
                      rows="5"
                      name="driver_condition_text"
                      onChange={ev => this.set(ev.target.name, ev.target.value)}
                      value={fields["driver_condition_text"]}
                    />
                  </Col>
                </Form.Group>
              </Tab> */}
            </OrgForm>
          </PortletBody>
          <PortletFooter sticky>
            <Button
              variant="success"
              disabled={isPending}
              onClick={this.handleSubmit}
              type="button"
              className={clsx("mr-2", {
                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                  isPending && !closeAfterSave,
              })}
            >
              Сохранить
            </Button>
            <Button
              color="primary"
              disabled={isPending}
              onClick={this.handleSubmitAndClose}
              type="button"
              className={clsx("mr-1", {
                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                  isPending && closeAfterSave,
              })}
            >
              Сохранить и закрыть
            </Button>
            <Button
              variant="danger"
              onClick={() => this.props.history.goBack()}
              type="button"
              className="mr-2"
            >
              Отменить
            </Button>
          </PortletFooter>
        </Portlet>
      </Form>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerForm),
);
