/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal, Form, Row, Col, Table, Spinner } from "react-bootstrap";
import staticStore from "../../store/staticStore";
import {
  PortletHeaderToolbar,
  Portlet,
  PortletHeader,
  PortletBody,
} from "../content/Portlet";
import { employeeEntity } from "../../store/ducks/entity.duck";
import clsx from "clsx";
import ConfirmButton from "../content/ConfirmButton";
import "./table.scss";
import { replaceNullToString } from "../../helper/object";

const mapStateToProps = ({ employees }, props) => ({
  errors: employees.errors,
  isPending: employees.isPending,
  isSuccess: employees.isSuccess,
  // list: employees.rows.filter(({ org_id, type }) => org_id === props.orgId),
  positions: staticStore.employeePositions,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEmployeeList: () => dispatch(employeeEntity.actions.list()),
  createEmployee: (fields) => dispatch(employeeEntity.actions.create(fields)),
  updateEmployee: (fields, id) =>
    dispatch(employeeEntity.actions.update(fields, id)),
  removeEmployee: (id) => dispatch(employeeEntity.actions.remove(id)),
});

class EmployeeList extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changed = this.changed.bind(this);
  }

  componentDidMount() {
    this.fetchEmployeeList();
  }

  async fetchEmployeeList() {
    const { data: list } = await employeeEntity.actions.search(
      { "org.id": this.props.orgId },
      { "org.id": "strict" },
    );
    this.setState({ list, isLoading: false });
  }

  static propTypes = {
    createEmployee: PropTypes.func.isRequired,
    updateEmployee: PropTypes.func.isRequired,
    fetchEmployeeList: PropTypes.func.isRequired,
    removeEmployee: PropTypes.func.isRequired,
    orgId: PropTypes.string,
    isPending: PropTypes.bool,
    isSuccess: PropTypes.bool.isRequired,
    positions: PropTypes.array.isRequired,
    title: PropTypes.string,
    errors: PropTypes.shape(),
    // list: PropTypes.arrayOf(
    //   PropTypes.shape({
    //     name: PropTypes.string.isRequired,
    //     position_type: PropTypes.string.isRequired
    //   })
    // )
  };

  static defaultProps = {
    isPending: false,
    title: "Контактные лица",
  };

  initialFields = {
    position_type: "",
    position_description: "",
    name: "",
    phone1: "",
    phone2: "",
    email: "",
    org_id: this.props.orgId,
  };

  state = {
    id: null,
    showModal: false,
    list: [],
    isLoading: true,
    fields: { ...this.initialFields },
  };

  get isCreate() {
    return this.state.id === null;
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  getEmployeeById(id) {
    return replaceNullToString(
      this.state.list.find((employee) => employee.id === id),
    );
  }

  handleShow(id = null, ev) {
    if (!ev || !ev.target.closest("button")) {
      this.setState({
        id,
        showModal: true,
        fields: id
          ? { ...this.getEmployeeById(id) }
          : { ...this.initialFields },
      });
    }
  }

  async handleSubmit(ev) {
    ev.preventDefault();
    const { fields, id } = this.state;
    if (this.isCreate) {
      await this.props.createEmployee(fields);
    } else {
      await this.props.updateEmployee(fields, id);
    }
    if (this.props.isSuccess) {
      this.fetchEmployeeList();
      this.handleClose();
    }
  }

  changed({ target: { name, value } }) {
    this.setState({
      fields: {
        ...this.state.fields,
        [name]: value,
      },
    });
  }

  render() {
    const { fields, list, isLoading } = this.state;
    const { isPending, positions, removeEmployee, errors } = this.props;
    return (
      <>
        <Portlet fluidHeight>
          <PortletHeader
            title={this.props.title}
            toolbar={
              <PortletHeaderToolbar>
                <Button
                  size="sm"
                  onClick={(ev) => this.handleShow()}
                  variant="success"
                >
                  Добавить
                </Button>
              </PortletHeaderToolbar>
            }
          />
          <PortletBody scrollable>
            <Table responsive striped hover>
              <thead>
                <tr>
                  <th width="30%">Имя</th>
                  <th width="10%">Должность</th>
                  <th with="60%">Телефон</th>
                  <th width="10%">Email</th>
                  <th width="1%" />
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <td colSpan="5" className="kt-align-center">
                    <Spinner animation="border" variant="primary" />
                  </td>
                )}
                {list.map(
                  ({
                    id,
                    name,
                    position_type,
                    position_description,
                    phone1,
                    phone2,
                    email,
                  }) => (
                    <tr key={id} onClick={(ev) => this.handleShow(id, ev)}>
                      <td>{name}</td>
                      <td>{position_description || position_type}</td>
                      <td>
                        {phone1}
                        {phone2 && (
                          <>
                            <br />
                            {phone2}
                          </>
                        )}
                      </td>
                      <td>{email || "-"}</td>
                      <td>
                        <ConfirmButton
                          size="sm"
                          action={() => removeEmployee(id)}
                          variant="label-danger"
                        >
                          удалить
                        </ConfirmButton>
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </Table>
          </PortletBody>
        </Portlet>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Данные сотрудника</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate onSubmit={this.handleSubmit}>
              <Form.Group>
                <Form.Label>ФИО</Form.Label>
                <Form.Control
                  name="name"
                  onChange={this.changed}
                  isInvalid={errors.hasOwnProperty("name")}
                  value={fields["name"]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors["name"]}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Row}>
                <Col md="4">
                  <Form.Label>Позиция</Form.Label>
                  <Form.Control
                    as="select"
                    name="position_type"
                    onChange={this.changed}
                    isInvalid={errors.hasOwnProperty("position_type")}
                    value={fields["position_type"]}
                  >
                    <option value="">- Неизвестна -</option>
                    {positions.map(({ title }) => (
                      <option key={title} value={title}>
                        {title}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors["position_type"]}
                  </Form.Control.Feedback>
                </Col>
                <Col md="8">
                  <Form.Label>&nbsp;</Form.Label>
                  <Form.Control
                    name="position_description"
                    isInvalid={errors.hasOwnProperty("position_description")}
                    onChange={this.changed}
                    value={fields["position_description"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["position_description"]}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col md="6">
                  <Form.Label>Телефон 1</Form.Label>
                  <Form.Control
                    name="phone1"
                    onChange={this.changed}
                    isInvalid={errors.hasOwnProperty("phone1")}
                    value={fields["phone1"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["phone1"]}
                  </Form.Control.Feedback>
                </Col>
                <Col md="6">
                  <Form.Label>Телефон 2</Form.Label>
                  <Form.Control
                    name="phone2"
                    onChange={this.changed}
                    isInvalid={errors.hasOwnProperty("phone2")}
                    value={fields["phone2"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["phone2"]}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col md="12">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    onChange={this.changed}
                    isInvalid={errors.hasOwnProperty("email")}
                    value={fields["email"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["email"]}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              отменить
            </Button>
            <Button
              variant="primary"
              disabled={isPending}
              onClick={this.handleSubmit}
              className={clsx({
                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                  isPending,
              })}
            >
              Сохранить
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeList);
