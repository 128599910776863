/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal, Form } from "react-bootstrap";
import {
  PortletHeaderToolbar,
  Portlet,
  PortletHeader,
  PortletBody,
} from "../content/Portlet";
import InputAutoComplete from "../content/AutoComplete/InputAutoComplete";
import dataSearch from "../../store/ducks/data_search.duck";
import { bankEntity } from "../../store/ducks/entity.duck";
import clsx from "clsx";
import ConfirmButton from "../content/ConfirmButton";
import { boolLabel } from "../../helper/label";

const mapStateToProps = ({ banks }, props) => ({
  isPending: banks.isPending,
  isSuccess: banks.isSuccess,
  errors: banks.errors,
  list: banks.rows.filter(({ org_id }) => org_id === props.orgId),
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchBank: (value) => dispatch(dataSearch.actions.searchBank(value)),
  searchBanks: () => {
    return bankEntity.actions.search(props.search, props.searchRule);
  },

  fetchBankList: () => dispatch(bankEntity.actions.list()),
  createBank: (fields) => dispatch(bankEntity.actions.create(fields)),
  updateBank: (fields, id) => dispatch(bankEntity.actions.update(fields, id)),
  removeBank: (id) => dispatch(bankEntity.actions.remove(id)),
});

class BankList extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changed = this.changed.bind(this);
  }

  static propTypes = {
    fetchBank: PropTypes.func.isRequired,
    createBank: PropTypes.func.isRequired,
    removeBank: PropTypes.func.isRequired,
    updateBank: PropTypes.func.isRequired,
    fetchBankList: PropTypes.func.isRequired,
    orgId: PropTypes.string,
    isPending: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    errors: PropTypes.shape(),
  };

  static defaultProps = {
    errors: {},
  };

  initialFields = {
    bic: "",
    name: "",
    correspondent_account: "",
    payment_account: "",
    org_id: this.props.orgId,
  };

  state = {
    showModal: false,
    isLoading: true,
    list: [],
    id: null,
    errors: {},
    fields: { ...this.initialFields },
  };

  async loadBanks() {
    const { data: list } = await this.props.searchBanks();
    this.setState({
      ...this.state,
      list,
    });
  }

  get isCreate() {
    return this.state.id === null;
  }

  UNSAFE_componentWillReceiveProps({ errors }) {
    if (errors !== this.state.errors) {
      this.setState({ errors });
    }
  }

  componentDidMount() {
    this.loadBanks();
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  getBankById(id) {
    return this.state.list.find((bank) => bank.id === id);
  }

  handleShow(id = null, ev) {
    if (ev) ev.preventDefault();
    this.setState({
      id,
      showModal: true,
      fields: id ? { ...this.getBankById(id) } : { ...this.initialFields },
    });
  }

  async handleSubmit(ev) {
    ev.preventDefault();
    if (this.isCreate) {
      await this.props.createBank(this.state.fields);
    } else {
      await this.props.updateBank(this.state.fields, this.state.id);
    }
    if (this.props.isSuccess) {
      this.handleClose();
      this.loadBanks();
      await this.props.fetchBankList();
    }
  }

  changed({ target: { name, value } }) {
    const { errors, fields } = this.state;
    delete errors[name];
    this.setState({
      errors,
      fields: { ...fields, [name]: value },
    });
  }

  selectBank({
    value: name,
    data: { bic, correspondentAccount: correspondent_account },
  }) {
    const { errors, fields } = this.state;
    delete errors["bic"];
    delete errors["correspondent_account"];
    delete errors["name"];
    this.setState({
      errors,
      fields: { ...fields, bic, name, correspondent_account },
    });
    return bic;
  }

  render() {
    const { fields, list } = this.state;
    const { fetchBank, isPending, removeBank, errors } = this.props;
    return (
      <>
        <Portlet fluidHeight>
          <PortletHeader
            title="Банковские счета"
            toolbar={
              <PortletHeaderToolbar>
                <Button
                  size="sm"
                  onClick={() => this.handleShow()}
                  variant="success"
                >
                  Добавить
                </Button>
              </PortletHeaderToolbar>
            }
          />
          <PortletBody scrollable>
            <div className="kt-widget4">
              {list.map(({ id, name, payment_account, is_main }) => (
                <div key={id} className="kt-widget4__item">
                  <div className="kt-widget4__info">
                    <a
                      className="kt-widget4__title"
                      onClick={(ev) => this.handleShow(id, ev)}
                    >
                      {name}{" "}
                      {is_main === "1" && (
                        <label className="kt-badge kt-badge--inline kt-badge--success kt-badge--rounded">
                          основной
                        </label>
                      )}
                      <p className="kt-widget4__text">{payment_account}</p>
                    </a>
                  </div>
                  <ConfirmButton
                    size="sm"
                    action={() => removeBank(id)}
                    variant="label-danger"
                  >
                    удалить
                  </ConfirmButton>
                </div>
              ))}
            </div>
          </PortletBody>
        </Portlet>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Банковские реквизиты</Modal.Title>
          </Modal.Header>
          <Form noValidate onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Form.Group>
                <Form.Label>БИК</Form.Label>
                <InputAutoComplete
                  name="bic"
                  fullWidth
                  select={(state) => state.banks}
                  getLabel={(bank) => `${bank.value} (${bank.data.bic})`}
                  fetchResults={fetchBank}
                  value={fields.bic}
                  onChange={this.changed}
                  isInvalid={errors.hasOwnProperty("bic")}
                  onSelect={(bank) => this.selectBank(bank)}
                />
                <Form.Control.Feedback type="invalid">
                  {errors["bic"]}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Наименование</Form.Label>
                <Form.Control
                  name="name"
                  isInvalid={errors.hasOwnProperty("name")}
                  onChange={this.changed}
                  value={fields["name"]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors["name"]}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Корр. счет</Form.Label>
                <Form.Control
                  name="correspondent_account"
                  onChange={this.changed}
                  isInvalid={errors.hasOwnProperty("correspondent_account")}
                  value={fields["correspondent_account"]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors["correspondent_account"]}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Рассчетный счет</Form.Label>
                <Form.Control
                  name="payment_account"
                  onChange={this.changed}
                  isInvalid={errors.hasOwnProperty("payment_account")}
                  value={fields["payment_account"]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors["payment_account"]}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <label className="kt-checkbox kt-checkbox--tick kt-checkbox--brand mt-1 mr-3">
                  <input
                    type="checkbox"
                    checked={fields["is_main"] === "1"}
                    name="is_main"
                    onChange={({ currentTarget }) =>
                      this.setState({
                        fields: {
                          ...this.state.fields,
                          is_main: currentTarget.checked ? "1" : "0",
                        },
                      })
                    }
                  />{" "}
                  Это основной счёт
                  <span />
                </label>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                отменить
              </Button>
              <Button
                variant="primary"
                disabled={isPending}
                onClick={this.handleSubmit}
                className={clsx({
                  "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                    isPending,
                })}
              >
                Сохранить
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BankList);
