import React from "react";
// import { Form } from 'react-bootstrap'
// import { Typeahead } from 'react-bootstrap-typeahead';
import EntityForm from "../../../components/entity/EntityForm";
import EntityList from "../../../components/entity/EntityList";

import { carLoadTypeEntity } from "../../../store/ducks/entity.duck";

const schema = {
  title: { label: "Название" },
};

export function CarLoadList() {
  return (
    <EntityList
      actions={carLoadTypeEntity.actions}
      actionTypes={carLoadTypeEntity.types}
      select={(state) => state.catalog.carLoadType}
      name="catalog/car_load_type"
    />
  );
}

export function CarLoadEdit(props) {
  return (
    <EntityForm
      name="catalog/car_load_type"
      id={props.match.params.id}
      schema={schema}
      actions={carLoadTypeEntity.actions}
      actionTypes={carLoadTypeEntity.types}
      select={(state) => state.catalog.carLoadType}
    />
  );
}

export function CarLoadCreate(props) {
  return (
    <EntityForm
      onSuccess={props.onSuccess}
      onCancel={props.onCancel}
      name="catalog/car_load_type"
      schema={schema}
      actions={carLoadTypeEntity.actions}
      actionTypes={carLoadTypeEntity.types}
      select={(state) => state.catalog.carLoadType}
    />
  );
}
