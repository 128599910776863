import React from "react";
import PropTypes from "prop-types";
import { Form, ListGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import "./autocomplete.scss";

class InnAutoComplete extends React.Component {
  static propTypes = {
    suggestions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        data: PropTypes.shape({
          inn: PropTypes.string,
        }),
      }),
    ),
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    isInvalid: PropTypes.bool,
    isValid: PropTypes.bool,
    fetchResults: PropTypes.func.isRequired,
    select: PropTypes.func.isRequired,
    name: PropTypes.string,
    isPending: PropTypes.bool,
    placeholder: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    getLabel: PropTypes.func.isRequired,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    fullWidth: false,
    required: false,
  };

  state = {
    isOpen: false,
    isShowFake: false,
    value: this.props.value ?? "",
  };

  search(event) {
    if (this.props.onChange) {
      this.props.onChange(event);
    }
    const { value } = event.target;
    this.setState({ value });
    if (value > "") {
      try {
        this.props.fetchResults(value);
      } catch (err) {}
    }
  }

  onSelect(org, ev) {
    ev.preventDefault();
    this.setState({
      isOpen: false,
      value: this.props.onSelect(org),
    });
  }

  UNSAFE_componentWillReceiveProps({ value }) {
    this.setState({ value });
  }

  render() {
    const {
      suggestions,
      isPending,
      name,
      fullWidth,
      required,
      isInvalid,
      isValid,
    } = this.props;
    const { isOpen } = this.state;
    return (
      <div className="autocomplete__inner">
        <Form.Control
          name={name}
          required={required}
          autoComplete="off"
          isInvalid={isInvalid}
          isValid={isValid}
          value={this.state.value}
          onChange={(ev) => this.search(ev)}
          onFocus={() => this.setState({ isOpen: true })}
          onBlur={(ev) => {
            if (ev.target.closest(".list-group-item")) {
              ev.target
                .closest(".list-group-item")
                .dispatchEvent(new Event("click"));
            }
          }}
        />

        {isPending ? (
          <LinearProgress />
        ) : (
          isOpen && (
            <ListGroup
              className="autocomplete"
              style={{ with: fullWidth ? "100%" : "" }}
            >
              {suggestions?.map((org) => (
                <ListGroup.Item
                  onClick={(ev) => this.onSelect(org, ev)}
                  key={org.value + (org.data.bic || org.data.inn)}
                  action
                >
                  {this.props.getLabel(org)}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (
  { data_search: { isPending, ...suggestions } },
  props,
) => ({
  isPending,
  suggestions: props.select(suggestions),
});

export default connect(mapStateToProps)(InnAutoComplete);
