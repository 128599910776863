import driver from "./.driver";

export const getSFDT = (docId) => driver.get(`document/show/${docId}`);

export const updateDocument = (data, docId) =>
  driver.post(`document/update/${docId}`, data);

export const createByContract = (contractId) =>
  driver.post(`document/create_by_contract/${contractId}`);

export const download = (id) => driver.open("/document/download/" + id);

export const pdfWithSignature = (id) =>
  driver.open("/document/pdf/" + id + "?signature=true");
