/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import AuthPage from "../pages/auth/AuthPage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import HomePage from "../pages/home/HomePage";
import { Modal, Button } from "react-bootstrap";
import popup from "../store/ducks/popup.duck";

export const Routes = withRouter(({ history }) => {
  const dispatch = useDispatch();
  const globalPopup = useSelector((state) => state.popup);
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const { isAuthorized, menuConfig, userLastLocation } = useSelector(
    ({ auth, builder: { menuConfig: conf } }) => ({
      menuConfig: conf,
      isAuthorized: auth.user != null,
      userLastLocation: routerHelpers.getLastLocation(),
    }),
    shallowEqual,
  );

  const closePopup = () => dispatch({ type: popup.types.Close });

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        {!isAuthorized ? (
          /* Render auth page when user at `/auth` and not authorized. */
          <Route path="/auth/login" component={AuthPage} />
        ) : (
          /* Otherwise redirect to root page (`/`) */
          <Redirect from="/auth" to={"/requests/progress"} />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={LogoutPage} />

        {!isAuthorized ? (
          /* Redirect to `/auth` when user is not authorized */
          <Redirect to="/auth/login" />
        ) : (
          <Layout>
            <HomePage userLastLocation={userLastLocation} />
          </Layout>
        )}
      </Switch>
      <Modal show={globalPopup.show} size="sm" onHide={closePopup}>
        <div style={{ boxShadow: "0 0 20px rgba(0,0,0,.4)" }}>
          <Modal.Header closeButton>
            <Modal.Title className={`kt-font-${globalPopup.variant}`}>
              {globalPopup.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {globalPopup.subtitle && <h5>{globalPopup.subtitle}</h5>}
            {globalPopup.content}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={closePopup}>OK</Button>
          </Modal.Footer>
        </div>
      </Modal>
    </LayoutContextProvider>
  );
});
