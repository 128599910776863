import * as service from "../../service/data_search";
import { getError } from "../../service/.errors";
const types = {
  Pending: "[Data Search] Pending ",
  Success: "[Data Search] Success ",
  Failure: "[Data Search] Failure ",
};

const initialState = {
  error: null,
  isPending: false,
  orgs: [],
  banks: [],
};

let innThrottler;
const searchInn = (inn) => async (dispatch) => {
  dispatch({ type: types.Pending });
  if (innThrottler) {
    clearTimeout(innThrottler);
  }
  innThrottler = setTimeout(async () => {
    innThrottler = null;
    try {
      const payload = await service.searchInn(inn);
      dispatch({ type: types.Success, orgs: payload.data });
    } catch (error) {
      dispatch({ type: types.Failure, error: getError(error) });
    }
  }, 1000);
};

let bankThrottler;
const searchBank = (inn) => async (dispatch) => {
  dispatch({ type: types.Pending });
  if (bankThrottler) {
    clearTimeout(bankThrottler);
  }
  bankThrottler = setTimeout(async () => {
    try {
      const payload = await service.searchBank(inn);
      bankThrottler = null;
      dispatch({ type: types.Success, banks: payload.data });
    } catch (error) {
      dispatch({ type: types.Failure, error });
    }
  }, 1000);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.Pending:
      return {
        error: null,
        isPending: true,
        orgs: initialState.orgs,
        banks: initialState.banks,
      };
    case types.Success:
      return {
        error: null,
        isPending: false,
        orgs: action.orgs ?? [],
        banks: action.banks ?? [],
      };
    case types.Failure:
      return {
        isPending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default {
  types,
  reducer,
  actions: { searchInn, searchBank },
};
