import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, InputGroup, FormControl, Button } from "react-bootstrap";
import clsx from "clsx";

function int(v) {
  return parseInt(v, 10);
}

export class FinanceTable extends Component {
  constructor(props, context) {
    super(props, context);

    this.save = this.save.bind(this);
  }

  static propTypes = {
    park_value: PropTypes.number,
    forward_value: PropTypes.number,

    disabled: PropTypes.bool,

    measurement: PropTypes.string,
    park_cost: PropTypes.number,
    rate: PropTypes.number,
    onSave: PropTypes.func,
    requestId: PropTypes.string,
    tax: PropTypes.shape({
      percentage: PropTypes.string,
    }),
  };

  state = {
    park_cost_total: 0,
    forwarding_total: 0,
    total: 0,
    isPending: false,
  };

  async save() {
    this.setState({ isPending: true });
    try {
      await this.props.onSave({
        park: this.state.park_cost_total,
        forwarding: this.state.forwarding_total,
      });
    } finally {
      this.setState({ isPending: false });
    }
  }

  componentDidMount() {
    this.setState(
      {
        park_cost_total: this.props.park_value,
        forwarding_total: this.props.forward_value,
      },
      this.recalc,
    );
  }

  recalc(oldTotal = null) {
    const { park_cost_total, forwarding_total } = this.state;
    const { rate } = this.props;
    const total =
      int(park_cost_total || 0) + int(forwarding_total || 0) + int(rate);
    if (total !== oldTotal) {
      this.setState({ total });
    }
  }

  setForward(cnt = 0) {
    const forwarding_total = int(cnt);
    this.setState({ forwarding_total: forwarding_total || "" }, this.recalc);
  }

  setParkCost(cnt = 0) {
    const park_cost_total = int(cnt);
    this.setState({ park_cost_total: park_cost_total || "" }, this.recalc);
  }

  render() {
    const { disabled } = this.props;
    const { park_cost_total, forwarding_total, total, isPending } = this.state;
    return (
      <>
        <Table striped>
          <thead>
            <tr>
              <th>название</th>
              <th>кол-во</th>
              <th>итог</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ставка заказчика</td>
              <td />
              <td>{this.props.rate}</td>
            </tr>
            <tr>
              <td>простой машины</td>
              <td>
                <InputGroup>
                  <FormControl
                    disabled={disabled}
                    style={{ width: "1px" }}
                    value={park_cost_total}
                    onChange={({ target }) =>
                      this.setParkCost(target.value.replace(/[^\d+]/, ""))
                    }
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>руб</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </td>
              <td>{park_cost_total}</td>
            </tr>
            <tr>
              <td>переадресация</td>
              <td>
                <InputGroup>
                  <FormControl
                    disabled={disabled}
                    style={{ width: "1px" }}
                    value={forwarding_total}
                    onChange={({ target }) =>
                      this.setForward(target.value.replace(/[^\d+]/, ""))
                    }
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>руб</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </td>
              <td>{forwarding_total}</td>
            </tr>
            <tr>
              <td>итог</td>
              <td />
              <td>
                <b>{total}</b>
              </td>
            </tr>
            {this.props.tax?.percentage ? (
              <tr>
                <td>
                  {this.props.tax?.percentage > 0
                    ? "В том числе НДС"
                    : "Без НДС"}
                </td>
                <td>{this.props.tax?.percentage ?? 0}%</td>
                <td>{(total * this.props.tax?.percentage) / 100}</td>
              </tr>
            ) : (
              <tr>
                <td>Без НДС</td>
                <td />
                <td />
              </tr>
            )}
          </tbody>
        </Table>
        {!disabled && (
          <Button
            style={{ width: 200, alignSelf: "flex-end" }}
            variant="outline-success"
            onClick={this.save}
            disabled={isPending}
            className={clsx({
              "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--success":
                isPending,
            })}
          >
            Сохранить
          </Button>
        )}
      </>
    );
  }
}
