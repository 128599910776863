import React, { useEffect, useState } from "react";
import RequestForm from "../common/RequestForm";
import EntityList from "../../../../components/entity/EntityList";
import { requestDeletedEntity } from "../../../../store/ducks/entity.duck";
import RequestCard from "../common/RequestCard";
import { useSelector, useDispatch } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import WordEditor from "../../../../partials/widgets/WordEditor/WordEditor";
import { REQUEST_PROGRESS_STATUS_ID } from "../../../../store/staticStore";
// import RequestDeletedAction from "./RequestDeletedAction";
import RequestListFilters from "../common/RequestListFilters";

export const RequestDeletedList = () => {
  return (
    <EntityList
      // multipleActionsComponent={props => (
      //   <RequestDeletedAction
      //     {...props}
      //     onRestore={requestDeletedEntity.actions.restore}
      //   />
      // )}
      actionsComponent={null}
      filterComponent={(props) => <RequestListFilters {...props} />}
      isServerFilter
      actions={requestDeletedEntity.actions}
      select={(state) => state.requests.deleted}
      hasFilters
      disableClick
      name="requests/deleted"
    />
  );
};
