import { format } from "date-fns";

const _toString = (value) => {
  if (typeof value === "string") {
    if (/^[\d.]+$/.test(value)) {
      return parseInt(value ?? null, 10);
    } else {
      return value;
    }
  } else if (value instanceof Date) {
    return format(value, "dd.MM.yyyy");
  } else if (value instanceof Array) {
    return value.join(", ");
  } else if (!value) {
    return "";
  }
  return JSON.stringify(value);
};

export const exportToXLSX = async (rows, cols) => {
  const { Grid, ExcelExport } = await import("@syncfusion/ej2-grids");
  Grid.Inject(ExcelExport);
  const data = rows.map((row) => {
    return {
      ...cols.reduce(
        (res, col) => ({
          ...res,
          [col.name]: _toString(row[`${col.name}_raw`] ?? row[col.name]),
        }),
        {},
      ),
    };
  });
  const grid = new Grid({
    dataSource: data,
    allowExcelExport: true,
    toolbar: ["ExcelExport"],
    columns: cols.map((col) => ({
      field: col.name,
      headerText: col.label,
      // type: col.type ?? null,
      textAlign: "Left",
      // format: col.format ?? null
    })),
  });
  grid.appendTo("#excelexport");
  grid.excelExport();
  grid.destroy();
};
