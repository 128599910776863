/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import { Tab, Nav } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import clsx from "clsx";
import historyDuck from "../../store/ducks/history.duck";
import { humanDateTimeParse } from "../../helper/date";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const mapStateToProps = (state) => ({
  isShow: state.history.show,
  rows: state.history.rows,
});
const mapDispatchToProps = (dispatch) => ({
  closeHistory: () => dispatch(historyDuck.actions.close()),
});

class HistoryPanel extends React.Component {
  state = { selectedTab: "History" };
  setTab = (_tabName) => {
    this.setState({ selectedTab: _tabName });
  };

  render() {
    return (
      <div
        id="kt_quick_panel"
        className={clsx("kt-quick-panel", {
          "kt-quick-panel--on": this.props.isShow,
        })}
      >
        <a
          onClick={() => this.props.closeHistory()}
          className="kt-quick-panel__close"
          id="kt_quick_panel_close_btn"
        >
          <i className="flaticon2-delete" />
        </a>
        <Tab.Container defaultActiveKey={this.state.selectedTab}>
          <div className="kt-quick-panel__nav">
            <Nav
              onSelect={this.setTab}
              className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand kt-notification-item-padding-x"
            >
              <Nav.Item className="nav-item">
                <Nav.Link eventKey="History" className="nav-link show">
                  История изменений
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="kt-quick-panel__content">
            <Tab.Content>
              <Tab.Pane eventKey="History">
                <PerfectScrollbar
                  options={perfectScrollbarOptions}
                  style={{
                    maxHeight: "calc(100vh - 100px)",
                    position: "relative",
                  }}
                >
                  <div className="kt-notification">
                    {this.props.rows.map((item) => (
                      <div key={item.id} className="kt-notification__item">
                        <div className="kt-notification__item-icon">
                          <i className={item.icon} />
                        </div>
                        <div className="kt-notification__item-details">
                          <div className="kt-notification__item-title">
                            {item.text}
                          </div>
                          <div className="kt-notification__item-time">
                            {item.user.lastname} {item.user.name}
                            <br />
                            {humanDateTimeParse(item.created_at.date)}
                            <br />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </PerfectScrollbar>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryPanel);
