import { createStore, applyMiddleware } from "redux";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import * as auth from "./ducks/auth.duck";
import * as i18n from "../../_metronic/ducks/i18n";
import * as builder from "../../_metronic/ducks/builder";
import popup from "./ducks/popup.duck";
import data_search from "./ducks/data_search.duck";
import {
  taxEntity,
  cargoEntity,
  userEntity,
  userRoleEntity,
  bankEntity,
  employeeEntity,
  customersEntity,
  driversEntity,
  carLoadTypeEntity,
  carsEntity,
  carBodyTypeEntity,
  requestProgressEntity,
  requestPendingEntity,
  carrierEntity,
  documentTemplateEntity,
  documentEntity,
  invoiceEntity,
  contractEntity,
  orgsEntity,
  requestContractEntity,
  cargoPackEntity,
  waybillEntity,
  ownOrgEntity,
  payOrderEntity,
  requestDeletedEntity,
} from "./ducks/entity.duck";
import { fileEntity } from "./ducks/file.duck";
import historyDuck from "./ducks/history.duck";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: i18n.reducer,
  builder: builder.reducer,
  customers: customersEntity.reducer,
  drivers: driversEntity.reducer,
  data_search: data_search.reducer,
  banks: bankEntity.reducer,
  employees: employeeEntity.reducer,
  contracts: contractEntity.reducer,
  cars: carsEntity.reducer,
  orgs: orgsEntity.reducer,
  popup: popup.reducer,
  history: historyDuck.reducer,
  file: fileEntity.reducer,
  carriers: carrierEntity.reducer,
  invoices: invoiceEntity.reducer,
  waybills: waybillEntity.reducer,
  documents: documentEntity.reducer,
  ownOrg: ownOrgEntity.reducer,
  documentTemplates: documentTemplateEntity.reducer,
  payOrders: payOrderEntity.reducer,
  requests: combineReducers({
    deleted: requestDeletedEntity.reducer,
    progress: requestProgressEntity.reducer,
    pending: requestPendingEntity.reducer,
    contract: requestContractEntity.reducer,
  }),
  catalog: combineReducers({
    carLoadType: carLoadTypeEntity.reducer,
    carBodyType: carBodyTypeEntity.reducer,
    users: userEntity.reducer,
    taxes: taxEntity.reducer,
    cargo: cargoEntity.reducer,
    userrole: userRoleEntity.reducer,
    cargoPack: cargoPackEntity.reducer,
  }),
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
