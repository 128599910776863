import React from "react";
// import { Form } from 'react-bootstrap'
// import { Typeahead } from 'react-bootstrap-typeahead';
import EntityForm from "../../../components/entity/EntityForm";
import EntityList from "../../../components/entity/EntityList";

import { taxEntity } from "../../../store/ducks/entity.duck";

const schema = {
  name: { label: "Название" },
  percentage: { label: "Процентная ставка" },
};

export function TaxList() {
  return (
    <EntityList
      actions={taxEntity.actions}
      actionTypes={taxEntity.types}
      select={(state) => state.catalog.taxes}
      name="catalog/tax"
    />
  );
}

export function TaxEdit(props) {
  return (
    <EntityForm
      name="catalog/tax"
      id={props.match.params.id}
      schema={schema}
      actions={taxEntity.actions}
      actionTypes={taxEntity.types}
      select={(state) => state.catalog.taxes}
    />
  );
}

export function TaxCreate(props) {
  return (
    <EntityForm
      name="catalog/tax"
      onSuccess={props.onSuccess}
      onCancel={props.onCancel}
      schema={schema}
      actions={taxEntity.actions}
      actionTypes={taxEntity.types}
      select={(state) => state.catalog.taxes}
    />
  );
}
