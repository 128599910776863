import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import pages from "../../router/urlconf";

export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/requests/progress" />
        }
        {pages.map(({ path, Component }) => (
          <Route exact path={path} key={path} component={Component} />
        ))}
        <Redirect to="/error/error-404" />
      </Switch>
    </Suspense>
  );
}
