import { replaceNullToString, replaceStringToNull } from "../../helper/object";

const humanReducer = ({ is_address_coincide, ...rest } = {}) => ({
  is_address_coincide: is_address_coincide === "1",
  ...replaceNullToString(rest),
});

const apiReducer = ({ is_address_coincide, ...rest } = {}) => ({
  ...replaceStringToNull(rest),
  is_address_coincide: is_address_coincide ? "1" : "0",
});

export default { from: humanReducer, to: apiReducer };
