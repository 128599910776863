import clsx from "clsx";
import React, { useState, useEffect, useMemo } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

export const ButtonCopy = ({ text, children, overlay }) => {
  const [copying, setCopying] = useState(false);
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => setCopied(false), 3000);
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  const btn = useMemo(
    () => (
      <Button
        size="sm"
        disabled={copying}
        className={clsx(
          copying && "kt-spinner kt-spinner--left kt-spinner--light",
          !children && "btn-icon",
          "mr-2",
        )}
        variant={copied ? "success" : `outline-primary`}
        onClick={async () => {
          setCopying(true);
          await navigator.clipboard.writeText(text);
          setCopying(false);
          setCopied(true);
        }}
      >
        {!copying && <i className={`fa fa-${copied ? "check" : "copy"}`} />}
        {children && children({ copied })}
      </Button>
    ),
    [copying, copied, children, text],
  );

  if (overlay) {
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 100, hide: 400 }}
        overlay={<Tooltip>{overlay(copied)}</Tooltip>}
      >
        {btn}
      </OverlayTrigger>
    );
  }
  return btn;
};
