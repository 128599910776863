import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import WordEditor from "./WordEditor";

class WordModal extends Component {
  constructor(props, ctx) {
    super(props, ctx);
    this.onClose = this.onClose.bind(this);
  }

  // handleShow() {
  //     this.setState({ show: true })
  // }

  onClose() {
    // if (this.props.onClose)
    this.props.onClose();
    // else
    // this.setState({ show: false })
  }

  render() {
    const { documentId, title } = this.props;
    return (
      <Modal size="xl" show={this.props.show} onHide={this.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title || "Документ"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WordEditor
            enableToolbar={false}
            wrapToPortlet={false}
            isReadOnly
            title={title}
            documentId={documentId}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={this.onClose}>
            закрыть
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withRouter(WordModal);
