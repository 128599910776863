import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import WordModal from "./WordModal";

class ButtonWordModal extends Component {
  state = {
    show: false,
  };

  render() {
    const { documentId, title, variant, onClick, children } = this.props;
    return (
      <>
        <Button
          variant={variant}
          children={children}
          onClick={() => this.setState({ show: true })}
        />
        <WordModal
          show={this.state.show}
          onClose={() => this.setState({ show: false })}
          title={title}
          documentId={documentId}
        />
      </>
    );
  }
}

export default withRouter(ButtonWordModal);
