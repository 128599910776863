import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { humanDate } from "../../../../helper/date";
import {
  Portlet,
  PortletHeader,
  PortletBody,
} from "../../../../partials/content/Portlet";
import ButtonWordModal from "../../../../partials/widgets/WordEditor/ButtonWordModal";
import * as fileService from "../../../../service/file";

export class ContractPreview extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    contract: PropTypes.shape(),
  };

  render() {
    const { title, contract } = this.props;

    return (
      <Portlet
        unfill
        variant="secondary"
        style={{ width: 230 }}
        className="mr-3 kt-portlet--head-sm"
      >
        <PortletHeader
          title={<span className="kt-font-dark kt-font-bolder">{title}</span>}
        />
        <PortletBody>
          <p className="kt-font-dark">
            <b>№{contract.number}</b> от {humanDate(contract.date_start)}
          </p>
          {/* <p className="kt-font-dark">
            Срок оплаты до {getPaymentDate(contract)} (
            {contract.payment_form.name})
          </p> */}
          {contract.custom_file_id ? (
            <Button
              onClick={() => fileService.download(contract.custom_file_id)}
              variant="outline-success"
            >
              Скачать
            </Button>
          ) : (
            <ButtonWordModal
              documentId={contract.document_id}
              variant="outline-primary"
            >
              Просмотр
            </ButtonWordModal>
          )}
        </PortletBody>
      </Portlet>
    );
  }
}
