import { parse } from "date-fns";
import { apiDate } from "../../helper/date";

const rowReducer = (carrier) => ({
  ...carrier,
  is_unreliable: carrier.is_unreliable === "1",
  passport_issued_date: carrier.passport_issued_date
    ? parse(carrier.passport_issued_date, "yyyy-MM-dd", new Date())
    : "",
});

const apiReducer = (carrier) => ({
  ...carrier,
  passport_issued_date: apiDate(carrier.passport_issued_date),
  is_unreliable: carrier.is_unreliable ? "1" : "0",
});

export default { from: rowReducer, to: apiReducer };
