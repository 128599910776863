import * as service from "../../service/entity.crud";
import { replaceStringToNull } from "../../helper/object";
import { getError } from "../../service/.errors";
import carrierReducers from "../reducer_entity/carrier";
import requestReducers from "../reducer_entity/request";
import invoiceReducers from "../reducer_entity/invoice";
import waybillReducers from "../reducer_entity/waybill";
import payOrderReducers from "../reducer_entity/payorder";
import userReducers from "../reducer_entity/user";
import contractReducers from "../reducer_entity/contract";
import taxReducer from "../reducer_entity/tax";
import driverReducer from "../reducer_entity/driver";
import requestContractReducers from "../reducer_entity/request_contract";
import documentTemplateReducers from "../reducer_entity/document_templates";
import settingsReducer, {
  defaults as settingsDefaults,
} from "../reducer_entity/settings";

export const events = {
  Pending: "[Event] Pending",
  Success: "[Event] Success",
  Silent: "[Event] Silent",
  Failure: "[Event] Failure",
};

const single = (types, name, id, query, silent) => async (dispatch) => {
  dispatch({
    type: types.Single,
    event: silent ? events.Silent : events.Pending,
    name,
    id,
  });
  try {
    const payload = await service.singleEntity(name, id, query);
    dispatch({ type: types.Single, event: events.Success, name, payload, id });
  } catch (error) {
    dispatch({
      type: types.Single,
      event: events.Failure,
      name,
      errors: getError(error) ?? error,
      id,
    });
  }
};

const create = (types, name, fields) => async (dispatch) => {
  dispatch({ type: types.Create, event: events.Pending, name });
  try {
    const { data } = await service.createEntity(
      name,
      replaceStringToNull(fields),
    );
    dispatch({
      type: types.Create,
      event: events.Success,
      name,
      payload: data,
      id: data.id,
    });
  } catch (error) {
    dispatch({
      type: types.Create,
      event: events.Failure,
      name,
      errors: getError(error) ?? error,
    });
  }
};

const update = (types, name, fields, id) => async (dispatch) => {
  dispatch({ type: types.Update, event: events.Pending, name, id });
  try {
    const payload = await service.updateEntity(
      name,
      replaceStringToNull(fields),
      id,
    );
    dispatch({ type: types.Update, event: events.Success, name, payload, id });
  } catch (error) {
    dispatch({
      type: types.Update,
      event: events.Failure,
      name,
      errors: getError(error) ?? error,
      id,
    });
  }
};

const list = (types, name, settings, simple) => async (dispatch) => {
  dispatch({ type: types.List, event: events.Pending, name, settings });
  try {
    const { data: payload } = await service.readEntities(
      name,
      settings ? settingsReducer.to(settings) : null,
      simple,
    );
    dispatch({
      type: types.List,
      event: events.Success,
      name,
      payload,
    });
  } catch (error) {
    dispatch({
      type: types.List,
      event: events.Failure,
      name,
      errors: error["messages"] ?? error,
    });
  }
};

const remove = (types, name, id, params) => async (dispatch) => {
  dispatch({ type: types.Delete, event: events.Pending, name });
  try {
    const payload = await service.deleteEntity(name, id, params);
    dispatch({ type: types.Delete, event: events.Success, name, payload, id });
  } catch (error) {
    dispatch({
      type: types.Delete,
      event: events.Failure,
      name,
      errors: error["messages"] ?? error,
      id,
    });
  }
};

const createReducer = (types, customReducer) => {
  const initialState = {
    error: null,
    errors: {},
    settings: settingsDefaults,
    rows: [],
    columns: [],
    single: {},
    event: events.Pending,
    isPending: false,
    isSuccess: false,
    lastId: null,
    type: null,
  };
  const reduceState = (oldState, action, newState = {}) => ({
    ...oldState,
    errors: action.errors || {},
    type: action.type,
    event: action.Success,
    isPending: action.event === events.Pending,
    isSuccess: action.event === events.Success,
    lastId: null,
    ...newState,
  });

  const reduceRow = (entity = {}) =>
    customReducer
      ? customReducer(entity)
      : {
          ...entity,
          ...(Object.keys(entity).indexOf("id") !== -1
            ? {
                id: parseInt(entity.id),
              }
            : {}),
        };
  const isSuccess = (action) => action.event === events.Success;

  return (state = initialState, action) => {
    switch (action.type) {
      case types.Create:
      case types.Update:
      case types.Silent:
      case types.Single:
        const id = action.payload?.data?.id || action.id;
        if (!id || action.event === events.Silent)
          return reduceState(state, action);
        return reduceState(state, action, {
          lastId: action.id,
          single: {
            ...state.single,
            [id]: reduceRow(action.payload?.data),
          },
        });
      case types.Delete:
        return reduceState(state, action, {
          rows: isSuccess(action)
            ? state.rows.filter((row) => row.id !== action.id)
            : state.rows,
        });
      case types.List:
        const columns = isSuccess(action)
          ? Object.keys(action.payload.columns).map((name) => ({
              name,
              label: action.payload.columns[name],
            }))
          : state.columns;

        return reduceState(state, action, {
          columns,
          countRows: action.payload?.countRows ?? state.countRows,
          totals: action.payload?.totals ?? state.totals,
          rows: isSuccess(action)
            ? action.payload.rows.map(reduceRow)
            : state.rows,
          settings: action.payload?.settings
            ? settingsReducer.from(action.payload?.settings, columns)
            : state.settings,
          error: action.error,
        });
      default:
        return state;
    }
  };
};

export const createCrud = (
  name,
  { from: rowReducer = (v) => v, to: apiReducer = (v) => v } = {},
) => {
  const types = {
    Create: `[${name}] Create`,
    Update: `[${name}] Update`,
    Single: `[${name}] Single`,
    List: `[${name}] List`,
    Delete: `[${name}] Delete`,
    Export: `[${name}] Export`,
  };
  const actions = {
    list: (settings, simple) => list(types, name, settings, simple),
    single: (id, query, silent = false) =>
      single(types, name, id, query, silent),
    create: (fields) => create(types, name, apiReducer(fields)),
    update: (fields, id) => update(types, name, apiReducer(fields), id),
    remove: (id, params) => remove(types, name, id, params),
    search: (query, rule) => service.searchEntities(name, query, rule),
    restore: (ids) => service.restore(name, ids),
    export: () => service.excelExport(name),
    clearErrors: () => (dispatch) =>
      dispatch({ type: types.Create, errors: [] }),
  };
  const reducer = createReducer(types, rowReducer);
  return { actions, types, reducer, name };
};

export const cargoEntity = createCrud("catalog/cargo");
export const cargoPackEntity = createCrud("catalog/cargo_pack");
export const carLoadTypeEntity = createCrud("catalog/car_load_type");
export const carBodyTypeEntity = createCrud("catalog/car_body_type");
export const userRoleEntity = createCrud("catalog/user_role");
export const bankEntity = createCrud("bank");
export const employeeEntity = createCrud("employee");
export const customersEntity = createCrud("customers");
export const driversEntity = createCrud("drivers", driverReducer);
export const carsEntity = createCrud("driver_cars");
export const documentEntity = createCrud("document");
export const orgsEntity = createCrud("orgs");
export const taxEntity = createCrud("catalog/tax", taxReducer);
export const userEntity = createCrud("catalog/user", userReducers);
export const carrierEntity = createCrud("driver_carrier", carrierReducers);
export const invoiceEntity = createCrud("invoice", invoiceReducers);
export const waybillEntity = createCrud("waybill", waybillReducers);
export const payOrderEntity = createCrud("payorder", payOrderReducers);
export const contractEntity = createCrud("contract", contractReducers);
export const ownOrgEntity = createCrud("own_org");
export const requestProgressEntity = createCrud(
  "requests/progress",
  requestReducers,
);
export const requestDeletedEntity = createCrud(
  "requests/deleted",
  requestReducers,
);
export const requestPendingEntity = createCrud(
  "requests/pending",
  requestReducers,
);
export const documentTemplateEntity = createCrud(
  "document_template",
  documentTemplateReducers,
);
export const requestContractEntity = createCrud(
  "requests/contract",
  requestContractReducers,
);
