import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Portlet,
  PortletHeader,
  PortletBody,
} from "../../../../partials/content/Portlet";
import { humanDate } from "../../../../helper/date";
import { DropdownButton, Dropdown, Modal, Button } from "react-bootstrap";
import WordModal from "../../../../partials/widgets/WordEditor/WordModal";
import {
  requestProgressEntity,
  requestContractEntity,
} from "../../../../store/ducks/entity.duck";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import * as documentService from "../../../../service/document";
import * as requestService from "../../../../service/request";

const mapDispatchToProps = (dispatch) => ({
  deleteContract: (id) => dispatch(requestContractEntity.actions.remove(id)),
  fetchRequest: (id) =>
    dispatch(
      requestProgressEntity.actions.single(id, { "": "extended" }, true),
    ),
});

class RequestDocPreview extends Component {
  static propTypes = {
    requestId: PropTypes.string,
    contract: PropTypes.shape({
      document_id: PropTypes.string,
      title: PropTypes.string,
    }),
  };

  set(key, val) {
    this.setState({
      fields: {
        ...this.state.fields,
        [key]: val,
      },
    });
  }

  state = {
    showModal: false,
    showDeleteModal: false,
    isPending: false,
  };

  async deleteContract() {
    this.setState({ isPending: true });
    await this.props.deleteContract(this.props.contract.id);
    await this.props.fetchRequest(this.props.requestId);
    this.setState({ isPending: false });
  }

  edit() {
    const uri = `${window.location.pathname}/contract/${this.props.contract.id}`;
    this.props.history.push(uri.replace("//", "/"));
  }

  getDownloadHandler(type) {
    return () => {
      if (type === "pdf") {
        requestService.pdfWithSignature(this.props.contract.id);
      } else if (type === "word") {
        documentService.download(this.props.contract.document_id);
      }
    };
  }

  render() {
    const { contract, disabled } = this.props;
    const { isPending } = this.state;

    return (
      <Portlet
        unfill
        variant="secondary"
        style={{ width: 230 }}
        className="mr-3 kt-portlet--head-sm"
      >
        <PortletHeader
          title={
            <span className="kt-font-dark kt-font-bolder">
              {contract?.title}
            </span>
          }
        />
        <PortletBody style={{ justifyContent: "space-between" }}>
          <p className="kt-font-dark">
            <b>№{contract.number}</b> от{" "}
            {contract.date && humanDate(contract.date)}
          </p>

          <DropdownButton style={{ width: "100%" }} title="Действия">
            <Dropdown.Item
              eventKey="show"
              onClick={() => this.setState({ showModal: true })}
            >
              <i className="fa fa-eye kt-font-lg kt-font-default" />
              &nbsp;Просмотр
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="show"
              onClick={this.getDownloadHandler("pdf")}
            >
              <i className="fa fa-file kt-font-lg kt-font-warning" />
              &nbsp;PDF с подписью
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="show"
              onClick={this.getDownloadHandler("word")}
            >
              <i className="fa fa-download kt-font-lg kt-font-success" />
              &nbsp;Скачать в формате Word
            </Dropdown.Item>
            {!disabled && (
              <Dropdown.Item eventKey="edit" onClick={() => this.edit()}>
                <i className="fa fa-edit kt-font-lg kt-font-info" />
                &nbsp;Изменить
              </Dropdown.Item>
            )}
            {!disabled && (
              <Dropdown.Item
                eventKey="delete"
                onClick={() => this.setState({ showDeleteModal: true })}
              >
                <i className="fa fa-trash kt-font-lg kt-font-danger" />
                &nbsp;Удалить
              </Dropdown.Item>
            )}
          </DropdownButton>
          <WordModal
            documentId={contract.document_id}
            title={
              <>
                {contract?.title} №{contract.number}
                <Button
                  size="sm"
                  variant="outline-success"
                  className="ml-2"
                  onClick={this.getDownloadHandler("word")}
                >
                  <i className="fa fa-download" /> Word
                </Button>
                <Button
                  size="sm"
                  variant="outline-info"
                  className="ml-2"
                  onClick={this.getDownloadHandler("pdf", true)}
                >
                  <i className="fa fa-download" /> PDF с подписью
                </Button>
              </>
            }
            show={this.state.showModal}
            onClose={() => this.setState({ showModal: false })}
          />
        </PortletBody>
        <Modal
          show={this.state.showDeleteModal}
          onHide={() => this.setState({ showDeleteModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p className="kt-font-danger">Внимание! Действие необратимо.</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6>Подтвердите удаление документа</h6>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isPending}
              variant="light"
              onClick={() => this.setState({ showDeleteModal: false })}
            >
              отменить
            </Button>
            <Button
              disabled={isPending}
              variant="danger"
              onClick={() => this.deleteContract()}
              className={clsx({
                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                  isPending,
              })}
            >
              Подтвердить
            </Button>
          </Modal.Footer>
        </Modal>
      </Portlet>
    );
  }
}

export default withRouter(connect(null, mapDispatchToProps)(RequestDocPreview));
