import React from "react";
import popupDuck from "../store/ducks/popup.duck";
import store from "../store/store";
import { actions } from "../store/ducks/auth.duck";

export class ValidationError extends Error {
  constructor(messages) {
    super(`ValidationError: ${JSON.stringify(messages, null, 2)}`);
    this.messages = messages;
  }
}

export const handleError = async (error) => {
  if (!error.response) {
    store.dispatch(
      popupDuck.actions.error({
        title: "Ошибка",
        content: (
          <p className="kt-font-lg kt-font-dark">
            Пожалуйста, проверьте подключение к интернету. <br />
            Если интернет есть, обратитесь к администратору системы.
            <br />
            Тип ошибки: [{error.status ?? error.code ?? "NETW"}]{" "}
            {error.message.toString()}
            <br />
            Запрос: [{error.config.method.toUpperCase()}] {error.config.url}
          </p>
        ),
      }),
    );
  } else {
    const { status, data } = error.response;
    switch (true) {
      case status === 400:
        if (error.config.method != "get")
          store.dispatch(
            popupDuck.actions.error({
              title: "Ошибка валидации",
              content:
                data.messages instanceof Array &&
                data.messages.length > 0 &&
                typeof data.messages[0] === "string" ? (
                  <p>{data.messages.join(<br />)}</p>
                ) : (
                  <p>Пожалуйста, проверьте форму на ошибки.</p>
                ),
            }),
          );
        if (data.messages) throw new ValidationError(data.messages);
        break;
      case status === 403:
        error.message =
          "У вас недостаточно прав для доступа к этот раздел. Обратитесь к администратору.";
        store.dispatch(
          popupDuck.actions.error({
            title: "Ошибка доступа",
            content: "У вас недостаточно прав для этого действия.",
          }),
        );
        break;
      case status === 401:
        if (store.getState().auth.user) {
          store.dispatch(actions.logout());
          store.dispatch(
            popupDuck.actions.error({
              title: "Ошибка авторизации",
              content: "Вы не авторизованы для этого действия.",
            }),
          );
        }
        break;
      case status >= 500:
        store.dispatch(
          popupDuck.actions.error({
            title: "Системная ошибка",
            content: (
              <p className="kt-font-lg kt-font-dark">
                Пожалуйста, скопируйте текст ниже и обратитесь к администратору
                системы. Запрос: [{error.config?.method}] {error.config?.url}{" "}
                <br />
                Ответ: [{status}] {JSON.stringify(data?.message)} <br />
              </p>
            ),
          }),
        );
        break;
    }
  }
  throw error;
};

export const getError = (error) => {
  if (error instanceof ValidationError) {
    return error.messages;
  } else {
    return { common: error.message, response: error.response };
  }
};
