import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Button, ProgressBar } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";
import ConfirmButton from "../ConfirmButton";
import * as service from "../../../service/file";
import "./file-list.scss";
import { useDispatch } from "react-redux";
import globalPopup from "../../../store/ducks/popup.duck";
import { getError } from "../../../service/.errors";

const FileIcon = ({ type, ...rest }) => {
  switch (type) {
    case "text/xml":
      return (
        <img alt="XML" src={toAbsoluteUrl("/media/files/xml.svg")} {...rest} />
      );
    case "application/pdf":
      return (
        <img alt="PDF" src={toAbsoluteUrl("/media/files/pdf.svg")} {...rest} />
      );
    case "application/doc":
    case "application/docx":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/msword":
      return (
        <img
          alt="Document"
          src={toAbsoluteUrl("/media/files/doc.svg")}
          {...rest}
        />
      );
    case "text/xls":
      return (
        <img
          alt="Sheet"
          src={toAbsoluteUrl("/media/files/xls.svg")}
          {...rest}
        />
      );
    case "image/jpg":
    case "image/jpeg":
      return (
        <img alt="pic" src={toAbsoluteUrl("/media/files/jpg.svg")} {...rest} />
      );
    default:
      return <i className="flaticon2-file kt-font-focus icon" {...rest} />;
  }
};

export default function FileList(props) {
  const dispatch = useDispatch();
  const [list, setFileList] = useState([]);
  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (props.onStartUpload) props.onStartUpload(acceptedFiles);
      setFileList((old) => [
        ...acceptedFiles.map((file) => ({
          key: parseInt(Math.random() * 1000),
          isPending: true,
          mime: file.type,
          title: file.name,
          progress: 1,
          raw: file,
        })),
        ...old,
      ]);
      // console.log('start', acceptedFiles.map(file => ({
      //   key: parseInt(Math.random() * 1000),
      //   isPending: true,
      //   mime: file.type,
      //   title: file.name,
      //   progress: 1,
      //   raw: file
      // })))
      const uploadedFiles = await Promise.all(
        acceptedFiles.map(async (uploadingFile) => {
          try {
            const response = await service.upload(
              uploadingFile,
              ({ progress }) => {
                setFileList((old) =>
                  old.map((file) => {
                    if (file.raw === uploadingFile) file.progress = progress;
                    return file;
                  }),
                );
              },
            );
            if (response.data && response.data.id) {
              setTimeout(() => {
                setFileList((old) =>
                  old.map((file) => {
                    if (file.raw === uploadingFile) {
                      response.data.key = file.raw.key;
                      return response.data;
                    }
                    return file;
                  }),
                );
              }, 1000);
              return response.data;
            } else {
              throw Error(response);
            }
          } catch (err) {
            setFileList((old) =>
              old.filter((file) => file.raw !== uploadingFile),
            );
            dispatch(
              globalPopup.actions.error({
                title: "Ошибка",
                content: getError(err),
              }),
            );
            // throw err
          }
        }),
      );
      props.onUpload(uploadedFiles.filter((file) => file && file.id > 0));
    },
    [props, dispatch],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: props.multiple,
  });
  useEffect(() => {
    if (props.uploadButton && props.uploadButton.current) {
      const runUpload = (ev) => getRootProps().onClick(ev);
      props.uploadButton.current.addEventListener("click", runUpload);
      return () =>
        props.uploadButton.current?.removeEventListener("click", runUpload);
    }
  }, [props.uploadButton, getRootProps]);

  useEffect(() => {
    if (props.defaultValue) {
      if (props.defaultValue.length) {
        service.list(props.defaultValue).then(({ data }) => {
          setFileList(data);
        });
      } else {
        setFileList([]);
      }
    }
  }, [props.defaultValue]);

  return (
    <div className="kt-widget4">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive && (
          <p>Положите файл{props.multiple ? "ы" : ""} сюда ...</p>
        )}
        {!props.uploadButton && (
          <>
            <h6 style={{ textAlign: "center", margin: "10px 0" }}>
              Перетащите файл{props.multiple ? "ы" : ""} сюда и они загрузятся
              автоматически
            </h6>
            <Button block>Добавить файл</Button>
          </>
        )}
      </div>
      {list.map((file) => (
        <div
          className="kt-widget4__item"
          key={file.key || file.id}
          style={{ opacity: file.deleting ? 0.5 : 1 }}
        >
          {file.isPending && (
            <ProgressBar
              now={file.progress}
              striped
              label={`${file.progress}%`}
            />
          )}
          {file.isError && (
            <ProgressBar
              now={100}
              variant="danger"
              label={`Ошибка. Файл "${file.path}" не загружен.`}
            />
          )}
          <div className="kt-widget4__pic kt-widget4__pic--pic ">
            <FileIcon type={file.mime} />
          </div>
          <div className="kt-widget4__info">
            <a
              href={file.fullpath}
              target="_blank"
              rel="noopener noreferrer"
              className="kt-widget4__title"
              download
            >
              {file.title}
            </a>
          </div>
          {!props.hideActions && (
            <>
              {file.fullpath && (
                <a
                  href={file.fullpath}
                  target="_blank"
                  download
                  rel="noopener noreferrer"
                  className="btn btn-sm btn-outline-success mr-3"
                >
                  <i className="flaticon2-download pr-0 kt-font-focus" />
                </a>
              )}
              {!file.isPending && !file.error && file.id && (
                <ConfirmButton
                  size="sm"
                  action={() => {
                    setFileList((old) =>
                      old.map((f) => ({
                        deleting: f.id === file.id,
                        ...f,
                      })),
                    );
                    props.onDelete(file);
                  }}
                  variant="label-danger"
                >
                  удалить
                </ConfirmButton>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
}
