import React from "react";
import RequestForm from "../common/RequestForm";
import EntityList from "../../../../components/entity/EntityList";
import { requestPendingEntity } from "../../../../store/ducks/entity.duck";
import RequestCard from "../common/RequestCard";
import { REQUEST_DRAFT_STATUS_ID } from "../../../../store/staticStore";
import { Link } from "react-router-dom";
import ConfirmButton from "../../../../partials/content/ConfirmButton";

export const RequestPendingList = () => {
  return (
    <EntityList
      actions={requestPendingEntity.actions}
      actionsComponent={(props) => (
        <>
          <Link
            size="sm"
            className="btn btn-clean btn-icon btn-icon-md"
            to={`/${props.name}/create?copy=${props.row.id}`}
          >
            <i title="Скопировать" className="flaticon2-copy" />
          </Link>
          <ConfirmButton
            isClear
            size="sm"
            action={() => props.remove(props.row.id)}
            variant="clean"
          >
            <i title="Удалить" className="la la-trash kt-font-danger" />
          </ConfirmButton>
        </>
      )}
      select={(state) => state.requests.pending}
      isServerFilter
      clickLink="/requests/pending/:id"
      name="requests/pending"
    />
  );
};

export const RequestPendingCreate = () => {
  return (
    <RequestForm
      entity={requestPendingEntity}
      creationStatusID={REQUEST_DRAFT_STATUS_ID}
      select={(state) => state.requests.pending}
    />
  );
};

export const RequestPendingCard = (props) => {
  return (
    <RequestCard
      entity={requestPendingEntity}
      select={(state) => state.requests.pending}
      id={props.match.params.id}
    />
  );
};

export const RequestPendingEdit = (props) => {
  return (
    <RequestForm
      entity={requestPendingEntity}
      select={(state) => state.requests.pending}
      creationStatusID={REQUEST_DRAFT_STATUS_ID}
      id={props.match.params.id}
    />
  );
};
