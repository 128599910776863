import driver from "./.driver";
import { stringify } from "../helper/query-stringifier";

export const getNumber = () => driver.get(`requests/progress/get_number`);

export const comment = (id, text) =>
  driver.post(`requests/progress/comment/${id}`, { comment: text });

export const changeStatus = (id, status_id) =>
  driver.post(`requests/progress/set_status/${id}`, { status_id });

export const setFinance = (id, payload) =>
  driver.post(`requests/progress/finance/${id}`, payload);

export const convert = (id) => driver.get(`requests/pending/convert/${id}`);

export const getContractNumber = () =>
  driver.get("requests/contract/get_number");

export const generateDocument = (contractId) =>
  driver.post("requests/contract/generate_document/" + contractId);

export const pdfWithSignature = (id) =>
  driver.open("/requests/contract/pdf/" + id + "?signature=true");

export const getList = (filter) =>
  driver.get(`/requests/search/${stringify(filter)}`);
