import React from "react";
import EntityForm from "../../../components/entity/EntityForm";
import EntityList from "../../../components/entity/EntityList";

import { userEntity, userRoleEntity } from "../../../store/ducks/entity.duck";

export function UserList() {
  return (
    <EntityList
      actions={userEntity.actions}
      select={(state) => state.catalog.users}
      name="catalog/user"
    />
  );
}

const schema = {
  name: { label: "Имя" },
  lastname: { label: "Фамилия" },
  email: { type: "email", label: "Email" },
  phone: { label: "Телефон" },
  password: { type: "password", label: "Пароль" },
  role_id: {
    label: "Роль",
    type: "select",
    select: {
      label: (role) => role.name,
      value: (role) => role.id,
      search: (name) => userRoleEntity.actions.search({ name }),
      emptyMessage: "Ролей не найдено",
    },
  },
};

export function UserEdit(props) {
  return (
    <EntityForm
      name="catalog/user"
      id={props.match.params.id}
      schema={schema}
      actions={userEntity.actions}
      actionTypes={userEntity.types}
      select={(state) => state.catalog.users}
    />
  );
}

export function UserCreate() {
  return (
    <EntityForm
      name="catalog/user"
      schema={schema}
      actions={userEntity.actions}
      actionTypes={userEntity.types}
      select={(state) => state.catalog.users}
    />
  );
}
