import * as routerHelpers from "../../router/RouterHelpers";
import { login, logout, getUserProfile } from "../../service/auth.crud";
import { events } from "./entity.duck";

export const authTypes = {
  Pending: "[Login] Pending",
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  UserProfile: "[Load User] Auth API",
};

const initialAuthState = {
  isPending: false,
  user: undefined,
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case authTypes.Login:
      return {
        isPending: action.event === events.Pending,
        user: undefined,
      };
    case authTypes.Logout:
      routerHelpers.forgotLastLocation();
      return initialAuthState;
    case authTypes.UserProfile:
      return {
        isPending: action.event === events.Pending,
        user: action.payload,
        error: action.error,
      };
    default:
      return state;
  }
};

export const actions = {
  login: (email, password) => async (dispatch) => {
    dispatch({ type: authTypes.Login, event: events.Pending });
    await login(email, password);
    dispatch({ type: authTypes.Login, event: events.Success });
  },
  logout: () => async (dispatch) => {
    await logout();
    dispatch({ type: authTypes.Logout });
  },
  requestUser: () => async (dispatch) => {
    dispatch({ type: authTypes.UserProfile, event: events.Pending });
    try {
      const user = await getUserProfile();
      dispatch({
        type: authTypes.UserProfile,
        event: events.Success,
        payload: user.data,
      });
    } catch (error) {
      dispatch({ type: authTypes.UserProfile, event: events.Failure, error });
    }
  },
};
