import React, { useState, useEffect } from "react";
import { apiDate, humanDate } from "../../helper/date";
import { parseISO, isAfter } from "date-fns";
import { replaceNullToString, replaceStringToNull } from "../../helper/object";
import { boolLabel } from "../../helper/label";
import { priceFormatter } from "../../helper/number";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import clsx from "clsx";
import { ButtonCopy } from "../../widgets/ButtonCopy";

const humanReducer = ({ summary, ...rest } = {}) => ({
  ...replaceNullToString(rest),
  summary: (
    <>
      <ButtonCopy
        text={summary}
        overlay={(copied) => (copied ? "Скопировано" : "Скопировать")}
      />
      {summary}
    </>
  ),
});

export default { from: humanReducer, to: (i) => i };
