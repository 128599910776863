import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Form, Button, Tab } from "react-bootstrap";
import clsx from "clsx";
import { LinearProgress } from "@material-ui/core";
import {
  Portlet,
  PortletHeader,
  PortletFooter,
  PortletBody,
} from "../../../partials/content/Portlet";
import { connect } from "react-redux";
import { ownOrgEntity } from "../../../store/ducks/entity.duck";
import OrgForm from "../../../partials/widgets/OrgForm";
import InputAutoComplete from "../../../partials/content/AutoComplete/InputAutoComplete";
import data_searchDuck from "../../../store/ducks/data_search.duck";
import FileList from "../../../partials/content/FileList/FileList";
import * as fileSerivce from "../../../service/file";

const mapStateToProps = (
  { ownOrg: { errors, isPending, isSuccess, rows } },
  props,
) => ({
  errors,
  isPending,
  isSuccess,
  rows,
});

const mapDispatchToProps = {
  list: ownOrgEntity.actions.list,
  update: ownOrgEntity.actions.update,
  fetchBank: data_searchDuck.actions.searchBank,
};

class SelfOrganizationPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeOrg = this.onChangeOrg.bind(this);
    this.onChangeBank = this.onChangeBank.bind(this);
  }

  static propTypes = {
    id: PropTypes.string,
    list: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    isPending: PropTypes.bool,
  };

  state = {
    isLoaded: false,
    isUploading: false,
    org: {
      org_type: "",
      inn: "",
      name: "",
      short_name: "",
      city: "",
      basis: "",
      address: "",
      real_address: "",
      is_address_coincide: false,
      kpp: "",
      ogrn: "",
      ati_code: "",
      ati_score: 0,
      phone1: "",
      phone2: "",
      phone3: "",
      email: "",
      website: "",
    },
    customer_files: [],
    driver_files: [],
    bank: {
      bic: "",
      name: "",
      correspondent_account: "",
      payment_account: "",
    },
    errors: [],
    validated: false,
  };

  selectBank({
    value: name,
    data: { bic, correspondentAccount: correspondent_account },
  }) {
    this.setState({
      bank: { ...this.state.bank, correspondent_account, bic, name },
    });
    return bic;
  }

  async componentDidMount() {
    this.props.list();
  }

  async UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.rows.length && nextProps.rows !== this.props.rows) {
      const [entry] = nextProps.rows;
      this.setState({
        isLoaded: true,
        org: { ...this.state.org, ...entry.org },
        bank: { ...this.state.bank, ...entry.bank },
        customer_files: entry.customer_files?.split(",") ?? [],
        driver_files: entry.driver_files?.split(",") ?? [],
      });
    }
  }

  async handleSubmit(ev) {
    ev.preventDefault();
    const [entry] = this.props.rows;
    // console.log(entry)
    const { org, bank, customer_files, driver_files } = this.state;
    await this.props.update(
      {
        org,
        bank,
        customer_files: customer_files.join(","),
        driver_files: driver_files.join(","),
      },
      entry.id,
    );
  }

  onChangeOrg(newOrg) {
    this.setState({ org: { ...this.state.org, ...newOrg } });
  }

  onChangeBank(ev) {
    this.setState({
      bank: {
        ...this.state.bank,
        [ev.target.name]: ev.target.value,
      },
    });
  }

  render() {
    const {
      isLoaded,
      validated,
      bank,
      customer_files,
      driver_files,
      isUploading,
    } = this.state;
    const { isPending, errors } = this.props;
    if (!isLoaded) {
      return <LinearProgress />;
    }
    return (
      <Form
        noValidate
        validated={validated}
        onSubmit={this.handleSubmit}
        style={{ maxWidth: 900, margin: "0 auto" }}
      >
        <Portlet>
          <PortletHeader title="Данные организации" />
          <PortletBody>
            <OrgForm
              errors={errors}
              onChange={this.onChangeOrg}
              fields={this.state.org}
            >
              <Tab eventKey="bank" title="Реквизиты">
                <Form.Group>
                  <Form.Label>БИК</Form.Label>
                  <InputAutoComplete
                    name="bic"
                    fullWidth
                    select={(state) => state.banks}
                    getLabel={({ value, data }) => `${value} (${data.bic})`}
                    fetchResults={this.props.fetchBank}
                    value={bank.bic}
                    onChange={this.onChangeBank}
                    isInvalid={errors.hasOwnProperty("bic")}
                    onSelect={(val) => this.selectBank(val)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["bic"]}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Наименование</Form.Label>
                  <Form.Control
                    name="name"
                    isInvalid={errors.hasOwnProperty("name")}
                    onChange={this.onChangeBank}
                    value={bank["name"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["name"]}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Корр. счет</Form.Label>
                  <Form.Control
                    name="correspondent_account"
                    onChange={this.onChangeBank}
                    isInvalid={errors.hasOwnProperty("correspondent_account")}
                    value={bank["correspondent_account"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["correspondent_account"]}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Рассчетный счет</Form.Label>
                  <Form.Control
                    name="payment_account"
                    onChange={this.onChangeBank}
                    isInvalid={errors.hasOwnProperty("payment_account")}
                    value={bank["payment_account"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["payment_account"]}
                  </Form.Control.Feedback>
                </Form.Group>
              </Tab>
              <Tab eventKey="customer_files" title="Документы для заказчика">
                <FileList
                  defaultValue={customer_files}
                  onStartUpload={() => this.setState({ isUploading: true })}
                  onUpload={(files) =>
                    this.setState({
                      customer_files: [
                        ...customer_files,
                        ...files.map((f) => f.id),
                      ],
                      isUploading: false,
                    })
                  }
                  onDelete={(file) =>
                    this.setState({
                      customer_files: customer_files.filter(
                        (id) => id !== file.id,
                      ),
                    })
                  }
                />
                {!customer_files.length ? null : (
                  <Button
                    variant="secondary"
                    onClick={() =>
                      fileSerivce.downloadZip(this.state.customer_files)
                    }
                  >
                    <i className="fa fa-download" />
                    &nbsp; Скачать все файлы одним архивом
                  </Button>
                )}
              </Tab>
              <Tab eventKey="driver_files" title="Документы для перевозчика">
                <FileList
                  defaultValue={driver_files}
                  onStartUpload={() => this.setState({ isUploading: true })}
                  onUpload={(files) =>
                    this.setState({
                      driver_files: [
                        ...driver_files,
                        ...files.map((f) => f.id),
                      ],
                      isUploading: false,
                    })
                  }
                  onDelete={(file) =>
                    this.setState({
                      driver_files: driver_files.filter((id) => id !== file.id),
                    })
                  }
                />
                {!driver_files.length ? null : (
                  <Button
                    variant="secondary"
                    onClick={() =>
                      fileSerivce.downloadZip(this.state.driver_files)
                    }
                  >
                    <i className="fa fa-download" />
                    &nbsp; Скачать все файлы одним архивом
                  </Button>
                )}
              </Tab>
            </OrgForm>
          </PortletBody>
          <PortletFooter sticky>
            <Button
              variant="success"
              disabled={isPending || isUploading}
              onClick={this.handleSubmit}
              type="button"
              className={clsx("mr-2", {
                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                  isPending,
              })}
            >
              Сохранить
            </Button>
            <Button
              variant="danger"
              disabled={isPending || isUploading}
              onClick={() => this.props.history.goBack()}
              type="button"
              className="mr-2"
            >
              Отменить
            </Button>
          </PortletFooter>
        </Portlet>
      </Form>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SelfOrganizationPage),
);
