import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { Form, Button, Modal } from "react-bootstrap";
import clsx from "clsx";
import "./select-entity.scss";

export default class SelectEntity extends Component {
  static propTypes = {
    search: PropTypes.func,
    isClearable: PropTypes.bool,
    allowSelectAll: PropTypes.bool,
    noOptionsMessage: PropTypes.string,
    errorText: PropTypes.string,
    value: PropTypes.any,
    getOptionLabel: PropTypes.func,
    getOptionValue: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    creatable: PropTypes.bool,
    CreateChild: PropTypes.func,
  };

  static defaultProps = {
    creatable: false,
  };

  selectRef = React.createRef();

  state = {
    value: "",
    disabled: false,
    multiple: false,
    options: [],
    selected: [],
    showModal: false,
    createdElementId: Math.random(),
  };

  request = {};

  fetchOptions(value, callback) {
    if (this.request[value]) {
      this.request[value].cbs.push(callback);
      return this.request[value].promise;
    }
    this.request[value] = { cbs: [callback] };
    this.request[value].promise = this.props.search(value).then(({ data }) => {
      // console.log(data)
      if (this.props.allowSelectAll) {
        data.unshift?.({ name: "Все", id: "all" });
      }
      this.setState({ options: data });
      this.request[value].cbs.forEach((cb) => cb(data));
      delete this.request[value];
    });
  }

  getObjValue(value) {
    const { getOptionValue, multiple } = this.props;
    if (multiple) {
      return this.state.options.filter((opt) =>
        value.includes(getOptionValue(opt)),
      );
    } else {
      return this.state.options.find((opt) => value === getOptionValue(opt));
    }
  }

  setValue(value) {
    if (!value) {
      this.setState({ value: "" });
    } else if (value && this.props.search) {
      if (!this.state.options.length) {
        this.fetchOptions("", () => this.setValue(value));
      } else {
        this.setState({ value: this.getObjValue(value) });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setValue(props.value);
  }

  componentDidMount() {
    this.setValue(this.props.value);
  }

  async afterCreate(entity) {
    this.setState(
      { showModal: false, createdElementId: entity.id },
      async () => {
        this.fetchOptions("", () => {
          if (this.props.multiple) {
            this.onChange([...this.props.value.map((id) => ({ id })), entity]);
          } else {
            this.onChange(entity);
          }
        });
      },
    );
  }

  // componentDidUpdate() {
  //   const { selected, options } = this.state
  //   if (this.props.allowSelectAll && selected?.find(el => el.id === 'all') && options.length > 1) {
  //     this.setState({ options: options.filter(el => el.id === 'all') });
  //   }
  // }

  // onChange(selected) {
  //   this.setState({ selected })
  //   this.props.onChange(selected)
  // }

  render() {
    return (
      <>
        <div
          className={clsx("search-inner", {
            "is-invalid": this.props.errorText > "",
          })}
        >
          {this.props.search ? (
            <AsyncSelect
              className={clsx(this.props.className, {
                // "is-invalid": this.props.errorText > ""
              })}
              isClearable={this.props.isClearable}
              cacheOptions
              defaultOptions
              key={this.state.createdElementId}
              isDisabled={this.props.disabled}
              value={this.state.value}
              loadOptions={this.props.search && this.fetchOptions.bind(this)}
              getOptionLabel={this.props.getOptionLabel}
              getOptionValue={this.props.getOptionValue}
              onChange={this.props.onChange}
              onInputChange={this.handleChange}
              loadingMessage={() => "Поиск..."}
              placeholder="не выбрано"
              isMulti={this.props.multiple}
              noOptionsMessage={() => this.props.noOptionsMessage}
            />
          ) : (
            <Select
              className={clsx(this.props.className, {
                // "is-invalid": this.props.errorText > ""
              })}
              isMulti={this.props.multiple}
              isClearable={this.props.isClearable}
              ref={this.selectRef}
              value={this.props.value}
              isDisabled={!this.props.static || this.props.disabled}
              options={this.props.options}
              getOptionLabel={this.props.getOptionLabel}
              getOptionValue={this.props.getOptionValue}
              onChange={this.props.onChange}
              errorText={this.props.errorText}
              placeholder="не выбрано"
              noOptionsMessage={() => this.props.noOptionsMessage}
            />
          )}
          {this.props.creatable && this.props.CreateChild && (
            <>
              <Button
                disabled={this.props.disabled}
                size="sm"
                block={false}
                variant="success"
                className="btn-icon ml-1"
                onClick={() => this.setState({ showModal: true })}
              >
                <i className="fa fa-plus" />
              </Button>
              <Modal
                show={this.state.showModal}
                onHide={() => this.setState({ showModal: false })}
              >
                <div style={{ boxShadow: "0 0 20px rgba(0,0,0,.4)" }}>
                  <Modal.Header closeButton>
                    <Modal.Title>Добавить элемент</Modal.Title>
                  </Modal.Header>
                  {
                    <this.props.CreateChild
                      key={this.state.createdElementId}
                      v
                      onSuccess={this.afterCreate.bind(this)}
                      onCancel={() => this.setState({ showModal: false })}
                    />
                  }
                </div>
              </Modal>
            </>
          )}
        </div>
        <Form.Control.Feedback type="invalid">
          {this.props.errorText}
        </Form.Control.Feedback>
      </>
    );
  }
}
