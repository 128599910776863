import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Form } from "react-bootstrap";
import SelectEntity from "./SelectEntity";
import WordEditor from "../../partials/widgets/WordEditor/WordEditor";

class EntityField extends Component {
  static propTypes = {
    col: PropTypes.shape({
      md: PropTypes.string,
    }),
    field: PropTypes.shape(),
    error: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.any,
    select: PropTypes.shape({
      label: PropTypes.func.isRequired,
      value: PropTypes.func.isRequired,
      emptyMessage: PropTypes.string,
      search: PropTypes.func.isRequired,
    }),
    src: PropTypes.shape({
      actions: PropTypes.shape({
        search: PropTypes.func,
      }),
    }),
  };

  static defaultProps = {
    disabled: false,
  };

  render() {
    return (
      <Col md="12" style={{ marginBottom: 10 }}>
        {this.renderType(this.props.type)}
      </Col>
    );
  }

  renderType(type) {
    switch (type) {
      case "select":
        return this.renderSelect();
      case "radio":
        if (!this.props.defaultValue) {
          setTimeout(() =>
            this.props.onChange({
              target: { value: this.props.field.options[0] },
            }),
          );
        }
        return (
          <>
            <Form.Label column sm="2">
              {this.props.children}
            </Form.Label>
            <Col md="12">
              {this.props.field.options.map((value, i) => (
                <Form.Check
                  key={`radio-${value}`}
                  type="radio"
                  id={`radio-${value}`}
                  onChange={() => this.props.onChange({ target: { value } })}
                  checked={this.props.defaultValue === value}
                  label={value}
                />
              ))}
              <hr />
            </Col>
          </>
        );
      case "word":
        return (
          <WordEditor
            sfdt={this.props.defaultValue}
            showTopActions={false}
            showBottomActions={false}
            onChange={async ({ source }) => {
              const blob = await source.documentEditor.saveAsBlob("Sfdt");
              if (blob && blob.text) {
                const value = JSON.parse(await blob.text());
                this.props.onChange({
                  target: { value },
                });
              }
            }}
          />
        );
      default:
        return this.renderInput();
    }
  }

  renderSelect() {
    const { children, onChange, defaultValue, error, disabled, name } =
      this.props;
    return (
      <>
        <Form.Label column sm="2">
          {children}
        </Form.Label>
        <SelectEntity
          name={name}
          errorText={error}
          disabled={disabled}
          search={this.props.select.search}
          onChange={(value) =>
            onChange({
              target: { name, value: this.props.select.value(value) },
            })
          }
          value={defaultValue}
          getOptionLabel={this.props.select.label}
          getOptionValue={this.props.select.value}
          noOptionsMessage={this.props.select.emptyMessage}
        />
      </>
    );
  }

  id = parseInt(Math.random() * 1000, 10);

  renderInput() {
    const { children, onChange, defaultValue, error, disabled } = this.props;
    return (
      <>
        <Form.Label column sm="2">
          {children}
        </Form.Label>
        <Form.Control
          disabled={disabled}
          type={this.props.type}
          isInvalid={error > ""}
          onChange={onChange}
          value={defaultValue}
          autoComplete={`form_input${this.id}`}
          name={`form_input${this.id}`}
          id={`form_input${this.id}`}
        />
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </>
    );
  }
}

export default EntityField;
