import React, { Component } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  customersEntity,
  driversEntity,
} from "../../../../store/ducks/entity.duck";
import { DateRangePicker } from "react-date-range";
import { humanDate, apiDate } from "../../../../helper/date";
import { format, parseISO } from "date-fns";
import SelectEntity from "../../../../components/entity/SelectEntity";
import { connect } from "react-redux";
import { RangePicker } from "../../../../partials/widgets/RangePicker";

const reduceFilter = (filter) => ({
  ...filter,
  no_payorder: filter?.no_payorder === "1",
  no_invoice: filter?.no_invoice === "1",
  no_trn: filter?.no_trn === "1",
  unload_date_end: filter?.unload_date_end
    ? parseISO(filter.unload_date_end)
    : "",
  unload_date_start: filter?.unload_date_start
    ? parseISO(filter.unload_date_start)
    : "",
  load_date_end: filter?.load_date_end ? parseISO(filter.load_date_end) : "",
  load_date_start: filter?.load_date_start
    ? parseISO(filter.load_date_start)
    : "",
});

const mapStateToProps = (_, props) => ({
  filter: reduceFilter(props.filter),
  onFilter: (newFilter, isDrop) =>
    props.onFilter(
      {
        ...props.filter,
        ...newFilter,
        ...(typeof newFilter.no_payorder === "boolean"
          ? { no_payorder: newFilter.no_payorder ? "1" : "0" }
          : {}),
        ...(typeof newFilter.no_invoice === "boolean"
          ? { no_invoice: newFilter.no_invoice ? "1" : "0" }
          : {}),
        ...(typeof newFilter.no_trn === "boolean"
          ? { no_trn: newFilter.no_trn ? "1" : "0" }
          : {}),
        ...(newFilter.unload_date_end
          ? { unload_date_end: apiDate(newFilter.unload_date_end) }
          : {}),
        ...(newFilter.unload_date_start
          ? { unload_date_start: apiDate(newFilter.unload_date_start) }
          : {}),
        ...(newFilter.load_date_end
          ? { load_date_end: apiDate(newFilter.load_date_end) }
          : {}),
        ...(newFilter.load_date_start
          ? { load_date_start: apiDate(newFilter.load_date_start) }
          : {}),
      },
      isDrop,
    ),
});

class RequestListFilters extends Component {
  static propTypes = {
    filter: PropTypes.shape(),
    onFilter: PropTypes.func,
    isPending: PropTypes.bool,
  };

  get isFilterSet() {
    return Object.keys(this.props.filter).find((key) => {
      const filter = this.props.filter[key];
      return (Array.isArray(filter) && filter.length) || Boolean(filter);
    });
  }

  dropFilters() {
    const emptyFilter = {};
    Object.keys(this.props.filter).forEach((key) => {
      if (Array.isArray(this.props.filter[key])) {
        emptyFilter[key] = [];
      } else {
        emptyFilter[key] = "";
      }
    });
    this.props.onFilter(emptyFilter, true);
  }

  handleChangeDate({ startDate, endDate, key }) {
    this.props.onFilter({
      ...this.props.filter,
      [`${key}_date_start`]: startDate,
      [`${key}_date_end`]: endDate,
    });
  }

  statuses = [
    { label: "В работе", value: "1" },
    { label: "На корректировке", value: "2" },
    { label: "Выгружена", value: "3" },
  ];

  render() {
    const {
      customer_id,
      driver_id,
      unload_date_start,
      unload_date_end,
      load_date_start,
      load_date_end,
      status_id,
      no_payorder,
      no_invoice,
      no_trn,
    } = this.props.filter;
    return (
      <div
        className="kt-form kt-form--label-right"
        style={{ position: "relative" }}
      >
        <Button
          onClick={this.dropFilters.bind(this)}
          hidden={!this.isFilterSet || this.props.isPending}
          size="sm"
          variant="danger"
          style={{
            position: "absolute",
            top: -25,
            left: 0,
            padding: "3px 10px",
          }}
        >
          сбросить фильтр
        </Button>
        <Row>
          <Col md="2">{this.props.children}</Col>
          <Col md="2" xl="2" className="kt-margin-b-20-tablet-and-mobile">
            <div className="kt-form__group">
              <Form.Label>Заказчик:</Form.Label>
              <SelectEntity
                isClearable
                multiple
                allowSelectAll
                search={(val) =>
                  customersEntity.actions.search({
                    "org.name": val,
                    has_requests: true,
                  })
                }
                onChange={(rows) =>
                  this.props.onFilter({
                    customer_id: rows?.map(({ id }) => id),
                  })
                }
                noOptionsMessage="Заказчиков не найдено"
                value={customer_id}
                getOptionLabel={({ id, short_name, name }) =>
                  `${short_name || name}`
                }
                getOptionValue={({ id }) => id}
              />
            </div>
          </Col>
          <Col md="2" xl="2" className="kt-margin-b-20-tablet-and-mobile">
            <div className="kt-form__group">
              <Form.Label>Исполнитель:</Form.Label>
              <SelectEntity
                isClearable
                multiple
                allowSelectAll
                search={(val) =>
                  driversEntity.actions.search({
                    "org.name": val,
                    has_requests: true,
                  })
                }
                onChange={(rows) =>
                  this.props.onFilter({ driver_id: rows?.map(({ id }) => id) })
                }
                noOptionsMessage="Заказчиков не найдено"
                value={driver_id}
                getOptionLabel={({ id, short_name, name }) =>
                  `${short_name || name}`
                }
                getOptionValue={(org) => org.id}
              />
            </div>
          </Col>

          <Col md="3" xl="3" className="kt-margin-b-20-tablet-and-mobile">
            <Row>
              <Col md="6" className="kt-form__group">
                <Form.Label>Дата загрузки:</Form.Label>
                <div>
                  <RangePicker
                    range={{
                      startDate: load_date_start || null,
                      endDate: load_date_end || null,
                      key: "load",
                    }}
                    onChange={(range) => this.handleChangeDate(range)}
                  />
                </div>
              </Col>
              <Col md="6" className="kt-form__group">
                <Form.Label>Дата выгрузки:</Form.Label>
                <div>
                  <RangePicker
                    range={{
                      startDate: unload_date_start || null,
                      endDate: unload_date_end || null,
                      key: "unload",
                    }}
                    onChange={(range) => this.handleChangeDate(range)}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col md="3" xl="3" className="kt-margin-b-20-tablet-and-mobile">
            <Row>
              <Col md="6">
                <div className="kt-form__group">
                  <Form.Label>Статус:</Form.Label>
                  <SelectEntity
                    isClearable
                    multiple
                    static
                    options={this.statuses}
                    value={status_id?.map((id) =>
                      this.statuses.find((s) => s.value === id),
                    )}
                    onChange={(selected) =>
                      this.props.onFilter({
                        status_id: selected?.map((i) => i.value) ?? [],
                      })
                    }
                    getOptionLabel={(val) => val.label}
                    getOptionValue={(val) => val.value}
                  />
                </div>
              </Col>
              <Col md="6">
                <div>
                  <label className="kt-checkbox kt-checkbox--tick kt-checkbox--brand mt-1 mr-3 mb-1">
                    <input
                      type="checkbox"
                      checked={no_payorder}
                      onChange={() =>
                        this.props.onFilter({ no_payorder: !no_payorder })
                      }
                    />
                    без платёжки
                    <span />
                  </label>
                </div>
                <div>
                  <label className="kt-checkbox kt-checkbox--tick kt-checkbox--brand mb-1">
                    <input
                      type="checkbox"
                      checked={no_invoice}
                      onChange={() =>
                        this.props.onFilter({ no_invoice: !no_invoice })
                      }
                    />
                    без cчёта
                    <span />
                  </label>
                </div>
                <div>
                  <label className="kt-checkbox kt-checkbox--tick kt-checkbox--brand">
                    <input
                      type="checkbox"
                      checked={no_trn}
                      onChange={() => this.props.onFilter({ no_trn: !no_trn })}
                    />
                    без ТРН
                    <span />
                  </label>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(mapStateToProps)(RequestListFilters);
