/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import clsx from "clsx";
import ReactDatePicker from "react-datepicker";
import "./datepicker.scss";
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Table,
  Badge,
  Tab,
  Tabs,
  InputGroup,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import {
  PortletHeaderToolbar,
  Portlet,
  PortletHeader,
  PortletBody,
} from "../../../../partials/content/Portlet";
import { carrierEntity } from "../../../../store/ducks/entity.duck";
import ConfirmButton from "../../../../partials/content/ConfirmButton";
import FileList from "../../../../partials/content/FileList/FileList";
import { replaceNullToString } from "../../../../helper/object";
import { LinearProgress } from "@material-ui/core";
import carrierReducer from "../../../../store/reducer_entity/carrier";

const mapStateToProps = ({ carriers }, props) => ({
  errors: carriers.errors,
  isPending: carriers.isPending,
  isSuccess: carriers.isSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  createCarrier: (fields) => dispatch(carrierEntity.actions.create(fields)),
  updateCarrier: (fields, id) =>
    dispatch(carrierEntity.actions.update(fields, id)),
  removeCarrier: (id) => dispatch(carrierEntity.actions.remove(id)),
});

class CarrierList extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changed = this.changed.bind(this);
  }

  static propTypes = {
    createCarrier: PropTypes.func.isRequired,
    updateCarrier: PropTypes.func.isRequired,
    removeCarrier: PropTypes.func.isRequired,
    driverId: PropTypes.string,
    isPending: PropTypes.bool,
    isSuccess: PropTypes.bool,
    title: PropTypes.string,
    errors: PropTypes.shape(),
  };

  static defaultProps = {
    isPending: false,
    isUploading: false,
    isLoadingName: false,
    title: "Контактные лица",
  };

  initialFields = {
    driver_id: this.props.driverId,
    name: "",
    phone1: "",
    phone2: "",
    passport_issued_date: new Date(),
    passport_issued_by: "",
    passport_number: "",
    licence_number: "",
    is_unreliable: false,
    files: [],
  };

  state = {
    id: null,
    showModal: false,
    suggestCarriers: [],
    list: [],
    isListPending: true,
    fields: { ...this.initialFields },
  };

  componentDidMount() {
    this.fetchCarrierList();
  }

  async fetchCarrierList() {
    const { type, driverId } = this.props;
    const { data: list } = await carrierEntity.actions.search(
      { "driver.id": this.props.driverId },
      { "driver.id": "strict" },
    );

    this.setState({
      list: list.map(carrierReducer.from),
      isListPending: false,
      fields: { ...this.state.fields },
    });
  }

  get isCreate() {
    return this.state.id === null;
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  getCarrierById(id) {
    return replaceNullToString(
      this.state.list.find((carrier) => carrier.id === id),
    );
  }

  handleShow(id = null, ev) {
    if (!ev || !ev.target.closest("button")) {
      this.setState({
        id,
        showModal: true,
        fields: id ? { ...this.getCarrierById(id) } : { ...this.initialFields },
      });
    }
  }

  async handleSubmit(ev) {
    ev.preventDefault();
    const fields = {
      ...this.state.fields,
      driver_id: this.props.driverId,
    };
    if (this.isCreate) {
      await this.props.createCarrier(fields);
    } else {
      await this.props.updateCarrier(fields, this.state.id);
    }
    if (this.props.isSuccess) {
      this.fetchCarrierList();
      this.handleClose();
    }
  }

  parseEvent(ev, value) {
    if (typeof ev === "object") {
      // got event
      return { name: ev.target.name, value: ev.target.value };
    } else {
      return { name: ev, value };
    }
  }

  async searchByName(name) {
    this.setState({ isLoadingName: true });
    try {
      const { data: carriers } = await carrierEntity.actions.search({ name });
      this.setState({ suggestCarriers: carriers });
    } catch (err) {
      this.setState({ suggestCarriers: [] });
    }
    this.setState({ isLoadingName: false });
  }

  selectCarrier(carrier, ev) {
    ev.preventDefault();

    this.setState({
      suggestCarriers: [],
      fields: {
        ...this.state.fields,
        ...carrierReducer.from(carrier),
      },
    });
  }

  changed(_name, _value) {
    const { name, value } = this.parseEvent(_name, _value);
    if (name === "name" && value.length > 2) {
      this.searchByName(value);
    }
    this.setState({
      fields: {
        ...this.state.fields,
        [name]: value,
      },
    });
  }

  whenUploaded(files) {
    this.setState({
      isUploading: false,
      fields: {
        ...this.state.fields,
        files: [...files.map((file) => file.id), ...this.state.fields["files"]],
      },
    });
  }

  render() {
    const { fields, isUploading, list, isListPending } = this.state;
    const { isPending, removeCarrier, errors } = this.props;
    return (
      <>
        <Portlet fluidHeight>
          <PortletHeader
            title="Водители"
            toolbar={
              <PortletHeaderToolbar>
                <Button
                  size="sm"
                  onClick={(ev) => this.handleShow()}
                  variant="success"
                >
                  Добавить
                </Button>
              </PortletHeaderToolbar>
            }
          />
          <PortletBody scrollable>
            <Table responsive striped hover>
              <thead>
                <tr>
                  <th width="34%">Имя</th>
                  <th with="25%">Телефон №1</th>
                  <th with="25%">Телефон №2</th>
                  <th with="15%" />
                  <th width="1%" />
                </tr>
              </thead>
              <tbody>
                {isListPending && (
                  <td colSpan="5" className="kt-align-center p-5">
                    <Spinner animation="border" variant="primary" />
                  </td>
                )}
                {list.map(({ id, name, phone1, phone2, is_unreliable }) => (
                  <tr key={id} onClick={(ev) => this.handleShow(id, ev)}>
                    <td>{name}</td>
                    <td>{phone1 || "-"}</td>
                    <td>{phone2 || "-"}</td>
                    <td>
                      {is_unreliable && (
                        <Badge variant="warning">ненадежный</Badge>
                      )}
                    </td>
                    <td>
                      <ConfirmButton
                        size="sm"
                        action={() => removeCarrier(id)}
                        variant="label-danger"
                      >
                        удалить
                      </ConfirmButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </PortletBody>
        </Portlet>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Данные сотрудника</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate onSubmit={this.handleSubmit}>
              <Tabs defaultActiveKey="main" id="org-tabs">
                <Tab eventKey="main" title="Основные данные">
                  <Form.Group as={Row}>
                    <Col md="8">
                      <Form.Label>ФИO</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            <i className="la la-user" />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          name="name"
                          onChange={this.changed}
                          isInvalid={errors.hasOwnProperty("name")}
                          value={fields["name"]}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors["name"]}
                        </Form.Control.Feedback>

                        {this.state.isLoadingName ? (
                          <LinearProgress style={{ width: "100%" }} />
                        ) : this.state.suggestCarriers.length ? (
                          <ListGroup
                            className="autocomplete"
                            style={{ with: "100%" }}
                          >
                            {this.state.suggestCarriers?.map((carrier) => (
                              <ListGroup.Item
                                onClick={(ev) =>
                                  this.selectCarrier(carrier, ev)
                                }
                                key={carrier.id}
                                action
                              >
                                {carrier.name}
                                {carrier.phone1 && ` (${carrier.phone1})`}
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        ) : null}
                      </InputGroup>
                      <Form.Check
                        type="checkbox"
                        name="is_unreliable"
                        label="ненадежный"
                        onChange={(ev) =>
                          this.changed("is_unreliable", ev.target.checked)
                        }
                        checked={fields["is_unreliable"] === true}
                        id="checkbox_is_unreliable"
                      />
                    </Col>
                    <Col md="4">
                      <Form.Label>Номер ВУ</Form.Label>
                      <Form.Control
                        name="licence_number"
                        onChange={this.changed}
                        isInvalid={errors.hasOwnProperty("licence_number")}
                        value={fields["licence_number"]}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors["licence_number"]}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col md="6">
                      <Form.Label>Номер паспорта</Form.Label>
                      <Form.Control
                        name="passport_number"
                        onChange={this.changed}
                        isInvalid={errors.hasOwnProperty("passport_number")}
                        value={fields["passport_number"]}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors["passport_number"]}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md="6">
                      <Form.Label>Когда выдан?</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            <i className="la la-calendar" />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <ReactDatePicker
                          className={clsx("form-control", {
                            "is-invalid": errors.hasOwnProperty(
                              "passport_issued_date",
                            ),
                          })}
                          locale="ru"
                          selected={fields["passport_issued_date"]}
                          dateFormat={"dd.MM.yyyy"}
                          onChange={(date) =>
                            this.setState({
                              fields: {
                                ...this.state.fields,
                                passport_issued_date: date,
                              },
                            })
                          }
                        />
                        <Form.Control
                          hidden
                          isInvalid={errors.hasOwnProperty(
                            "passport_issued_by",
                          )}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors["passport_issued_by"]}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Col md="12">
                      <Form.Label>Кем выдан</Form.Label>
                      <Form.Control
                        name="passport_issued_by"
                        onChange={this.changed}
                        isInvalid={errors.hasOwnProperty("passport_issued_by")}
                        value={fields["passport_issued_by"]}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors["passport_issued_by"]}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Col md="6">
                      <Form.Label>Телефон №1</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            <i className="la la-phone" />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          name="phone1"
                          onChange={this.changed}
                          isInvalid={errors.hasOwnProperty("phone1")}
                          value={fields["phone1"]}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors["phone1"]}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <Form.Label>Телефон №2</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            <i className="la la-phone" />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          name="phone2"
                          onChange={this.changed}
                          isInvalid={errors.hasOwnProperty("phone2")}
                          value={fields["phone2"]}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors["phone2"]}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                  </Form.Group>
                </Tab>
                <Tab eventKey="files" title="Сканы документов">
                  <FileList
                    defaultValue={fields["files"]}
                    onStartUpload={() => this.setState({ isUploading: true })}
                    onUpload={(files) => this.whenUploaded(files)}
                    onDelete={(file) =>
                      this.setState({
                        fields: {
                          ...this.state.fields,
                          files: fields["files"].filter((id) => id !== file.id),
                        },
                      })
                    }
                  />
                </Tab>
              </Tabs>
              <hr />
              <Button
                variant="primary"
                type="submit"
                disabled={isPending || isUploading}
                onClick={this.handleSubmit}
                className={clsx("pull-right ml-2", {
                  "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                    isPending,
                })}
              >
                Сохранить
              </Button>
              <Button
                variant="secondary"
                type="button"
                disabled={isPending || isUploading}
                onClick={this.handleClose}
                className="pull-right ml-2"
              >
                отменить
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CarrierList);
