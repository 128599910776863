import React from "react";
import EntityForm from "../../../components/entity/EntityForm";
import EntityList from "../../../components/entity/EntityList";

import { cargoPackEntity } from "../../../store/ducks/entity.duck";

export function CargoPackList() {
  return (
    <EntityList
      actions={cargoPackEntity.actions}
      select={(state) => state.catalog.cargoPack}
      name="catalog/cargo_pack"
    />
  );
}

const schema = {
  title: { label: "Название" },
};

export function CargoPackEdit(props) {
  return (
    <EntityForm
      name="catalog/cargo_pack"
      id={props.match.params.id}
      schema={schema}
      actions={cargoPackEntity.actions}
      actionTypes={cargoPackEntity.types}
      select={(state) => state.catalog.cargoPack}
    />
  );
}

export function CargoPackCreate(props) {
  return (
    <EntityForm
      name="catalog/cargo_pack"
      onSuccess={props.onSuccess}
      onCancel={props.onCancel}
      schema={schema}
      actions={cargoPackEntity.actions}
      actionTypes={cargoPackEntity.types}
      select={(state) => state.catalog.cargoPack}
    />
  );
}
