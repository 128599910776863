import React, { useEffect, useState } from "react";
import RequestForm from "../common/RequestForm";
import EntityList from "../../../../components/entity/EntityList";
import {
  requestProgressEntity,
  requestContractEntity,
} from "../../../../store/ducks/entity.duck";
import RequestCard from "../common/RequestCard";
import { useSelector, useDispatch } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import WordEditor from "../../../../partials/widgets/WordEditor/WordEditor";
import { REQUEST_PROGRESS_STATUS_ID } from "../../../../store/staticStore";
import RequestListAction from "./RequestListAction";
import RequestListFilters from "../common/RequestListFilters";
import RequestRowActions from "../common/RequestRowActions";

export const RequestProgressList = () => {
  return (
    <EntityList
      multipleActionsComponent={(props) => <RequestListAction {...props} />}
      actionsComponent={(props) => <RequestRowActions {...props} />}
      filterComponent={(props) => <RequestListFilters {...props} />}
      isServerFilter
      actions={requestProgressEntity.actions}
      select={(state) => state.requests.progress}
      hasFilters
      hasActions
      clickLink="/requests/progress/:id"
      name="requests/progress"
    />
  );
};

export const RequestProgressCreate = () => {
  return (
    <RequestForm
      select={(state) => state.requests.progress}
      creationStatusID={REQUEST_PROGRESS_STATUS_ID}
      entity={requestProgressEntity}
    />
  );
};

export const RequestProgressCard = (props) => {
  return (
    <RequestCard
      select={(state) => state.requests.progress}
      entity={requestProgressEntity}
      id={props.match.params.id}
    />
  );
};

export const RequestProgressEdit = (props) => {
  return (
    <RequestForm
      entity={requestProgressEntity}
      id={props.match.params.id}
      creationStatusID={REQUEST_PROGRESS_STATUS_ID}
      select={(state) => state.requests.progress}
    />
  );
};

export const RequestProgressContractEdit = (props) => {
  const dispatch = useDispatch();
  const contracts = useSelector((state) => state.requests.contract);
  // const user_role = useSelector(state => state.auth.user.role_code)
  const [documentId, setDocumentId] = useState(null);
  const [isPending, setPending] = useState(true);
  const { contract_id } = props.match.params;

  useEffect(() => {
    dispatch(requestContractEntity.actions.single(contract_id));
  }, [contract_id, dispatch]);

  useEffect(() => {
    if (contracts.single[contract_id]) {
      setDocumentId(contracts.single[contract_id].document_id);
      setPending(false);
    }
  }, [contracts, contract_id]);

  return isPending ? (
    <LinearProgress />
  ) : document === null ? (
    "404"
  ) : (
    <WordEditor
      documentId={documentId}
      // isReadOnly={user_role !== 'chief'}
    />
  );
};
