import React, { createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Portlet,
  PortletHeader,
  PortletBody,
  PortletHeaderToolbar,
} from "../../../partials/content/Portlet";
import { LinearProgress } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import { driversEntity } from "../../../store/ducks/entity.duck";
import { fileEntity } from "../../../store/ducks/file.duck";
import { find } from "../../../helper/object";
import { Row, Col, Button, ListGroup } from "react-bootstrap";
import CarList from "./widgets/CarList";
import CarrierList from "./widgets/CarrierList";
import EmployeeList from "../../../partials/widgets/EmployeeList";
import ContractList from "../../../partials/widgets/ContractList";
import BanksList from "../../../partials/widgets/BanksList";
import FileList from "../../../partials/content/FileList/FileList";
import clsx from "clsx";
import { ButtonCopy } from "../../../widgets/ButtonCopy";
import { ErrorPage404 } from "../../errors/ErrorPage404";

const mapDispatchToProps = (dispatch) => ({
  fetchDriver: (id) => dispatch(driversEntity.actions.single(id)),
  removeFile: (id) => dispatch(fileEntity.actions.remove(id)),
  saveFiles: (org_id, fileIds) =>
    dispatch(fileEntity.actions.saveOrgFiles(org_id, fileIds)),
});

const mapStateToProps = ({ drivers }, props) => ({
  isPending: drivers.isPending ?? false,
  driver: drivers.single[props.id] ?? {},
});

class DriverCard extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    isPending: PropTypes.bool.isRequired,
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
    }).isRequired,
    driver: PropTypes.shape({
      name: PropTypes.string,
      org_id: PropTypes.string,
    }).isRequired,
    fetchDriver: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { fetchDriver, id } = this.props;
    fetchDriver(id);
  }

  uploadBtnRef = createRef();

  listLeft = {
    short_name: "Короткое наименование",
    city: "Город",
    address: "Юр. адрес",
    real_address: "Факт. адрес",
    inn: "ИНН",
    kpp: "КПП",
    ogrn: "ОГРН",
  };

  listRight = {
    phone1: "Телефон 1",
    phone2: "Телефон 2",
    phone3: "Телефон 3",
    email: "Email",
    website: "Сайт",
  };

  state = {
    files: [],
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.driver.files !== this.props.driver.files) {
      this.setState({ files: newProps.driver.files });
    }
  }

  render() {
    if (this.props.isPending) {
      return <LinearProgress />;
    }

    if (!this.props.driver.id) {
      return <ErrorPage404 />;
    }

    const { driver, id } = this.props;
    const summary = `ИНН ${driver.inn}, ${driver.name}, ${driver.address}`;
    return (
      <>
        <Row>
          <Col xl="6" md="12">
            <Portlet fluidHeight>
              <PortletHeader
                title={`${driver.name}`}
                toolbar={
                  <PortletHeaderToolbar>
                    {/* <Button variant="secondary" className="flaticon2-file">
                      &nbsp;&nbsp;История изменений
                    </Button> */}
                    <Link
                      to={`/drivers/${id}/edit`}
                      style={{ marginLeft: ".5em" }}
                    >
                      <Button
                        variant="primary"
                        type="button"
                        className="flaticon2-edit"
                      >
                        &nbsp;&nbsp;Изменить
                      </Button>
                    </Link>
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody>
                <Row>
                  <Col md="6">
                    <h3>Компания</h3>
                    <ListGroup variant="flush">
                      {Object.keys(this.listLeft)
                        .filter((f) => find(driver, f))
                        .map((field) => (
                          <ListGroup.Item key={field}>
                            <b>{this.listLeft[field]}</b>
                            <br />
                            {find(driver, field)}
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  </Col>
                  <Col md="6">
                    <h3>Контакты</h3>
                    <ListGroup variant="flush">
                      {Object.keys(this.listRight)
                        .filter((f) => find(driver, f))
                        .map((field) => (
                          <ListGroup.Item key={field}>
                            <b>{this.listRight[field]}</b>
                            <br />
                            {find(driver, field)}
                          </ListGroup.Item>
                        ))}
                      {driver.ati_code && (
                        <ListGroup.Item>
                          <b>АТИ</b>
                          <br />
                          <a
                            href={`https://ati.su/firms/${driver.ati_code}/info`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <b>Код {driver.ati_code}</b>
                            <br />
                            {Array.from({ length: 5 }).map((_, i) => (
                              <i
                                key={i}
                                className={clsx("la la-star", {
                                  "kt-font-warning": i <= driver.ati_score,
                                  "kt-font-dark": i > driver.ati_score,
                                })}
                              />
                            ))}
                          </a>
                        </ListGroup.Item>
                      )}
                      <ListGroup.Item>
                        <b>Сводка</b>
                        <p>{summary}</p>
                        <ButtonCopy text={summary}>
                          {({ copied }) =>
                            copied ? "Скопировано" : "Скопировать сводку"
                          }
                        </ButtonCopy>
                      </ListGroup.Item>
                    </ListGroup>
                  </Col>
                </Row>
              </PortletBody>
            </Portlet>
          </Col>
          <Col xl="3" lg="6" md="6">
            <BanksList
              orgId={driver.org_id}
              search={{ "driver.id": id }}
              searchRule={{ "driver.id": "strict" }}
            />
          </Col>
          <Col xl="3" lg="6" md="6">
            <ContractList
              orgId={driver.org_id}
              type="driver"
              filterTemplates={(req) => {
                req.data = req.data.filter(
                  ({ side, usage_type }) =>
                    side === "Клиент" && usage_type === "Договоров перевозчику",
                );
                return req;
              }}
            />
          </Col>
          <Col xl="6" md="12">
            <Portlet fluidHeight>
              <PortletHeader
                title="Файлы"
                toolbar={
                  <PortletHeaderToolbar>
                    <Button ref={this.uploadBtnRef} size="sm" variant="success">
                      Добавить
                    </Button>
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody scrollable>
                <FileList
                  uploadButton={this.uploadBtnRef}
                  defaultValue={this.state.files}
                  onUpload={(files) =>
                    this.setState(
                      () => ({
                        files: [
                          ...this.state.files,
                          ...files.map((file) => file.id),
                        ],
                      }),
                      () =>
                        this.props.saveFiles(driver.org_id, this.state.files),
                    )
                  }
                  onDelete={(file) =>
                    this.setState(
                      () => ({
                        files: this.state.files.filter((_id) => {
                          return _id !== file.id;
                        }),
                      }),
                      () => this.props.removeFile(file.id),
                    )
                  }
                />
              </PortletBody>
            </Portlet>
          </Col>
          <Col xl="6" lg="12">
            <EmployeeList orgId={driver.org_id} title="Контактные лица" />
          </Col>
          <Col md="6" lg="6" xl="3">
            <CarList
              orgId={driver.org_id}
              driverId={this.props.id}
              type="car"
            />
          </Col>
          <Col md="6" lg="6" xl="3">
            <CarList
              orgId={driver.org_id}
              driverId={this.props.id}
              type="truck"
              title="Полуприцепы"
            />
          </Col>
          <Col xl="6" lg="12">
            <CarrierList
              driverId={id}
              type="driver"
              position={{ code: "driver", title: "Водитель" }}
              title="Водители"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DriverCard),
);
