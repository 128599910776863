import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Col,
  Row,
  Button,
  Alert,
  ButtonGroup,
  Form,
  Modal,
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { LinearProgress } from "@material-ui/core";
import {
  Portlet,
  PortletHeader,
  PortletBody,
  PortletHeaderToolbar,
} from "../../../../partials/content/Portlet";
import { connect } from "react-redux";
import { humanDate } from "../../../../helper/date";
import Timeline from "./Timeline";
import { Link } from "react-router-dom";
import { compareDesc, parseISO } from "date-fns";
import { FinanceTable } from "./FinanceTable";
import * as service from "../../../../service/entity.crud";
import * as requestService from "../../../../service/request";
import RequestDocuments from "./RequestDocuments";
import "./styles.scss";
import clsx from "clsx";
import {
  REQUEST_ADJUST_STATUS_ID,
  REQUEST_DONE_STATUS_ID,
  REQUEST_DRAFT_STATUS_ID,
} from "../../../../store/staticStore";
import popupDuck from "../../../../store/ducks/popup.duck";
import WaybillList from "../../../../partials/widgets/WaybillList";
import InvoiceList from "../../../../partials/widgets/InvoiceList";
import {
  invoiceEntity,
  waybillEntity,
} from "../../../../store/ducks/entity.duck";

const mapStateToProps = (state, { select, id }) => ({
  user: state.auth.user,
  isPending: select(state).isPending ?? false,
  request: select(state).single[id] ?? {},
  errors: select(state).errors,
  shipment: [
    ...(select(state).single[id]?.shipment_load || []),
    ...(select(state).single[id]?.shipment_unload || []),
  ].sort((a, b) => compareDesc(a.datetime, b.datetime)),
  // waybills: state.waybills.rows.filter(({ request_id }) => request_id === id)
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchRequest: (id, silent = false) =>
    dispatch(props.entity.actions.single(id, { "": "extended" }, silent)),
  updateRequest: (fields, id) =>
    dispatch(props.entity.actions.update(fields, id)),
  error: ({ title, content }) =>
    dispatch(popupDuck.actions.error({ title, content })),
});

class RequestCard extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    isPending: PropTypes.bool.isRequired,
    request: PropTypes.shape({
      number: PropTypes.string,
    }).isRequired,
    shipment: PropTypes.array,
    fetchRequest: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.refetchWaybills = this.refetchWaybills.bind(this);
    this.refetchInvoices = this.refetchInvoices.bind(this);
  }

  componentDidMount() {
    const { fetchRequest, id } = this.props;
    fetchRequest(id);
    this.refetchWaybills();
    this.refetchInvoices();
  }

  async refetchWaybills() {
    const { data: waybills } = await waybillEntity.actions.search(
      { "request.id": this.props.id },
      { "request.id": "strict" },
    );
    this.setState({
      ...this.state,
      waybills: waybills.map((wb) => ({
        ...wb,
        date: wb.date ? parseISO(wb.date) : wb.date,
      })),
      isWaybillLoading: false,
    });
  }

  async refetchInvoices() {
    const { data: invoices } = await invoiceEntity.actions.search(
      { "req2inv.request_id": this.props.id },
      { "req2inv.request_id": "strict" },
    );

    this.setState({
      ...this.state,
      invoices: invoices.map((inv) => ({
        ...inv,
        invoice_date: inv.invoice_date
          ? parseISO(inv.invoice_date)
          : inv.invoice_date,
        upd_date: inv.upd_date ? parseISO(inv.upd_date) : inv.upd_date,
        sf_date: inv.sf_date ? parseISO(inv.sf_date) : inv.sf_date,
        payment_delay_date: inv.payment_delay_date
          ? parseISO(inv.payment_delay_date)
          : inv.payment_delay_date,
      })),
      isInvoicesLoading: false,
    });
  }

  componentDidUpdate(prevProps) {
    const { request } = this.props;
    if (
      request?.comment !== null &&
      request?.comment !== prevProps.request?.comment
    ) {
      this.setState({ comment: this.props.request?.comment });
    }
  }

  updateRequest(payload) {
    try {
      return service.updateEntity(
        this.props.entity.name,
        payload,
        this.props.id,
      );
    } catch (err) {}
  }

  contractList(type) {
    const res = [];
    const {
      request: { customer_contract, driver_contract, contracts },
    } = this.props;
    switch (type) {
      case "customer":
        if (customer_contract) {
          res.push({ ...customer_contract, title: "Договор заказчика" });
        }
        if (contracts?.length) {
          res.push(...contracts.filter(({ side }) => side === "Исполнитель"));
        }
        break;
      case "driver":
        if (driver_contract) {
          res.push({ ...driver_contract, title: "Договор исполнителя" });
        }
        if (contracts?.length) {
          res.push(...contracts.filter(({ side }) => side === "Клиент"));
        }
        break;
      default:
        return res;
    }
    return res;
  }

  state = {
    isPending: false,
    modalComment: false,
    comment: "",
    modalToAdjustment: false,
    waybills: [],
    isWaybillLoading: true,
    invoices: [],
    isInvoicesLoading: true,
  };

  get isCompleted() {
    return false;
  }

  async editComment() {
    this.setState({ isPending: true });
    try {
      await requestService.comment(this.props.id, this.state.comment);
      await this.props.fetchRequest(this.props.id, true);
      this.setState({ modalComment: false });
    } finally {
      this.setState({ isPending: false });
    }
  }

  async changeStatus(status_id) {
    this.setState({ isPending: true });
    try {
      await requestService.changeStatus(this.props.id, status_id);
      await this.props.fetchRequest(this.props.id, true);
      this.setState({ modalToAdjustment: false });
    } finally {
      this.setState({ isPending: false });
    }
  }

  async convert() {
    this.setState({ isPending: true });
    try {
      await requestService.convert(this.props.id);
      this.props.history.push(`/requests/progress/${this.props.id}`);
    } catch (err) {
      this.setState({ isPending: false });
      this.props.error({
        title: "Ошибка",
        content:
          err?.messages?.map((mess, i) => (
            <span key={mess} className={i >= 2 ? "kt-font-bolder" : ""}>
              {i ? <br /> : ""}
              {mess}
            </span>
          )) ?? err,
      });
    }
  }

  get isDone() {
    return this.props.request.status_id === REQUEST_DONE_STATUS_ID;
  }

  get isAdjustment() {
    return this.props.request.status_id === REQUEST_ADJUST_STATUS_ID;
  }

  get isDraft() {
    return this.props.request.status_id === REQUEST_DRAFT_STATUS_ID;
  }

  /* eslint-disable complexity */
  render() {
    const {
      isPending,
      isWaybillLoading,
      comment,
      waybills,
      invoices,
      isInvoicesLoading,
    } = this.state;

    if (this.props.isPending || isWaybillLoading) {
      return <LinearProgress />;
    }

    const {
      id,
      shipment,
      entity,
      request: {
        customer,
        customer_employee,
        customer_contract,
        customer_request_doc_id,
        driver,
        driver_request_doc_id,
        driver_employee,
        driver_carrier,
        driver_contract,
        driver_car,
        driver_trailer,
        ...request
      },
    } = this.props;
    if (this.props.errors?.response?.status === 404) {
      return (
        <div className="kt-grid kt-grid--ver kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1">
            <div className="kt-error-v1__container">
              <h1 className="kt-error-v1__number">404</h1>
              <p className="kt-error-v1__desc">Такой заявки не существует.</p>
              <p className="kt-error-v1__desc">
                Если вы попали на эту страницу по ссылке из системы, обратитесь
                к администратору
              </p>
              <p className="kt-error-v1__desc">
                <Button onClick={() => this.props.history.goBack()}>
                  Назад
                </Button>
              </p>
            </div>
          </div>
        </div>
      );
    }
    return (
      <>
        {this.isAdjustment && (
          <Alert variant="warning" className="kt-jt-center">
            <div className="kt-font-lg kt-font-bolder">
              Заявка в статусе "на корретировке". Она не будет выгружена в 1с,
              пока не перейдет в статус "выгружена". Для смены статуса, нажмите
              на зеленую кнопку слева в блоке информации о заявке.
            </div>
          </Alert>
        )}
        {this.isDone && (
          <Alert variant="success" className="kt-jt-center">
            <div className="kt-font-lg kt-font-bolder">
              Заявка прошла выгрузку. Изменения недоступны.
              {!waybills.length &&
                "После внесения транспортной накладной корректировка станет недоступна."}
            </div>
          </Alert>
        )}
        <div className="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
          <div className="kt-grid__item kt-app__toggle kt-app__aside">
            <Portlet>
              <PortletHeader className="kt-portlet__head--noborder">
                <PortletHeaderToolbar />
              </PortletHeader>
              <PortletBody>
                <div className="kt-widget kt-widget--user-profile-2">
                  <div className="kt-widget__head">
                    <div className="kt-widget__info">
                      <span className="kt-widget__username">
                        Заявка
                        {!this.isDraft && (
                          <>
                            {" "}
                            №{request.number}
                            {request.date && <> от {humanDate(request.date)}</>}
                          </>
                        )}
                      </span>
                      <span className="kt-widget__desc">&nbsp;</span>
                    </div>
                  </div>

                  <div className="kt-widget__body">
                    <div className="kt-widget__content">
                      <div className="kt-widget__stats kt-margin-r-20">
                        <div className="kt-widget__icon">
                          <i className="la la-truck" />
                        </div>
                        <div className="kt-widget__details">
                          <span className="kt-widget__title">Груз</span>
                          <span className="kt-widget__value">
                            {request.cargo_type_title}
                          </span>
                        </div>
                      </div>
                    </div>
                    {request.cargo_size_z &&
                      request.cargo_size_x &&
                      request.cargo_size_y && (
                        <div className="kt-widget__content">
                          <div className="kt-widget__stats kt-margin-r-20">
                            <div className="kt-widget__icon">
                              <i className="la la-arrows-alt" />
                            </div>
                            <div className="kt-widget__details">
                              <span className="kt-widget__title">Размеры</span>
                              <span className="kt-widget__value">
                                {request.cargo_size_z}м. &times;{" "}
                                {request.cargo_size_x}м. &times;{" "}
                                {request.cargo_size_y}м.
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    <Row>
                      {request.cargo_weight && (
                        <Col md="5">
                          <div className="kt-widget__content">
                            <div className="kt-widget__stats kt-margin-r-20">
                              <div className="kt-widget__icon">
                                <i className="la la-balance-scale" />
                              </div>
                              <div className="kt-widget__details">
                                <span className="kt-widget__title">Масса</span>
                                <span className="kt-widget__value">
                                  {request.cargo_weight}т.
                                </span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}
                      {request.cargo_value && (
                        <Col md="7">
                          <div className="kt-widget__content">
                            <div className="kt-widget__stats kt-margin-r-20">
                              <div className="kt-widget__icon">
                                <i className="la la-cart-arrow-down" />
                              </div>
                              <div className="kt-widget__details">
                                <span className="kt-widget__title">Объем</span>
                                <span className="kt-widget__value">
                                  {request.cargo_value}м<sup>3</sup>
                                </span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>
                    <div className="kt-widget__content">
                      {request.pack && (
                        <div className="kt-widget__stats kt-margin-r-20">
                          <div className="kt-widget__icon">
                            <i
                              className="fa fa-box-open"
                              style={{ fontSize: "2em", fontWeight: 900 }}
                            />
                          </div>
                          <div className="kt-widget__details">
                            <span className="kt-widget__title">Упаковка</span>
                            <span className="kt-widget__value">
                              {request.pack?.title}
                              {request.pack_quantity
                                ? ` ${request.pack_quantity}шт`
                                : ""}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    {request.more_conditions && (
                      <div className="kt-widget__section">
                        <b>Дополнительные условия</b>: <br />
                        {request.more_conditions}
                      </div>
                    )}
                    {request.comment && (
                      <div className="kt-widget__section">
                        <b>Комментарий</b>: <br />
                        {request.comment}
                      </div>
                    )}
                  </div>

                  <div className="kt-widget__footer">
                    <ButtonGroup vertical size="lg" style={{ width: "100%" }}>
                      {!this.isDone && (
                        <Link
                          key={"edit"}
                          to={`/${entity.name}/${id}/edit`}
                          className="btn btn-label-info btn-upper"
                        >
                          <i className="la la-edit" />
                          &nbsp;Редактировать
                        </Link>
                      )}
                      {this.isDone &&
                        (waybills.length > 0 ? (
                          <Alert variant="secondary">
                            <b>
                              корректировка недоступна после добавления
                              транспортной накладной
                            </b>
                          </Alert>
                        ) : (
                          <Button
                            key={"adj"}
                            variant="label-info"
                            onClick={() =>
                              this.setState({ modalToAdjustment: true })
                            }
                            className="btn-upper"
                          >
                            <i className="la la-warning kt-font-warning" />
                            &nbsp;На корректировку
                          </Button>
                        ))}

                      <Button
                        key={"comment"}
                        variant="label-info"
                        onClick={() => this.setState({ modalComment: true })}
                        className="btn-upper"
                      >
                        <i className="la la-comment kt-font-default" />
                        &nbsp;Комментарий
                      </Button>
                      {this.isAdjustment && (
                        <Button
                          key={"done"}
                          variant="label-success"
                          onClick={() =>
                            this.changeStatus(REQUEST_DONE_STATUS_ID)
                          }
                          className="btn-upper"
                          disabled={isPending}
                        >
                          &nbsp;Завершить корректировку
                        </Button>
                      )}
                      {this.isDraft && (
                        <Button
                          key={"convert"}
                          onClick={() => this.convert()}
                          disabled={isPending}
                          variant="label-success"
                          className="btn-upper"
                        >
                          <i className="la la-check" />
                          &nbsp;Сформировать заявку
                        </Button>
                      )}
                    </ButtonGroup>
                  </div>
                </div>
              </PortletBody>
            </Portlet>
            <Col md="12">
              <Portlet>
                <PortletHeader title="Погрузка / выгрузка" />
                <PortletBody>
                  {request.is_car_return && (
                    <Alert variant="secondary">
                      <b>Кругорейс</b>
                    </Alert>
                  )}
                  <Timeline>
                    {shipment.map(
                      ({
                        id: num,
                        type,
                        date,
                        time,
                        organization,
                        address,
                        contact,
                      }) => (
                        <Timeline.Item
                          key={num}
                          time={time}
                          date={date}
                          subtitle={organization}
                          variant={type === "load" ? "success" : "danger"}
                          title={type === "load" ? "Погрузка" : "Выгрузка"}
                        >
                          <p>{address}</p>
                          <p>{contact}</p>
                        </Timeline.Item>
                      ),
                    )}
                  </Timeline>
                </PortletBody>
              </Portlet>
            </Col>
          </div>
          <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
            {!this.isDraft && (
              <Row>
                {this.isDone && (
                  <>
                    <Col md="4">
                      <WaybillList
                        list={waybills}
                        requestId={this.props.id}
                        onUpdate={this.refetchWaybills}
                      />
                    </Col>
                    <Col md="8">
                      <InvoiceList
                        list={invoices}
                        isPending={isInvoicesLoading}
                        onUpdate={this.refetchInvoices}
                        requestId={this.props.id}
                      />
                    </Col>
                  </>
                )}
                <Col md="6">
                  <RequestDocuments
                    disabled={this.isDone}
                    filter={(templates) => ({
                      data: templates.data.filter(
                        ({ side }) => side === "Исполнитель",
                      ),
                    })}
                    number={request.number_customer ?? request.number}
                    date={request.date}
                    title="Документы заказчика"
                    variant="warning-light"
                    contracts={this.contractList("customer")}
                    requestId={id}
                  />
                </Col>
                <Col md="6">
                  <RequestDocuments
                    disabled={this.isDone}
                    filter={(templates) => ({
                      data: templates.data.filter(
                        ({ side }) => side === "Клиент",
                      ),
                    })}
                    number={request.number}
                    date={request.date}
                    title="Документы исполнителя"
                    variant="success-light"
                    contracts={this.contractList("driver")}
                    requestId={id}
                  />
                </Col>
                <Col md="6">
                  <Portlet fluidHeight>
                    <PortletHeader
                      variant="warning-light"
                      title="Финансы заказчика"
                    />
                    <PortletBody>
                      <FinanceTable
                        disabled={this.isDone}
                        tax={customer_contract?.payment_form}
                        onSave={({ park, forwarding }) =>
                          requestService.setFinance(id, {
                            park_quantity_customer: park,
                            forwarding_customer: forwarding,
                          })
                        }
                        measurement={customer_contract?.delay_price_measurement}
                        park_value={parseInt(
                          request?.park_quantity_customer,
                          10,
                        )}
                        forward_value={parseInt(
                          request?.forwarding_customer,
                          10,
                        )}
                        rate={parseFloat(request?.cost_customer ?? 0)}
                        park_cost={parseFloat(
                          customer_contract?.delay_price ?? 0,
                        )}
                      />
                    </PortletBody>
                  </Portlet>
                </Col>
                <Col md="6">
                  <Portlet fluidHeight>
                    <PortletHeader
                      variant="success-light"
                      title="Финансы перевозчика"
                    />
                    <PortletBody>
                      <FinanceTable
                        disabled={this.isDone}
                        tax={driver_contract?.payment_form}
                        onSave={({ park, forwarding }) =>
                          requestService.setFinance(id, {
                            park_quantity_driver: park,
                            forwarding_driver: forwarding,
                          })
                        }
                        measurement={driver_contract?.delay_price_measurement}
                        park_value={parseInt(request?.park_quantity_driver, 10)}
                        forward_value={parseInt(request?.forwarding_driver, 10)}
                        rate={parseFloat(request?.cost_driver ?? 0)}
                        park_cost={parseFloat(
                          driver_contract?.delay_price ?? 0,
                        )}
                      />
                    </PortletBody>
                  </Portlet>
                </Col>
              </Row>
            )}

            <Row>
              <Col md="6">
                <Portlet fluidHeight>
                  <PortletHeader
                    variant="warning-light"
                    title="Заказчик"
                    toolbar={
                      <PortletHeaderToolbar>
                        {customer && (
                          <Link to={`/customers/${customer.id}`}>
                            <Button variant="light">
                              <i className="la la-arrow-right" />
                              карточка организации&nbsp;
                            </Button>
                          </Link>
                        )}
                      </PortletHeaderToolbar>
                    }
                  />
                  <PortletBody fluid>
                    <div className="kt-widget12">
                      <div className="kt-widget12__content">
                        {customer && (
                          <div className="kt-widget12__item">
                            <div className="kt-widget12__info">
                              <span className="kt-widget12__desc">
                                Организация
                              </span>
                              <span className="kt-widget12__value">
                                {customer.short_name ?? customer.name}
                              </span>
                            </div>
                          </div>
                        )}
                        {customer_employee && (
                          <div className="kt-widget12__item">
                            <div className="kt-widget12__info">
                              <span className="kt-widget12__desc">Контакт</span>
                              <span className="kt-widget12__value">
                                {customer_employee.name}
                                {customer_employee.phone1 ? (
                                  <>
                                    <br />
                                    {customer_employee.phone1}
                                  </>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                        {customer_contract && (
                          <div className="kt-widget12__item">
                            <div className="kt-widget12__info">
                              <span className="kt-widget12__desc">Договор</span>
                              <span className="kt-widget12__value">
                                №{customer_contract.number} от{" "}
                                {humanDate(customer_contract.date_start)}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </PortletBody>
                </Portlet>
              </Col>
              <Col md="6">
                <Portlet fluidHeight>
                  <PortletHeader
                    variant="success-light"
                    title="Исполнитель"
                    toolbar={
                      driver && (
                        <PortletHeaderToolbar>
                          <Link to={`/drivers/${driver.id}`}>
                            <Button variant="light">
                              <i className="la la-arrow-right" />
                              карточка организации&nbsp;
                            </Button>
                          </Link>
                        </PortletHeaderToolbar>
                      )
                    }
                  />
                  <PortletBody fluid>
                    <div className="kt-widget12">
                      <div className="kt-widget12__content">
                        {driver && (
                          <div className="kt-widget12__item">
                            <div className="kt-widget12__info">
                              <span className="kt-widget12__desc">
                                Организация
                              </span>
                              <span className="kt-widget12__value">
                                {driver.short_name ?? driver.name}
                              </span>
                            </div>
                          </div>
                        )}
                        {driver_employee && (
                          <div className="kt-widget12__item">
                            <div className="kt-widget12__info">
                              <span className="kt-widget12__desc">Контакт</span>
                              <span className="kt-widget12__value">
                                {driver_employee.name}
                                {driver_employee.phone1 ? (
                                  <>
                                    <br />
                                    {driver_employee.phone1}
                                  </>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                        {driver_contract && (
                          <div className="kt-widget12__item">
                            <div className="kt-widget12__info">
                              <span className="kt-widget12__desc">Договор</span>
                              <span className="kt-widget12__value">
                                №{driver_contract.number} от{" "}
                                {humanDate(driver_contract.date_start)}
                              </span>
                            </div>
                          </div>
                        )}
                        {driver_carrier && (
                          <div className="kt-widget12__item">
                            <div className="kt-widget12__info">
                              <span className="kt-widget12__desc">
                                Водитель
                              </span>
                              <span className="kt-widget12__value">
                                {driver_carrier.name}
                                {driver_carrier.phone1 ? (
                                  <>
                                    <br />
                                    {driver_carrier.phone1}
                                  </>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </div>
                        )}

                        {driver_car && (
                          <div className="kt-widget12__item">
                            <div className="kt-widget12__info">
                              <span className="kt-widget12__desc">
                                Автомобиль
                              </span>
                              <span className="kt-widget12__value">
                                {driver_car.model} ({driver_car.gov_number})
                              </span>
                            </div>
                          </div>
                        )}

                        {driver_trailer && (
                          <div className="kt-widget12__item">
                            <div className="kt-widget12__info">
                              <span className="kt-widget12__desc">Прицеп</span>
                              <span className="kt-widget12__value">
                                {driver_trailer.body_type?.title} (
                                {driver_trailer.gov_number})
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </PortletBody>
                </Portlet>
                <Modal
                  show={this.state.modalComment}
                  onHide={() => this.setState({ modalComment: false })}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Комментарий к заявке</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Control
                      as="textarea"
                      value={comment}
                      onChange={({ target }) =>
                        this.setState({ comment: target.value })
                      }
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      disabled={isPending}
                      variant="default"
                      onClick={() => this.setState({ modalComment: false })}
                    >
                      отменить
                    </Button>
                    <Button
                      className={clsx({
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                          isPending,
                      })}
                      disabled={isPending}
                      variant="success"
                      onClick={() => this.editComment()}
                    >
                      сохранить
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal
                  show={this.state.modalToAdjustment}
                  onHide={() => this.setState({ modalToAdjustment: false })}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Подтвердите действие</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <h6>
                      Заявки со статусом "на корректировке" не выгружаются в 1с,
                      пока статус не станет "готово". Вы уверены, что хотите
                      пометить заявку на корректировку?
                    </h6>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      disabled={isPending}
                      variant="default"
                      onClick={() =>
                        this.setState({ modalToAdjustment: false })
                      }
                    >
                      отменить
                    </Button>
                    <Button
                      className={clsx({
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                          isPending,
                      })}
                      disabled={isPending}
                      variant="success"
                      onClick={() =>
                        this.changeStatus(REQUEST_ADJUST_STATUS_ID)
                      }
                    >
                      подтвердить
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RequestCard),
);
