import React, { Component } from "react";
import PropTypes from "prop-types";
import "./l10n";
import "./styles";
import {
  Portlet,
  PortletHeader,
  PortletBody,
  PortletHeaderToolbar,
  PortletFooter,
} from "../../content/Portlet";
import { Button, Spinner, Alert } from "react-bootstrap";
import {
  Toolbar,
  DocumentEditorContainerComponent,
} from "@syncfusion/ej2-react-documenteditor";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import * as fileService from "../../../service/file";
import * as docService from "../../../service/document";

DocumentEditorContainerComponent.Inject(Toolbar);

class WordEditor extends Component {
  static propTypes = {
    enableToolbar: PropTypes.bool,
    sfdt: PropTypes.any,
    onChange: PropTypes.func,
    isReadOnly: PropTypes.bool,
    wrapToPortlet: PropTypes.bool,
    showTopActions: PropTypes.bool,
    showBottomActions: PropTypes.bool,
  };

  static defaultProps = {
    enableToolbar: true,
    isReadOnly: false,
    wrapToPortlet: true,
    showTopActions: true,
    showBottomActions: true,
  };

  documentRef = React.createRef();
  get documentEditor() {
    return this.documentRef.current?.documentEditor;
  }

  state = {
    closeAfterSave: false,
    isPending: false,
    document: null,
    isLoaded: false,
  };
  oldIsPending = false;

  componentWillUnmount() {
    this.documentEditor.destroy();
  }

  print() {
    this.documentEditor.print();
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { documentId, sfdt } = props;
    if (
      (documentId != null && documentId !== this.props.documentId) ||
      sfdt != null
    ) {
      if (!this.state.isLoaded) {
        this.loadDocument(props).then(this.save.bind(this));
      }
    }
  }

  async loadDocument(props = this.props) {
    const { isReadOnly, sfdt, documentId } = props;
    if (!this.documentEditor) return;
    if (sfdt) {
      this.setState({ isLoaded: true });
      this.documentEditor.isReadOnly = isReadOnly;
      this.documentEditor.open(sfdt);
    } else if (documentId != null) {
      await new Promise(async (resolve) => {
        this.setState({ isPending: true, isLoaded: true });
        const { data: document } = await docService.getSFDT(documentId);
        this.documentEditor.isReadOnly = isReadOnly;
        this.documentEditor.open(document.sfdt);
        this.setState({ document, isPending: false }, resolve);
      });
    }
  }

  download() {
    this.documentEditor.save(this.props.title || "sample", "Docx");
  }

  async save() {
    if (!this.state.document || this.props.isReadOnly) return;
    this.setState({ isPending: true });
    const { title } = this.props;
    const { document } = this.state;
    // generate new docx file and sfdt content
    const [newFile, newBlob] = await Promise.all([
      this.documentEditor.saveAsBlob("Docx"),
      this.documentEditor.saveAsBlob("Sfdt"),
    ]);
    if (!newBlob || !newBlob.text) {
      this.setState({ isPending: false });
      return;
    }
    // delete old file if nesessary
    if (document.file) {
      await fileService.remove(document.file.id);
    }
    // save modified sfdt and deleted file
    await docService.updateDocument(
      { sfdt: await newBlob.text(), file_id: null },
      document.id,
    );

    // upload new file
    const {
      data: { id: file_id },
    } = await fileService.upload(newFile, () => void 0, title + ".docx");
    // save modified file_id
    const { data: newDocument } = await docService.updateDocument(
      { file_id },
      document.id,
    );
    this.setState({
      document: newDocument,
      isPending: false,
    });
  }

  close() {
    this.props.history.goBack();
  }

  async saveAndClose() {
    this.setState({ closeAfterSave: true });
    await this.save();
    if (this.state.closeAfterSave) {
      this.close();
    }
  }

  renderDocument() {
    const { isReadOnly, enableToolbar } = this.props;
    return (
      <DocumentEditorContainerComponent
        style={{ height: "100%" }}
        id="container"
        enableToolbar={!isReadOnly && enableToolbar}
        ref={this.documentRef}
        showPropertiesPane={false}
        // serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
        documentEditorSettings={{
          pageOutline: "#E0E0E0",
          acceptTab: true,
        }}
        created={async () => {
          this.documentEditor.pageOutline = "#E0E0E0";
          this.documentEditor.acceptTab = true;
          this.documentEditor.resize();
          await this.loadDocument();
          if (this.state.document) await this.save();
        }}
        contentChange={this.props.onChange}
        enableLocalPaste
        toolbarItems={
          isReadOnly
            ? []
            : [
                "Open",
                "Separator",
                "Undo",
                // 'Redo',
                "Separator",
                // 'Image',
                "Table",
                "Hyperlink",
                "Separator",
                "Header",
                "Footer",
                "PageSetup",
                "PageNumber",
                // 'Break',
                "Separator",
                "Find",
                "Separator",
              ]
        }
      />
    );
  }

  render() {
    const { closeAfterSave, isPending } = this.state;
    const {
      title,
      isReadOnly,
      wrapToPortlet,
      showBottomActions,
      showTopActions,
    } = this.props;
    return (
      <>
        {wrapToPortlet ? (
          <Portlet>
            <PortletHeader
              title={title || "Документ!"}
              toolbar={
                showTopActions && (
                  <PortletHeaderToolbar>
                    <Button
                      className="ml-2"
                      variant="outline-success"
                      disabled={isPending}
                      onClick={() => this.download()}
                    >
                      <i className="la la-download" />
                      Скачать в формате Word
                    </Button>
                    {/* <Button
                      className="ml-2"
                      disabled={isPending}
                      variant="outline-dark"
                      onClick={() =>
                        docService.pdfWithSignature(this.props.documentId)
                      }
                    >
                      <i className="la la-file" />
                      Скачать PDF с печатью
                    </Button> */}
                    <Button
                      className="ml-2"
                      variant="outline-info"
                      disabled={isPending}
                      onClick={() => this.print()}
                    >
                      <i className="la la-print" />
                      Распечатать
                    </Button>
                  </PortletHeaderToolbar>
                )
              }
            />
            {isReadOnly && (
              <PortletBody>
                <Alert variant="outline-info" className="kt-font-boldest">
                  Для этого документа доступен только просмотр.
                </Alert>
              </PortletBody>
            )}
            <PortletBody fit style={{ height: "500px", position: "relative" }}>
              {this.renderDocument()}
              {isPending && (
                <div className="spinner-inner">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )}
            </PortletBody>
            {!isReadOnly && showBottomActions && (
              <PortletFooter>
                <Button
                  disabled={isPending}
                  className={clsx("mr-2", {
                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                      isPending && !closeAfterSave,
                  })}
                  variant="success"
                  onClick={() => this.save()}
                >
                  <i className="la la-save" />
                  Сохранить
                </Button>
                <Button
                  disabled={isPending}
                  className={clsx("mr-2", {
                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                      isPending && closeAfterSave,
                  })}
                  variant="info"
                  onClick={() => this.saveAndClose()}
                >
                  <i className="la la-check" />
                  Сохранить и закрыть
                </Button>
                <Button
                  disabled={isPending}
                  className="pull-right"
                  onClick={() => this.close()}
                  variant="danger"
                >
                  <i className="la la-close" />
                  Закрыть
                </Button>
              </PortletFooter>
            )}
          </Portlet>
        ) : (
          this.renderDocument()
        )}
      </>
    );
  }
}

export default withRouter(WordEditor);
