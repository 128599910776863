import React from "react";
import { connect } from "react-redux";
import EntityForm from "../../../components/entity/EntityForm";
import { userEntity } from "../../../store/ducks/entity.duck";

const schema = {
  name: { label: "Имя" },
  lastname: { label: "Фамилия" },
  email: { type: "email", label: "Email" },
  phone: { label: "Телефон" },
  password: { type: "password", label: "Пароль" },
};

const ProfilePage = (props) => {
  return (
    <EntityForm
      name="user"
      id={props.user.id.toString()}
      schema={schema}
      actions={userEntity.actions}
      actionTypes={userEntity.types}
      select={(state) => state.catalog.users}
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ProfilePage);
