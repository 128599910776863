import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import urlconf from "../../app/router/urlconf";
import { matchPath, generatePath, useLocation } from "react-router-dom";

/**
 * Both context used to create inside react `redux`-like global state managed
 * entirely by react.
 *
 * @see https://kentcdodds.com/blog/how-to-use-react-context-effectively
 */

const LayoutContext = {
  /**
   * Stores layout state, can be consumed globally.
   */
  State: createContext(null),

  /**
   * Stores `dispatch` function to update layout state, intended to be internal.
   */
  Dispatch: createContext(null),
};

/**
 * Layout action types, used to filter out dispatched actions.
 */
const actionTypes = {
  /**
   * Initializes layout state from provided `{ pathname, menuConfig }` action
   * payload.
   */
  INIT: "INIT",

  /**
   * Updates current subheader from provided `{ title }` action payload.
   */
  SET_SUBHEADER: "SET_SUBHEADER",

  /**
   * Controls splash screen visibility.
   */
  SHOW_SPLASH_SCREEN: "SHOW_SPLASH_SCREEN",
  HIDE_SPLASH_SCREEN: "HIDE_SPLASH_SCREEN",
};

/**
 * Used to lazily create initial layout state.
 */
function init({ pathname }) {
  const breadcrumbs = urlconf
    .map((conf) => {
      const match = matchPath(pathname, conf);
      return match
        ? {
            ...conf,
            path: generatePath(conf.path, match.params),
            origPath: conf.path,
          }
        : conf.path;
    })
    .filter((conf) => pathname.includes(conf.path))
    .sort((a, b) => a.path?.length - b.path?.length);
  breadcrumbs.pop();
  const pageConfig = urlconf.find((conf) =>
    matchPath(pathname, { ...conf, exact: true }),
  );

  const state = {
    subheader: {
      title: "",
      breadcrumb: [],
      description: "",
      isList: false,
      history: "",
      pathname: "",
    },
    splashScreen: { refs: {} },
  };
  if (pageConfig) {
    const pageMatch = matchPath(pathname, pageConfig);
    let history = pageConfig.history;
    if (history && pageMatch) {
      history = generatePath(pageConfig.history, pageMatch.params);
    }
    state.subheader.pathname = pathname;
    state.subheader.breadcrumb = breadcrumbs;
    state.subheader.title = pageConfig.title;
    state.subheader.history = history;
    state.subheader.isList = Boolean(pageConfig.isList);
  }

  return state;
}

function reducer(state, { type, payload }) {
  if (type === actionTypes.INIT) {
    const nextState = init(payload);

    // Update only subheader.
    return { ...state, subheader: nextState.subheader };
  }

  if (type === actionTypes.SET_SUBHEADER) {
    return { ...state, subheader: payload };
  }

  if (type === actionTypes.SHOW_SPLASH_SCREEN) {
    return {
      ...state,
      splashScreen: {
        ...state.splashScreen,
        refs: { ...state.splashScreen.refs, [payload.id]: true },
      },
    };
  }

  if (type === actionTypes.HIDE_SPLASH_SCREEN) {
    const { [payload.id]: skip, ...nextRefs } = state.splashScreen.refs;

    return {
      ...state,
      splashScreen: { ...state.splashScreen, refs: nextRefs },
    };
  }

  return state;
}

/**
 * Creates layout reducer and provides it's `state` and ` dispatch`.
 */
export function LayoutContextProvider({ history, children }) {
  const params = useLocation();
  const [state, dispatch] = useReducer(
    reducer,
    { pathname: history.location.pathname, id: params.id },
    // See https://reactjs.org/docs/hooks-reference.html#lazy-initialization
    init,
  );

  // Subscribe to history changes and reinitialize on each change.
  useEffect(
    () =>
      history.listen(({ pathname }) => {
        dispatch({
          type: actionTypes.INIT,
          payload: { pathname },
        });
      }),

    /**
     * Passing `history` and `menuConfig` to `deps` ensures that `useEffect`
     * will cleanup current `history` listener and will dispatch `INIT`
     * with `menuConfig` reference from current render.
     *
     * @see https://reactjs.org/docs/hooks-reference.html#conditionally-firing-an-effect
     */
    [history],
  );

  const { refs: splashScreenRefs } = state.splashScreen;
  const splashScreenVisible = useMemo(
    () => Object.keys(splashScreenRefs).length > 0,
    [splashScreenRefs],
  );

  useEffect(() => {
    const splashScreen = document.getElementById("splash-screen");
    if (splashScreenVisible) {
      splashScreen.classList.remove("hidden");

      return () => {
        splashScreen.classList.add("hidden");
      };
    }

    const timeout = setTimeout(() => {
      splashScreen.classList.add("hidden");
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [splashScreenVisible]);

  // Pass state and dispatch to it's contexts.
  return (
    <LayoutContext.State.Provider value={state}>
      <LayoutContext.Dispatch.Provider value={dispatch}>
        {children}
      </LayoutContext.Dispatch.Provider>
    </LayoutContext.State.Provider>
  );
}

/**
 * Used to access latest layout context state.
 *
 * @example
 *
 * export function Subheader() {
 *   return (
 *     <LayoutContextConsumer>
 *       {({ subheader: { title } }) => <h1>{title}</h1>}
 *     </LayoutContextConsumer>
 *   );
 * }
 */
export const LayoutContextConsumer = LayoutContext.State.Consumer;

/**
 * Hook to access latest layout context state.
 *
 * @example
 *
 * export function Subheader() {
 *   const { subheader: { title } } = useLayoutContext();
 *
 *   return <h1>{title}</h1>;
 * }
 */
export function useLayoutContext() {
  const context = useContext(LayoutContext.State);

  if (!context) {
    throw new Error("");
  }

  return context;
}

/**
 * Used to override layout subheader state.
 */
export function LayoutSubheader({ title, breadcrumb, description }) {
  const dispatch = useContext(LayoutContext.Dispatch);

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_SUBHEADER,
      payload: { title, breadcrumb, description },
    });
  }, [dispatch, title, breadcrumb, description]);

  return null;
}

export function LayoutSplashScreen({ visible = false }) {
  const dispatch = useContext(LayoutContext.Dispatch);

  useEffect(() => {
    if (!visible) {
      return;
    }

    const id = Math.random();

    dispatch({ type: actionTypes.SHOW_SPLASH_SCREEN, payload: { id } });

    return () => {
      // console.log("HIDE SPLASH");
      dispatch({ type: actionTypes.HIDE_SPLASH_SCREEN, payload: { id } });
    };
  }, [visible, dispatch]);

  return null;
}
