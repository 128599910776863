import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";
import clsx from "clsx";
import { LinearProgress } from "@material-ui/core";
import {
  Portlet,
  PortletHeader,
  PortletFooter,
  PortletBody,
} from "../../../partials/content/Portlet";
import { connect } from "react-redux";
import { driversEntity } from "../../../store/ducks/entity.duck";
import {
  replaceNullToString,
  replaceStringToNull,
  isEmpty,
} from "../../../helper/object";
import OrgForm from "../../../partials/widgets/OrgForm";

const mapStateToProps = (
  { drivers: { errors, type, lastId, single, isSuccess, isPending } },
  props,
) => {
  const id =
    props.id ||
    (type === driversEntity.types.Create && isSuccess && lastId) ||
    null;
  return {
    errors,
    id,
    isPending,
    isSuccess,
    defaults: replaceNullToString(single[id]),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchDriver: (id) => dispatch(driversEntity.actions.single(id)),
  createDriver: (fields) => dispatch(driversEntity.actions.create(fields)),
  updateDriver: (fields, id) =>
    dispatch(driversEntity.actions.update(fields, id)),
});

class DriverForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitAndClose = this.handleSubmitAndClose.bind(this);
  }

  componentDidMount() {
    const { fetchDriver, id } = this.props;
    if (!this.isCreate) {
      fetchDriver(id).then(() => {
        // First load of editable element, fill values
        this.setState({
          isLoaded: true,
          fields: { ...this.state.fields, ...this.props.defaults },
        });
      });
    }
  }

  static propTypes = {
    id: PropTypes.string,
    fetchDriver: PropTypes.func.isRequired,
    createDriver: PropTypes.func.isRequired,
    updateDriver: PropTypes.func.isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    defaults: PropTypes.shape(),
    isPending: PropTypes.bool,
    isSuccess: PropTypes.bool,
  };

  state = {
    isLoaded: this.isCreate,
    closeAfterSave: false,
    fields: {
      org_type: "ooo",
      inn: "",
      name: "",
      short_name: "",
      director: "",
      director_short: "",
      passport: "",
      city: "",
      basis: "",
      address: "",
      real_address: "",
      is_address_coincide: false,
      kpp: "",
      ogrn: "",
      ati_code: "",
      ati_score: 0,
      phone1: "",
      phone2: "",
      phone3: "",
      email: "",
      website: "",
    },
    errors: [],
    validated: false,
  };

  get isCreate() {
    return !this.props.id;
  }

  async handleSubmitAndClose(ev) {
    this.setState({ closeAfterSave: true });
    this.handleSubmit(ev);
  }

  async handleSubmit(ev) {
    ev.preventDefault();
    const { fields: rawFields } = this.state;
    const fields = replaceStringToNull(rawFields);
    if (this.isCreate) {
      await this.props.createDriver(fields);
    } else {
      await this.props.updateDriver(fields, this.props.id);
    }
    if (!isEmpty(this.props.errors) && this.state.closeAfterSave) {
      this.setState({ closeAfterSave: false });
    }
  }

  onChange(fields) {
    this.setState({
      fields: { ...this.state.fields, ...fields },
    });
  }

  render() {
    const { isLoaded, validated, closeAfterSave } = this.state;
    const { history, isPending, id, errors } = this.props;
    if (!isLoaded) {
      return <LinearProgress />;
    }
    if (!isPending && isLoaded && isEmpty(errors) && id > 0) {
      if (closeAfterSave) {
        return <Redirect to={`/drivers/${id}`} />;
      } else if (history.location.pathname.startsWith("/drivers/create")) {
        history.replace(`/drivers/${id}/edit`);
      }
    }
    return (
      <Form
        noValidate
        validated={validated}
        onSubmit={this.handleSubmit}
        style={{ maxWidth: 900, margin: "0 auto" }}
      >
        <Portlet>
          <PortletHeader title="Данные организации" />
          <PortletBody>
            <OrgForm
              errors={errors}
              onChange={this.onChange.bind(this)}
              fields={this.state.fields}
            />
          </PortletBody>
          <PortletFooter sticky>
            <Button
              variant="success"
              disabled={isPending}
              onClick={this.handleSubmit}
              type="button"
              className={clsx("mr-2", {
                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                  isPending && !closeAfterSave,
              })}
            >
              Сохранить
            </Button>
            <Button
              color="primary"
              disabled={isPending}
              onClick={this.handleSubmitAndClose}
              type="button"
              className={clsx("mr-1", {
                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                  isPending && closeAfterSave,
              })}
            >
              Сохранить и закрыть
            </Button>
            <Button
              variant="danger"
              onClick={() => this.props.history.goBack()}
              type="button"
              className="mr-2"
            >
              Отменить
            </Button>
          </PortletFooter>
        </Portlet>
      </Form>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DriverForm),
);
