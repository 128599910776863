import { apiDate } from "../../helper/date";
import { parseISO } from "date-fns";
import { replaceNullToString, replaceStringToNull } from "../../helper/object";

const humanReducer = ({ date, ...rest } = {}) => ({
  date: parseISO(date),
  ...replaceNullToString(rest),
});

const apiReducer = ({ date, ...rest } = {}) => ({
  ...replaceStringToNull(rest),
  date: date && apiDate(date),
});

export default { from: humanReducer, to: apiReducer };
