import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  ButtonGroup,
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import clsx from "clsx";
import { LinearProgress } from "@material-ui/core";
import {
  customersEntity,
  employeeEntity,
  driversEntity,
  carsEntity,
  cargoEntity,
  carrierEntity,
  contractEntity,
  bankEntity,
  cargoPackEntity,
} from "../../../../store/ducks/entity.duck";
import {
  Portlet,
  PortletHeader,
  PortletFooter,
  PortletBody,
} from "../../../../partials/content/Portlet";
import { connect } from "react-redux";
import {
  replaceNullToString,
  isEmpty,
  replaceStringToNull,
} from "../../../../helper/object";
import SelectEntity from "../../../../components/entity/SelectEntity";
import * as service from "../../../../service/request";
import { humanDateParse } from "../../../../helper/date";
import { RequestShipment } from "./RequestShipment";
import { CargoCreate } from "../../catalog/Cargo";
import { CargoPackCreate } from "../../catalog/CargoPack";
import ReactDatePicker from "react-datepicker";
import { REQUEST_DRAFT_STATUS_ID } from "../../../../store/staticStore";

const getCopyId = () => new URLSearchParams(window.location.search).get("copy");

const mapStateToProps = (state, { select, id, entity }) => {
  const { lastId, isPending, isSuccess, single, errors, type } = select(state);
  const isCreate = type === entity.types.Create;
  return {
    errors,
    isPending,
    isSuccess,
    id: id || (isCreate ? lastId : null),
    defaults: replaceNullToString(single[id ?? getCopyId()] ?? {}),
    drivers: state.drivers.rows,
    customers: state.customers.rows,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  fetchRequest: (id) => dispatch(props.entity.actions.single(id)),
  createRequest: (fields) => dispatch(props.entity.actions.create(fields)),
  updateRequest: (fields, id) =>
    dispatch(props.entity.actions.update(fields, id)),
});

class RequestForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitAndClose = this.handleSubmitAndClose.bind(this);
  }

  static propTypes = {
    id: PropTypes.string,
    isConvert: PropTypes.bool,
    fetchRequest: PropTypes.func.isRequired,
    createRequest: PropTypes.func.isRequired,
    updateRequest: PropTypes.func.isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    defaults: PropTypes.shape(),
    isPending: PropTypes.bool,
  };

  get initialShipmentFields() {
    return {
      id: `new${parseInt(Math.random() * 100000)}`,
      type: "",
      request_id: "",
      organization: "",
      city: "",
      address: "",
      contact: "",
      date: "",
      time: "",
    };
  }

  state = {
    isLoaded: this.isCreate,
    isLoadingNumber: false,
    closeAfterSave: false,
    fields: {
      id: "",
      number_customer: "",
      number: "",
      date: new Date(),
      cargo_title: "",
      cargo_type_id: "",
      cargo_weight: "",
      cargo_size_x: "",
      cargo_value: "",
      cargo_size_y: "",
      cargo_size_z: "",
      cargo_info: "",
      load_conditions: "",
      car_conditions: "",
      is_car_return: false,

      cost_driver: "",
      driver_id: "",
      driver_contract_id: "",
      driver_bank_id: "",
      driver_carrier_id: "",
      driver_employee_id: "",
      driver_trailer_id: "",
      driver_car_id: "",
      driver_price_by_agreement: false,
      driver_more_conditions: "",
      driver_rewrite_deadline_days: false,
      driver_deadline_measurement: "workdays",
      driver_deadline_days: "",

      customer_id: "",
      cost_customer: "",
      customer_bank_id: "",
      customer_contract_id: "",
      customer_employee_id: "",
      customer_price_by_agreement: false,
      customer_more_conditions: "",
      customer_rewrite_deadline_days: false,
      customer_deadline_measurement: "workdays",
      customer_deadline_days: "",

      pack_id: "",
      pack_quantity: "",

      shipment_load: [{ ...this.initialShipmentFields, type: "load" }],
      shipment_unload: [{ ...this.initialShipmentFields, type: "unload" }],
    },
    errors: [],
    validated: false,
  };

  get isCreate() {
    return !this.props.id;
  }

  forbidCopyKeys = [
    "date",
    "number",
    "number_customer",
    "driver_deadline_days",
    "driver_deadline_measurement",
    "driver_rewrite_deadline_days",
    "customer_deadline_days",
    "customer_deadline_measurement",
    "customer_rewrite_deadline_days",
  ];

  componentDidMount() {
    const copyId = getCopyId();
    if (!this.isCreate || copyId) {
      const { fetchRequest, id } = this.props;
      fetchRequest(id ?? copyId).then(() => {
        const { defaults } = this.props;
        if (this.isCreate && copyId) {
          Object.keys(defaults).forEach((key) => {
            if (this.forbidCopyKeys.includes(key)) {
              // no need to copy this key, is should be changed
              defaults[key] = "";
            }
          });
          [...defaults.shipment_load, ...defaults.shipment_unload].forEach(
            (ship) => {
              delete ship.date;
            },
          );
        }
        Object.keys(defaults).forEach((key) => {
          if (!this.state.fields.hasOwnProperty(key)) {
            // key is not in fields so it is a trash
            delete defaults[key];
          }
        });

        this.setState({
          isLoaded: true,
          fields: { ...this.state.fields, ...defaults },
        });
      });
    }
  }

  async searchEmployee(field, pk, query) {
    if (!pk) return [];
    return employeeEntity.actions.search(
      { [field]: pk, "org_employee.name": query },
      { [field]: "strict" },
    );
  }

  async handleSubmitAndClose(ev) {
    this.setState({ closeAfterSave: true });
    this.handleSubmit(ev);
  }

  async handleSubmit(ev) {
    ev.preventDefault();
    const { fields: rawFields } = this.state;
    const fields = replaceStringToNull(rawFields);
    if (this.isCreate) {
      fields.status_id = this.props.creationStatusID;
      await this.props.createRequest(fields);
    } else {
      await this.props.updateRequest(fields, this.props.id);
    }
    if (!isEmpty(this.props.errors)) {
      this.setState({ closeAfterSave: false });
    }
  }

  set(field, value) {
    this.setState({
      fields: { ...this.state.fields, [field]: value },
    });
  }

  setShipment(key, newShipment, id) {
    const name = `shipment_${key}`;
    const { fields } = this.state;
    if (newShipment === null) {
      // delete
      this.set(
        name,
        fields[name].filter((oldShipment) => {
          return oldShipment.id !== id;
        }),
      );
    } else {
      // modify
      this.set(
        name,
        fields[name].map((oldShipment) => {
          return oldShipment.id === id
            ? { ...oldShipment, ...newShipment }
            : oldShipment;
        }),
      );
    }
  }

  async generateNumber() {
    this.setState({ isLoadingNumber: true });
    const {
      data: { number },
    } = await service.getNumber();
    this.set("number", number);
    this.setState({ isLoadingNumber: false });
  }

  get isDraft() {
    return this.props.creationStatusID === REQUEST_DRAFT_STATUS_ID;
  }

  render() {
    if (!this.state.isLoaded) {
      return <LinearProgress />;
    }
    const { fields, validated, closeAfterSave, isLoadingNumber } = this.state;
    const { history, isPending, isSuccess, id, errors, entity } = this.props;
    if (!isPending && isSuccess && isEmpty(errors) && id > 0) {
      if (closeAfterSave) {
        return <Redirect to={`/${entity.name}/${id}`} />;
      } else if (
        history.location.pathname.startsWith(`/${entity.name}/create`)
      ) {
        history.replace(`/${entity.name}/${id}/edit`);
      }
    }
    const { driver_id, customer_id } = fields;
    return (
      <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
        <Portlet>
          <PortletHeader title="Данные заявки" />
          <PortletBody>
            <div className="kt-form kt-form--label-right">
              <div className="kt-form__body">
                <div className="kt-section kt-section--first">
                  <div className="kt-section__body">
                    <Form.Group as={Row}>
                      <Col md="3" />
                      <Col md={this.isDraft ? 4 : 1}>
                        <Form.Label className="col-form-label">
                          Номер заказчика
                        </Form.Label>
                        <Form.Control
                          placeholder="номер заказчика"
                          name="number_customer"
                          isInvalid={errors["number_customer"]}
                          onChange={(ev) =>
                            this.set(ev.target.name, ev.target.value)
                          }
                          value={fields["number_customer"]}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors["number_customer"]}
                        </Form.Control.Feedback>
                      </Col>
                      {!this.isDraft && (
                        <Col md="3">
                          <Form.Label className="col-form-label">
                            Номер заявки АТЛ
                          </Form.Label>
                          <InputGroup>
                            <InputGroup.Prepend>
                              <Button
                                block
                                onClick={this.generateNumber.bind(this)}
                                disabled={isLoadingNumber}
                                variant="info"
                              >
                                {isLoadingNumber ? (
                                  <i className="kt-spinner kt-spinner--center kt-spinner--md kt-spinner--light" />
                                ) : (
                                  "сгенерировать"
                                )}
                              </Button>
                            </InputGroup.Prepend>
                            <Form.Control
                              placeholder="номер заявки"
                              name="number"
                              isInvalid={errors["number"]}
                              onChange={(ev) =>
                                this.set(ev.target.name, ev.target.value)
                              }
                              value={fields["number"]}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors["number"]}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Col>
                      )}
                      <Col md="2">
                        <Form.Label className="col-form-label">
                          Дата заявки
                        </Form.Label>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className="la la-calendar" />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <ReactDatePicker
                            className={clsx("form-control", {
                              "is-invalid": errors.hasOwnProperty("date"),
                            })}
                            locale="ru"
                            selected={fields["date"]}
                            dateFormat={"dd.MM.yyyy"}
                            onChange={(date) =>
                              this.setState({
                                fields: { ...this.state.fields, date: date },
                              })
                            }
                          />
                          <Form.Control
                            hidden
                            isInvalid={errors.hasOwnProperty("date")}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors["date"]}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Col>
                      <Col md="3" />
                    </Form.Group>
                    <div className="kt-separator kt-separator--border-dashed kt-separator--portlet-fit kt-separator--space-lg" />
                    <div className="row">
                      <label className="col-xl-3" />
                      <div className="col-lg-9 col-xl-6">
                        <h3 className="kt-section__title kt-section__title-sm  kt-font-boldest kt-font-lg">
                          Груз:
                        </h3>
                      </div>
                    </div>
                    <Form.Group as={Row}>
                      <Form.Label
                        as={Col}
                        xl="3"
                        lg="3"
                        className="col-form-label"
                      >
                        Наименование груза
                      </Form.Label>
                      <Col lg="9" xl="6">
                        <SelectEntity
                          creatable
                          errorText={errors["cargo_type_id"]}
                          CreateChild={CargoCreate}
                          search={(val) =>
                            cargoEntity.actions.search({ title: val })
                          }
                          noOptionsMessage={"Типов не найдено"}
                          onChange={(props) =>
                            this.set("cargo_type_id", props.id)
                          }
                          value={fields["cargo_type_id"]}
                          getOptionLabel={({ title }) => title}
                          getOptionValue={(props) => props.id}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-4">
                      <Col
                        xl={{ offset: 3, span: 3 }}
                        lg={{ offset: 3, span: 9 }}
                      >
                        <Form.Label className="col-form-label">
                          Тип упаковки
                        </Form.Label>
                        <SelectEntity
                          creatable
                          errorText={errors["pack_id"]}
                          CreateChild={CargoPackCreate}
                          search={(val) =>
                            cargoPackEntity.actions.search({ title: val })
                          }
                          noOptionsMessage={"Упаковок не найдено"}
                          onChange={(props) => this.set("pack_id", props.id)}
                          value={fields["pack_id"]}
                          getOptionLabel={({ title }) => title}
                          getOptionValue={(props) => props.id}
                        />
                      </Col>
                      <Col
                        xl={{ span: 3, offset: 0 }}
                        lg={{ offset: 3, span: 9 }}
                      >
                        <Form.Label className="col-form-label">
                          Количество упаковки
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            isInvalid={errors["pack_quantity"]}
                            name="pack_quantity"
                            value={fields["pack_quantity"]}
                            onChange={(ev) =>
                              this.set(ev.target.name, ev.target.value)
                            }
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>шт</InputGroup.Text>
                          </InputGroup.Append>
                          <Form.Control.Feedback type="invalid">
                            {errors["pack_quantity"]}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label
                        as={Col}
                        xl="3"
                        lg="3"
                        className="col-form-label"
                      >
                        Характеристика груза
                      </Form.Label>
                      <Col lg="9" xl="6">
                        <Form.Control
                          as="textarea"
                          isInvalid={errors["cargo_info"]}
                          name="cargo_info"
                          value={fields["cargo_info"]}
                          onChange={(ev) =>
                            this.set(ev.target.name, ev.target.value)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors["cargo_info"]}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4">
                      <Col
                        xl={{ offset: 3, span: 3 }}
                        lg={{ offset: 3, span: 9 }}
                      >
                        <Form.Label className="col-form-label">
                          Масса груза, т
                        </Form.Label>
                        <InputGroup>
                          <InputGroup.Append>
                            <InputGroup.Text>
                              <i className="fa fa-balance-scale-right" />
                            </InputGroup.Text>
                          </InputGroup.Append>
                          <Form.Control
                            isInvalid={errors["cargo_weight"]}
                            name="cargo_weight"
                            value={fields["cargo_weight"]}
                            onChange={(ev) =>
                              this.set(ev.target.name, ev.target.value)
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors["cargo_weight"]}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Col>

                      <Col
                        xl={{ span: 3, offset: 0 }}
                        lg={{ offset: 3, span: 9 }}
                      >
                        <Form.Label className="col-form-label">
                          Объем груза, м<sup>3</sup>
                        </Form.Label>
                        <Form.Control
                          isInvalid={errors["cargo_value"]}
                          name="cargo_value"
                          value={fields["cargo_value"]}
                          onChange={(ev) =>
                            this.set(ev.target.name, ev.target.value)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors["cargo_value"]}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label
                        as={Col}
                        xl="3"
                        lg="3"
                        className="col-form-label"
                      >
                        Габариты груза, м
                      </Form.Label>
                      <Col lg="9" xl="6">
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>Длина:</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            name="cargo_size_z"
                            isInvalid={errors["cargo_size_z"]}
                            placeholder="длина, м"
                            value={fields["cargo_size_z"]}
                            onChange={(ev) =>
                              this.set(ev.target.name, ev.target.value)
                            }
                          />
                          <InputGroup.Prepend>
                            <InputGroup.Text>Ширина:</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            isInvalid={errors["cargo_size_x"]}
                            name="cargo_size_x"
                            placeholder="ширина, м"
                            value={fields["cargo_size_x"]}
                            onChange={(ev) =>
                              this.set(ev.target.name, ev.target.value)
                            }
                          />
                          <InputGroup.Prepend>
                            <InputGroup.Text>Высота:</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            isInvalid={errors["cargo_size_y"]}
                            name="cargo_size_y"
                            placeholder="высота, м"
                            value={fields["cargo_size_y"]}
                            onChange={(ev) =>
                              this.set(ev.target.name, ev.target.value)
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors["cargo_size_x"] ||
                              errors["cargo_size_y"] ||
                              errors["cargo_size_z"]}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label
                        as={Col}
                        xl="3"
                        lg="3"
                        className="col-form-label"
                      >
                        Условия осуществления погрузочно-разгрузочных работ
                      </Form.Label>
                      <Col lg="9" xl="6">
                        <Form.Control
                          as="textarea"
                          isInvalid={errors["load_conditions"]}
                          name="load_conditions"
                          value={fields["load_conditions"]}
                          onChange={(ev) =>
                            this.set(ev.target.name, ev.target.value)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors["load_conditions"]}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label
                        as={Col}
                        xl="3"
                        lg="3"
                        className="col-form-label"
                      >
                        Требования к транспортному средству
                      </Form.Label>
                      <Col lg="9" xl="6">
                        <Form.Control
                          as="textarea"
                          isInvalid={errors["car_conditions"]}
                          name="car_conditions"
                          value={fields["car_conditions"]}
                          onChange={(ev) =>
                            this.set(ev.target.name, ev.target.value)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors["load_conditions"]}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    <div className="kt-separator kt-separator--border-dashed kt-separator--portlet-fit kt-separator--space-lg" />
                    <div className="row">
                      <div className="col-lg-9 col-xl-6">
                        <h3 className="kt-section__title kt-section__title-sm  kt-font-boldest kt-font-lg">
                          Погрузка и выгрузка:
                        </h3>
                      </div>
                    </div>
                    <Row>
                      <Col md="6">
                        {fields.shipment_load.map((shipment, index) => (
                          <div key={shipment.id}>
                            <h3>
                              Погрузка в пункте №{index + 1} &nbsp;
                              <ButtonGroup>
                                {/* <Button disabled={!customer_id} size="sm">заполнить из заказчика</Button> */}
                                {fields.shipment_load.length > 1 && (
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={(ev) =>
                                      this.setShipment(
                                        "load",
                                        null,
                                        shipment.id,
                                      )
                                    }
                                  >
                                    удалить пункт загрузки
                                  </Button>
                                )}
                              </ButtonGroup>
                            </h3>
                            <RequestShipment
                              fields={shipment}
                              errors={(errors["load"] || [])[index] ?? {}}
                              onChange={(field, value) =>
                                this.setShipment(
                                  "load",
                                  { [field]: value },
                                  shipment.id,
                                )
                              }
                            />
                          </div>
                        ))}
                        <Button
                          onClick={() =>
                            this.set("shipment_load", [
                              ...fields.shipment_load,
                              { ...this.initialShipmentFields, type: "load" },
                            ])
                          }
                          variant="outline-success"
                        >
                          добавить пункт погрузки
                        </Button>
                      </Col>
                      <Col md="6">
                        {fields.shipment_unload.map((shipment, index) => (
                          <div key={shipment.id}>
                            <h3>
                              Выгрузка в пункте №{index + 1} &nbsp;
                              <ButtonGroup>
                                {/* <Button disabled={!customer_id} size="sm">заполнить из заказчика</Button> */}
                                {fields.shipment_unload.length > 1 && (
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={(ev) =>
                                      this.setShipment(
                                        "unload",
                                        null,
                                        shipment.id,
                                      )
                                    }
                                  >
                                    удалить пункт выгрузки
                                  </Button>
                                )}
                              </ButtonGroup>
                            </h3>
                            <RequestShipment
                              fields={shipment}
                              errors={(errors["unload"] || [])[index] ?? {}}
                              onChange={(field, value) =>
                                this.setShipment(
                                  "unload",
                                  { [field]: value },
                                  shipment.id,
                                )
                              }
                            />
                          </div>
                        ))}
                        <Button
                          onClick={() =>
                            this.set("shipment_unload", [
                              ...fields.shipment_unload,
                              { ...this.initialShipmentFields, type: "unload" },
                            ])
                          }
                          variant="outline-success"
                        >
                          добавить пункт выгрузки
                        </Button>
                      </Col>
                    </Row>
                    <div className="kt-separator kt-separator--border-dashed kt-separator--portlet-fit kt-separator--space-lg" />
                    <Row>
                      <Col md="6">
                        <Portlet>
                          <PortletHeader
                            variant="warning-light"
                            title="Заказчик"
                          />
                          <PortletBody>
                            <div>
                              <SelectEntity
                                errorText={errors["customer_id"]}
                                search={(val) =>
                                  customersEntity.actions.search({
                                    "org.name": val,
                                  })
                                }
                                onChange={(props) =>
                                  this.set("customer_id", props.id)
                                }
                                noOptionsMessage="Заказчиков не найдено"
                                value={customer_id}
                                getOptionLabel={({
                                  id: num,
                                  short_name,
                                  name,
                                }) => `№${num} ${short_name || name}`}
                                getOptionValue={(props) => props.id}
                              />
                            </div>
                            <div>
                              <Form.Label className="col-form-label">
                                Ставка заказчика
                              </Form.Label>
                              <InputGroup>
                                <InputGroup.Prepend>
                                  <InputGroup.Text>
                                    <span>&#x20bd;</span>
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                  name="cost_customer"
                                  isInvalid={errors["cost_customer"]}
                                  value={fields["cost_customer"]}
                                  onChange={(ev) =>
                                    this.set(ev.target.name, ev.target.value)
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors["cost_customer"]}
                                </Form.Control.Feedback>
                              </InputGroup>
                              <Form.Check
                                label="По согласованию (запишется в листе заявки)"
                                id="customer_price_by_agreement"
                                name="customer_price_by_agreement"
                                checked={fields["customer_price_by_agreement"]}
                                onChange={(ev) =>
                                  this.set(ev.target.name, ev.target.checked)
                                }
                              />
                            </div>
                            <div>
                              <Form.Label className="col-form-label">
                                Договор
                              </Form.Label>
                              <SelectEntity
                                isClearable
                                key={customer_id}
                                search={
                                  customer_id
                                    ? (val) =>
                                        contractEntity.actions.search(
                                          {
                                            "customer.id": customer_id,
                                            number: val,
                                          },
                                          { "customer.id": "strict" },
                                        )
                                    : null
                                }
                                disabled={!customer_id}
                                placeholder={
                                  customer_id ? null : "Выберите заказчика"
                                }
                                noOptionsMessage={"Договоров не найдено"}
                                onChange={(contract) =>
                                  this.set(
                                    "customer_contract_id",
                                    contract?.id ?? "",
                                  )
                                }
                                value={fields["customer_contract_id"]}
                                errorText={errors["customer_contract_id"]}
                                getOptionLabel={({ number, date_start }) =>
                                  `№ ${number} от ${humanDateParse(date_start)}`
                                }
                                getOptionValue={(props) => props.id}
                              />
                            </div>
                            {!fields["customer_contract_id"] && (
                              <div>
                                <Form.Label className="col-form-label">
                                  Реквизиты для договора-заявки
                                </Form.Label>
                                <SelectEntity
                                  isClearable
                                  disabled={!customer_id}
                                  search={
                                    customer_id
                                      ? (val) =>
                                          bankEntity.actions.search(
                                            {
                                              "org_bank.name": val,
                                              "customer.id": customer_id,
                                            },
                                            { "customer.id": "strict" },
                                          )
                                      : null
                                  }
                                  onChange={(bank) =>
                                    this.set("customer_bank_id", bank?.id ?? "")
                                  }
                                  errorText={errors["customer_bank_id"]}
                                  value={fields["customer_bank_id"]}
                                  getOptionLabel={(bank) =>
                                    bank && (
                                      <>
                                        {bank.payment_account} {bank.name}{" "}
                                        {bank.is_main === "1" && (
                                          <label className="kt-badge kt-badge--inline kt-badge--success kt-badge--rounded">
                                            основной
                                          </label>
                                        )}
                                      </>
                                    )
                                  }
                                  getOptionValue={(bank) => bank?.id ?? null}
                                  noOptionsMessage={"Банков не найдено"}
                                  key={customer_id}
                                />
                              </div>
                            )}
                            <div>
                              <Form.Label className="col-form-label">
                                Контактное лицо
                              </Form.Label>
                              <SelectEntity
                                key={customer_id}
                                errorText={errors["customer_employee_id"]}
                                search={(val) =>
                                  this.searchEmployee(
                                    "customer.id",
                                    customer_id,
                                    val,
                                  )
                                }
                                disabled={!customer_id}
                                placeholder={
                                  customer_id ? null : "Выберите заказчика"
                                }
                                noOptionsMessage={"Контактов не найдено"}
                                onChange={(props) =>
                                  this.set("customer_employee_id", props.id)
                                }
                                value={fields["customer_employee_id"]}
                                getOptionLabel={({ name, phone1 }) =>
                                  `${name}${phone1 ? ` (${phone1})` : ""}`
                                }
                                getOptionValue={(props) => props.id}
                              />
                            </div>
                            <hr />
                            <Row>
                              <Col md="9">
                                <Form.Label>Другой срок оплаты</Form.Label>
                                <InputGroup>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>
                                      <Form.Check
                                        id="customer_rewrite_deadline_days"
                                        name="customer_rewrite_deadline_days"
                                        checked={
                                          fields[
                                            "customer_rewrite_deadline_days"
                                          ]
                                        }
                                        onChange={(ev) =>
                                          this.set(
                                            ev.target.name,
                                            ev.target.checked,
                                          )
                                        }
                                      />
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control
                                    isInvalid={errors["customer_deadline_days"]}
                                    disabled={
                                      !fields.customer_rewrite_deadline_days
                                    }
                                    name="customer_deadline_days"
                                    value={fields["customer_deadline_days"]}
                                    onChange={(ev) =>
                                      this.set(ev.target.name, ev.target.value)
                                    }
                                  />
                                  <Form.Control
                                    disabled={
                                      !fields.customer_rewrite_deadline_days
                                    }
                                    as="select"
                                    isInvalid={
                                      errors["customer_deadline_measurement"]
                                    }
                                    name="customer_deadline_measurement"
                                    onChange={({ target: { name, value } }) =>
                                      this.set(name, value)
                                    }
                                    value={fields.customer_deadline_measurement}
                                  >
                                    <option value="workdays">банковских</option>
                                    <option value="weekdays">
                                      календарных
                                    </option>
                                  </Form.Control>
                                  <InputGroup.Append>
                                    <InputGroup.Text>дней</InputGroup.Text>
                                  </InputGroup.Append>
                                  <Form.Control.Feedback type="invalid">
                                    {errors["customer_deadline_days"]}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Col>
                              <Col
                                md="3"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Form.Check
                                  label="Кругорейс"
                                  isInvalid={errors["is_car_return"]}
                                  id="is_car_return"
                                  name="is_car_return"
                                  checked={fields["is_car_return"]}
                                  onChange={(ev) =>
                                    this.set(ev.target.name, ev.target.checked)
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors["is_car_return"]}
                                </Form.Control.Feedback>
                              </Col>
                            </Row>
                            <hr />
                            <Form.Label>Дополнительные условия</Form.Label>
                            <Form.Control
                              as="textarea"
                              isInvalid={errors["customer_more_conditions"]}
                              name="customer_more_conditions"
                              value={fields["customer_more_conditions"]}
                              onChange={(ev) =>
                                this.set(ev.target.name, ev.target.value)
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors["customer_more_conditions"]}
                            </Form.Control.Feedback>
                          </PortletBody>
                        </Portlet>
                      </Col>
                      <Col md="6">
                        <Portlet>
                          <PortletHeader
                            variant="success-light"
                            title="Исполнитель"
                          />
                          <PortletBody>
                            <div>
                              <SelectEntity
                                search={(val) =>
                                  driversEntity.actions.search({
                                    "org.name": val,
                                  })
                                }
                                onChange={(props) =>
                                  this.set("driver_id", props.id)
                                }
                                errorText={errors["driver_id"]}
                                noOptionsMessage="Исполнителей не найдено"
                                value={driver_id}
                                getOptionLabel={({
                                  id: num,
                                  short_name,
                                  name,
                                }) => `№${num} ${short_name || name}`}
                                getOptionValue={(org) => org.id}
                              />
                            </div>
                            <Form.Label className="col-form-label">
                              Ставка перевозчика
                            </Form.Label>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  <span>&#x20bd;</span>
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <Form.Control
                                name="cost_driver"
                                isInvalid={errors["cost_driver"]}
                                value={fields["cost_driver"]}
                                onChange={(ev) =>
                                  this.set(ev.target.name, ev.target.value)
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors["cost_driver"]}
                              </Form.Control.Feedback>
                            </InputGroup>
                            <Form.Check
                              label="По согласованию (запишется в листе заявки)"
                              id="driver_price_by_agreement"
                              name="driver_price_by_agreement"
                              checked={fields["driver_price_by_agreement"]}
                              onChange={(ev) =>
                                this.set(ev.target.name, ev.target.checked)
                              }
                            />

                            <div>
                              <Form.Label className="col-form-label">
                                Договор
                              </Form.Label>
                              <SelectEntity
                                isClearable
                                key={driver_id}
                                errorText={errors["driver_contract_id"]}
                                search={
                                  driver_id
                                    ? (number) =>
                                        contractEntity.actions.search(
                                          {
                                            "driver.id": driver_id,
                                            number,
                                          },
                                          { "driver.id": "strict" },
                                        )
                                    : null
                                }
                                disabled={!driver_id}
                                placeholder={
                                  driver_id ? null : "Выберите исполнителя"
                                }
                                noOptionsMessage={"Договоров не найдено"}
                                onChange={(contract) =>
                                  this.set(
                                    "driver_contract_id",
                                    contract?.id ?? "",
                                  )
                                }
                                value={fields["driver_contract_id"]}
                                getOptionLabel={({ number, date_start }) =>
                                  `№ ${number} от ${humanDateParse(date_start)}`
                                }
                                getOptionValue={(contract) => contract.id}
                              />
                            </div>
                            <div>
                              <Form.Label className="col-form-label">
                                Реквизиты для договора-заявки
                              </Form.Label>
                              <SelectEntity
                                isClearable
                                disabled={!driver_id}
                                key={driver_id}
                                search={
                                  driver_id
                                    ? (val) =>
                                        bankEntity.actions.search(
                                          {
                                            "org_bank.name": val,
                                            "driver.id": driver_id,
                                          },
                                          { "driver.id": "strict" },
                                        )
                                    : null
                                }
                                onChange={(bank) =>
                                  this.set("driver_bank_id", bank?.id ?? "")
                                }
                                errorText={errors["driver_bank_id"]}
                                value={fields["driver_bank_id"]}
                                getOptionLabel={(bank) =>
                                  bank && (
                                    <>
                                      {bank.payment_account} {bank.name}{" "}
                                      {bank.is_main === "1" && (
                                        <label className="kt-badge kt-badge--inline kt-badge--success kt-badge--rounded">
                                          основной
                                        </label>
                                      )}
                                    </>
                                  )
                                }
                                getOptionValue={(bank) => bank.id}
                                noOptionsMessage={"Банков не найдено"}
                              />
                            </div>
                            <div>
                              <Form.Label className="col-form-label">
                                Контактное лицо
                              </Form.Label>
                              <SelectEntity
                                key={driver_id}
                                search={(val) =>
                                  this.searchEmployee(
                                    "driver.id",
                                    driver_id,
                                    val,
                                  )
                                }
                                disabled={!driver_id}
                                placeholder={
                                  driver_id ? null : "Выберите исполнителя"
                                }
                                noOptionsMessage={"Контактов не найдено"}
                                errorText={errors["driver_employee_id"]}
                                onChange={(props) =>
                                  this.set("driver_employee_id", props.id)
                                }
                                value={fields["driver_employee_id"]}
                                getOptionLabel={({ name, phone1 }) =>
                                  `${name}${phone1 ? ` (${phone1})` : ""}`
                                }
                                getOptionValue={(props) => props.id}
                              />
                            </div>
                            <Row>
                              <Col md="6">
                                <Form.Label className="col-form-label">
                                  Автомобиль
                                </Form.Label>
                                <SelectEntity
                                  key={driver_id}
                                  search={
                                    driver_id
                                      ? (model) =>
                                          carsEntity.actions.search(
                                            {
                                              "driver.id": driver_id,
                                              model,
                                              type: "car",
                                            },
                                            {
                                              "driver.id": "strict",
                                              type: "strict",
                                            },
                                          )
                                      : null
                                  }
                                  errorText={errors["driver_car_id"]}
                                  disabled={!driver_id}
                                  placeholder={
                                    driver_id ? null : "Выберите исполнителя"
                                  }
                                  noOptionsMessage={"Автомобилей не найдено"}
                                  onChange={(props) =>
                                    this.set("driver_car_id", props.id)
                                  }
                                  value={fields["driver_car_id"]}
                                  getOptionLabel={({ model, gov_number }) =>
                                    `${model} ${gov_number}`
                                  }
                                  getOptionValue={(car) => car.id}
                                />
                              </Col>
                              <Col md="6">
                                <Form.Label className="col-form-label">
                                  Прицеп
                                </Form.Label>
                                <SelectEntity
                                  key={driver_id}
                                  search={
                                    driver_id
                                      ? (q) =>
                                          carsEntity.actions.search(
                                            {
                                              "driver.id": driver_id,
                                              gov_number: q,
                                              type: "truck",
                                            },
                                            {
                                              "driver.id": "strict",
                                              type: "strict",
                                            },
                                          )
                                      : null
                                  }
                                  errorText={errors["driver_trailer_id"]}
                                  disabled={!driver_id}
                                  placeholder={
                                    driver_id ? null : "Выберите исполнителя"
                                  }
                                  noOptionsMessage={"Прицепов не найдено"}
                                  onChange={(props) =>
                                    this.set("driver_trailer_id", props.id)
                                  }
                                  value={fields["driver_trailer_id"]}
                                  getOptionLabel={({ gov_number, body_type }) =>
                                    `${body_type?.title} ${gov_number}`
                                  }
                                  getOptionValue={(truck) => truck.id}
                                />
                              </Col>
                            </Row>
                            <div>
                              <Form.Label className="col-form-label">
                                Водитель
                              </Form.Label>
                              <SelectEntity
                                key={driver_id}
                                search={
                                  driver_id
                                    ? (name) =>
                                        carrierEntity.actions.search(
                                          { "driver.id": driver_id, name },
                                          { "driver.id": "strict" },
                                        )
                                    : null
                                }
                                disabled={!driver_id}
                                placeholder={
                                  driver_id ? null : "Выберите исполнителя"
                                }
                                noOptionsMessage={"Контактов не найдено"}
                                errorText={errors["driver_carrier_id"]}
                                onChange={(carrier) =>
                                  this.set("driver_carrier_id", carrier.id)
                                }
                                value={fields["driver_carrier_id"]}
                                getOptionLabel={({ name, phone1 }) =>
                                  `${name}${phone1 ? ` (${phone1})` : ""}`
                                }
                                getOptionValue={(props) => props.id}
                              />
                            </div>
                            <hr />
                            <Row>
                              <Col md="9">
                                <Form.Label>Другой срок оплаты</Form.Label>
                                <InputGroup>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>
                                      <Form.Check
                                        id="driver_rewrite_deadline_days"
                                        name="driver_rewrite_deadline_days"
                                        checked={
                                          fields["driver_rewrite_deadline_days"]
                                        }
                                        onChange={(ev) =>
                                          this.set(
                                            ev.target.name,
                                            ev.target.checked,
                                          )
                                        }
                                      />
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control
                                    isInvalid={errors["driver_deadline_days"]}
                                    disabled={
                                      !fields.driver_rewrite_deadline_days
                                    }
                                    name="driver_deadline_days"
                                    value={fields["driver_deadline_days"]}
                                    onChange={(ev) =>
                                      this.set(ev.target.name, ev.target.value)
                                    }
                                  />
                                  <InputGroup.Append>
                                    <Form.Control
                                      disabled={
                                        !fields.driver_rewrite_deadline_days
                                      }
                                      as="select"
                                      isInvalid={
                                        errors["driver_deadline_measurement"]
                                      }
                                      name="driver_deadline_measurement"
                                      onChange={({ target: { name, value } }) =>
                                        this.set(name, value)
                                      }
                                      value={fields.driver_deadline_measurement}
                                    >
                                      <option value="workdays">
                                        банковских
                                      </option>
                                      <option value="weekdays">
                                        календарных
                                      </option>
                                    </Form.Control>
                                    <InputGroup.Text>дней</InputGroup.Text>
                                  </InputGroup.Append>
                                  <Form.Control.Feedback type="invalid">
                                    {errors["driver_deadline_days"]}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Col>
                              <Col
                                md="3"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Form.Check
                                  label="Кругорейс"
                                  isInvalid={errors["is_car_return"]}
                                  id="is_car_return"
                                  name="is_car_return"
                                  checked={fields["is_car_return"]}
                                  onChange={(ev) =>
                                    this.set(ev.target.name, ev.target.checked)
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors["is_car_return"]}
                                </Form.Control.Feedback>
                              </Col>
                            </Row>
                            <hr />
                            <Form.Label>Дополнительные условия</Form.Label>
                            <Form.Control
                              as="textarea"
                              isInvalid={errors["driver_more_conditions"]}
                              name="driver_more_conditions"
                              value={fields["driver_more_conditions"]}
                              onChange={(ev) =>
                                this.set(ev.target.name, ev.target.value)
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors["driver_more_conditions"]}
                            </Form.Control.Feedback>
                          </PortletBody>
                        </Portlet>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </PortletBody>
          <PortletFooter sticky>
            <Button
              variant="success"
              disabled={isPending}
              onClick={this.handleSubmit}
              type="button"
              className={clsx("mr-2", {
                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                  isPending && !closeAfterSave,
              })}
            >
              Сохранить
            </Button>
            <Button
              color="primary"
              disabled={isPending}
              onClick={this.handleSubmitAndClose}
              type="button"
              className={clsx("mr-1", {
                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                  isPending && closeAfterSave,
              })}
            >
              Сохранить и закрыть
            </Button>
            <Button
              variant="danger"
              onClick={() => this.props.history.goBack()}
              type="button"
              className="mr-2"
            >
              Отменить
            </Button>
          </PortletFooter>
        </Portlet>
      </Form>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RequestForm),
);
