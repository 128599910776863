/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Alert, Table, Spinner } from "react-bootstrap";
import {
  PortletHeaderToolbar,
  Portlet,
  PortletHeader,
  PortletBody,
} from "../content/Portlet";
import InvoiceFormModal from "./InvoiceFormModal";
import { humanDate, humanDateParse } from "../../helper/date";
import { boolLabel } from "../../helper/label";
import ConfirmButton from "../content/ConfirmButton";
import staticStore from "../../store/staticStore";
import {
  invoiceEntity,
  requestProgressEntity,
} from "../../store/ducks/entity.duck";

const mapStateToProps = ({ invoices, requests }, props) => ({
  isPending: requests.progress.isPending || props.isPending,
  types: staticStore.invoiceTypes,
});
const mapDispatchToProps = (dispatch) => ({
  fetchInvoiceList: () => dispatch(invoiceEntity.actions.list(null, true)),
  fetchRequests: () => dispatch(requestProgressEntity.actions.list(null, true)),
  removeInvoice: (id) => dispatch(invoiceEntity.actions.remove(id)),
});

class InvoiceList extends React.Component {
  static propTypes = {
    requestId: PropTypes.string.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  state = {
    showModal: false,
    invoiceId: null,
  };

  get isCreate() {
    return this.state.id === null;
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow(ev, invoice) {
    if (ev) ev.preventDefault();

    if (!ev?.target?.closest("button")) {
      this.setState({
        invoice,
        showModal: true,
      });
    }
  }

  render() {
    const { list, isPending, removeInvoice, types } = this.props;
    const { invoice, showModal } = this.state;
    return (
      <>
        <Portlet fluidHeight>
          <PortletHeader
            title="Счета и оплата"
            toolbar={
              <PortletHeaderToolbar>
                <Button
                  size="sm"
                  onClick={() => this.handleShow()}
                  variant="outline-success"
                >
                  Добавить счет
                </Button>
              </PortletHeaderToolbar>
            }
          />
          <PortletBody scrollable style={{ paddingTop: "0" }}>
            <div className="kt-widget4">
              {isPending && (
                <div className="kt-align-center pt-5">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
              {!isPending && list.length === 0 && (
                <Alert variant="secondary" className="mt-5">
                  Не найдено ни одного счета
                </Alert>
              )}
              {!isPending && list.length > 0 && (
                <Table responsive striped hover>
                  <thead>
                    <tr>
                      <th>Тип</th>
                      <th>Счёт</th>
                      <th>Дата оплаты</th>
                      <th>Оплачен</th>
                      <th>Номера заявок</th>
                      <th>УПД/Акт</th>
                      <th>Счет-фактура</th>
                      <th>Платёжные поручения</th>
                      <th width="1%" />
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((row) => {
                      return (
                        <tr
                          key={row.id}
                          onClick={(ev) => this.handleShow(ev, row)}
                        >
                          <td>
                            {row.type === "debet" && (
                              <i className="kt-font-info fa fa-arrow-right" />
                            )}
                            {row.type === "credit" && (
                              <i className="kt-font-warning fa fa-arrow-left" />
                            )}
                            &nbsp;
                            {
                              types.find(({ value }) => value === row.type)
                                ?.label
                            }
                          </td>
                          <td>
                            <b>{row.invoice_number} </b>
                            {row.invoice_date && humanDate(row.invoice_date)}
                          </td>
                          <td>
                            {row.payment_delay_date &&
                              humanDate(row.payment_delay_date)}
                          </td>
                          <td>
                            {boolLabel(row.is_payed, {
                              true: "да",
                              false: "нет",
                              mid: "частично",
                            })}
                          </td>
                          <td>
                            {row.requests.map(({ number, id }, i) => (
                              <span key={id}>
                                {number}
                                {i + 1 === row.requests.length ? "" : ", "}
                              </span>
                            ))}
                          </td>
                          <td>
                            {row.upd_number && (
                              <>
                                <b>{row.upd_number} </b>
                                {row.upd_date && humanDate(row.upd_date)}
                              </>
                            )}
                          </td>
                          <td>
                            {row.sf_number && (
                              <>
                                <b>{row.sf_number} </b>
                                {row.sf_date && humanDate(row.sf_date)}
                              </>
                            )}
                          </td>
                          <td>
                            {row.pay_orders.map((payOrd) => (
                              <span key={payOrd.id}>
                                №{payOrd.number} от{" "}
                                {humanDateParse(payOrd.date)}
                                <br />
                              </span>
                            ))}
                          </td>
                          <td>
                            <ConfirmButton
                              size="sm"
                              action={async () => {
                                await removeInvoice(row.id);
                                this.props.onUpdate();
                              }}
                              variant="label-danger"
                            >
                              удалить
                            </ConfirmButton>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </div>
          </PortletBody>
        </Portlet>
        <InvoiceFormModal
          show={showModal}
          onShow={this.handleShow}
          onClose={this.handleClose}
          onComplete={this.props.onUpdate}
          requestIDs={
            invoice
              ? invoice?.requests?.map((r) => r.id) ?? []
              : [this.props.requestId]
          }
          invoiceId={invoice?.id}
        />
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);
