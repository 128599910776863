import React, { Component } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Button } from "react-bootstrap";
import InvoiceFormModal from "../../../../partials/widgets/InvoiceFormModal";

class RequestListAction extends Component {
  static propTypes = {
    selected: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ).isRequired,
  };

  static defaultProps = {
    selected: [],
  };

  constructor(props, context) {
    super(props, context);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  state = {
    showed: false,
  };

  handleShow() {
    this.setState({ showed: true });
  }

  handleClose() {
    this.setState({ showed: false });
  }

  render() {
    const { selected } = this.props;
    return (
      <div
        className={clsx("kt-form kt-form--label-align-right", {
          collapse: !selected.length,
        })}
      >
        <div className="row align-items-center">
          <div className="col-xl-12">
            <div className="kt-form__group kt-form__group--inline">
              <div className="kt-form__label kt-form__label-no-wrap">
                <label className="kt-font-bold kt-font-danger-">
                  Выбраны&nbsp;
                  <span id="kt_datatable_selected_number1">
                    {selected.length}
                  </span>
                  шт:
                </label>
              </div>
              <div className="kt-form__control">
                <div className="btn-toolbar">
                  <Button
                    variant="outline-info"
                    size="sm"
                    className="mr-2"
                    onClick={() => this.setState({ showed: true })}
                  >
                    Добавить счёт
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <InvoiceFormModal
          show={this.state.showed}
          onShow={this.handleShow}
          onClose={this.handleClose}
          onComplete={this.props.onComplete}
          requestIDs={selected?.map((req) => req.id)}
        />
      </div>
    );
  }
}

export default RequestListAction;
