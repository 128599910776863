import React, { Component } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { humanDate } from "../../helper/date";

export default class EntityListTable extends Component {
  constructor(props, context) {
    super(props, context);
    this.headStickyUpdate = this.headStickyUpdate.bind(this);
    this.tableRef = React.createRef();
    this.thRefs["checkbox"] = React.createRef();
    this.thRefs["actions"] = React.createRef();
    props.columns.forEach((col) => {
      this.thRefs[col.label] = React.createRef();
    });
  }

  thRefs = {};

  static propTypes = {
    isPending: PropTypes.bool,
    hasCheckboxes: PropTypes.bool,
    columns: PropTypes.array,
    rows: PropTypes.array,
    totals: PropTypes.array,
    sort: PropTypes.shape({
      field: PropTypes.string,
      order: PropTypes.string,
    }),
    onSort: PropTypes.func,
    navigate: PropTypes.func,
    trigger: PropTypes.func,
    triggerAll: PropTypes.func,
    selected: PropTypes.array.isRequired,
    columnColors: PropTypes.array,
  };

  static defaultProps = {
    columns: [],
    rows: [],
    sort: {
      field: "",
      order: "asc",
    },
    selected: [],
  };

  resolveType(val) {
    if (val instanceof Date) {
      return humanDate(val);
    }
    return val;
  }

  componentDidMount() {
    this.headStickyUpdate();
    window.addEventListener("scroll", this.headStickyUpdate);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.headStickyUpdate);
  }

  headStickyUpdate() {
    if (this.tableRef) {
      const keys = Object.keys(this.thRefs);
      const firstTr = keys.find((label) => Boolean(this.thRefs[label].current));
      if (this.thRefs[firstTr].current) {
        const { top } = this.tableRef.current.getBoundingClientRect();
        const offset =
          top -
          (document.getElementById("table_filters")?.offsetHeight || 0) -
          70;
        keys.forEach((label) => {
          if (this.thRefs[label].current) {
            this.thRefs[label].current.style.transform = `translate3d(0, ${
              offset < 0 ? `${-offset}px` : `0px`
            }, 0)`;
          }
        });
      }
    }
  }

  isChangedValue(index) {
    const {
      sort: { field },
      rows,
      isPending,
    } = this.props;
    // console.log(field)
    if (
      !isPending &&
      field &&
      ["days", "date"].find((key) => field.includes(key))
    ) {
      return rows[index + 1] && rows[index + 1][field] !== rows[index][field];
    }
    return false;
  }

  render() {
    const {
      columns,
      rows,
      navigate,
      selected,
      selectedAll,
      sort,
      onSort,
      freeWidth,
      actions,
      totals,
      columnColors,
      hasCheckboxes,
    } = this.props;
    return (
      <div
        className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded kt-datatable--scroll"
        ref={this.tableRef}
      >
        <table className="kt-datatable__table " style={{ display: "table" }}>
          <thead className="kt-datatable__head">
            <tr className="kt-datatable__row">
              <th
                ref={this.thRefs["checkbox"]}
                className="kt-datatable__cell--center kt-datatable__cell kt-datatable__cell--check"
                style={{ width: "1%" }}
              >
                {hasCheckboxes && (
                  <span style={{ width: "20px" }}>
                    <label className="kt-checkbox kt-checkbox--single kt-checkbox--all kt-checkbox--solid">
                      <input
                        type="checkbox"
                        onChange={({ target: { checked } }) =>
                          this.props.triggerAll(checked)
                        }
                        checked={selectedAll}
                      />
                      &nbsp;
                      <span />
                    </label>
                  </span>
                )}
              </th>

              {columns.map(({ label, originField }) => (
                <th
                  className={clsx("kt-datatable__cell", {
                    "kt-datatable__cell--fullwidth": freeWidth,
                    "kt-datatable__cell--sort kt-datatable__cell--sorted":
                      sort.field === originField,
                  })}
                  ref={this.thRefs[label]}
                  onClick={onSort ? () => onSort(originField) : null}
                  key={originField}
                  style={{ backgroundColor: columnColors[originField] ?? "" }}
                >
                  <span>
                    {label}
                    {sort.field === originField && (
                      <i
                        className={`flaticon2-arrow-${
                          sort.order === "desc" ? "up" : "down"
                        }`}
                      />
                    )}
                  </span>
                </th>
              ))}
              {actions && (
                <th
                  className="kt-datatable__cell"
                  ref={this.thRefs["actions"]}
                  width="1%"
                />
              )}
            </tr>
          </thead>
          <tbody className="kt-datatable__body ps">
            {rows.map((row, index) => (
              <tr
                key={row.id}
                onDoubleClick={() => navigate && navigate(row)}
                className={clsx("kt-datatable__row", {
                  "kt-datatable__row--even": index % 2 === 0,
                  "kt-datatable__row--active": selected.includes(row.id),
                  "kt-datatable__row--separated": this.isChangedValue(index),
                })}
              >
                <td
                  className="kt-datatable__cell--center --sm kt-datatable__cell kt-datatable__cell--check"
                  style={{ width: "20px" }}
                >
                  {hasCheckboxes && (
                    <span style={{ width: "20px" }}>
                      <label className="kt-checkbox kt-checkbox--single kt-checkbox--all kt-checkbox--solid">
                        <input
                          checked={selected.includes(row.id)}
                          onChange={({ target }) =>
                            this.props.trigger(row.id, target.checked)
                          }
                          type="checkbox"
                        />
                        &nbsp;
                        <span />
                      </label>
                    </span>
                  )}
                </td>

                {columns.map((col) => (
                  <td
                    key={col.originField}
                    style={{
                      backgroundColor: columnColors[col.originField] ?? "",
                    }}
                    className={clsx("kt-datatable__cell --sm", {
                      "kt-datatable__cell--fullwidth": freeWidth,
                    })}
                    title={
                      typeof row[col.name] === "string"
                        ? row[col.name]
                        : undefined
                    }
                  >
                    {this.resolveType(row[col.name])}
                  </td>
                ))}
                {actions && (
                  <td className="kt-datatable__cell--center kt-datatable__cell --sm">
                    <span
                      style={{
                        overflow: "visible",
                        position: "relative",
                        width: "110px",
                      }}
                    >
                      {actions(row)}
                    </span>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
          {totals.find(Boolean) && (
            <tfoot className="kt-datatable__foot">
              <tr className="kt-datatable__row kt-font-boldest">
                <td className="kt-datatable__cell kt-datatable__cell--right">
                  итог
                </td>
                {columns.map((col, i) => (
                  <td className="kt-datatable__cell" key={col.originField}>
                    {totals[i]}
                  </td>
                ))}
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    );
  }
}
