import deepFind from "deep-find";

export const find = (obj, key) => {
  try {
    return deepFind(obj, key);
  } catch (err) {
    return "";
  }
};

export const replaceNullToString = (obj = {}) =>
  Object.keys(obj).reduce(
    (result, key) => ({
      ...result,
      [key]: obj[key] ?? "",
    }),
    {},
  );

export const replaceStringToNull = (obj = {}) =>
  Object.keys(obj).reduce(
    (result, key) => ({
      ...result,
      [key]:
        obj[key] === ""
          ? null
          : obj[key] instanceof String
            ? obj[key].trim()
            : obj[key],
    }),
    {},
  );

export const isEmpty = (obj) => !obj || Object.keys(obj).length === 0;

export const isArraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};
