import React, { useState, useEffect, useMemo } from "react";
import EntityList from "../../../components/entity/EntityList";
import { driversEntity } from "../../../store/ducks/entity.duck";
import DriverForm from "./DriverForm";
import DriverCard from "./DriverCard";
import WordEditor from "../../../partials/widgets/WordEditor/WordEditor";
import { LinearProgress } from "@material-ui/core";
import { getDocument } from "../../../service/contract";
import { useSelector } from "react-redux";
import { humanDate } from "../../../helper/date";

export function DriversList() {
  return (
    <EntityList
      actions={driversEntity.actions}
      select={(state) => state.drivers}
      clickLink="/drivers/:id"
      name="drivers"
    />
  );
}

export function DriverCreate() {
  return (
    <DriverForm
      actions={driversEntity.actions}
      actionTypes={driversEntity.types}
      select={(state) => state.drivers}
    />
  );
}

export function DriverEdit(props) {
  return (
    <DriverForm
      id={props.match.params.id}
      actions={driversEntity.actions}
      actionTypes={driversEntity.types}
      select={(state) => state.drivers}
    />
  );
}

export function DriverShow(props) {
  return <DriverCard id={props.match.params.id} />;
}

export function DriverContract(props) {
  const { contract_id } = props.match.params;
  // const user_role = useSelector(state => state.auth.user.role_code)
  const contract = useSelector((state) =>
    state.contracts.rows.find((c) => c.id === contract_id),
  );
  const [documentId, setDocumentId] = useState(null);
  const [isPending, setPending] = useState(true);

  useEffect(() => {
    getDocument(contract_id).then(({ data }) => {
      setDocumentId(data.document.id);
      setPending(false);
    });
  }, [contract_id]);

  const title = useMemo(() => {
    if (contract) {
      const date = humanDate(contract.date_start);
      return `Договор ${contract.number} от ${date}`;
    }
    return "Договор";
  }, [contract]);

  return isPending ? (
    <LinearProgress />
  ) : document === null ? (
    "404"
  ) : (
    <WordEditor
      title={title}
      // isReadOnly={user_role !== 'chief'}
      documentId={documentId}
    />
  );
}
