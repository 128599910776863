import React, { Component } from "react";
import PropTypes from "prop-types";
import { DropdownButton, Dropdown, Button } from "react-bootstrap";
import clsx from "clsx";

function* range(start, end) {
  for (let i = start; i <= end; i++) {
    yield i;
  }
}

export default class EntityListPager extends Component {
  static propTypes = {
    onPageChange: PropTypes.func.isRequired,
    onPerPageChange: PropTypes.func.isRequired,
    availablePerPage: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    rowsLength: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    isPending: PropTypes.bool,
    onRefresh: PropTypes.func,
  };

  eq(page, eq) {
    return this.props.isPending || page === eq;
  }

  render() {
    const {
      currentPage,
      rowsLength,
      onPerPageChange,
      onPageChange,
      rowsPerPage,
      availablePerPage,
      isPending,
    } = this.props;
    const curLimit = currentPage * rowsPerPage;
    const pages = Math.ceil(rowsLength / rowsPerPage);
    return (
      <div className="kt-datatable__pager kt-datatable--paging-loaded">
        <ul className="kt-datatable__pager-nav">
          <li>
            <Button
              title="Первая"
              variant="outline-default"
              className={clsx(
                "kt-datatable__pager-link kt-datatable__pager-link--first",
                {
                  "kt-datatable__pager-link--disabled": this.eq(currentPage, 1),
                },
              )}
              onClick={this.eq(currentPage, 1) ? null : () => onPageChange(1)}
              disabled={this.eq(currentPage, 1)}
            >
              <i className="flaticon2-fast-back pr-0" />
            </Button>
          </li>
          <li>
            <Button
              title="Предыдущая"
              variant="outline-default"
              className={clsx(
                "kt-datatable__pager-link kt-datatable__pager-link--prev",
                {
                  "kt-datatable__pager-link--disabled": this.eq(currentPage, 1),
                },
              )}
              onClick={
                this.eq(currentPage, 1)
                  ? null
                  : () => onPageChange(currentPage - 1)
              }
              disabled={this.eq(currentPage, 1)}
            >
              <i className="flaticon2-back pr-0" />
            </Button>
          </li>
          {[...range(currentPage - 3, currentPage + 1)].map((pageNum) => {
            if (pageNum < 0 || pageNum >= pages) {
              if (pages > 3)
                return (
                  <li key={pageNum}>
                    <Button
                      variant="outline-default"
                      disabled
                      className="kt-datatable__pager-link kt-datatable__pager-link-number kt-datatable__pager-link--disabled"
                    />
                  </li>
                );
              return null; //"Ошибка пагинации"
            } else {
              return (
                <li key={pageNum}>
                  <Button
                    variant="outline-default"
                    className={clsx(
                      "kt-datatable__pager-link kt-datatable__pager-link-number",
                      {
                        "kt-datatable__pager-link--active":
                          currentPage === pageNum + 1,
                        "kt-datatable__pager-link--disabled": isPending,
                      },
                    )}
                    disabled={isPending}
                    onClick={isPending ? null : () => onPageChange(pageNum + 1)}
                    title={pageNum + 1}
                  >
                    {pageNum + 1}
                  </Button>
                </li>
              );
            }
          })}
          <li>
            <Button
              className={clsx(
                "kt-datatable__pager-link kt-datatable__pager-link--last",
                {
                  "kt-datatable__pager-link--disabled": this.eq(
                    currentPage,
                    pages,
                  ),
                },
              )}
              variant="outline-default"
              onClick={
                this.eq(currentPage, pages)
                  ? null
                  : () => onPageChange(currentPage + 1)
              }
              disabled={this.eq(currentPage, pages)}
              title="Следующая"
            >
              <i className="flaticon2-next pr-0" />
            </Button>
          </li>
          <li>
            <Button
              variant="outline-default"
              className={clsx(
                "kt-datatable__pager-link kt-datatable__pager-link--last",
                {
                  "kt-datatable__pager-link--disabled": this.eq(
                    currentPage,
                    pages,
                  ),
                },
              )}
              onClick={
                this.eq(currentPage, pages) ? null : () => onPageChange(pages)
              }
              disabled={this.eq(currentPage, pages)}
              title="Последняя"
            >
              <i className="flaticon2-fast-next pr-0" />
            </Button>
          </li>
        </ul>
        <div className="kt-datatable__pager-info">
          {this.props.onRefresh && (
            <Button
              className="btn btn-success btn-bold btn-icon-h mr-5"
              onClick={this.props.onRefresh}
              disabled={isPending}
            >
              <i className="flaticon-refresh" />
              &nbsp;обновить
            </Button>
          )}
          <span className="kt-datatable__pager-detail">Выводить по &nbsp;</span>
          <div className="dropdown bootstrap-select kt-datatable__pager-size">
            <DropdownButton
              disabled={isPending}
              title={rowsPerPage}
              className="kt-datatable__pager-size"
              onSelect={
                isPending ? null : (val) => onPerPageChange(parseInt(val))
              }
            >
              {availablePerPage.map((pageCnt) => (
                <Dropdown.Item key={pageCnt} eventKey={pageCnt}>
                  {pageCnt}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          <span className="kt-datatable__pager-detail">
            Показано&nbsp;
            {(currentPage - 1) * rowsPerPage + 1} -{" "}
            {curLimit > rowsLength ? rowsLength : curLimit}
            &nbsp;из {rowsLength}шт
          </span>
        </div>
      </div>
    );
  }
}
