import React from "react";
import EntityForm from "../../../components/entity/EntityForm";
import EntityList from "../../../components/entity/EntityList";

import { documentTemplateEntity } from "../../../store/ducks/entity.duck";

const schema = {
  title: { label: "Название" },
  side: {
    label: "Наша сторона",
    type: "radio",
    options: ["Клиент", "Исполнитель"],
  },
  usage_type: {
    label: "Используется в формировании",
    type: "radio",
    options: ["Заявок", "Договоров заказчику", "Договоров перевозчику"],
  },
  sfdt: {
    label: "Контент",
    type: "word",
  },
};

export function DocumentTemplateList() {
  return (
    <EntityList
      actions={documentTemplateEntity.actions}
      actionTypes={documentTemplateEntity.types}
      select={(state) => state.documentTemplates}
      name="document_template"
    />
  );
}

export function DocumentTemplateEdit(props) {
  return (
    <EntityForm
      name="document_template"
      id={props.match.params.id}
      schema={schema}
      actions={documentTemplateEntity.actions}
      actionTypes={documentTemplateEntity.types}
      select={(state) => state.documentTemplates}
    />
  );
}

export function DocumentTemplateCreate() {
  return (
    <EntityForm
      name="document_template"
      schema={schema}
      actions={documentTemplateEntity.actions}
      actionTypes={documentTemplateEntity.types}
      select={(state) => state.documentTemplates}
    />
  );
}
