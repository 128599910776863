import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Alert,
  Button,
  Modal,
  Row,
  Col,
  Form,
  InputGroup,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "../../helper/object";
import { LinearProgress } from "@material-ui/core";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../partials/content/Portlet";
import EntityListTable from "./EntityListTable";
import EntityListPager from "./EntityListPager";
import "./list.scss";
import { ReactSortable } from "react-sortablejs";
import ConfirmButton from "../../partials/content/ConfirmButton";
import { exportToXLSX } from "../../helper/export";
import { defaults as settingsDefaults } from "../../store/reducer_entity/settings";
import { priceFormatter } from "../../helper/number";
import ColorPicker from "./ColorPicker";
import { Checkbox } from "react-bootstrap";
import { Label } from "react-bootstrap";

const mapStateToProps = (state, { select }) => {
  const { isPending, rows, settings, errors, countRows, totals } =
    select(state);
  return {
    isPending,
    settings,
    rows,
    countRows,
    totals,
    errors,
    message: {
      show: !isEmpty(errors),
      title: "Ошибка",
      description: errors.message ?? Object.keys(errors).join(""),
    },
    user: state.auth.user,
  };
};

const mapDisptchToProps = (dispatch, { actions, clickLink, name }) => ({
  list: (settings) => dispatch(actions.list(settings)),
  remove: (id) => dispatch(actions.remove(id)),
  getLink: (id) =>
    clickLink ? clickLink.replace(":id", id) : `/${name}/edit/${id}`,
});

class EntityList extends Component {
  constructor(props, context) {
    super(props, context);
    this.saveHook = this.saveHook.bind(this);

    this.onSort = this.onSort.bind(this);
    this.onFilter = this.onFilter.bind(this);
    // this.onDelete = this.onDelete.bind(this);
    // this.onChangeStatus = this.onChangeStatus.bind(this);

    this.trigger = this.trigger.bind(this);
    this.triggerSelected = this.triggerSelected.bind(this);

    this.handleShowColumns = this.handleShowColumns.bind(this);
    this.handleCloseColumns = this.handleCloseColumns.bind(this);

    this.actionCompleted = this.actionCompleted.bind(this);
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    clickLink: PropTypes.string,
    history: PropTypes.shape(),
    list: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    select: PropTypes.func.isRequired,
    filterFn: PropTypes.func,
    filterComponent: PropTypes.func,
    isPending: PropTypes.bool,
    actionsComponent: PropTypes.func,
    multipleActionsComponent: PropTypes.func,
    freeWidth: PropTypes.bool,
    message: PropTypes.shape({
      show: PropTypes.bool,
      title: PropTypes.string,
      description: PropTypes.string,
    }),
    settings: PropTypes.shape({
      sort: PropTypes.shape({
        field: PropTypes.string,
        order: PropTypes.string,
      }),
      rowsPerPage: PropTypes.number,
      currentPage: PropTypes.number,
      filter: PropTypes.shape(),
    }),
    rows: PropTypes.array,
  };

  static defaultProps = {
    filterFn: (rows) => rows,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.settings !== this.props.settings) {
      const {
        // columnConf,
        settings,
      } = nextProps;
      this.setState({
        columnConf: settings.columnConf,
        columnColors: settings.columnColors,
        sort: settings.sort,
        rowsPerPage: settings.rowsPerPage,
        currentPage: settings.currentPage,
        filter: settings.filter,
        // textFilter: settings.textFilter
      });
    }
  }

  componentDidMount() {
    this.props.list();
  }

  state = {
    selected: [],
    selectedAll: false,
    editColumns: false,
    rowsPerPage: this.props.settings.rowsPerPage,
    currentPage: this.props.settings.currentPage,
    sort: this.props.settings.sort,
    columnConf: this.props.settings.columnConf,
    columnColors: this.props.settings.columnColors,
    filter: this.props.settings.filter,
    textFilter: "",
  };

  handleCloseColumns() {
    this.setState({ editColumns: false });
    this.saveHook();
  }

  handleShowColumns() {
    this.setState({ editColumns: true });
  }

  getLink(row) {
    const { clickLink, name } = this.props;
    return clickLink
      ? clickLink.replace(":id", row.id)
      : `/${name}/edit/${row.id}`;
  }

  triggerSelected(predict) {
    if (predict) {
      const rows = this.props.isServerFilter
        ? this.props.rows
        : this.filterRowsGlobal(
            this.props.filterFn(this.props.rows, this.state.filter),
          );
      this.setState({
        selected: rows.map(({ id }) => id),
        selectedAll: true,
      });
    } else {
      this.setState({
        selected: [],
        selectedAll: false,
      });
    }
  }

  trigger(id) {
    const { rows } = this.props;
    const { selected: active } = this.state;
    if (active.includes(id)) {
      this.setState({
        selected: active.filter((rowId) => rowId !== id),
        selectedAll: false,
      });
    } else {
      const selected = [...active, id];
      this.setState({
        selected,
        selectedAll: !rows.find((row) => !selected.includes(row.id)),
      });
    }
  }

  // saveThrottle = null

  get settings() {
    const {
      rowsPerPage,
      currentPage,
      sort,
      columnConf,
      columnColors,
      filter,
      textFilter,
    } = this.state;
    return {
      sort,
      columnConf,
      columnColors,
      rowsPerPage,
      currentPage,
      filter,
      textFilter,
    };
  }

  saveHook() {
    this.props.list(this.settings);
  }

  onSort(field) {
    if (this.state.sort.field === field) {
      if (this.state.sort.order === "asc") {
        this.setState({ sort: { field: null } }, this.saveHook);
      } else {
        this.setState({ sort: { field, order: "asc" } }, this.saveHook);
      }
    } else {
      this.setState({ sort: { field, order: "desc" } }, this.saveHook);
    }
  }

  actionCompleted(shouldDropSelection) {
    if (shouldDropSelection) {
      this.setState({
        selected: [],
      });
    }
    this.props.list();
  }

  onFilter(filter = this.state.filter, isDrop = false) {
    /// we should cancel all page settings when use filters.
    // const shouldBeSaved = filter !== this.state.filter
    this.triggerSelected(false); // drop selection
    this.setState(
      {
        currentPage: settingsDefaults.currentPage,
        filter,
        textFilter: isDrop ? "" : this.state.textFilter,
      },
      this.saveHook,
    );
  }

  paginateRows(rows) {
    const { currentPage, rowsPerPage } = this.state;
    return rows.slice(
      (currentPage - 1) * rowsPerPage, // start
      currentPage * rowsPerPage, // end
    );
  }

  textFilter(value) {
    this.triggerSelected(false); // drop selection
    this.setState(
      {
        currentPage: settingsDefaults.currentPage,
        textFilter: value,
      },
      () => {
        if (!this.props.isServerFilter) return;
        if (this.throttler) clearTimeout(this.throttler);
        this.throttler = setTimeout(() => {
          this.throttler = null;
          this.saveHook();
        }, 500);
      },
    );
  }

  renderSearchField() {
    return (
      <>
        <Form.Label>Поиск по всем полям</Form.Label>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              <i className="la la-search" />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            onChange={({ target }) => this.textFilter(target.value)}
            value={this.state.textFilter ?? ""}
          />
        </InputGroup>
      </>
    );
  }

  getTotal(key, rows) {
    if (this.props.isServerFilter) {
      return this.props.totals[key]
        ? priceFormatter.format(this.props.totals[key])
        : "";
    }
    if (!rows || !rows.length || !rows[0].hasOwnProperty(`${key}_raw`)) {
      return "";
    }
    const total = rows.reduce((sum, row) => {
      return sum + parseInt(row[`${key}_raw`], 10);
    }, 0);
    if (isNaN(total)) return "";
    return priceFormatter.format(total);
  }

  filterRowsGlobal(rows) {
    const { textFilter } = this.state;
    return rows.filter(
      (row) =>
        !textFilter ||
        Object.keys(row).find((key) => {
          return (
            typeof row[key] === "string" &&
            new RegExp(`^.*${textFilter}.*$`, "gi").test(row[key])
          );
        }),
    );
  }

  export() {
    this.props.actions.export();
  }

  renderActions(row) {
    if (this.props.actionsComponent === null) {
      return <></>;
    }
    if (typeof this.props.actionsComponent === "function") {
      return (
        <this.props.actionsComponent
          row={row}
          onUpdate={this.actionCompleted}
          {...this.props}
        />
      );
    }
    return (
      <ConfirmButton
        isClear
        size="sm"
        action={() => this.props.remove(row.id)}
        variant="clean"
      >
        <i className="la la-trash kt-font-danger" />
      </ConfirmButton>
    );
  }

  changeColor(colName, color) {
    this.setState({
      columnColors: {
        ...this.state.columnColors,
        [colName]: color === "#ffffff" ? "" : color,
      },
    });
  }

  render() {
    const {
      rows,
      settings: {
        columnConf: { showed: columns },
      },
      isPending,
      message,
      history,
      pager,
    } = this.props;
    const {
      // search,
      selected,
      selectedAll,
      currentPage,
      rowsPerPage,
      sort,
      editColumns,
      columnConf,
      columnColors,
    } = this.state;
    const _columns = columns
      .map((col) => ({
        ...col,
        originField: col.name,
        name: col.name.split(".").pop(),
      }))
      .filter(({ field }) => field !== "id");
    // TODO: refactor to backend filter & paginate all entities
    // For now isServerFilter prop is flag that we paginated & filtred on backed

    const filtredRows = this.props.isServerFilter
      ? rows
      : this.filterRowsGlobal(this.props.filterFn(rows, this.state.filter));
    const paginatedRows = this.props.isServerFilter
      ? rows
      : this.paginateRows(filtredRows);
    return (
      <Portlet>
        <PortletHeader
          title="Список записей"
          toolbar={
            <PortletHeaderToolbar>
              {this.props.multipleActionsComponent &&
                this.props.multipleActionsComponent({
                  onComplete: (shouldDropSelection) =>
                    this.actionCompleted(shouldDropSelection),
                  selected: selected
                    .map((id) => filtredRows.find((row) => row.id === id))
                    .filter((el) => !!el),
                })}

              <Button
                disabled={isPending}
                variant="default"
                size="sm"
                // onClick={() => exportToXLSX(filtredRows, _columns)}
                onClick={() =>
                  this.props.isServerFilter
                    ? this.export()
                    : exportToXLSX(filtredRows, _columns)
                }
              >
                <i className="la la-file-text" />
                &nbsp;Экспорт в Excel
              </Button>
              <div style={{ display: "none" }} id="excelexport" />
              <Button
                disabled={isPending}
                variant="default"
                size="sm"
                className="btn-icon ml-1"
                onClick={this.handleShowColumns}
              >
                <i className="flaticon2-settings" />
              </Button>
            </PortletHeaderToolbar>
          }
        />
        {isPending ? (
          <PortletBody key="progress" className="kt-portlet-body--progress" fit>
            <LinearProgress />
          </PortletBody>
        ) : (
          <div style={{ height: 4 }} /> // placeholder
        )}
        {message.show && (
          <PortletBody>
            <Alert show={message.show} variant="danger">
              <div style={{ textAlign: "center", width: "100%" }}>
                <b>{message.description}</b>
              </div>
            </Alert>
          </PortletBody>
        )}
        {this.props.filterComponent && (
          <PortletBody sticky id="table_filters" key="filter">
            <this.props.filterComponent
              onFilter={this.onFilter}
              filter={this.state.filter}
              isPending={isPending}
            >
              {this.renderSearchField()}
            </this.props.filterComponent>
          </PortletBody>
        )}
        {!this.props.filterComponent && (
          <PortletBody sticky id="table_filters">
            <Row>
              <Col md="3">{this.renderSearchField()}</Col>
            </Row>
          </PortletBody>
        )}
        <PortletBody
          fit
          className="kt-datatable kt-datatable--default"
          key="table"
        >
          {!isPending &&
            isEmpty(this.props.errors) &&
            !paginatedRows.length && (
              <PortletBody>
                <Alert variant="secondary">
                  <div style={{ textAlign: "center" }}>
                    Не найдено ни одной записи
                  </div>
                </Alert>
              </PortletBody>
            )}
          {paginatedRows.length > 0 && (
            <EntityListTable
              isPending={isPending}
              freeWidth={this.props.freeWidth}
              navigate={(row) =>
                !this.props.disableClick && history.push(this.getLink(row))
              }
              hasCheckboxes={!!this.props.multipleActionsComponent}
              selected={selected}
              trigger={this.trigger}
              selectedAll={selectedAll}
              triggerAll={this.triggerSelected}
              sort={sort}
              onSort={this.onSort}
              columns={_columns}
              columnColors={columnColors}
              totals={columns.map(({ name }) =>
                this.getTotal(name, filtredRows),
              )}
              // this.props.user.role_code === 'chief'
              // ?
              // columns.map(({ name }) => this.getTotal(name, filtredRows))
              // : []
              // }
              actions={(row) => this.renderActions(row)}
              rows={paginatedRows}
              pager={pager}
            />
          )}
          <EntityListPager
            isPending={isPending}
            availablePerPage={[5, 10, 20, 30, 40, 50, 100]}
            onPageChange={(page) =>
              this.setState({ currentPage: page }, this.saveHook)
            }
            onPerPageChange={(perPage) =>
              this.setState(
                { rowsPerPage: perPage, currentPage: 1 },
                this.saveHook,
              )
            }
            rowsLength={
              this.props.isServerFilter
                ? this.props.countRows || 0
                : filtredRows.length
            }
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            onRefresh={() => this.props.list()}
          />
        </PortletBody>
        <Modal show={editColumns} onHide={this.handleCloseColumns}>
          <Modal.Header closeButton>
            <Modal.Title>Настройки отображения</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md="6">
                <h5>Скрыть</h5>
                <ReactSortable
                  style={{ height: 375, overflow: "auto" }}
                  sort={false}
                  group="columns"
                  list={columnConf.hidden}
                  setList={(hidden) =>
                    this.setState({
                      columnConf: { ...columnConf, hidden },
                    })
                  }
                >
                  {columnConf.hidden.map(({ label, name }) => (
                    <Button
                      active
                      variant="light"
                      className="text-left btn__row-edit"
                      block
                      key={name}
                    >
                      {label}
                    </Button>
                  ))}
                </ReactSortable>
              </Col>
              <Col md="6">
                <h5>Показывать</h5>
                <ReactSortable
                  style={{ height: 375, overflow: "auto" }}
                  group="columns"
                  list={columnConf.showed}
                  setList={(showed) =>
                    this.setState({
                      columnConf: { ...columnConf, showed },
                    })
                  }
                >
                  {columnConf.showed.map(({ label, name, hex }) => (
                    <Button
                      active
                      className="text-left btn__row-edit"
                      variant="light"
                      block
                      key={name}
                    >
                      {label}
                      <ColorPicker
                        color={columnColors[name]}
                        onChange={({ color }) => this.changeColor(name, color)}
                      />
                    </Button>
                  ))}
                </ReactSortable>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleCloseColumns}>
              Готово
            </Button>
          </Modal.Footer>
        </Modal>
      </Portlet>
    );
  }
}

export default withRouter(
  connect(null, mapDisptchToProps)(connect(mapStateToProps)(EntityList)),
);
