import { apiDate } from "../../helper/date";
import { parseISO } from "date-fns";
import contractReducers from "../reducer_entity/contract";
import { boolLabel } from "../../helper/label";
import requestContractReducer from "./request_contract";
import { reasonList } from "../../pages/home/request/constants";

const shipmentHumanReducer = (shipment = {}) => ({
  ...shipment,
  request_id: parseInt(shipment.request_id),
  date: shipment.date ? parseISO(shipment.date) : "",
});

const humanReducer = ({
  shipment_unload,
  shipment_load,
  driver_contract,
  customer_contract,
  invoice_credit_is_payed,
  invoice_debet_is_payed,
  contracts,
  is_car_return,
  driver_price_by_agreement,
  customer_price_by_agreement,
  customer_rewrite_deadline_days,
  driver_rewrite_deadline_days,
  status_id,
  delete_reason,
  delete_reason_description,
  date,
  ...rest
} = {}) => ({
  ...rest,
  date: date ? parseISO(date) : null,
  // date_load: date_load ? parseISO(date_load) : null,
  // date_unload: date_unload ? parseISO(date_unload) : null,
  shipment_unload: shipment_unload
    ? shipment_unload.map(shipmentHumanReducer)
    : [],
  shipment_load: shipment_load ? shipment_load.map(shipmentHumanReducer) : [],
  contracts: contracts ? contracts.map(requestContractReducer.from) : [],
  driver_contract: driver_contract && contractReducers.from(driver_contract),
  customer_contract:
    customer_contract && contractReducers.from(customer_contract),
  invoice_credit_is_payed: boolLabel(invoice_credit_is_payed, {
    true: "оплачен",
    false: "не оплачен",
    mid: "частично оплачен",
  }),
  invoice_debet_is_payed: boolLabel(invoice_debet_is_payed, {
    true: "оплачен",
    false: "не оплачен",
    mid: "частично оплачен",
  }),
  status_id: status_id ? parseInt(status_id, 10) : null,
  is_car_return: is_car_return === "1",
  customer_rewrite_deadline_days: customer_rewrite_deadline_days === "1",
  driver_rewrite_deadline_days: driver_rewrite_deadline_days === "1",
  customer_price_by_agreement: customer_price_by_agreement === "1",
  driver_price_by_agreement: driver_price_by_agreement === "1",
  delete_reason:
    delete_reason === "other"
      ? delete_reason_description
      : reasonList[delete_reason],
});

const shipmentApiReducer = (shipment = {}) => ({
  ...shipment,
  date: apiDate(shipment.date),
});

const apiReducer = ({
  shipment_unload,
  shipment_load,
  date,
  is_car_return,
  customer_rewrite_deadline_days,
  driver_rewrite_deadline_days,
  ...rest
} = {}) => ({
  ...rest,
  ...(customer_rewrite_deadline_days !== null
    ? {
        customer_rewrite_deadline_days: customer_rewrite_deadline_days
          ? "1"
          : "0",
      }
    : {}),
  ...(driver_rewrite_deadline_days !== null
    ? { driver_rewrite_deadline_days: driver_rewrite_deadline_days ? "1" : "0" }
    : {}),
  ...(is_car_return !== null
    ? { is_car_return: is_car_return ? "1" : "0" }
    : {}),
  ...(date ? { date: apiDate(date) } : {}),
  ...(shipment_unload
    ? { shipment_unload: shipment_unload.map(shipmentApiReducer) }
    : {}),
  ...(shipment_load
    ? { shipment_load: shipment_load.map(shipmentApiReducer) }
    : {}),
});

export default { from: humanReducer, to: apiReducer };
