import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Modal, Form, Col, Row, Alert } from "react-bootstrap";
import {
  Portlet,
  PortletHeader,
  PortletBody,
  PortletHeaderToolbar,
} from "../../../../partials/content/Portlet";
import clsx from "clsx";
import ReactDatePicker from "react-datepicker";
import * as requestService from "../../../../service/request";
import {
  documentTemplateEntity,
  requestContractEntity,
} from "../../../../store/ducks/entity.duck";
import SelectEntity from "../../../../components/entity/SelectEntity";
import { ContractPreview } from "./ContractPreview";
import { withRouter } from "react-router-dom";
import RequestDocPreview from "./RequestDocPreview";

const mapStateToProps = ({ requests: { contract } }, props) => ({
  errors: contract.errors,
  lastId: contract.isSuccess && contract.lastId,
  isPending: contract.isPending,
  isSuccess: contract.isSuccess,
});

const mapDispatchToProps = {
  createContract: requestContractEntity.actions.create,
  deleteContract: requestContractEntity.actions.remove,
  clearErrors: requestContractEntity.actions.clearErrors,
};

class RequestDocuments extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static propTypes = {
    disabled: PropTypes.bool,
    number: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    contracts: PropTypes.array,
    requestId: PropTypes.string.isRequired,
    errors: PropTypes.shape(),
    filter: PropTypes.func,
    lastId: PropTypes.any,
    isPending: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
  };

  state = {
    showModal: false,
    isPending: false,
    isLoadingNumber: false,
    fields: {
      template_id: "",
      document_id: "",
      date: this.props.date,
      number: this.props.number,
      request_id: this.props.requestId,
    },
    errors: {},
  };

  // async generateNumber() {
  //   this.setState({ isLoadingNumber: true });
  //   const { data } = await requestService.getContractNumber();
  //   this.set("number", data.number);
  //   this.setState({ isLoadingNumber: false });
  // }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  async handleSubmit(ev) {
    ev.preventDefault();
    // if (!this.state.fields.number) {
    //   await this.generateNumber();
    // }
    await this.props.createContract(this.state.fields);
    if (this.props.isSuccess && this.props.lastId) {
      const contractId = this.props.lastId;
      try {
        await requestService.generateDocument(contractId);
        this.props.history.push(
          `/requests/progress/${this.props.requestId}/contract/${contractId}`,
        );
      } catch (err) {
        // delete contract if document creation fails
        await this.props.deleteContract(contractId);
        // const _err = getError(err)
        // console.log(_err)
      }
    }
  }

  set(key, val) {
    this.setState({
      fields: {
        ...this.state.fields,
        [key]: val,
      },
    });
  }

  render() {
    const {
      isPending,
      errors,
      contracts,
      requestId,
      disabled,
      title,
      variant,
    } = this.props;
    const { fields, isLoadingNumber } = this.state;

    return (
      <Portlet fluidHeight>
        <PortletHeader
          variant={variant}
          title={`${title || "Документы"}`}
          toolbar={
            !disabled && (
              <PortletHeaderToolbar>
                <Button size="sm" variant="light" onClick={this.handleShow}>
                  Новый документ
                </Button>
              </PortletHeaderToolbar>
            )
          }
        />
        <PortletBody
          fluid
          wrap
          style={{ alignItems: "flex-start", alignContent: "flex-start" }}
        >
          {!contracts.length && (
            <Alert variant="light">У заявки еще нет ни одного документа</Alert>
          )}
          {contracts.map((contract) =>
            contract.fromRequest ? (
              <RequestDocPreview
                disabled={disabled}
                contract={contract}
                requestId={requestId}
                key={`req${contract.id}`}
              />
            ) : (
              <ContractPreview
                disabled={disabled}
                key={`con${contract.id}`}
                title={contract.title}
                contract={contract}
              />
            ),
          )}
        </PortletBody>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Form noValidate onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Новый документ заявки</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row}>
                <Col md="12">
                  <Form.Label>Шаблон</Form.Label>
                  <SelectEntity
                    search={async (val) =>
                      this.props.filter(
                        await documentTemplateEntity.actions.search({
                          title: val,
                          usage_type: "Заявок",
                        }),
                      )
                    }
                    disabled={fields.document_id > 0}
                    noOptionsMessage="Шаблонов не найдено"
                    errorText={errors["template_id"]}
                    value={fields["template_id"]}
                    isInvalid={errors.hasOwnProperty("template_id")}
                    getOptionLabel={(props) => props.title}
                    getOptionValue={({ id }) => id}
                    onChange={(val) => this.set("template_id", val.id)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col md="12">
                  <Form.Label>Дата</Form.Label>
                  <ReactDatePicker
                    // isClearable
                    disabled={fields.document_id > 0}
                    className={clsx("form-control block", {
                      "is-invalid": errors.hasOwnProperty("date"),
                    })}
                    locale="ru"
                    selected={fields["date"]}
                    dateFormat={"dd.MM.yyyy"}
                    onChange={(date) => this.set("date", date)}
                  />
                  <Form.Control
                    hidden
                    isInvalid={errors.hasOwnProperty("date")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["date"]}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                disabled={isPending || isLoadingNumber}
                onClick={this.handleClose}
              >
                отменить
              </Button>
              <Button
                variant="primary"
                disabled={isPending || isLoadingNumber}
                onClick={this.handleSubmit}
                className={clsx({
                  "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                    isPending,
                })}
              >
                Сгенерировать
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </Portlet>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RequestDocuments),
);
