import React, { Component } from "react";
import { humanDate } from "../../../../helper/date";
import { Button } from "react-bootstrap";

class Timeline extends Component {
  render() {
    return (
      <div className="kt-timeline-v1 kt-timeline-v1--justified">
        <div className="kt-timeline-v1__items">{this.props.children}</div>
      </div>
    );
  }
}

class TimelineItem extends Component {
  render() {
    return (
      <>
        <div className="kt-timeline-v1__marker" />
        <div className="kt-timeline-v1__item">
          <div className="kt-timeline-v1__item-circle">
            <div className={`kt-bg-${this.props.variant}`} />
          </div>
          <span className="kt-timeline-v1__item-time kt-font-brand">
            {humanDate(this.props.date)} {this.props.time}
          </span>
          <div className="kt-timeline-v1__item-content">
            <div className="kt-timeline-v1__item-title">
              <span className={`kt-font-${this.props.variant}`}>
                {this.props.title}
              </span>
              <br />
              {this.props.subtitle}
            </div>
            <div className="kt-timeline-v1__item-body">
              {this.props.children}
            </div>
            <div className="kt-timeline-v1__item-actions">
              {this.props.action && (
                <Button
                  onClick={(ev) => this.props.action(ev)}
                  variant="outline-success"
                  size="sm"
                >
                  Редактировать
                </Button>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

Timeline.Item = TimelineItem;
export default Timeline;
