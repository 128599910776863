import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { priceFormatter } from "../../../helper/number";
import ReactDatePicker from "react-datepicker";
import { Radio, FormControlLabel, RadioGroup } from "@material-ui/core";
import { requestProgressEntity } from "../../../store/ducks/entity.duck";

const getInitialFields = () => ({
  key: `new${parseInt(Math.random() * 1000)}`,
  number: "",
  date: "",
});

export const InvoiceListActionPayOrder = ({
  show,
  onHide,
  errors: errorProp,
  isPending,
  onSubmit,
  selected,
}) => {
  const [isDivided, setDivided] = useState(false);
  const [bulk, setBulk] = useState([getInitialFields()]);
  const [errors, setErrors] = useState([]);
  const [payedRequests, setPayedRequests] = useState([]);
  const [requestSum, setRequestSum] = useState([]);

  useEffect(() => setErrors(errorProp || []), [errorProp]);

  const forbiddenRequests = useMemo(() => {
    return [].concat(...selected.map((item) => item.payed_requests));
  }, [selected]);

  useEffect(() => {
    if (show) {
      // сброс стейта на инициализационный
      setBulk([getInitialFields()]);
      setDivided(!!selected.find((invoice) => invoice.is_divided));
      setPayedRequests(
        [].concat(...selected.map((item) => item.requests_numbers)),
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [show]);

  useEffect(() => {
    if (show && !isDivided) {
      setPayedRequests(
        [].concat(...selected.map((item) => item.requests_numbers)),
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [show, isDivided]);

  const hasMoreThanOneRequest = useMemo(() => {
    return !!selected.find((item) => item.requests_numbers.length > 1);
  }, [selected]);

  useEffect(() => {
    if (!selected.length || !show) return;
    const list = selected.reduce((acc, item) => {
      item.requests_numbers.forEach((number) => {
        acc[number] = item.type;
      });
      return acc;
    }, {});

    requestProgressEntity.actions
      .search({ number: Object.keys(list) }, { number: "strict" })
      .then(({ data }) => {
        setRequestSum(
          data.reduce((acc, req) => {
            const entity = list[req.number] === "debet" ? "customer" : "driver";
            const price =
              +req[`cost_${entity}`] +
              +req[`forwarding_${entity}`] +
              +req[`park_quantity_${entity}`];

            return { ...acc, [req.number]: price };
          }, {}),
        );
      });
  }, [selected, show]);

  const onChange = ({ name, value }, key) => {
    setErrors((oldErrors) => {
      if (!Array.isArray(errors)) return [];
      const newErrors = [...errors];
      delete newErrors[name];
      return newErrors;
    });
    setBulk((old) =>
      bulk.map((fields) => {
        if (fields.key !== key) return fields;
        return { ...fields, [name]: value };
      }),
    );
  };

  const triggerPayed = (requestId) => {
    setPayedRequests((old) => {
      const payed = old.find((id) => id === requestId);
      if (payed) return old.filter((id) => id !== requestId);
      return [...old, requestId];
    });
  };

  const onRemove = (rmKey) => {
    setBulk((old) => old.filter(({ key }) => key !== rmKey));
  };

  const totalAmount = useMemo(() => {
    const sum = payedRequests.reduce((result, id) => {
      if (forbiddenRequests.includes(id)) return result;
      return result + requestSum[id];
    }, 0);
    return sum ? priceFormatter.format(sum) : "...";
  }, [payedRequests, requestSum, forbiddenRequests]);

  const handleSubmit = (ev) => {
    if (ev) ev.preventDefault();
    onSubmit({
      bulk,
      isDivided,
      payedRequests: payedRequests.filter(
        (req) => !forbiddenRequests.includes(req),
      ),
    });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Данные оплаты и платежных поручений</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="kt-widget1">
            {selected.map((row, i) => (
              <>
                <div className="kt-widget1__item" key={row.id}>
                  <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title">
                      Счёт №{row.invoice_number}&nbsp;
                      {!isDivided && row.pay_order_ids && (
                        <span className="kt-font-warning">
                          <i className="la la-warning kt-font-xl" />
                          &nbsp;найден другой п/п
                        </span>
                      )}
                    </h3>
                  </div>
                  <span className="kt-widget1__number">
                    {row.amount_formatted}
                  </span>
                </div>
                {isDivided &&
                  row.requests_numbers?.map((requestId) => (
                    <div
                      key={requestId}
                      className={clsx(
                        "kt-widget1__item invoice-action__request-checkbox",
                      )}
                    >
                      <div className="kt-widget1__info">
                        <label
                          className={clsx(
                            "kt-checkbox kt-checkbox--tick kt-checkbox--brand invoice-action__request-checkbox-label",
                            {
                              "invoice-action__request-price-del":
                                !payedRequests.includes(requestId),
                              "kt-opacity-6":
                                forbiddenRequests.includes(requestId),
                            },
                          )}
                        >
                          <input
                            type="checkbox"
                            checked={
                              !forbiddenRequests.includes(requestId) &&
                              payedRequests.includes(requestId)
                            }
                            disabled={forbiddenRequests.includes(requestId)}
                            onChange={() => triggerPayed(requestId)}
                          />
                          заявка <b>{requestId}</b>{" "}
                          {forbiddenRequests.includes(requestId) && (
                            <b className="kt-font-warning">
                              <i className="la la-warning" />
                              &nbsp;найден другой п/п
                            </b>
                          )}
                          <span />
                        </label>
                      </div>
                      <span
                        className={clsx(
                          "kt-widget1__number invoice-action__request-price",
                          {
                            "invoice-action__request-price-del":
                              !payedRequests.includes(requestId),
                          },
                        )}
                      >
                        {requestSum[requestId] ? (
                          priceFormatter.format(requestSum[requestId])
                        ) : (
                          <Spinner animation="border" size="sm" role="status" />
                        )}
                      </span>
                    </div>
                  ))}
              </>
            ))}
            <div className="kt-widget1__item">
              <div className="kt-widget1__info">
                <h3 className="kt-widget1__title">
                  <b>
                    итого
                    {forbiddenRequests.length > 0 && (
                      <> без учёта оплаченных ранее заявок</>
                    )}
                  </b>
                </h3>
              </div>
              <span className="kt-widget1__number kt-font-success">
                {totalAmount}
              </span>
            </div>
          </div>
          {hasMoreThanOneRequest && (
            <>
              <div>
                <h5 className="mb-3">Оплата</h5>
                <RadioGroup
                  value={isDivided}
                  onChange={(ev) => setDivided(ev.target.value === "1")}
                >
                  <FormControlLabel
                    value="0"
                    checked={!isDivided}
                    control={<Radio />}
                    label="Полностью оплачено"
                  />
                  <FormControlLabel
                    value="1"
                    checked={isDivided}
                    control={<Radio />}
                    label="Частично оплачено"
                  />
                </RadioGroup>
              </div>
              <hr />
            </>
          )}
          <h5 className="mb-3">Платёжные поручения</h5>
          {bulk.map((fields, i) => (
            <Form.Group as={Row} key={fields.key}>
              <Col md="6">
                <Form.Label>Номер</Form.Label>
                <Form.Control
                  name="number"
                  isInvalid={errors?.[i] && errors[i].hasOwnProperty("number")}
                  onChange={(ev) => onChange(ev.target, fields.key)}
                  value={fields["number"]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.[i] && errors[i]["number"]}
                </Form.Control.Feedback>
              </Col>
              <Col md="5">
                <Form.Label>Дата</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <i className="la la-calendar" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <ReactDatePicker
                    isClearable
                    placeholderText="дата"
                    selected={fields["date"]}
                    className={clsx("form-control", {
                      "is-invalid": errors[i]?.hasOwnProperty("date"),
                    })}
                    dateFormat="dd.MM.yyyy"
                    onChange={(value) =>
                      onChange({ name: "date", value }, fields.key)
                    }
                  />
                  <Form.Control
                    hidden
                    isInvalid={errors[i] && errors[i]["date"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors[i] && errors[i]["date"]}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
              {bulk.length !== 1 && (
                <Col md="1" className="payorder-btn__delete">
                  <Button
                    className="btn-icon"
                    variant="danger"
                    onClick={() => onRemove(fields.key)}
                  >
                    <i className="fa fa-trash" />
                  </Button>
                </Col>
              )}
            </Form.Group>
          ))}
          <Button
            variant="outline-success"
            onClick={() => setBulk((old) => [...old, getInitialFields()])}
          >
            <i className="fa fa-plus" /> добавить строку
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            отменить
          </Button>
          <Button
            variant="primary"
            disabled={isPending}
            onClick={handleSubmit}
            className={clsx({
              "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                isPending,
            })}
          >
            Сохранить
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
