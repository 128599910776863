import React, { Component } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Button,
  Form,
  Modal,
  Col,
  Row,
  InputGroup,
  Alert,
  Spinner,
} from "react-bootstrap";
import SelectEntity from "../../components/entity/SelectEntity";
import {
  invoiceEntity,
  requestProgressEntity,
} from "../../store/ducks/entity.duck";
import { connect } from "react-redux";
import ReactDatePicker from "react-datepicker";
import staticStore from "../../store/staticStore";
import { priceFormatter } from "../../helper/number";
import { parseISO } from "date-fns";

const mapStateToProps = (
  { invoices: { isSuccess, isPending, errors }, requests },
  props,
) => ({
  isPending: isPending || requests.progress.isPending,
  isSuccess,
  errors,
  types: staticStore.invoiceTypes,
});
const mapDispatchToProps = (dispatch, props) => ({
  searchRequests: () =>
    requestProgressEntity.actions.search(
      { id: props.requestIDs },
      { id: "strict" },
    ),
  createInvoice: (fields) => dispatch(invoiceEntity.actions.create(fields)),
  updateInvoice: (fields, id) =>
    dispatch(invoiceEntity.actions.update(fields, id)),
});

class InvoiceFormModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    invoiceId: PropTypes.string,
    requestIDs: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    onComplete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.changed = this.changed.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  get isCreate() {
    return !this.props.invoiceId;
  }

  componentDidMount() {
    this.setState(this.initialState);
  }

  async handleSubmit(ev) {
    ev.preventDefault();
    const fields = {
      ...this.state.fields,
      requests: this.state.requests.map(({ id }) => id),
    };
    const origin = this.getInvoiceById();
    if (this.props.invoiceId && origin && fields) {
      /// forbid to change type of created request
      fields.type = origin.type;
    }
    if (this.isCreate) {
      await this.props.createInvoice(fields);
    } else {
      await this.props.updateInvoice(fields, this.props.invoiceId);
    }
    if (this.props.isSuccess) {
      this.props.onComplete();
      this.props.onClose();
    }
  }

  initialFields = {
    type: "",
    amount: "",
    invoice_number: "",
    invoice_date: "",
    act_date: "",
    upd_number: "",
    upd_date: "",
    sf_number: "",
    sf_date: "",
    requests: this.props.requestIDs,
  };

  initialState = {
    id: null,
    isPendingRequests: false,
    errors: {},
    fields: { ...this.initialFields },
    requests: [],
    invoices: [],
  };

  state = this.initialState;

  async refetchInvoices(id) {
    const { data } = await invoiceEntity.actions.search(
      {
        "req2inv.request_id": this.props.requestIDs,
      },
      { "req2inv.request_id": "strict" },
    );

    const invoices = data.map((inv) => ({
      ...inv,

      invoice_date: inv.invoice_date
        ? parseISO(inv.invoice_date)
        : inv.invoice_date,
      upd_date: inv.upd_date ? parseISO(inv.upd_date) : inv.upd_date,
      sf_date: inv.sf_date ? parseISO(inv.sf_date) : inv.sf_date,
      payment_delay_date: inv.payment_delay_date
        ? parseISO(inv.payment_delay_date)
        : inv.payment_delay_date,
      act_date: inv.act_date ? parseISO(inv.act_date) : inv.act_date,
    }));

    this.setState({
      invoices,
      fields: { ...invoices.find((i) => i.id === this.props.invoiceId) },
    });
  }

  getInvoiceById() {
    return {
      ...this.state.invoices.find((i) => i.id === this.props.invoiceId),
    };
  }

  componentDidMount() {
    this.reloadRequests();
  }

  async reloadRequests() {
    if (!this.props.requestIDs.length) return;
    this.setState({ isPendingRequests: true });
    const { data: requests } = await this.props.searchRequests();
    this.setState({ isPendingRequests: false, requests });
  }

  componentDidUpdate({ show, invoiceId, requestIDs }, prevState) {
    if (this.props.invoiceId !== invoiceId) {
      this.refetchInvoices();
    }
    if (show !== this.props.show) {
      if (this.props.show) {
        this.reloadRequests();
      }
      if (!this.props.invoiceId)
        this.setState({ fields: { ...this.initialFields } });
    }
    if (!this.props.requestIDs.length) {
      if (this.state.requests.length) this.setState({ requests: [] });
    } else {
      // if (!isArraysEqual(requestIDs, this.props.requestIDs)) {
      //     this.reloadRequests()
      // }
    }
  }

  changed({ target: { name, value } }) {
    const { errors, fields } = this.state;
    delete errors[name];
    this.setState({
      errors,
      fields: { ...fields, [name]: value },
    });
  }

  calcTotalCost(type) {
    return this.state.requests.reduce(
      (acc, r) => this.calcCost(r, type) + acc,
      0,
    );
  }

  calcCost(row, type) {
    return [
      `cost_${type}`,
      `park_quantity_${type}`,
      `forwarding_${type}`,
    ].reduce((acc, col) => acc + parseInt(row[col] ?? 0, 10), 0);
  }

  alreadyHasInvoice(row) {
    const { type } = this.state.fields || {};
    const { has_driver_invoice, has_customer_invoice } = row;
    return (
      this.isCreate &&
      ((type === "credit" && has_driver_invoice) ||
        (type === "debet" && has_customer_invoice))
    );
  }

  get isInvalid() {
    return (
      this.state.requests.find(this.alreadyHasInvoice.bind(this)) !== undefined
    );
  }

  render() {
    const { errors, types, show, isPending } = this.props;
    const { fields, requests, isPendingRequests } = this.state;
    return (
      <Modal show={show} onHide={this.props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Добавить счёт</Modal.Title>
        </Modal.Header>
        <Form noValidate onSubmit={this.handleSubmit}>
          <Modal.Body>
            {this.isInvalid && (
              <Alert variant="danger" className="kt-font-boldest">
                Невозможно создать общий счёт: в заявку уже добавлен другой
                счет. Удалите старый счет и добавление станет активным.
              </Alert>
            )}
            <div className="kt-widget1">
              {isPendingRequests ? (
                <div className="kt-align-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <>
                  {requests.map((row) => (
                    <div className="kt-widget1__item" key={row.id}>
                      <div className="kt-widget1__info">
                        <h3 className="kt-widget1__title">
                          Заявка №{row.number}&nbsp;
                          {this.alreadyHasInvoice(row) && (
                            <span className="kt-font-warning">
                              <i className="la la-warning kt-font-xl" />
                              &nbsp;счёт уже добавлен
                            </span>
                          )}
                        </h3>
                      </div>
                      <span className="kt-widget1__number">
                        {fields.type === "debet" &&
                          `+ ${priceFormatter.format(
                            this.calcCost(row, "customer"),
                          )}`}
                        {fields.type === "credit" &&
                          `- ${priceFormatter.format(
                            this.calcCost(row, "driver"),
                          )}`}
                      </span>
                    </div>
                  ))}
                  <div className="kt-widget1__item">
                    <div className="kt-widget1__info">
                      <h3 className="kt-widget1__title">
                        <b>итого</b>
                      </h3>
                    </div>
                    <span
                      className={clsx("kt-widget1__number", {
                        "kt-font-success": fields.type === "debet",
                        "kt-font-danger": fields.type === "credit",
                      })}
                    >
                      {fields.type === "debet" &&
                        `+ ${priceFormatter.format(
                          this.calcTotalCost("customer"),
                        )}`}
                      {fields.type === "credit" &&
                        `- ${priceFormatter.format(
                          this.calcTotalCost("driver"),
                        )}`}
                    </span>
                  </div>
                </>
              )}
            </div>
            {!isPendingRequests && (
              <>
                <Form.Group as={Row}>
                  <Col md="6">
                    <Form.Label>Тип счёта</Form.Label>
                    <SelectEntity
                      disabled={!this.isCreate}
                      static
                      errorText={errors["type"]}
                      isSearchable={false}
                      onChange={({ value }) =>
                        this.changed({ target: { name: "type", value } })
                      }
                      value={
                        types.find((opt) => opt.value === fields?.type) || ""
                      }
                      name="type"
                      options={types}
                      placeholder="не выбрано"
                    />
                  </Col>
                  <Col md="6">
                    <Form.Label>Номер счета</Form.Label>
                    <Form.Control
                      name="invoice_number"
                      isInvalid={errors.hasOwnProperty("invoice_number")}
                      onChange={this.changed}
                      value={fields?.invoice_number}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors["invoice_number"]}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col md="6">
                    <Form.Label>Сумма счета</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <span>&#x20bd;</span>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        name="amount"
                        isInvalid={errors.hasOwnProperty("amount")}
                        onChange={this.changed}
                        value={fields?.amount}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors["amount"]}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                  <Col md="6">
                    <Form.Label>Дата счета</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <i className="la la-calendar" />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <ReactDatePicker
                        isClearable
                        placeholderText="Дата счета"
                        selected={fields?.invoice_date}
                        name="invoice_date"
                        className={clsx("form-control", {
                          "is-invalid": errors.hasOwnProperty("invoice_date"),
                        })}
                        dateFormat="dd.MM.yyyy"
                        onChange={(value) =>
                          this.changed({
                            target: { name: "invoice_date", value },
                          })
                        }
                      />
                      <Form.Control
                        hidden
                        isInvalid={errors.hasOwnProperty("invoice_date")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors["invoice_date"]}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col md="6">
                    <Form.Label>Акт или УПД</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <span>№</span>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        name="upd_number"
                        isInvalid={errors.hasOwnProperty("upd_number")}
                        onChange={this.changed}
                        value={fields?.upd_number}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors["upd_number"]}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                  <Col md="6">
                    <Form.Label>Дата акта/УПД</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <i className="la la-calendar" />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <ReactDatePicker
                        isClearable
                        selected={fields?.upd_date}
                        name="upd_date"
                        className={clsx("form-control", {
                          "is-invalid": errors.hasOwnProperty("upd_date"),
                        })}
                        dateFormat="dd.MM.yyyy"
                        onChange={(value) =>
                          this.changed({
                            target: { name: "upd_date", value },
                          })
                        }
                      />
                      <Form.Control
                        hidden
                        isInvalid={errors.hasOwnProperty("upd_date")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors["upd_date"]}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col md="6">
                    <Form.Label>Счёт-фактура</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <span>№</span>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        name="sf_number"
                        isInvalid={errors.hasOwnProperty("sf_number")}
                        onChange={this.changed}
                        value={fields?.sf_number}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors["sf_number"]}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                  <Col md="6">
                    <Form.Label>Дата счёт-фактуры</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <i className="la la-calendar" />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <ReactDatePicker
                        isClearable
                        selected={fields?.sf_date}
                        name="sf_date"
                        className={clsx("form-control", {
                          "is-invalid": errors.hasOwnProperty("sf_date"),
                        })}
                        dateFormat="dd.MM.yyyy"
                        onChange={(value) =>
                          this.changed({
                            target: { name: "sf_date", value },
                          })
                        }
                      />
                      <Form.Control
                        hidden
                        isInvalid={errors.hasOwnProperty("sf_date")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors["sf_date"]}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col md="6">
                    <Form.Label>Дата получения док-тов</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <i className="la la-calendar" />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <ReactDatePicker
                        isClearable
                        placeholderText="Дата получения"
                        selected={fields?.act_date}
                        name="act_date"
                        className={clsx("form-control", {
                          "is-invalid": errors.hasOwnProperty("act_date"),
                        })}
                        dateFormat="dd.MM.yyyy"
                        onChange={(value) =>
                          this.changed({
                            target: { name: "act_date", value },
                          })
                        }
                      />
                      <Form.Control
                        hidden
                        isInvalid={errors.hasOwnProperty("act_date")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors["act_date"]}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                  {/*<Col md="6">
                                <Form.Label>Дата оплаты</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            <i className="la la-calendar" />
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <ReactDatePicker
                                        isClearable
                                        placeholderText="Дата оплаты"
                                        selected={fields?.payment_delay_date}
                                        name="payment_delay_date"
                                        className={clsx("form-control", {
                                            "is-invalid": errors.hasOwnProperty("payment_delay_date")
                                        })}
                                        dateFormat="dd.MM.yyyy"
                                        onChange={value =>
                                            this.changed({
                                                target: { name: "payment_delay_date", value }
                                            })
                                        }
                                    />
                                    <Form.Control hidden isInvalid={errors.hasOwnProperty("payment_delay_date")} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors["payment_delay_date"]}
                                    </Form.Control.Feedback>
                                </InputGroup>
                                    </Col>*/}
                </Form.Group>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onClose}>
              отменить
            </Button>
            <Button
              variant="primary"
              disabled={isPending || this.isInvalid}
              onClick={this.handleSubmit}
              className={clsx({
                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                  isPending,
              })}
            >
              Сохранить
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceFormModal);
