import driver from "./.driver";
import { stringify } from "../helper/query-stringifier";

export function searchInn(inn) {
  return driver.get(`service/inn/${inn}`);
}

export function searchBank(bik) {
  return driver.get(`service/bank/${bik}`);
}

export function getAtiFirm(code) {
  return driver.get(`service/ati/${code}`);
}

export function getAtiFirmByEgr(query) {
  return driver.get(`service/ati_egr/${stringify(query)}`);
}
