import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Row, Form, Button } from "react-bootstrap";
import EntityField from "./EntityField";
import clsx from "clsx";
import { LinearProgress } from "@material-ui/core";
import { connect } from "react-redux";
import {
  Portlet,
  PortletBody,
  PortletFooter,
} from "../../partials/content/Portlet";
import { isEmpty } from "../../helper/object";

const mapStateToProps = (state, { select, id }) => ({
  id: id || select(state).lastId,
  errors: select(state).errors,
  entity: select(state).single[id],
  isPending: select(state).isPending,
  isSuccess: select(state).isSuccess,
});
const mapDispatchToProps = (dispatch, props) => ({
  single: (id) => dispatch(props.actions.single(id)),
  create: (fields) => dispatch(props.actions.create(fields)),
  update: (fields, id) => dispatch(props.actions.update(fields, id)),
});

class EntityForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitAndClose = this.handleSubmitAndClose.bind(this);
  }

  componentDidMount() {
    if (!this.isCreate) {
      const { single, id } = this.props;
      single(id).then(() => {
        this.setState({
          isLoaded: true,
          fields: { ...this.props.entity },
        });
      });
    }
  }

  UNSAFE_componentWillReceiveProps({ isSuccess, id, errors }) {
    if (isSuccess && isEmpty(errors) && id && this.isCreate) {
      this.setState({ redirect: true });
    }
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    entity: PropTypes.shape(),
    errors: PropTypes.shape(),
    isSuccess: PropTypes.bool,
    isPending: PropTypes.bool,
    id: PropTypes.string,
    history: PropTypes.shape().isRequired,
    schema: PropTypes.shape(),
    single: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    select: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
  };

  static defaultProps = {
    isPending: true,
    isSuccess: false,
  };

  state = {
    isLoaded: this.isCreate,
    closeAfterSave: false,
    fields: {},
    errors: {},
    redirect: false,
  };

  get isCreate() {
    return this.props.id == null;
  }

  set(field, value) {
    this.setState({
      fields: { ...this.state.fields, [field]: value },
    });
  }

  async handleSubmitAndClose(ev) {
    this.setState({ closeAfterSave: true });
    this.handleSubmit(ev);
  }

  async handleSubmit(ev) {
    ev.preventDefault();
    const { create, update, id, errors } = this.props;
    const { fields } = this.state;
    if (this.isCreate) {
      await create(fields);
    } else {
      await update(fields, id);
    }

    if (!this.props.isSuccess || !isEmpty(errors)) {
      this.setState({
        closeAfterSave: false,
        redirect: false,
        errors: errors,
      });
    }
    if (this.props.isSuccess && this.props.onSuccess) {
      this.props.onSuccess({ ...fields, id: this.props.id });
    }
  }

  render() {
    const { isLoaded, closeAfterSave } = this.state;
    const { isPending, errors, name, id } = this.props;
    if (!isPending && isLoaded && isEmpty(errors) && !this.props.onSuccess) {
      if (closeAfterSave) {
        this.props.history.goBack();
      } else if (this.state.redirect) {
        return <Redirect to={`/${name}/edit/${id}`} />;
      }
    }
    return (
      <Form onSubmit={this.handleSubmit} noValidate autoComplete="off">
        {/* Disable Autocomplete workaround */}
        <label
          htmlFor="fk_email"
          style={{ opacity: 0.01, position: "absolute", cursor: "default" }}
        >
          Email
        </label>
        <input
          id="fk_email"
          style={{ opacity: 0.01, position: "absolute", cursor: "default" }}
          type="email"
        />
        <input
          style={{ opacity: 0.01, position: "absolute", cursor: "default" }}
          type="password"
        />
        {/* // Disable Autocomplete workaround */}
        {/* <input type="email" /> */}
        {!isLoaded && <LinearProgress />}
        <Portlet>
          <PortletBody>
            <Row>
              {Object.keys(this.props.schema).map((field, _id) => (
                <EntityField
                  key={_id}
                  name={field}
                  error={errors[field]}
                  disabled={!isLoaded}
                  onChange={(event) => this.set(field, event.target.value)}
                  defaultValue={this.state.fields[field] || ""}
                  type={this.props.schema[field].type}
                  field={this.props.schema[field]}
                  select={this.props.schema[field].select}
                >
                  {this.props.schema[field].label}
                </EntityField>
              ))}
            </Row>
          </PortletBody>
          <PortletFooter>
            <Button
              variant="success"
              disabled={isPending}
              onClick={this.handleSubmit}
              type="button"
              className={clsx("mr-2", {
                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                  isPending && !closeAfterSave,
              })}
            >
              Сохранить
            </Button>
            <Button
              color="primary"
              disabled={isPending}
              onClick={this.handleSubmitAndClose}
              type="button"
              className={clsx("mr-2", {
                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                  isPending && closeAfterSave,
              })}
            >
              Сохранить и закрыть
            </Button>
            <Button
              variant="danger"
              onClick={() =>
                this.props.onCancel
                  ? this.props.onCancel()
                  : this.props.history.goBack()
              }
              disabled={isPending}
              type="button"
              className="mr-2 pull-right"
            >
              Отменить
            </Button>
          </PortletFooter>
        </Portlet>
      </Form>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EntityForm),
);
