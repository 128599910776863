import React from "react";
import EntityForm from "../../../components/entity/EntityForm";
import EntityList from "../../../components/entity/EntityList";

import { cargoEntity } from "../../../store/ducks/entity.duck";

export function CargoList() {
  return (
    <EntityList
      actions={cargoEntity.actions}
      select={(state) => state.catalog.cargo}
      name="catalog/cargo"
    />
  );
}

const schema = {
  title: { label: "Название" },
};
export function CargoEdit(props) {
  return (
    <EntityForm
      name="catalog/cargo"
      id={props.match.params.id}
      schema={schema}
      actions={cargoEntity.actions}
      actionTypes={cargoEntity.types}
      select={(state) => state.catalog.cargo}
    />
  );
}

export function CargoCreate(props) {
  return (
    <EntityForm
      name="catalog/cargo"
      onSuccess={props.onSuccess}
      onCancel={props.onCancel}
      schema={schema}
      actions={cargoEntity.actions}
      actionTypes={cargoEntity.types}
      select={(state) => state.catalog.cargo}
    />
  );
}
