export default {
  header: {
    self: {},
    items: [
      {
        title: "Журнал заявок",
        page: "requests/progress",
        bullet: "dot",
        icon: "flaticon2-layers",
        entity: "requests/progress",
      },
      {
        title: "Оперативный журнал",
        page: "requests/pending",
        bullet: "dot",
        icon: "flaticon-calendar-with-a-clock-time-tools",
        entity: "requests/pending",
      },
      {
        title: "Перевозчики",
        page: "drivers",
        bullet: "dot",
        icon: "flaticon2-delivery-truck",
        entity: "drivers",
      },
      {
        title: "Заказчики",
        page: "customers",
        bullet: "dot",
        icon: "flaticon2-soft-icons-1",
        entity: "customers",
      },
      {
        title: "Договоры",
        page: "contract",
        bullet: "dot",
        icon: "flaticon2-contract",
        entity: "customers",
      },
      {
        title: "Счета и платежи",
        page: "invoice",
        bullet: "dot",
        icon: "flaticon2-document",
        entity: "invoices",
      },
      {
        title: "Удаленные заявки",
        page: "requests/deleted",
        bullet: "dot",
        icon: "flaticon2-trash",
        entity: "requests/deleted",
      },
      // {
      // 	title: 'Рабочий стол',
      // 	root: true,
      // 	icon: 'flaticon2-architecture-and-city',
      // 	page: 'dashboard',
      // 	bullet: 'dot',
      // },
      // { section: 'Заявки' },
      // {
      // 	title: 'Реестр заявок',
      // 	page: 'requests/progress',
      // 	bullet: 'dot',
      // 	icon: 'flaticon2-layers'
      // },
      // {
      // 	title: 'Предварительные заказы',
      // 	page: 'requests/pending',
      // 	bullet: 'dot',
      // 	icon: 'flaticon2-chat'
      // },
      // { section: 'Перевозчики' },
      // {
      // 	title: 'Список перевозчиков',
      // 	page: 'drivers',
      // 	bullet: 'dot',
      // 	icon: 'flaticon2-delivery-truck'
      // },
      // { section: 'Заказчики' },
      // {
      // 	title: 'Список заказчиков',
      // 	page: 'organizations',
      // 	bullet: 'dot',
      // 	icon: 'flaticon2-soft-icons-1'
      // },
      // { section: 'Настройки' },
      // {
      // 	title: 'Сменить пароль',
      // 	page: 'settings/change-password',
      // 	bullet: 'dot',
      // 	icon: 'flaticon2-user-1'
      // },
      // {
      // 	title: 'Справочники',
      // 	root: true,
      // 	bullet: 'dot',
      // 	icon: 'flaticon2-open-text-book',
      // 	submenu: [
      // 		{
      // 			title: 'НДС',
      // 			page: 'settings/catalog/tax',
      // 			bullet: 'dot',
      // 		},
      // 		{
      // 			title: 'Модели автомобилей',
      // 			page: 'settings/catalog/auto',
      // 			bullet: 'dot',
      // 		},
      // 		{
      // 			title: 'Модели полуприцепов',
      // 			page: 'settings/catalog/trailer',
      // 			bullet: 'dot',
      // 		},
      // 	]
      // },
      // {
      // 	title: 'Пользователи',
      // 	bullet: 'dot',
      // 	page: 'settings/user',
      // 	icon: 'flaticon2-user'
      // },
      // {
      // 	title: 'Система',
      // 	bullet: 'dot',
      // 	page: 'settings/system',
      // 	icon: 'flaticon2-gear'
      // },
    ],
  },
  aside: {
    self: {},
    items: [
      // {
      // 	title: 'Рабочий стол',
      // 	root: true,
      // 	icon: 'flaticon2-architecture-and-city',
      // 	page: 'dashboard',
      // 	bullet: 'dot',
      // },
      // { section: 'Заявки' },
      // {
      // 	title: 'Реестр заявок',
      // 	page: 'requests/progress',
      // 	bullet: 'dot',
      // 	icon: 'flaticon2-layers'
      // },
      // {
      // 	title: 'Предварительные заказы',
      // 	page: 'requests/pending',
      // 	bullet: 'dot',
      // 	icon: 'flaticon2-chat'
      // },
      // { section: 'Перевозчики' },
      // {
      // 	title: 'Список перевозчиков',
      // 	page: 'drivers',
      // 	bullet: 'dot',
      // 	icon: 'flaticon2-delivery-truck'
      // },
      // { section: 'Заказчики' },
      // {
      // 	title: 'Список заказчиков',
      // 	page: 'organizations',
      // 	bullet: 'dot',
      // 	icon: 'flaticon2-soft-icons-1'
      // },
      // { section: 'Настройки' },
      // {
      // 	title: 'Сменить пароль',
      // 	page: 'settings/change-password',
      // 	bullet: 'dot',
      // 	icon: 'flaticon2-user-1'
      // },
      // {
      // 	title: 'Справочники',
      // 	root: true,
      // 	bullet: 'dot',
      // 	icon: 'flaticon2-open-text-book',
      // 	submenu: [
      // 		{
      // 			title: 'НДС',
      // 			page: 'catalog/tax',
      // 			bullet: 'dot',
      // 			entity: "catalog/tax",
      // 		},
      // 		{
      // 			title: 'Изменить',
      // 			page: 'catalog/tax/:id/edit',
      // 		},
      // 		{
      // 			title: 'Добавить',
      // 			page: 'catalog/tax/create',
      // 		},
      // 		{
      // 			title: 'Модели автомобилей',
      // 			page: 'catalog/auto',
      // 			bullet: 'dot',
      // 		},
      // 		{
      // 			title: 'Модели полуприцепов',
      // 			page: 'catalog/trailer',
      // 			bullet: 'dot',
      // 		},
      // 		{
      // 			title: 'Виды грузов',
      // 			page: 'catalog/trailer',
      // 			bullet: 'dot',
      // 		},
      // 	]
      // },
      // {
      // 	title: 'Пользователи',
      // 	bullet: 'dot',
      // 	page: 'settings/user',
      // 	icon: 'flaticon2-user'
      // },
      // {
      // 	title: 'Система',
      // 	bullet: 'dot',
      // 	page: 'settings/system',
      // 	icon: 'flaticon2-gear'
      // },
      {
        title: "Профиль",
        page: "profile",
        bullet: "dot",
        icon: "flaticon2-user",
      },
    ],
  },
};
