const encode = (val) =>
  val === null || val === undefined ? "" : encodeURIComponent(val);

const isObject = (val) => typeof val === "object" && val !== null;

const buildChains = (obj, parent) => {
  return Object.keys(obj).reduce((result, key) => {
    let chain = `${parent}[${encodeURIComponent(key)}]`;
    if (isObject(obj[key])) {
      chain = buildChains(obj[key], chain).join("&");
    } else {
      chain += `=${encode(obj[key])}`;
    }
    result.push(chain);
    return result;
  }, []);
};

/// Builds php beautiful query string from object
/// example: {foo: {bar: "baz", boo: "doo"}} => foo[bar]=baz&foo[boo]=doo
/// more examples in ./query-stringifier.test.js
export const stringify = (obj) => {
  return Object.keys(obj)
    .reduce((result, key) => {
      if (key === false) {
        return result;
      }
      const acc = result + "&";
      if (key === "") {
        return acc + obj[key];
      } else if (isObject(obj[key])) {
        const chains = buildChains(obj[key], key).join("&");
        return acc + chains;
      } else {
        const val = encode(obj[key]);
        return acc + `${encode(key)}${val ? `=${val}` : ""}`;
      }
    }, "")
    .replace(/([&&]+)/g, "&")
    .replace(/(^&|&$)/g, "");
};
