import React from "react";
import EntityList from "../../../components/entity/EntityList";
import { invoiceEntity } from "../../../store/ducks/entity.duck";
import InvoiceListActions from "./InvoiceListActions";
import InvoiceListFilters from "./InvoiceListFilters";

export function InvoicePageList() {
  return (
    <EntityList
      disableClick
      actionsComponent={null}
      freeWidth
      isServerFilter
      multipleActionsComponent={(props) => <InvoiceListActions {...props} />}
      filterComponent={(props) => <InvoiceListFilters {...props} />}
      filterFn={InvoiceListFilters.filterRows}
      actions={invoiceEntity.actions}
      select={(state) => state.invoices}
      name="invoice"
    />
  );
}
