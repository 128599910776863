import driver from "./.driver";
import { stringify } from "../helper/query-stringifier";

export const list = (id = []) => driver.get(`file?` + stringify({ id }));

export const upload = (file, onProgress = () => null, fileName) => {
  const data = new FormData();
  data.append("file", file, fileName?.replace(/\//g, "-"));
  return driver.post(`file/upload`, data, {
    onUploadProgress: function (progressEvent) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      onProgress({ progress: percentCompleted, event: progressEvent, file });
    },
  });
};

export const saveOrgFiles = (orgId, ids) =>
  driver.post(`file/org/${orgId}`, { id: ids });

export const remove = (pk) => driver.post(`file/delete/${pk}`);

export const download = (id) => driver.open("/file/download/" + id);

export const downloadZip = (ids) =>
  driver.open("/file/download_zip?" + stringify({ ids }));
