import driver from "./.driver";

export const getNumber = (type) => driver.get(`contract/get_number/${type}`);

export const getDocument = (contractId) =>
  driver.get(`contract/get_document/${contractId}`);

// export const updateDocument = (contractId, fields) => driver.post(
//     `contract/update_document/${contractId}`, fields
// )
