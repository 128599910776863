import { parseISO } from "date-fns";
import { apiDate, zeroDate } from "../../helper/date";

// export const getPaymentDate = contract => {
//   const days = parseInt(contract.deadline_days);
//   switch (contract.deadline_days_type) {
//     case "workdays":
//       return format(addBusinessDays(contract.date_start, days), "dd.MM.yyyy");
//     case "weekdays":
//     default:
//       return format(addDays(contract.date_start, days), "dd.MM.yyyy");
//   }
// };

export const humanReducer = ({ date_start, date_end, ...contract }) => ({
  ...contract,
  date_start: !zeroDate(date_start) && parseISO(date_start),
  date_end: !zeroDate(date_end) && parseISO(date_end),
});

const apiReducer = ({ date_start, date_end, ...fields }) => ({
  ...fields,
  date_start: apiDate(date_start),
  date_end: apiDate(date_end),
});

export default { from: humanReducer, to: apiReducer };
