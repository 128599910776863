/** Core */
import React, { createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Portlet,
  PortletHeader,
  PortletBody,
  PortletHeaderToolbar,
} from "../../../partials/content/Portlet";
import { LinearProgress } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
/** Helpers */
import { find } from "../../../helper/object";
/** Store & ducks */
import staticStore from "../../../store/staticStore";
import { fileEntity } from "../../../store/ducks/file.duck";
import { customersEntity } from "../../../store/ducks/entity.duck";
import { Row, Col, Button, ListGroup } from "react-bootstrap";
/** Widgets */
import EmployeeList from "../../../partials/widgets/EmployeeList";
import ContractList from "../../../partials/widgets/ContractList";
import BanksList from "../../../partials/widgets/BanksList";
import FileList from "../../../partials/content/FileList/FileList";
import clsx from "clsx";
import { ErrorPage404 } from "../../errors/ErrorPage404";

const mapDispatchToProps = (dispatch) => ({
  fetchCustomer: (id) => dispatch(customersEntity.actions.single(id)),
  removeFile: (id) => dispatch(fileEntity.actions.remove(id)),
  saveFiles: (org_id, fileIds) =>
    dispatch(fileEntity.actions.saveOrgFiles(org_id, fileIds)),
});

const mapStateToProps = ({ customers }, props) => ({
  isPending: customers.isPending ?? false,
  customer: customers.single[props.id] ?? {},
  contractConditionByCode: (contractCode) =>
    staticStore.contractConditions.find(({ code }) => {
      return code === contractCode;
    }),
});

class CustomerCard extends React.Component {
  constructor(props, context) {
    super(props, context);
    props.fetchCustomer(props.id);
  }

  static propTypes = {
    id: PropTypes.string,
    isPending: PropTypes.bool.isRequired,
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
    }).isRequired,
    customer: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    fetchCustomer: PropTypes.func.isRequired,
    contractConditionByCode: PropTypes.func.isRequired,
  };

  listLeft = {
    city: "Город",
    "cargo_type.title": "Вид груза",
    address: "Юр. адрес",
    real_address: "Факт. адрес",
    inn: "ИНН",
    kpp: "КПП",
    ogrn: "ОГРН",
  };

  listRight = {
    phone1: "Телефон 1",
    phone2: "Телефон 2",
    phone3: "Телефон 3",
    email: "Email",
    website: "Сайт",
  };

  uploadBtnRef = createRef();

  state = {
    files: [],
  };
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.customer.files !== this.props.customer.files) {
      this.setState({ files: newProps.customer.files });
    }
  }

  render() {
    if (this.props.isPending) {
      return <LinearProgress />;
    }

    if (!this.props.customer.id) {
      return <ErrorPage404 />;
    }

    const { id, customer, contractConditionByCode } = this.props;
    return (
      <>
        <Row>
          <Col xl="6" md="12">
            <Portlet fluidHeight>
              <PortletHeader
                title={`${customer.name}`}
                toolbar={
                  <PortletHeaderToolbar>
                    {/* <Button variant="secondary" className="flaticon2-file">
                      &nbsp;&nbsp;История изменений
                    </Button> */}
                    <Link
                      to={`/customers/${id}/edit`}
                      style={{ marginLeft: ".5em" }}
                    >
                      <Button
                        variant="primary"
                        type="button"
                        className="flaticon2-edit"
                      >
                        &nbsp;&nbsp;Изменить
                      </Button>
                    </Link>
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody>
                <Row>
                  <Col md="4">
                    <h3>Компания</h3>
                    <ListGroup variant="flush">
                      {Object.keys(this.listLeft)
                        .filter((f) => find(customer, f))
                        .map((field) => (
                          <ListGroup.Item key={field}>
                            <b>{this.listLeft[field]}</b>
                            <br />
                            {find(customer, field)}
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  </Col>
                  <Col md="4">
                    <h3>Контакты</h3>
                    <ListGroup variant="flush">
                      {Object.keys(this.listRight)
                        .filter((f) => find(customer, f))
                        .map((field) => (
                          <ListGroup.Item key={field}>
                            <b>{this.listRight[field]}</b>
                            <br />
                            {find(customer, field)}
                          </ListGroup.Item>
                        ))}
                      {customer.ati_code && (
                        <ListGroup.Item>
                          <b>АТИ</b>
                          <br />
                          <a
                            href={`https://ati.su/firms/${customer.ati_code}/info`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <b>Код {customer.ati_code}</b>
                            <br />
                            {Array.from({ length: 5 }).map((_, i) => (
                              <i
                                key={i}
                                className={clsx("la la-star", {
                                  "kt-font-warning": i <= customer.ati_score,
                                  "kt-font-dark": i > customer.ati_score,
                                })}
                              />
                            ))}
                          </a>
                        </ListGroup.Item>
                      )}
                    </ListGroup>
                  </Col>
                  <Col md="4">
                    <h3>Условия</h3>
                    <ListGroup variant="flush">
                      {customer.self_condition_text && (
                        <ListGroup.Item>
                          <b>Особые условия для заказчиа</b>
                          <br />
                          {
                            contractConditionByCode(
                              customer.self_condition_type,
                            ).title
                          }
                          :<br />
                          {customer.self_condition_text}
                        </ListGroup.Item>
                      )}
                      {customer.driver_condition_text && (
                        <ListGroup.Item>
                          <b>Особые условия для перевозчика</b>
                          <br />
                          {
                            contractConditionByCode(
                              customer.driver_condition_type,
                            ).title
                          }
                          :<br />
                          {customer.driver_condition_text}
                        </ListGroup.Item>
                      )}
                    </ListGroup>
                  </Col>
                </Row>
              </PortletBody>
            </Portlet>
          </Col>
          <Col xl="3" lg="6" md="6">
            <BanksList
              orgId={customer.org_id}
              search={{ "customer.id": customer.id }}
              searchRule={{ "customer.id": "strict" }}
            />
          </Col>
          <Col xl="3" lg="6" md="6">
            <ContractList
              orgId={customer.org_id}
              type="customer"
              filterTemplates={(req) => {
                req.data = req.data.filter(
                  ({ side, usage_type }) =>
                    side === "Исполнитель" &&
                    usage_type === "Договоров заказчику",
                );
                return req;
              }}
            />
          </Col>
          <Col xl="6" lg="12">
            <Portlet fluidHeight>
              <PortletHeader
                title="Файлы"
                toolbar={
                  <PortletHeaderToolbar>
                    <Button ref={this.uploadBtnRef} size="sm" variant="success">
                      Добавить
                    </Button>
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody scrollable>
                <FileList
                  uploadButton={this.uploadBtnRef}
                  defaultValue={this.state.files}
                  onUpload={(files) =>
                    this.setState(
                      () => ({
                        files: [
                          ...this.state.files,
                          ...files.map((file) => file.id),
                        ],
                      }),
                      () =>
                        this.props.saveFiles(customer.org_id, this.state.files),
                    )
                  }
                  onDelete={(file) =>
                    this.setState(
                      () => ({
                        files: this.state.files.filter(
                          (_id) => _id !== file.id,
                        ),
                      }),
                      () => this.props.removeFile(file.id),
                    )
                  }
                />
              </PortletBody>
            </Portlet>
          </Col>
          <Col xl="6" lg="12">
            <EmployeeList
              orgId={customer.org_id}
              type="contact"
              title="Контактные лица"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerCard),
);
