import React, { Component } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Button, Form, Modal, Col, Row, InputGroup } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import * as payorderService from "../../../service/pay_order";
import "./actions.scss";
import { apiDate } from "../../../helper/date";
import { priceFormatter } from "../../../helper/number";
import InvoiceDateModal from "./InvoiceDateModal";
import { InvoiceListActionPayOrder } from "./InvoiceListActionPayOrder";

class InvoiceListAction extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleShowInvoiceDateModal =
      this.handleShowInvoiceDateModal.bind(this);
  }

  async handleSubmit({ bulk, isDivided, payedRequests }) {
    this.setState({ isPending: true });
    try {
      await payorderService.bulk_create({
        pay_orders: bulk.map((fields) => ({
          ...fields,
          date: apiDate(fields.date),
        })),
        invoices: this.props.selected.map(({ id }) => id),
        is_divided: isDivided ? "1" : "0",
        payed_requests: payedRequests,
      });
      this.props.onComplete();
      this.handleClose();
    } catch (err) {
      this.setState({ errors: err.messages ?? {} });
    } finally {
      this.setState({ isPending: false });
    }
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  handleShowInvoiceDateModal() {
    this.setState({ showInvoiceDateModal: true });
  }

  static propTypes = {
    selected: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
  };

  state = {
    showModal: false,
    showDeleteModal: false,
    showInvoiceDateModal: false,
  };

  async deletePayOrders() {
    this.setState({ isPending: true });
    const { selected } = this.props;
    const ids = selected.map(({ pay_order_ids }) => pay_order_ids.split(", "));
    try {
      await payorderService.bulk_delete(
        [].concat(...ids.filter((el) => el > "")),
      );
      this.props.onComplete();
    } finally {
      this.setState({ isPending: false, showDeleteModal: false });
    }
  }

  render() {
    const { selected } = this.props;
    const { bulk, showModal, isPending, errors } = this.state;
    return (
      <div
        className={clsx("kt-form kt-form--label-align-right", {
          collapse: !selected.length,
        })}
      >
        <div className="row align-items-center">
          <div className="col-xl-12">
            <div className="kt-form__group kt-form__group--inline">
              <div className="kt-form__label kt-form__label-no-wrap">
                <label className="kt-font-bold kt-font-danger-">
                  Выбраны&nbsp;
                  <span id="kt_datatable_selected_number1">
                    {selected.length}
                  </span>
                  шт:
                </label>
              </div>
              <div className="kt-form__control">
                <div className="btn-toolbar">
                  <Button
                    variant="outline-success"
                    size="sm"
                    className="mr-2"
                    onClick={this.handleShowInvoiceDateModal}
                  >
                    + Дата оплаты
                  </Button>
                  <Button
                    variant="outline-info"
                    size="sm"
                    className="mr-2"
                    onClick={this.handleShow}
                  >
                    Добавить п/п
                  </Button>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    className="mr-2"
                    onClick={() => this.setState({ showDeleteModal: true })}
                  >
                    Удалить п/п
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <InvoiceDateModal
          onSuccess={() => {
            this.setState({ showInvoiceDateModal: false });
            this.props.onComplete(true);
          }}
          invoices={selected}
          show={this.state.showInvoiceDateModal}
          onHide={() => this.setState({ showInvoiceDateModal: false })}
        />
        <Modal
          show={this.state.showDeleteModal}
          onHide={() => this.setState({ showDeleteModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Подтвердите действие</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6>
              Вы уверены, что хотите удалить все платёжные поручения у cчетов №
              {selected.map(({ invoice_number }) => invoice_number).join(", ")}?
            </h6>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isPending}
              variant="default"
              onClick={() => this.setState({ showDeleteModal: false })}
            >
              отменить
            </Button>
            <Button
              className={clsx({
                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                  isPending,
              })}
              disabled={isPending}
              variant="danger"
              onClick={() => this.deletePayOrders()}
            >
              подтвердить
            </Button>
          </Modal.Footer>
        </Modal>
        <InvoiceListActionPayOrder
          selected={selected}
          errors={errors}
          show={showModal}
          isPending={isPending}
          onSubmit={this.handleSubmit}
          onHide={this.handleClose}
        />
      </div>
    );
  }
}

export default InvoiceListAction;
