import { apiDate } from "../../helper/date";
import { parseISO } from "date-fns";
import { replaceNullToString, replaceStringToNull } from "../../helper/object";

const humanReducer = ({
  date,
  // date_receive,
  is_payed,
  ...rest
} = {}) => ({
  is_payed: is_payed === "1" ? true : false,
  date: parseISO(date),
  // date_receive: parseISO(date_receive),
  ...replaceNullToString(rest),
});

const apiReducer = ({
  date,
  // date_receive,
  is_payed,
  ...rest
} = {}) => ({
  ...replaceStringToNull(rest),
  is_payed: is_payed === true ? "1" : "0",
  date: date && apiDate(date),
  // date_receive: date_receive && apiDate(date_receive),
});

export default { from: humanReducer, to: apiReducer };
