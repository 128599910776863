import Axios from "axios";
import { handleError } from "./.errors";

const isDev = process.env.NODE_ENV !== "production";
const API_HOST = isDev ? "http://localhost:8000" : "";
const API_URL = `${API_HOST}/api`;

const timeout = isDev ? -1 : 30; // seconds
const requestConf = {
  withCredentials: true,
  timeout: timeout * 1000, // after 30 seconds show an error
  timeoutErrorMessage: `Время ответа сервера превысило ожидаемое: cервер не ответил в течении ${timeout} секунд.`,
};

const driver = {
  get: (endpoint, config = {}) => {
    const conf = { ...requestConf, ...config };
    return Axios.get(`${API_URL}/${endpoint}`, conf).catch(handleError);
  },
  post: (endpoint, data, config = {}) => {
    const conf = { ...requestConf, ...config };
    return Axios.post(`${API_URL}/${endpoint}`, data, conf).catch(handleError);
  },
  open: (uri) => window.open(API_URL + uri),
};

export default driver;
