import { format, isEqual } from "date-fns";
import React, { useMemo, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Form,
  InputGroup,
  Overlay,
} from "react-bootstrap";
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range";
import { ru } from "date-fns/locale";
import { humanDate } from "../../../helper/date";

const staticRanges = defaultStaticRanges.map((range) => ({
  ...range,
  hasCustomRendering: true,
}));
const l10n = {
  Today: "Сегодня",
  Yesterday: "Вчера",
  "This Week": "Эта неделя",
  "Last Week": "Прошлая неделя",
  "This Month": "Этот месяц",
  "Last Month": "Прошлый месяц",
};
defaultInputRanges[0].label = "дней до сегодня";
defaultInputRanges[1].label = "дней начиная с сегодня";

export function RangePicker({ range, onChange, type }) {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [selection, setRange] = useState(range);
  const hasSelection = useMemo(
    () => !!(range.startDate && range.endDate),
    [range],
  );

  const value = useMemo(() => {
    if (!hasSelection) return "";
    const { startDate, endDate } = range;
    if (isEqual(startDate, endDate)) return humanDate(startDate);
    return `${humanDate(startDate)} - ${humanDate(endDate)}`;
  }, [range, hasSelection]);
  return (
    <>
      <InputGroup>
        <Form.Control
          ref={target}
          name={`${type}_date`}
          readOnly
          value={value}
          onClick={() => setShow(true)}
        />
        {hasSelection && (
          <InputGroup.Append>
            <Button
              variant="danger"
              onClick={() => {
                onChange({ key: range.key, startDate: "", endDate: "" });
                setShow(false);
              }}
            >
              &times;
            </Button>
          </InputGroup.Append>
        )}
      </InputGroup>
      <Overlay target={target.current} show={show} placement="bottom">
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              backgroundColor: "#fff",
              boxShadow: "0 0 5px 5px rgba(0,0,0,.15)",
              borderRadius: 5,
              padding: 10,
            }}
          >
            <DateRangePicker
              startDatePlaceholder="начальная дата"
              endDatePlaceholder="конечная дата"
              locale={ru}
              staticRanges={staticRanges}
              inputRanges={defaultInputRanges}
              renderStaticRangeLabel={({ label }) => l10n[label]}
              ranges={[selection]}
              onChange={(ranges) => setRange(ranges[range.key])}
            />
            <div className="mt-3 flex">
              <Button
                size="s"
                variant="success"
                className="pull-right"
                onClick={() => {
                  onChange(selection);
                  setShow(false);
                }}
              >
                Выбрать
              </Button>
              <Button
                size="s"
                variant="outline-danger"
                onClick={() => setShow(false)}
              >
                Отменить
              </Button>
            </div>
          </div>
        )}
      </Overlay>
    </>
  );
}
