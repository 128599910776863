export default {
  employeePositions: [
    { code: "employee", title: "Сотрудник" },
    { code: "director", title: "Руководитель" },
  ],
  contractConditions: [
    { code: "append", title: "Добавить к существующим" },
    { code: "replace", title: "Заменить существующие" },
  ],
  invoiceTypes: [
    { label: "заказчику", value: "debet" },
    { label: "от перевозчика", value: "credit" },
  ],
};

export const REQUEST_PROGRESS_STATUS_ID = 1;
export const REQUEST_ADJUST_STATUS_ID = 2;
export const REQUEST_DONE_STATUS_ID = 3;
export const REQUEST_DRAFT_STATUS_ID = 4;
