import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import clsx from "clsx";
import { Radio, FormControlLabel, RadioGroup } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { reasonList } from "../constants";

export default function RequestActions(props) {
  const [isModalShowed, setModalShowed] = useState(false);
  const [isPending, setPending] = useState(false);
  const [reason, setReason] = useState("");
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();

  const {
    actions: { remove },
    row: { id },
  } = props;

  const handleDelete = useCallback(async () => {
    setPending(true);
    await dispatch(remove(id, { reason, description }));
    setPending(false);
    setModalShowed(false);
  }, [reason, description, remove, id, dispatch]);

  const handleChangeReason = (event) => {
    setReason(event.target.value);
  };

  return (
    <>
      <Link
        size="sm"
        className="btn btn-clean btn-icon btn-icon-md"
        to={`/${props.name}/create?copy=${props.row.id}`}
      >
        <i title="Скопировать" className="flaticon2-copy" />
      </Link>
      <Button
        className="btn-clean btn-icon btn-icon-md"
        size="sm"
        onClick={() => setModalShowed(true)}
        variant="clean"
      >
        <i className="la la-trash kt-font-danger" />
      </Button>
      <Modal show={isModalShowed} onHide={() => setModalShowed(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Удаление заявки</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>
            Если вы уверены, что хотите удалить заявку №{props.row.number}, то
            выберите причину удаления и подтвердите действие:
          </h6>
          <RadioGroup
            aria-label="причина"
            name="delete-reason"
            value={reason}
            onChange={handleChangeReason}
          >
            {Object.keys(reasonList).map((slug) => (
              <FormControlLabel
                key={slug}
                value={slug}
                control={<Radio />}
                label={reasonList[slug]}
              />
            ))}
            {reason === "other" && (
              <Form.Control
                onChange={(ev) => setDescription(ev.target.value)}
                value={description}
              />
            )}
          </RadioGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isPending}
            variant="default"
            onClick={() => setModalShowed(false)}
          >
            отменить
          </Button>
          <Button
            className={clsx({
              "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                isPending,
            })}
            disabled={isPending || !reason}
            variant="danger"
            onClick={handleDelete}
          >
            подтвердить удаление
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
