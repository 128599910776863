import {
  format as _format,
  parseISO,
  isAfter,
  isBefore,
  isEqual,
} from "date-fns";
import { ru } from "date-fns/locale";

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
const format = function (date, formatStr = "PP") {
  try {
    return _format(date, formatStr, { locale: ru });
  } catch (err) {
    return "-";
  }
};

export const zeroDate = (dateString) =>
  !dateString || dateString.startsWith("0000-00-00");

export const humanDateParse = (dateString) => {
  if (!dateString) return null;
  return humanDate(parseISO(dateString));
};
export const humanDate = (date) => {
  if (!date) return null;
  return format(date, "dd.MM.yyyy");
};
export const humanDateTime = (date) => {
  if (!date) return null;
  return format(date, "dd.MM.yyyy HH:mm");
};
export const humanDateTimeParse = (date) => {
  if (!date) return null;
  return humanDateTime(parseISO(date));
};

export const apiDate = (date) => (date ? format(date, "yyyy-MM-dd") : null);
export const apiDateTime = (date) =>
  date ? format(date, "yyyy-MM-dd HH:mm:ss") : null;

// const parseDateStr = date => {
//   const [d, m, y] = date.split(".");
//   return parseISO(`${y}-${m}-${d}`);
// };

export const isAfterOrEqual = (date, after) => {
  if (!after) return true; // не отфильтровано
  if (!date) return false; // отфильтровано без даты в записи - не с чем сравнивать
  let parsedDate = date;
  let parsedAfter = after;
  if (typeof date === "string") {
    const [d, m, y] = date.split(".");
    parsedDate = parseISO(`${y}-${m}-${d}`);
  }
  if (!(after instanceof Date)) {
    parsedAfter = parseISO(date);
  }
  return isAfter(parsedDate, parsedAfter) || isEqual(parsedDate, parsedAfter);
};

export const isBeforeOrEqual = (date, before) => {
  if (!before) return true; // не отфильтровано
  if (!date) return false; // отфильтровано без даты в записи - не с чем сравнивать
  let parsedDate = date;
  let parsedBefore = before;
  if (typeof date === "string") {
    const [d, m, y] = date.split(".");
    parsedDate = parseISO(`${y}-${m}-${d}`);
  }
  if (!(before instanceof Date)) {
    parsedBefore = parseISO(before);
  }
  return (
    isBefore(parsedDate, parsedBefore) || isEqual(parsedDate, parsedBefore)
  );
};

export const daterangepickerLocale = {
  format: "DD.MM.yyyy",
  cancelLabel: "отмена",
  applyLabel: "применить",
  // fromLabel?: '',
  toLabel: "след.",
  separator: " - ",
  weekLabel: "неделя",
  firstDay: 1,
  daysOfWeek: ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
  monthNames: [
    "январь",
    "февраль",
    "март",
    "апрель",
    "май",
    "июнь",
    "июль",
    "август",
    "сентябрь",
    "октябрь",
    "ноябрь",
    "декабрь",
  ],
};
