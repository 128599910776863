// const humanReducer = template => ({
//     ...template,
//     sfdt: JSON.parse(template.sfdt)
// })

const apiReducer = (template) => ({
  ...template,
  sfdt: JSON.stringify(template.sfdt),
});

export default { from: (v) => v, to: apiReducer };
