import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { Tooltip, Overlay, Button } from "react-bootstrap";
import clsx from "clsx";

export default class ConfirmButton extends Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
    this.trigger = this.trigger.bind(this);
    this.submit = this.submit.bind(this);
  }

  static propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    action: PropTypes.func.isRequired,
    isClear: PropTypes.bool,
  };

  state = { show: false };

  submit() {
    this.trigger();
    this.props.action();
  }

  trigger() {
    this.setState({ show: !this.state.show });
  }

  render() {
    const { show } = this.state;
    const { children, action, isClear, ...rest } = this.props;
    return (
      <>
        <Button
          size="sm"
          ref={this.ref}
          onClick={this.trigger}
          className={clsx({ "btn-clean btn-icon btn-icon-md": isClear })}
          {...rest}
        >
          <i />
          {children}
        </Button>
        <Overlay
          target={this.ref.current}
          show={show}
          trigger="click"
          placement="left"
        >
          {({ show: _, ...props }) => (
            <Tooltip id="overlay-example" {...props}>
              Вы уверены?
              <Button
                block
                variant="success"
                size="sm"
                className="flaticon2-checkmark"
                onClick={this.submit}
              >
                <i />
                Да
              </Button>
              <Button
                block
                variant="danger"
                onClick={this.trigger}
                size="sm"
                className="flaticon-circle"
              >
                <i />
                Нет
              </Button>
            </Tooltip>
          )}
        </Overlay>
      </>
    );
  }
}
