import ProfilePage from "../pages/home/profile/ProfilePage";
import {
  RequestProgressCreate,
  RequestProgressEdit,
  RequestProgressList,
  RequestProgressCard,
  RequestProgressContractEdit,
} from "../pages/home/request/progress/RequestProgressPage";
import { RequestDeletedList } from "../pages/home/request/deleted/RequestDeletedPage";
import {
  RequestPendingCreate,
  RequestPendingEdit,
  RequestPendingList,
  RequestPendingCard,
} from "../pages/home/request/pending/RequestPendingPage";
import { TaxCreate, TaxEdit, TaxList } from "../pages/home/catalog/Tax";
import { CargoCreate, CargoEdit, CargoList } from "../pages/home/catalog/Cargo";
import { UserEdit, UserList, UserCreate } from "../pages/home/catalog/User";
import {
  CustomersList,
  CustomerCreate,
  CustomerEdit,
  CustomerShow,
  CustomerContract,
} from "../pages/home/customers/CustomersPage";
import {
  DriversList,
  DriverCreate,
  DriverEdit,
  DriverShow,
  DriverContract,
} from "../pages/home/drivers/DriversPage";
import {
  CarLoadCreate,
  CarLoadEdit,
  CarLoadList,
} from "../pages/home/catalog/CarLoad";
import {
  CarBodyCreate,
  CarBodyEdit,
  CarBodyList,
} from "../pages/home/catalog/CarBody";
import SystemSettings from "../pages/home/SystemSettings";
import {
  DocumentTemplateCreate,
  DocumentTemplateEdit,
  DocumentTemplateList,
} from "../pages/home/catalog/DocumentTemplates";
import { UserRoleList } from "../pages/home/catalog/UserRole";
import { ContractsList } from "../pages/home/contracts/ContractsPage";
import SelfOrganizationPage from "../pages/home/selforganization/SelfOrganizationPage";
import {
  CargoPackList,
  CargoPackCreate,
  CargoPackEdit,
} from "../pages/home/catalog/CargoPack";
import { InvoicePageList } from "../pages/home/invoice/InvoicePage";
import DocumentRegistryReport from "../pages/home/report/DocumentRegistry";

export default [
  {
    path: "/requests/progress",
    title: "Заявки",
    Component: RequestProgressList,
    isList: TextTrackCue,
  },
  {
    path: "/requests/progress/create",
    title: "Создать заявку",
    Component: RequestProgressCreate,
  },
  {
    path: "/requests/progress/:id(\\d+)",
    title: "Карточка заявки",
    Component: RequestProgressCard,
    history: "request/:id",
  },
  {
    path: "/requests/progress/:id(\\d+)/edit",
    title: "Изменить заявку",
    Component: RequestProgressEdit,
  },
  {
    path: "/requests/progress/:id(\\d+)/contract/:contract_id(\\d+)",
    title: "Изменить документ",
    Component: RequestProgressContractEdit,
  },
  {
    path: "/requests/deleted",
    title: "Удаленные заявки",
    Component: RequestDeletedList,
  },
  {
    path: "/requests/pending",
    title: "Предварительные заказы",
    Component: RequestPendingList,
    isList: true,
  },
  {
    path: "/requests/pending/create",
    title: "Создать заявку",
    Component: RequestPendingCreate,
  },
  {
    path: "/requests/pending/:id(\\d+)",
    title: "Карточка заявки",
    Component: RequestPendingCard,
  },
  {
    path: "/requests/pending/:id(\\d+)/edit",
    title: "Изменить заявку",
    Component: RequestPendingEdit,
  },

  {
    path: "/drivers",
    title: "Перевозчики",
    Component: DriversList,
    isList: true,
  },
  {
    path: "/drivers/create",
    Component: DriverCreate,
    title: "Добавить перевозчика",
  },
  {
    path: "/drivers/:id(\\d+)",
    Component: DriverShow,
    title: "Карточка перевозчика",
  },
  {
    path: "/drivers/:id(\\d+)/edit",
    Component: DriverEdit,
    title: "Изменить организацию",
  },
  {
    path: "/drivers/:id(\\d+)/contract/:contract_id(\\d+)",
    Component: DriverContract,
    title: "Редактор документа",
  },
  {
    path: "/customers/:id(\\d+)",
    Component: CustomerShow,
    title: "Карточка организации",
  },
  {
    path: "/customers/:id(\\d+)/edit",
    Component: CustomerEdit,
    title: "Изменить организацию",
  },
  {
    path: "/customers/:id(\\d+)/contract/:contract_id(\\d+)",
    Component: CustomerContract,
    title: "Редактор документа",
  },
  {
    path: "/customers/create",
    Component: CustomerCreate,
    title: "Добавить организацию",
  },
  {
    path: "/customers",
    title: "Организации",
    Component: CustomersList,
    isList: true,
  },
  {
    path: "/catalog/tax/create",
    Component: TaxCreate,
    title: "Добавить тип НДС",
  },
  {
    path: "/catalog/tax/edit/:id(\\d+)",
    Component: TaxEdit,
    title: "Изменить НДС",
  },
  {
    path: "/catalog/tax",
    Component: TaxList,
    title: "Список НДС",
    isList: true,
  },

  {
    path: "/catalog/car_load_type/create",
    Component: CarLoadCreate,
    title: "Добавить тип загрузки",
  },
  {
    path: "/catalog/car_load_type/edit/:id(\\d+)",
    Component: CarLoadEdit,
    title: "Изменить тип загрузки",
  },
  {
    path: "/catalog/car_load_type",
    Component: CarLoadList,
    title: "Список типов загрузки",
    isList: true,
  },

  {
    path: "/catalog/car_body_type/create",
    Component: CarBodyCreate,
    title: "Добавить тип кузова",
  },
  {
    path: "/catalog/car_body_type/edit/:id(\\d+)",
    Component: CarBodyEdit,
    title: "Изменить тип кузова",
  },
  {
    path: "/catalog/car_body_type",
    Component: CarBodyList,
    title: "Список типов кузовов",
    isList: true,
  },

  {
    path: "/catalog/cargo/create",
    Component: CargoCreate,
    title: "Добавить вид груза",
  },
  {
    path: "/catalog/cargo/edit/:id(\\d+)",
    Component: CargoEdit,
    title: "Изменить вид груза",
  },
  {
    path: "/catalog/cargo",
    Component: CargoList,
    title: "Список видов груза",
    isList: true,
  },

  {
    path: "/catalog/cargo_pack/create",
    Component: CargoPackCreate,
    title: "Добавить вид груза",
  },
  {
    path: "/catalog/cargo_pack/edit/:id(\\d+)",
    Component: CargoPackEdit,
    title: "Изменить вид груза",
  },
  {
    path: "/catalog/cargo_pack",
    Component: CargoPackList,
    title: "Список видов груза",
    isList: true,
  },

  {
    path: "/catalog/user/create",
    Component: UserCreate,
    title: "Добавить пользователя",
  },
  {
    path: "/catalog/user/edit/:id(\\d+)",
    Component: UserEdit,
    title: "Изменить пользователя",
  },
  {
    path: "/catalog/user",
    Component: UserList,
    title: "Сотрудники",
    isList: true,
  },
  {
    path: "/catalog/user_role",
    Component: UserRoleList,
    title: "Настройки системы",
  },
  {
    path: "/catalog/user_role",
    Component: UserList,
    title: "Сотрудники",
    isList: true,
  },
  {
    path: "/profile",
    Component: ProfilePage,
    title: "Профиль",
  },
  {
    path: "/document_template",
    Component: DocumentTemplateList,
    isList: true,
    title: "Шаблоны документов",
  },
  {
    path: "/document_template/create",
    Component: DocumentTemplateCreate,
    title: "Добавить шаблон",
  },
  {
    path: "/document_template/edit/:id(\\d+)",
    Component: DocumentTemplateEdit,
    title: "Изменить шаблон",
  },
  {
    path: "/contract",
    Component: ContractsList,
    title: "Документы",
  },
  {
    path: "/invoice",
    Component: InvoicePageList,
    title: "Счета",
  },
  {
    path: "/organization",
    Component: SelfOrganizationPage,
    title: "Наша организация",
  },
  {
    path: "/system",
    Component: SystemSettings,
    title: "Настройки системы",
  },
  {
    path: "/report/document_registry",
    Component: DocumentRegistryReport,
    title: "Отчёт: Реестр счетов и ТРН",
  },
];
