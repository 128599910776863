import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Form,
  Button,
  FormGroup,
  InputGroup,
  Tabs,
  Tab,
} from "react-bootstrap";
import { connect } from "react-redux";
import dataSearch from "../../store/ducks/data_search.duck";
import InputAutoComplete from "../content/AutoComplete/InputAutoComplete";
import SelectEntity from "../../components/entity/SelectEntity";
import * as service from "../../service/data_search";
import clsx from "clsx";
import popupDuck from "../../store/ducks/popup.duck";
import orgReducers from "../../store/reducer_entity/org";
import { getError } from "../../service/.errors";

const mapDispatchToProps = (dispatch, props) => ({
  fetchInn: (value) => dispatch(dataSearch.actions.searchInn(value)),
  alert: (action) => dispatch(popupDuck.actions.error(action)),
  fields: orgReducers.from(props.fields),
});

class OrgForm extends Component {
  static propTypes = {
    fields: PropTypes.shape({
      inn: PropTypes.string,
      org_type: PropTypes.string,
      name: PropTypes.string,
      short_name: PropTypes.string,
      city: PropTypes.string,
      real_address: PropTypes.string,
      address: PropTypes.string,
      is_address_coincide: PropTypes.bool,
      ogrn: PropTypes.string,
      kpp: PropTypes.string,
      phone1: PropTypes.string,
      phone2: PropTypes.string,
      phone3: PropTypes.string,
      website: PropTypes.string,
      ati_code: PropTypes.string,
      ati_score: PropTypes.any,
      passport: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
  };

  state = {
    atiSync: false,
    atiScore: 0,
    atiPending: false,
  };

  typeOptions = [
    { label: "ИП", value: "ip" },
    { label: "другое (ООО, АО, ОАО и т.д)", value: "ooo" },
  ];

  withInitials(fullname) {
    const nameParts = fullname.split(" ");
    if (nameParts.length === 3) {
      const [surname, name, patronymic] = nameParts;
      return `${surname} ${name.slice(0, 1)}. ${patronymic.slice(0, 1)}.`;
    }
    return "";
  }

  selectOrg({ data: { inn, ogrn, kpp, address, opf, name } }) {
    const org_type =
      this.typeOptions.find(({ label }) => label === opf.short)?.value || "ooo";
    this.props.onChange({
      address: address.value,
      // real_address: address.value,
      // is_address_coincide: true,
      city: address.data.city,
      org_type,
      name: name.short_with_opf,
      short_name: name.short ?? this.withInitials(name.full),
      director_short: this.withInitials(name.full),
      inn,
      ogrn,
      kpp,
    });
  }

  getType(value) {
    return this.typeOptions.find((type) => type.value === value) || {};
  }

  componentDidMount() {
    this.checkAti(true);
  }

  async checkAti(silent = false) {
    const { ati_code } = this.props.fields;
    if (ati_code) {
      this.setState({
        atiPending: true,
        atiSync: false,
        atiScore: 0,
      });
      try {
        const [{ data: firm }] = await Promise.all([
          service.getAtiFirm(ati_code),
          new Promise((r) => setTimeout(r, 1000)),
        ]);
        // console.log(firm)
        this.setState({
          atiScore: firm.score,
          atiSync: true,
        });
        this.props.onChange({
          ati_score: firm.score,
          // ati_code:
        });
      } catch (err) {
        // console.log(silent)
        if (!silent) {
          this.props.alert({
            title: "Ошибка загрузки",
            content: getError(err),
          });
        }
      }
      this.setState({ atiPending: false });
    }
  }

  cancelAti() {
    this.setState({ atiSync: false, atiScore: false });
    this.props.onChange({ ati_score: 0 });
  }

  render() {
    const {
      inn,
      org_type,
      name,
      short_name,
      city,
      real_address,
      director_short,
      director,
      passport,
      basis,
      address,
      is_address_coincide,
      ogrn,
      kpp,
      phone1,
      phone2,
      phone3,
      website,
      email,
      ati_code,
    } = this.props.fields;
    const { onChange, errors } = this.props;
    return (
      <Tabs defaultActiveKey="main">
        <Tab eventKey="main" title="Основные">
          <FormGroup as={Row}>
            <Col sm={{ span: 3 }}>
              <Form.Label>ИНН</Form.Label>
              <InputAutoComplete
                name="inn"
                select={(state) => state.orgs}
                isInvalid={errors.hasOwnProperty("inn")}
                getLabel={(org) => `${org.value} (${org.data.inn})`}
                value={inn}
                fetchResults={this.props.fetchInn.bind(this)}
                onChange={({ target: { value } }) => {
                  this.props.fetchInn(value);
                  onChange({ inn: value });
                }}
                onSelect={(org) => this.selectOrg(org)}
              />
              <Form.Control.Feedback type="invalid">
                {errors["inn"]}
              </Form.Control.Feedback>
            </Col>
            <Col sm={{ span: 6 }}>
              <Form.Label>Название организации</Form.Label>
              <Form.Control
                name="name"
                isInvalid={errors.hasOwnProperty("name")}
                onChange={(ev) =>
                  onChange({ [ev.target.name]: ev.target.value })
                }
                value={name}
              />
              <Form.Control.Feedback type="invalid">
                {errors["name"]}
              </Form.Control.Feedback>
            </Col>
            <Col sm={{ span: 3 }}>
              <Form.Label>Тип</Form.Label>
              <SelectEntity
                static
                errorText={errors["org_type"]}
                isSearchable={false}
                onChange={({ value }) => onChange({ org_type: value })}
                value={this.getType(org_type) || ""}
                name="org_type"
                options={this.typeOptions}
                placeholder="не выбрано"
              />
            </Col>
          </FormGroup>
          <FormGroup as={Row}>
            <Col md="12">
              <Form.Label>ФИО Директора, сокращенно</Form.Label>
              <Form.Control
                name="director_short"
                isInvalid={errors.hasOwnProperty("director_short")}
                onChange={(ev) =>
                  onChange({ [ev.target.name]: ev.target.value })
                }
                value={director_short}
              />
              <Form.Control.Feedback type="invalid">
                {errors["director_short"]}
              </Form.Control.Feedback>
            </Col>
          </FormGroup>
          {org_type === "ip" && (
            <FormGroup as={Row}>
              <Col md="12">
                <Form.Label>
                  Паспортные данные полностью: номер, кем выдан, дата выдачи
                </Form.Label>
                <Form.Control
                  name="passport"
                  isInvalid={errors.hasOwnProperty("passport")}
                  onChange={(ev) =>
                    onChange({ [ev.target.name]: ev.target.value })
                  }
                  value={passport}
                />
                <Form.Control.Feedback type="invalid">
                  {errors["passport"]}
                </Form.Control.Feedback>
              </Col>
            </FormGroup>
          )}
          {org_type === "ooo" && (
            <FormGroup as={Row}>
              <Col md="12">
                <Form.Label>
                  В лице кого заключается договор? (Должность, ФИО в родительном
                  падеже полностью)
                </Form.Label>
                <Form.Control
                  name="director"
                  isInvalid={errors.hasOwnProperty("director")}
                  onChange={(ev) =>
                    onChange({ [ev.target.name]: ev.target.value })
                  }
                  value={director}
                />
                <Form.Control.Feedback type="invalid">
                  {errors["director"]}
                </Form.Control.Feedback>
              </Col>
            </FormGroup>
          )}
          <FormGroup as={Row}>
            <Col md="12">
              <Form.Label>
                На основании чего заключаем договор? (например, "устава",
                "доверенности" и т.д)
              </Form.Label>
              <Form.Control
                name="basis"
                isInvalid={errors.hasOwnProperty("basis")}
                onChange={(ev) =>
                  onChange({ [ev.target.name]: ev.target.value })
                }
                value={basis}
              />
              <Form.Control.Feedback type="invalid">
                {errors["basis"]}
              </Form.Control.Feedback>
            </Col>
          </FormGroup>
          <FormGroup as={Row}>
            <Col sm={{ span: 6 }}>
              <Form.Label>Краткое наименование</Form.Label>
              <Form.Control
                name="short_name"
                isInvalid={errors.hasOwnProperty("short_name")}
                onChange={(ev) =>
                  onChange({ [ev.target.name]: ev.target.value })
                }
                value={short_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors["short_name"]}
              </Form.Control.Feedback>
            </Col>
            <Col sm={{ span: 6 }}>
              <Form.Label>Город</Form.Label>
              <Form.Control
                name="city"
                isInvalid={errors.hasOwnProperty("city")}
                onChange={(ev) =>
                  onChange({ [ev.target.name]: ev.target.value })
                }
                value={city}
              />
              <Form.Control.Feedback type="invalid">
                {errors["city"]}
              </Form.Control.Feedback>
            </Col>
          </FormGroup>
          <FormGroup as={Row}>
            <Col sm={{ span: 12 }}>
              <Form.Label>Юридический адрес</Form.Label>
              <Form.Control
                name="address"
                isInvalid={errors.hasOwnProperty("address")}
                onChange={(ev) =>
                  onChange({ [ev.target.name]: ev.target.value })
                }
                value={address}
              />
              <Form.Control.Feedback type="invalid">
                {errors["address"]}
              </Form.Control.Feedback>
            </Col>
          </FormGroup>
          <FormGroup as={Row}>
            <Col sm={{ span: 12 }}>
              <Form.Label>
                Фактический адрес&nbsp;&nbsp;
                <Form.Check
                  inline
                  required
                  label="Совпадает с юридическим"
                  id="is_address_coincide"
                  name="is_address_coincide"
                  onChange={(ev) =>
                    onChange({
                      [ev.target.name]: ev.target.checked ? "1" : "0",
                    })
                  }
                  checked={is_address_coincide}
                />
              </Form.Label>
              <Form.Control
                name="real_address"
                isInvalid={errors.hasOwnProperty("real_address")}
                disabled={is_address_coincide}
                onChange={(ev) =>
                  onChange({ [ev.target.name]: ev.target.value })
                }
                value={is_address_coincide ? address : real_address}
              />
              <Form.Control.Feedback type="invalid">
                {errors["real_address"]}
              </Form.Control.Feedback>
            </Col>
          </FormGroup>
          <FormGroup as={Row}>
            <Col sm={{ span: 4 }}>
              <Form.Label>ОГРН</Form.Label>
              <Form.Control
                name="ogrn"
                isInvalid={errors.hasOwnProperty("ogrn")}
                onChange={(ev) =>
                  onChange({ [ev.target.name]: ev.target.value })
                }
                value={ogrn}
              />
              <Form.Control.Feedback type="invalid">
                {errors["ogrn"]}
              </Form.Control.Feedback>
            </Col>
            {org_type === "ooo" && (
              <Col sm={{ span: 4 }}>
                <Form.Label>КПП</Form.Label>
                <Form.Control
                  name="kpp"
                  isInvalid={errors.hasOwnProperty("kpp")}
                  onChange={(ev) =>
                    onChange({ [ev.target.name]: ev.target.value })
                  }
                  value={kpp}
                />
                <Form.Control.Feedback type="invalid">
                  {errors["kpp"]}
                </Form.Control.Feedback>
              </Col>
            )}
            <Col sm={{ span: 4 }}>
              <Form.Label>
                Код в АТИ &nbsp;
                <a
                  href={`https://ati.su/firms/${ati_code}/info`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {this.state.atiSync &&
                    Array.from({ length: 5 }).map((_, i) => (
                      <i
                        key={i}
                        className={clsx("la la-star", {
                          "kt-font-warning": i <= this.state.atiScore,
                          "kt-font-dark": i > this.state.atiScore,
                        })}
                      />
                    ))}
                </a>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name="ati_code"
                  isInvalid={errors.hasOwnProperty("ati_code")}
                  disabled={this.state.atiSync}
                  isValid={this.state.atiSync}
                  onChange={(ev) =>
                    onChange({
                      [ev.target.name]: (ev.target.value || "").trim(),
                    })
                  }
                  value={ati_code}
                />
                <InputGroup.Append>
                  {this.state.atiSync ? (
                    <Button
                      onClick={this.cancelAti.bind(this)}
                      variant="outline-danger"
                    >
                      &times;
                    </Button>
                  ) : (
                    <Button
                      disabled={this.state.atiPending}
                      onClick={() => this.checkAti(false)}
                      variant="info"
                    >
                      {this.state.atiPending ? (
                        <i className="kt-spinner kt-spinner--center kt-spinner--md kt-spinner--light" />
                      ) : (
                        "проверить"
                      )}
                    </Button>
                  )}
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">
                  {errors["ati_code"]}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </FormGroup>
          <div className="kt-separator kt-separator--space-lg kt-separator--border-solid" />
        </Tab>
        <Tab eventKey="contact" title="Контакты">
          <div className="kt-section">
            <h3 className="kt-section__title">Контакты</h3>
            <FormGroup as={Row}>
              <Col sm={{ span: 4 }}>
                <Form.Label>Телефон №1 (для договора)</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text variant="info">
                      <i className="fa fa-phone" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    name="phone1"
                    isInvalid={errors.hasOwnProperty("phone1")}
                    onChange={(ev) =>
                      onChange({ [ev.target.name]: ev.target.value })
                    }
                    value={phone1}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["phone1"]}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
              <Col sm={{ span: 4 }}>
                <Form.Label>Телефон №2</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text variant="info">
                      <i className="fa fa-phone" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    name="phone2"
                    isInvalid={errors.hasOwnProperty("phone2")}
                    onChange={(ev) =>
                      onChange({ [ev.target.name]: ev.target.value })
                    }
                    value={phone2}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["phone2"]}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
              <Col sm={{ span: 4 }}>
                <Form.Label>Телефон №3</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text variant="info">
                      <i className="fa fa-phone" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    name="phone3"
                    isInvalid={errors.hasOwnProperty("phone3")}
                    onChange={(ev) =>
                      onChange({ [ev.target.name]: ev.target.value })
                    }
                    value={phone3}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["phone3"]}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup as={Row}>
              <Col sm={{ span: 6 }}>
                <Form.Label>Email</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text variant="info">
                      <i className="fa flaticon-mail-1" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    name="email"
                    isInvalid={errors.hasOwnProperty("email")}
                    onChange={(ev) =>
                      onChange({ [ev.target.name]: ev.target.value })
                    }
                    value={email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["email"]}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
              <Col sm={{ span: 6 }}>
                <Form.Label>Сайт</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text variant="info">
                      <i className="fa fa-sitemap" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    name="website"
                    isInvalid={errors.hasOwnProperty("website")}
                    onChange={(ev) =>
                      onChange({ [ev.target.name]: ev.target.value })
                    }
                    value={website}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["website"]}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
            </FormGroup>
          </div>
        </Tab>
        {this.props.children}
      </Tabs>
    );
  }
}

export default connect(null, mapDispatchToProps)(OrgForm);
