/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Tabs,
  Tab,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import {
  PortletHeaderToolbar,
  Portlet,
  PortletHeader,
  PortletBody,
} from "../../../../partials/content/Portlet";
import {
  carLoadTypeEntity,
  carBodyTypeEntity,
} from "../../../../store/ducks/entity.duck";
import clsx from "clsx";
import ConfirmButton from "../../../../partials/content/ConfirmButton";
import { carsEntity } from "../../../../store/ducks/entity.duck";
import "./select.scss";
import SelectEntity from "../../../../components/entity/SelectEntity";
import FileList from "../../../../partials/content/FileList/FileList";
import { CarLoadCreate } from "../../catalog/CarLoad";
import { CarBodyCreate } from "../../catalog/CarBody";
import { replaceNullToString } from "../../../../helper/object";

const mapStateToProps = (state, props) => ({
  isPending: state.cars.isPending,
  isSuccess: state.cars.isSuccess,
  errors: state.cars.errors,
  // list: state.cars.rows.filter(
  //   ({ driver_id, type }) => driver_id === props.driverId && type === props.type
  // )
});
const mapDispatchToProps = (dispatch) => ({
  createCar: (fields) => dispatch(carsEntity.actions.create(fields)),
  updateCar: (fields, id) => dispatch(carsEntity.actions.update(fields, id)),
  removeCar: (id) => dispatch(carsEntity.actions.remove(id)),
});

class CarList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changed = this.changed.bind(this);
  }

  static propTypes = {
    createCar: PropTypes.func.isRequired,
    removeCar: PropTypes.func.isRequired,
    updateCar: PropTypes.func.isRequired,
    // fetchCarList: PropTypes.func.isRequired,
    driverId: PropTypes.string,
    errors: PropTypes.shape(),
    isPending: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    type: PropTypes.string,
    title: PropTypes.string,
    // list: PropTypes.arrayOf(
    //   PropTypes.shape({
    //     load_types: PropTypes.arrayOf(PropTypes.string),
    //     model: PropTypes.string,
    //     gov_number: PropTypes.string.isRequired,
    //     body_type_id: PropTypes.string.isRequired
    //   })
    // )
  };

  static defaultProps = {
    errors: {},
    type: "car",
    title: "Автомобили",
  };

  initialFields = {
    driver_id: this.props.driverId,
    type: this.props.type,
    id: "",
    load_types: [],
    files: this.props.files || [],
    body_type_id: "",
    model: "",
    gov_number: "",
    max_weight: "",
    max_volume: "",
    comment: "",
  };

  state = {
    showModal: false,
    isUploading: false,
    id: null,
    list: [],
    isListPending: true,
    fields: { ...this.initialFields },
  };

  get isCreate() {
    return this.state.id === null;
  }

  async fetchCarList() {
    const { type, driverId } = this.props;
    const { data: list } = await carsEntity.actions.search(
      { "driver.id": this.props.driverId, type },
      { "driver.id": "strict", type: "strict" },
    );

    this.setState({
      list,
      isListPending: false,
    });
  }

  componentDidMount() {
    this.fetchCarList();
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  getCarById(carId) {
    const car = this.state.list.find(({ id }) => id === carId);
    return replaceNullToString(car);
  }

  handleShow(id = null, ev) {
    if (ev) ev.preventDefault();
    this.setState({
      id,
      showModal: true,
      fields: id ? { ...this.getCarById(id) } : { ...this.initialFields },
    });
  }

  async handleSubmit(ev) {
    ev.preventDefault();
    const { fields, id } = this.state;
    fields.driver_id = this.props.driverId;
    fields.type = this.props.type;
    if (this.isCreate) {
      await this.props.createCar(fields);
    } else {
      await this.props.updateCar(fields, id);
    }
    if (this.props.isSuccess) {
      this.fetchCarList();
      this.handleClose();
    }
  }

  changed(key, val) {
    let newFields;
    if (typeof key == "object") {
      const {
        target: { name, value },
      } = key;
      newFields = { [name]: value };
    } else {
      newFields = { [key]: val };
    }
    this.setState({
      fields: { ...this.state.fields, ...newFields },
    });
  }

  whenUploaded(files) {
    this.setState({
      isUploading: false,
      fields: {
        ...this.state.fields,
        files: [...files.map((file) => file.id), ...this.state.fields["files"]],
      },
    });
  }

  isInvalid(key) {
    return this.props.errors.hasOwnProperty(key);
  }

  render() {
    const { fields, isUploading, list, isListPending } = this.state;
    const { isPending, removeCar, errors } = this.props;
    return (
      <>
        <Portlet fluidHeight>
          <PortletHeader
            title={this.props.title}
            toolbar={
              <PortletHeaderToolbar>
                <Button
                  size="sm"
                  onClick={() => this.handleShow()}
                  variant="success"
                >
                  Добавить
                </Button>
              </PortletHeaderToolbar>
            }
          />
          <PortletBody scrollable>
            <div className="kt-widget4">
              {isListPending && (
                <div className="kt-align-center p-5">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
              {list.map(({ id, model, gov_number, body_type }) => (
                <div key={id} className="kt-widget4__item">
                  <div className="kt-widget4__info">
                    <a
                      className="kt-widget4__title"
                      onClick={(ev) => this.handleShow(id, ev)}
                    >
                      {this.props.type === "car"
                        ? `${model} ${body_type && `(${body_type?.title})`}`
                        : body_type?.title}
                      <p className="kt-widget4__text">{gov_number}</p>
                    </a>
                  </div>
                  <ConfirmButton
                    size="sm"
                    action={() => removeCar(id)}
                    variant="label-danger"
                  >
                    удалить
                  </ConfirmButton>
                </div>
              ))}
            </div>
          </PortletBody>
        </Portlet>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Form noValidate onSubmit={this.handleSubmit} action="#">
            <Modal.Header closeButton>
              <Modal.Title>Характеристики</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tabs defaultActiveKey="main" id="org-tabs">
                <Tab eventKey="main" title="Основные данные">
                  <Form.Group as={Row}>
                    {this.props.type === "car" && (
                      <Col md="8">
                        <Form.Label>Модель</Form.Label>
                        <Form.Control
                          name="model"
                          isInvalid={this.isInvalid("model")}
                          onChange={this.changed}
                          value={fields["model"]}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors["model"]}
                        </Form.Control.Feedback>
                      </Col>
                    )}
                    {this.props.type !== "car" && (
                      <Col md="8">
                        <Form.Label>Тип кузова</Form.Label>
                        <SelectEntity
                          creatable
                          CreateChild={CarBodyCreate}
                          search={(val) =>
                            carBodyTypeEntity.actions.search({ title: val })
                          }
                          noOptionsMessage="Типов не найдено"
                          errorText={errors["body_type_id"]}
                          value={fields["body_type_id"]}
                          isInvalid={this.isInvalid("body_type_id")}
                          getOptionLabel={(body) => body.title}
                          getOptionValue={(body) => body.id}
                          onChange={(body) =>
                            this.changed("body_type_id", body.id)
                          }
                        />
                      </Col>
                    )}
                    <Col md="4">
                      <Form.Label>Гос. номер</Form.Label>
                      <Form.Control
                        name="gov_number"
                        isInvalid={this.isInvalid("gov_number")}
                        onChange={this.changed}
                        value={fields["gov_number"]}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors["gov_number"]}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  {this.props.type === "car" && (
                    <Form.Group as={Row}>
                      <Col md="12">
                        <Form.Label>Тип кузова</Form.Label>
                        <SelectEntity
                          creatable
                          CreateChild={CarBodyCreate}
                          search={(val) =>
                            carBodyTypeEntity.actions.search({ title: val })
                          }
                          noOptionsMessage="Типов не найдено"
                          errorText={errors["body_type_id"]}
                          value={fields["body_type_id"]}
                          isInvalid={this.isInvalid("body_type_id")}
                          getOptionLabel={(body) => body.title}
                          getOptionValue={(body) => body.id}
                          onChange={(body) =>
                            this.changed("body_type_id", body.id)
                          }
                        />
                      </Col>
                    </Form.Group>
                  )}
                  <Form.Group as={Row}>
                    <Col md="12">
                      <Form.Label>Тип загрузки</Form.Label>
                      <SelectEntity
                        creatable
                        CreateChild={CarLoadCreate}
                        search={(val) =>
                          carLoadTypeEntity.actions.search({ title: val })
                        }
                        noOptionsMessage="Типов не найдено"
                        errorText={errors["load_types"]}
                        value={fields["load_types"]}
                        isInvalid={this.isInvalid("load_types")}
                        getOptionLabel={(car) => car.title}
                        getOptionValue={(car) => car.id}
                        multiple
                        onChange={(val) =>
                          this.changed(
                            "load_types",
                            (val || []).map(({ id }) => id),
                          )
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors["load_types"]}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col md="12">
                      <Form.Label>Максимальная загрузка, т.</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="max_weight"
                          isInvalid={this.isInvalid("max_weight")}
                          onChange={this.changed}
                          value={fields["max_weight"]}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors["max_weight"]}
                        </Form.Control.Feedback>
                        <InputGroup.Append>
                          <InputGroup.Text>тонн</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </Col>
                    {/* <Col md="6">
                                            <Form.Label>Макс. объем</Form.Label>
                                            <Form.Control
                                                isInvalid={this.isInvalid("max_volume")}
                                                name="max_volume"
                                                onChange={this.changed}
                                                value={fields["max_volume"]}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors["max_volume"]}
                                            </Form.Control.Feedback>
                                        </Col> */}
                  </Form.Group>
                </Tab>
                <Tab eventKey="files" title="Сканы документов">
                  <FileList
                    defaultValue={fields["files"]}
                    onStartUpload={() => this.setState({ isUploading: true })}
                    onUpload={(files) => this.whenUploaded(files)}
                    onDelete={(file) =>
                      this.changed(
                        "files",
                        fields["files"].filter((id) => id !== file.id),
                      )
                    }
                  />
                </Tab>
              </Tabs>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                отменить
              </Button>
              <Button
                variant="primary"
                onClick={this.handleSubmit}
                disabled={isPending || isUploading}
                className={clsx({
                  "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                    isPending,
                })}
              >
                Сохранить
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CarList);
