import * as historyService from "../../service/history";

const types = {
  Open: "[History] Open",
  Close: "[History] Close",
};

const load = (uri) => async (dispatch) => {
  dispatch({ type: types.Open, isPending: true });
  try {
    const { data: payload } = await historyService.load(uri);
    dispatch({ type: types.Open, isPending: false, payload });
  } catch (error) {
    dispatch({ type: types.Open, isPending: false, error });
  }
};

const close = () => (dispatch) => dispatch({ type: types.Close });

const initialState = {
  isPending: false,
  error: null,
  rows: [],
};

const getIcon = (event) => {
  switch (event) {
    case "edit":
      return "fa fa-edit kt-font-primary";
    case "add":
      return "fa fa-plus kt-font-success";
    case "remove":
      return "fa fa-trash kt-font-danger";
    default:
      return "";
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.Open:
      return {
        isPending: action.isPending,
        rows: (action.payload || []).map((item) => ({
          ...item,
          icon: getIcon(item.event),
        })),
        show: true,
      };
    case types.Close:
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};
export default {
  actions: { load, close },
  reducer,
  types,
};
