import React from "react";
import { apiDate, humanDate } from "../../helper/date";
import { parseISO, isAfter } from "date-fns";
import { replaceNullToString, replaceStringToNull } from "../../helper/object";
import { boolLabel } from "../../helper/label";
import { priceFormatter } from "../../helper/number";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import clsx from "clsx";

const humanReducer = ({
  invoice_date,
  invoice_fact_date,
  act_date,
  payment_delay_date,
  pay_deadline_date,
  is_payed_label,
  amount_formatted,
  type_label,
  requests,
  is_divided,
  requests_with_problem,
  requests_sum,
  payment_deadline,
  upd_date,
  sf_date,
  ...rest
} = {}) => {
  const payed_requests =
    typeof rest.payed_requests === "string"
      ? rest.payed_requests.split(", ")
      : [];

  return {
    ...replaceNullToString(rest),
    is_payed_label: boolLabel(is_payed_label, {
      true: "оплачен",
      false: "не оплачен",
      mid: "частично оплачен",
    }),
    is_payed_label_raw: is_payed_label === "1" ? "оплачен" : "не оплачен",
    amount_formatted_raw: amount_formatted,
    amount_formatted: priceFormatter.format(amount_formatted),
    type_label_raw: type_label === "credit" ? "от перевозчика" : "заказчику",
    type_label: (
      <span
        style={{ display: "inline-block" }}
        className={clsx(
          `kt-badge kt-badge--inline kt-badge--pill kt-badge--rounded`,
          {
            "kt-badge--info kt-font-light": type_label === "debet",
            "kt-badge--warning": type_label === "credit",
          },
        )}
      >
        {type_label === "debet" ? "заказчику" : "от перевозчика"}
      </span>
    ),
    is_divided: is_divided === "1",
    payed_requests,
    requests_numbers:
      typeof requests === "string" ? requests.split(", ") : requests,
    requests:
      typeof requests === "string"
        ? requests.split(", ").map((number, i, arr) => {
            const comma = i === arr.length - 1 ? "" : ", ";
            const value = `${number}${comma}`;
            if ((requests_with_problem || []).includes(number)) {
              return (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="kt-font-danger">
                      !В заявке нет ТРН!
                    </Tooltip>
                  }
                >
                  <span style={{ display: "inline" }}>
                    <i className="la la-warning kt-font-md kt-font-danger" />{" "}
                    {value}
                  </span>
                </OverlayTrigger>
              );
            }
            if (payed_requests.includes(number) && is_payed_label === "2")
              return (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="kt-font-danger">
                      заявка оплачена
                    </Tooltip>
                  }
                >
                  <span style={{ display: "inline" }}>
                    <i className="la la-check kt-font-md kt-font-success" />{" "}
                    {value}
                  </span>
                </OverlayTrigger>
              );
            return value;
          })
        : requests,
    // requests_raw: requests,
    requests_sum_raw: requests_sum,
    requests_sum: requests_sum && (
      <span
        className={clsx({
          "kt-font-danger": requests_sum !== amount_formatted,
        })}
      >
        {priceFormatter.format(requests_sum)}
      </span>
    ),
    invoice_date: invoice_date && parseISO(invoice_date),
    invoice_fact_date: invoice_fact_date && parseISO(invoice_fact_date),
    act_date: act_date && parseISO(act_date),
    payment_delay_date: payment_delay_date && parseISO(payment_delay_date),
    payment_deadline_raw: payment_deadline && parseISO(payment_deadline),
    pay_deadline_date: pay_deadline_date && parseISO(pay_deadline_date),

    upd_date: upd_date && parseISO(upd_date),
    sf_date: sf_date && parseISO(sf_date),

    payment_deadline: (() => {
      if (!payment_deadline) return false;
      const date = parseISO(payment_deadline);
      if (date && isAfter(new Date(), date) && is_payed_label !== "1") {
        return <span className="kt-font-danger">{humanDate(date)}</span>;
      }
      return humanDate(date);
    })(),
  };
};

const apiReducer = ({
  invoice_date,
  invoice_fact_date,
  act_date,
  payment_delay_date,
  is_payed,
  upd_date,
  sf_date,
  ...rest
} = {}) => ({
  ...replaceStringToNull(rest),
  is_payed: is_payed ? "1" : "0",
  invoice_date: invoice_date && apiDate(invoice_date),
  invoice_fact_date: invoice_fact_date && apiDate(invoice_fact_date),
  act_date: act_date && apiDate(act_date),
  upd_date: upd_date && apiDate(upd_date),
  sf_date: sf_date && apiDate(sf_date),
  payment_delay_date: payment_delay_date && apiDate(payment_delay_date),
});

export default { from: humanReducer, to: apiReducer };
