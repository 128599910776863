import driver from "./.driver";
import { stringify } from "../helper/query-stringifier";
import { apiDate } from "../helper/date";

const reduceRegistryQuery = (query) => ({
  customer_id: query.customer_id,
  date_start: apiDate(query.date_start),
  date_end: apiDate(query.date_end),
  acceptor: query.acceptor,
  assigneer: query.assigneer,
  include_trn: query.include_trn,
  number: query.number,
  date: apiDate(query.date),
  check_numbers: query.check_numbers,
});

export const loadDocumentRegistry = (query) =>
  driver.get(
    `report/document_registry?${stringify(reduceRegistryQuery(query))}`,
  );

const downloadDocumentRegistry = (query) =>
  driver.open(
    `/report/document_registry_xlsx?${stringify(reduceRegistryQuery(query))}`,
  );

export default {
  loadDocumentRegistry,
  downloadDocumentRegistry,
};
