/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import objectPath from "object-path";
import { LayoutContextConsumer } from "../LayoutContext";
import * as builder from "../../ducks/builder";
import BreadCrumbs from "./components/BreadCrumbs";
import historyDuck from "../../../app/store/ducks/history.duck";

class SubHeader extends React.Component {
  render() {
    const { subheaderClasses, subheaderContainerClasses } = this.props;
    return (
      <>
        <div
          className={`kt-subheader kt-grid__item ${subheaderClasses}`}
          id="kt_subheader"
        >
          <div className={`kt-container ${subheaderContainerClasses}`}>
            {/*Subheader Main*/}
            <LayoutContextConsumer>
              {({
                subheader: { title, isList, history, breadcrumb, pathname },
              }) => (
                <>
                  <div className="kt-subheader__main">
                    {breadcrumb.length > 0 && (
                      <BreadCrumbs items={breadcrumb} />
                    )}
                    <h3 className="kt-subheader__title">{title}</h3>
                    {isList && (
                      <>
                        <Link
                          to={`${pathname}/create`}
                          className="btn btn-label-primary btn-bold btn-icon-h"
                        >
                          Добавить
                        </Link>
                      </>
                    )}
                  </div>

                  <div className="kt-subheader__toolbar">
                    {history && (
                      <div className="kt-subheader__wrapper">
                        <button
                          type="button"
                          className="btn kt-subheader__btn-primary"
                          id="kt-history__toggler"
                          onClick={() => this.props.loadHistory(history)}
                        >
                          <i className="flaticon2-file" />
                          История изменений
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )}
            </LayoutContextConsumer>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  menuHeaderDisplay: objectPath.get(
    store.builder.layoutConfig,
    "header.menu.self.display",
  ),
  layout: objectPath.get(store.builder.layoutConfig, "subheader.layout"),
  fluid:
    objectPath.get(store.builder.layoutConfig, "footer.self.width") === "fluid",
  clear: objectPath.get(store.builder.layoutConfig, "subheader.clear"),
  isOpen: false,
  subheaderClasses: builder.selectors.getClasses(store, {
    path: "subheader",
    toString: true,
  }),
  subheaderContainerClasses: builder.selectors.getClasses(store, {
    path: "subheader_container",
    toString: true,
  }),
});

const mapDispatchToProps = (dispatch) => ({
  loadHistory: (uri) => dispatch(historyDuck.actions.load(uri)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubHeader),
);
