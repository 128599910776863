export const defaults = {
  rowsPerPage: 10,
  currentPage: 1,
  sort: {},
  columnConf: {
    hidden: [],
    showed: [],
  },
  columnColors: {},
  filter: {},
  textFilter: "",
};

const fromApi = (settings = defaults, columns = []) => ({
  ...defaults,
  ...settings,
  columnConf: {
    hidden: columns.filter((col) => !settings.columns?.includes(col.name)),
    showed:
      settings.columns?.map((id) => {
        const col = columns.find(({ name }) => name === id);
        if (!col && process.env.NODE_ENV !== "production")
          throw Error(
            `Column ${id} not found. Please, checke backend "allowedCols" property`,
          );
        return col;
      }) ?? [],
  },
});

const toApi = ({ columnConf, ...rest } = defaults) => ({
  ...rest,
  columns: columnConf.showed.map((col) => col.name), // send only showed columns
});

export default {
  from: fromApi,
  to: toApi,
};
