/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { format } from "date-fns";
import {
  Button,
  Modal,
  Form,
  InputGroup,
  Row,
  Col,
  ButtonGroup,
  Alert,
  Spinner,
} from "react-bootstrap";
import {
  PortletHeaderToolbar,
  Portlet,
  PortletHeader,
  PortletBody,
} from "../content/Portlet";
import {
  bankEntity,
  taxEntity,
  documentTemplateEntity,
  contractEntity,
} from "../../store/ducks/entity.duck";
import clsx from "clsx";
import ConfirmButton from "../content/ConfirmButton";
import ReactDatePicker from "react-datepicker";
import SelectEntity from "../../components/entity/SelectEntity";
import * as service from "../../service/contract";
import { Redirect, withRouter, Link } from "react-router-dom";
import FileList from "../content/FileList/FileList";
import { fileEntity } from "../../store/ducks/file.duck";
import * as documentSerivce from "../../service/document";
import * as fileService from "../../service/file";
import { TaxCreate } from "../../pages/home/catalog/Tax";

const mapStateToProps = ({ contracts, documents }, props) => ({
  created: contracts.created,
  errors: contracts.errors,
  lastId: contracts.lastId,
  isPending: contracts.isPending || documents.isPending,
  isSuccess: contracts.isSuccess,
  createdDocumentId: documents.isSuccess && documents.lastId,
  list: contracts.rows.filter(({ org_id }) => org_id === props.orgId),
  getContractById: (id) =>
    contracts.rows.find((contract) => contract.id === id),
});

const mapDispatchToProps = {
  listContracts: contractEntity.actions.list,
  createContract: contractEntity.actions.create,
  updateContract: contractEntity.actions.update,
  removeContract: contractEntity.actions.remove,
  clearErrors: contractEntity.actions.clearErrors,
  deleteFile: fileEntity.actions.remove,
};

class ContractList extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitClose = this.handleSubmitClose.bind(this);
    this.generateDocument = this.generateDocument.bind(this);
  }

  static propTypes = {
    errors: PropTypes.shape({}),
    createContract: PropTypes.func.isRequired,
    removeContract: PropTypes.func.isRequired,
    updateContract: PropTypes.func.isRequired,
    listContracts: PropTypes.func.isRequired,
    contractId: PropTypes.string,
    orgId: PropTypes.string,
    type: PropTypes.string,
    filterTemplates: PropTypes.func.isRequired,
    isPending: PropTypes.bool.isRequired,
    list: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    errors: {},
  };

  uploadBtn = React.createRef();

  defaultDeadlineDays = {
    customer: 5,
    driver: 15,
  };

  initialFields = {
    number: "",
    date_start: new Date(),
    template_id: "",
    date_end: "",
    custom_file_id: "",
    document_id: "",
    org_bank_id: "",
    payment_form_id: "",
    payment_condition: "",
    deadline_days: this.defaultDeadlineDays[this.props.type] ?? "",
    deadline_days_type: "workdays",
    delay_price: 2000,
    delay_price_measurement: "day",
  };

  state = {
    showModal: false,
    id: null,
    isLoadingNumber: false,
    fields: { ...this.initialFields },
  };

  closeAfterSave = false;
  genDocAfterSave = false;

  get isCreate() {
    return this.state.id === null;
  }

  handleClose() {
    this.setState({ showModal: false, id: null });
  }

  handleShow(id = null, ev) {
    if (ev) ev.preventDefault();
    this.setState({
      id,
      showModal: true,
      fields: id
        ? { ...this.props.getContractById(id) }
        : { ...this.initialFields },
    });
  }

  async generateNumber() {
    this.setState({ isLoadingNumber: true });
    try {
      const {
        data: { number },
      } = await service.getNumber(this.props.type);
      this.set("number", number);
    } finally {
      this.setState({ isLoadingNumber: false });
    }
  }

  componentDidMount() {
    this.props.listContracts(null, true);
  }

  async handleSubmitClose(ev) {
    this.closeAfterSave = true;
    await this.handleSubmit(ev);
  }

  async handleSubmit(ev) {
    if (ev) ev.preventDefault();
    if (this.genDocAfterSave) {
      this.setState({ isDocumentPending: true });
    }
    if (!this.state.fields.number) {
      await this.generateNumber();
    }
    const fields = {
      ...this.state.fields,
      org_id: this.props.orgId,
    };

    if (this.isCreate) {
      await this.props.createContract(fields);
    } else {
      await this.props.updateContract(fields, this.state.id);
    }
    if (this.props.isSuccess) {
      if (this.isCreate) this.setState({ id: this.props.lastId });
      if (this.closeAfterSave) this.setState({ showModal: false });
      if (this.genDocAfterSave) {
        // TODO: refactor to reducer duck
        try {
          const docResponse = await documentSerivce.createByContract(
            this.state.id,
          );
          this.set("document_id", docResponse.data.id);
          this.props.history.push(this.editLink(this.state.id));
        } finally {
          this.setState({ isDocumentPending: false });
        }
      }
      this.props.listContracts(null, true);
    }
    this.closeAfterSave = false;
    this.genDocAfterSave = false;
    this.setState({ isDocumentPending: false });
  }

  set(key, value) {
    this.setState({
      fields: {
        ...this.state.fields,
        [key]: value,
      },
    });
  }

  async generateDocument() {
    this.genDocAfterSave = true;
    await this.handleSubmit();
    this.genDocAfterSave = false;
  }

  async deleteFile() {
    // await this.props.deleteFile(this.state.fields.custom_file_id)
    this.set("custom_file_id", null);
  }

  editLink(contractId) {
    return `${window.location.pathname.replace(
      /(.*)\/$/,
      "$1",
    )}/contract/${contractId}`;
  }

  render() {
    const { list, isPending, removeContract, errors, filterTemplates } =
      this.props;
    if (!isPending && this.props.created) {
      return <Redirect to={this.editLink(this.props.created.id)} />;
    }
    const { fields, id, isDocumentPending } = this.state;
    return (
      <>
        <Portlet fluidHeight>
          <PortletHeader
            title="Договоры и заявки"
            toolbar={
              <PortletHeaderToolbar>
                <Button
                  size="sm"
                  onClick={() => this.handleShow()}
                  variant="success"
                >
                  Добавить
                </Button>
              </PortletHeaderToolbar>
            }
          />
          <PortletBody scrollable>
            <div className="kt-widget4 kt-align-center">
              {isPending && <Spinner animation="border" variant="primary" />}
              {!isPending &&
                list.map((contract) => (
                  <div key={contract.id} className="kt-widget4__item">
                    <div className="kt-widget4__info">
                      <Button
                        variant="light"
                        onClick={() => this.handleShow(contract.id)}
                        className="kt-widget4__title kt-align-left"
                      >
                        Договор №{contract.number}
                        {contract.date_start &&
                          ` от ${format(contract.date_start, "dd.MM.yyyy")}`}
                        <p className="kt-widget4__text">
                          {contract.payment_form?.name}
                          {/* Срок оплаты {getPaymentDate(contract)} */}
                        </p>
                      </Button>
                    </div>
                    <ConfirmButton
                      size="sm"
                      action={() => removeContract(contract.id)}
                      variant="label-danger"
                    >
                      удалить
                    </ConfirmButton>
                  </div>
                ))}
            </div>
          </PortletBody>
        </Portlet>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Form noValidate onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Договор</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {fields.document_id && (
                <Alert variant="outline-primary">
                  Сгенерирован документ. Данные доступны только для чтения. Для
                  редактирования вы можете удалить документ и сгенерировать
                  новый с новыми данными, либо открыть документ, нажав на кнопку
                  "открыть" внизу.
                </Alert>
              )}
              <Form.Group as={Row}>
                <Col md="7">
                  <Form.Label>Шаблон</Form.Label>
                  <SelectEntity
                    search={async (val) =>
                      filterTemplates(
                        await documentTemplateEntity.actions.search({
                          title: val,
                        }),
                      )
                    }
                    disabled={fields.document_id > 0}
                    noOptionsMessage="Шаблонов не найдено"
                    errorText={errors["template_id"]}
                    value={fields["template_id"]}
                    isInvalid={errors.hasOwnProperty("template_id")}
                    getOptionLabel={({ title }) => title}
                    getOptionValue={(props) => props.id}
                    onChange={(val) => this.set("template_id", val.id)}
                  />
                </Col>
                <Col md="5">
                  <Form.Label>Срок оплаты, в сутках</Form.Label>
                  <InputGroup>
                    <Form.Control
                      disabled={fields.document_id > 0}
                      name="deadline_days"
                      isInvalid={errors.hasOwnProperty("deadline_days")}
                      onChange={({ target: { name, value } }) =>
                        this.set(name, value)
                      }
                      value={fields.deadline_days}
                    />
                    <Form.Control
                      disabled={fields.document_id > 0}
                      as="select"
                      isInvalid={errors.hasOwnProperty("deadline_days_type")}
                      name="deadline_days_type"
                      onChange={({ target: { name, value } }) =>
                        this.set(name, value)
                      }
                      value={fields.deadline_days_type}
                    >
                      <option value="workdays">банковских</option>
                      <option value="weekdays">календарных</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors["deadline_days_type"] || errors["deadline_days"]}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col md="4">
                  <Form.Label>Номер</Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="number"
                      disabled={fields.document_id > 0}
                      placeholder="авто"
                      isInvalid={errors.hasOwnProperty("number")}
                      onChange={(ev) =>
                        this.set(ev.target.name, ev.target.value)
                      }
                      value={fields["number"]}
                    />
                    <InputGroup.Append>
                      <Button
                        variant="info"
                        style={{ zIndex: 0 }}
                        disabled={
                          this.state.isLoadingNumber || fields.document_id > 0
                        }
                        className={clsx("kt-font-light la", {
                          "flaticon-refresh": !this.state.isLoadingNumber,
                          "kt-spinner kt-spinner--md kt-spinner--light pr-2 pl-5":
                            this.state.isLoadingNumber,
                        })}
                        onClick={() => this.generateNumber()}
                      />
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">
                      {errors["number"]}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
                <Col md="4">
                  <Form.Label>Дата</Form.Label>
                  <ReactDatePicker
                    disabled={fields.document_id > 0}
                    className={clsx("form-control block", {
                      "is-invalid": errors.hasOwnProperty("date_start"),
                    })}
                    locale="ru"
                    selected={fields["date_start"]}
                    dateFormat={"dd.MM.yyyy"}
                    onChange={(date) => this.set("date_start", date)}
                  />
                  <Form.Control
                    hidden
                    isInvalid={errors.hasOwnProperty("date_start")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["date_start"]}
                  </Form.Control.Feedback>
                </Col>
                <Col md="4">
                  <Form.Label>Действует до</Form.Label>
                  <ReactDatePicker
                    disabled={fields.document_id > 0}
                    className={clsx("form-control block", {
                      "is-invalid": errors.hasOwnProperty("date_end"),
                    })}
                    locale="ru"
                    selected={fields["date_end"]}
                    dateFormat={"dd.MM.yyyy"}
                    onChange={(date) => this.set("date_end", date)}
                  />
                  <Form.Control
                    hidden
                    isInvalid={errors.hasOwnProperty("date_end")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["date_end"]}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col md="7">
                  <Form.Label>Реквизиты</Form.Label>
                  <SelectEntity
                    disabled={fields.document_id > 0}
                    search={(val) =>
                      bankEntity.actions.search(
                        {
                          "org_bank.name": val,
                          "org_bank.org_id": this.props.orgId,
                        },
                        { "org_bank.org_id": "strict" },
                      )
                    }
                    onChange={(bank) => this.set("org_bank_id", bank.id)}
                    errorText={errors["org_bank_id"]}
                    value={fields["org_bank_id"]}
                    getOptionLabel={(bank) =>
                      `${bank.payment_account} ${bank.name}`
                    }
                    getOptionValue={(bank) => bank.id}
                    noOptionsMessage={"Банков не найдено"}
                  />
                </Col>
                <Col md="5">
                  <Form.Label>Форма оплаты</Form.Label>
                  <SelectEntity
                    creatable
                    CreateChild={TaxCreate}
                    disabled={fields.document_id > 0}
                    search={(val) => taxEntity.actions.search({ name: val })}
                    onChange={(payForm) =>
                      this.set("payment_form_id", payForm.id)
                    }
                    value={fields["payment_form_id"]}
                    errorText={errors["payment_form_id"]}
                    getOptionLabel={(payForm) => payForm.name}
                    getOptionValue={(payForm) => payForm.id}
                    noOptionsMessage={"Типов оплаты не найдено"}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col md="12">
                  <Form.Label>Комментарий</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="payment_condition"
                    isInvalid={errors.hasOwnProperty("payment_condition")}
                    onChange={({ target: { name, value } }) =>
                      this.set(name, value)
                    }
                    value={fields.payment_condition}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["payment_condition"]}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <h3>Документ</h3>
              <hr />
              {!id ? (
                <Alert variant="outline-primary">
                  Для работы с документом, сохраните изменения.
                </Alert>
              ) : (
                <>
                  <FileList
                    hideActions
                    uploadButton={this.uploadBtn}
                    onDelete={() => this.deleteFile()}
                    onUpload={([file]) =>
                      this.set("custom_file_id", file?.id ?? null)
                    }
                    defaultValue={[fields.custom_file_id]}
                    multiple={false}
                  />
                  {isDocumentPending ? (
                    <div
                      style={{
                        height: "80px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  ) : (
                    <>
                      {fields.document_id && <h5>Действия с документом:</h5>}
                      {(fields.document_id || fields.custom_file_id) && (
                        <ButtonGroup style={{ width: "100%" }}>
                          {fields.document_id && (
                            <Link
                              to={this.editLink(fields.id)}
                              className="btn btn-outline-primary"
                            >
                              <i
                                className="flaticon-edit"
                                style={{ display: "block", float: "none" }}
                              />
                              &nbsp;открыть
                            </Link>
                          )}
                          <Button
                            variant="outline-success"
                            onClick={() => {
                              if (fields.document_id)
                                documentSerivce.download(fields.document_id);
                              else fileService.download(fields.custom_file_id);
                            }}
                          >
                            <i
                              className="flaticon-download"
                              style={{ display: "block", float: "none" }}
                            />
                            скачать .docx
                          </Button>
                          {fields.document_id && (
                            <Button
                              variant="outline-primary"
                              onClick={() =>
                                documentSerivce.pdfWithSignature(
                                  fields.document_id,
                                )
                              }
                            >
                              <i
                                className="flaticon-download"
                                style={{ display: "block", float: "none" }}
                              />
                              .pdf с печатью
                            </Button>
                          )}
                          <Button
                            variant="outline-danger"
                            onClick={() => {
                              if (fields.custom_file_id) this.deleteFile();
                              else this.set("document_id", null);
                            }}
                          >
                            <i
                              className="flaticon2-trash"
                              style={{ display: "block", float: "none" }}
                            />
                            удалить
                          </Button>
                        </ButtonGroup>
                      )}
                      {!fields.custom_file_id && !fields.document_id && (
                        <ButtonGroup style={{ width: "100%" }}>
                          <Button
                            ref={this.uploadBtn}
                            variant="outline-primary"
                            style={{
                              width: "50%",
                              display: "flex",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <i className="fa fa-upload" />
                            Загрузить готовый <br />
                            документ
                          </Button>
                          <Button
                            variant="outline-primary"
                            style={{
                              width: "50%",
                              display: "flex",
                              justifyContent: "space-evenly",
                            }}
                            onClick={this.generateDocument}
                          >
                            <i className="fa fa-plus" />
                            Сгенерировать новый <br />
                            документ
                          </Button>
                        </ButtonGroup>
                      )}
                    </>
                  )}
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                disabled={isPending}
                onClick={this.handleClose}
              >
                отменить
              </Button>
              <Button
                variant="primary"
                disabled={isPending}
                onClick={this.handleSubmit}
                className={clsx({
                  "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                    isPending && !this.closeAfterSave,
                })}
              >
                Сохранить
              </Button>
              <Button
                variant="success"
                disabled={isPending}
                onClick={this.handleSubmitClose}
                className={clsx({
                  "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                    isPending && this.closeAfterSave,
                })}
              >
                Сохранить и закрыть
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContractList),
);
