import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ErrorPage404 } from "./ErrorPage404";
import { ErrorPage500 } from "./ErrorPage500";

export default function ErrorsPage() {
  return (
    <Switch>
      <Redirect from="/error" exact to="/error/error-404" />
      <Route path="/error/error-404" component={ErrorPage404} />
      <Route path="/error/error-500" component={ErrorPage500} />
    </Switch>
  );
}
